import { useCallback, useEffect, FC } from "react";

import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Grid, Text } from "theme-ui";

import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { LabelIcon, XIcon } from "src/ui/icons";
import { Modal } from "src/ui/modal";
import { CreatableSelect, Option } from "src/ui/select";

import { createObjectFromOptions } from "./create-object-from-options";
import { createOptionsFromObject } from "./create-options-from-object";

interface ExistingLabelOption {
  key: string | null;
  values: string[];
}

type Props = {
  description: string;
  existingLabelOptions?: ExistingLabelOption[];
  hint?: string;
  isOpen: boolean;
  labels?: Record<string, string>;
  loading: boolean;
  saveLabel?: string;
  title: string;
  onClose: () => void;
  onSave: (labels: Record<string, string>) => Promise<void>;
};

export const EditLabels: FC<Readonly<Props>> = ({
  description,
  existingLabelOptions = [],
  hint,
  isOpen,
  labels: initialLabels,
  loading,
  saveLabel,
  title,
  onClose,
  onSave,
}) => {
  const getInitialValues = useCallback(() => {
    const initialValues = createOptionsFromObject(initialLabels);

    return initialValues.length ? initialValues : [{ key: "", value: "" }];
  }, [initialLabels]);

  const { control, handleSubmit, watch, reset, getValues } = useForm<{ labels: { key: string; value: string }[] }>({
    defaultValues: {
      labels: getInitialValues(),
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "labels",
  });

  const formLabels = watch("labels");
  const validLabels = formLabels.filter(({ key, value }) => key !== "" && value !== "");

  const keyOptions: Array<{ label: string; value: string | null }> = existingLabelOptions
    ?.filter(({ key }) => !formLabels.find((label) => label.key === key))
    ?.map(({ key }) => ({ value: key, label: key! }));

  // Reset form values when modal is closed
  useEffect(() => {
    if (!isOpen) {
      reset({ labels: getInitialValues() });
    }
  }, [getInitialValues, isOpen]);

  const optionsForExistingLabelValues = (key: string | undefined): Option[] => {
    if (!key) {
      return [];
    }
    const existingLabelValues = existingLabelOptions.find((option) => option.key === key)?.values || [];
    return existingLabelValues?.map((value) => ({ value, label: value }));
  };

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button loading={loading} onClick={handleSubmit(() => onSave(createObjectFromOptions(validLabels)))}>
            {saveLabel}
          </Button>
        </>
      }
      header={
        <Row sx={{ alignItems: "center" }}>
          <LabelIcon sx={{ mr: 2 }} />
          <Heading variant="h3">{title}</Heading>
        </Row>
      }
      isOpen={isOpen}
      sx={{ width: "500px" }}
      onClose={onClose}
    >
      <Text>{description}</Text>
      <Text sx={{ color: "base.5", fontSize: 0 }}>{hint}</Text>
      <Grid gap={4} sx={{ mt: 8 }}>
        {fields.map((field, index) => (
          <Row key={field.id} sx={{ alignItems: "center" }}>
            <Controller
              control={control}
              name={`labels.${index}.key` as const}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  formatCreateLabel={(string) => {
                    return `Use new key: ${string}`;
                  }}
                  isLoading={loading}
                  noOptionsMessage={() => "Type to create a new key"}
                  options={keyOptions}
                  placeholder="Enter a key"
                  onChange={(option) => {
                    field.onChange(option.value);
                  }}
                />
              )}
            />
            <Text sx={{ mx: 2, color: "base.4" }}>=</Text>
            <Controller
              control={control}
              name={`labels.${index}.value` as const}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  formatCreateLabel={(string) => {
                    return `Use new value: ${string}`;
                  }}
                  isLoading={loading}
                  noOptionsMessage={() => "Type to create a new value"}
                  options={optionsForExistingLabelValues(getValues().labels[index]?.key)}
                  placeholder="Enter a value"
                  onChange={(option) => {
                    field.onChange(option.value);
                  }}
                />
              )}
            />
            <Button sx={{ ml: 2 }} variant="plain" onClick={() => remove(index)}>
              <XIcon color="base.4" size={14} />
            </Button>
          </Row>
        ))}
        <Button variant="secondary" onClick={() => append({ key: "", value: "" }, { shouldFocus: false })}>
          Add label
        </Button>
      </Grid>
    </Modal>
  );
};
