import { ReactElement, useEffect } from "react";

import * as Yup from "yup";

import { Field } from "src/ui/field";
import { RadioGroup } from "src/ui/radio";

import { CustomQuery, CustomQueryViewProps, CustomQueryFormProps } from "../custom-query";
import * as cohorts from "./cohorts";

function QueryForm({ source, query, onChange, error, setError }: Readonly<CustomQueryFormProps>): ReactElement<any, any> {
  const renderForm = (type: string) => {
    switch (type) {
      case "cohort":
        return <cohorts.QueryForm error={error} query={query} setError={setError} source={source} onChange={onChange} />;
      default:
        return `Type ${type} not valid for Mixpanel source`;
    }
  };

  useEffect(() => {
    if (!query?.type) {
      onChange({ type: "cohort" });
    }
  }, [query]);

  return (
    <>
      <Field description="Select the Mixpanel's data source." label="What do you want to query?" size="large">
        <RadioGroup
          options={[{ label: "Users from a cohort", value: "cohort", description: "Users defined in a Mixpanel Cohort" }]}
          value={query?.type || "cohort"}
          onChange={(type) => onChange({ type })}
        />
      </Field>

      {query?.type && renderForm(query.type)}
    </>
  );
}

function QueryView(props: Readonly<CustomQueryViewProps>): ReactElement<any, any> | null {
  switch (props.query.type) {
    case "cohort":
      return <cohorts.QueryView {...props} />;
    default:
      props.setError(new Error(`Type ${props.query.type} not valid for Mixpanel source`));
      return null;
  }
}

export default {
  QueryForm,
  QueryView,
  querySchema: Yup.lazy<CustomQuery | undefined>((query) => {
    switch (query?.["type"]) {
      case "cohort":
        return cohorts.querySchema;
      default:
        return Yup.object().shape({ type: Yup.string().required() });
    }
  }),
};
