import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import { Button, Heading, Row, SearchInput, useToast } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import pluralize from "pluralize";
import { Text } from "theme-ui";

import audiencePlaceholder from "src/assets/placeholders/audience.svg";
import searchPlaceholder from "src/assets/placeholders/search.svg";
import { AudiencesDemo } from "src/components/audiences/audiences-demo";
import {
  createdByFilterConfig,
  Filters,
  labelFilterConfig,
  syncStatusFilterConfig,
  useFilter,
} from "src/components/folders/filters";
import { Folders } from "src/components/folders/folder-list";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolderState } from "src/components/folders/use-folder-state";
import { EditLabels } from "src/components/labels/edit-labels";
import { LabelsCell } from "src/components/labels/labels-cell";
import { Page } from "src/components/layout";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { PageAlert } from "src/components/page-alert";
import { Permission } from "src/components/permission";
import { PermissionedLinkButton } from "src/components/permissioned-button";
import { SyncsCell } from "src/components/syncs/syncs-cell";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  AudiencesQuery,
  MinimalAudiencesQuery,
  ResourcePermissionGrant,
  SegmentsBoolExp,
  SegmentsOrderBy,
  SyncsBoolExp,
  useAddLabelsToAudiencesMutation,
  useAudienceFiltersQuery,
  useAudiencesQuery,
  useDeleteModelsMutation,
  useMinimalAudiencesQuery,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { Menu, MenuOption } from "src/ui/menu";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { Placeholder } from "src/ui/table/placeholder";
import { useRowSelect } from "src/ui/table/use-row-select";
import { TextWithTooltip } from "src/ui/text";
import { useDestinations } from "src/utils/destinations";
import { useIncrementalQuery } from "src/utils/incremental-query";
import { useNavigate } from "src/utils/navigate";
import { abbreviateNumber } from "src/utils/numbers";
import { openUrl } from "src/utils/urls";

import { useLabels } from "../../components/labels/use-labels";

enum SortKeys {
  Name = "name",
  NumSyncs = "syncs_aggregate.count",
  UpdatedAt = "updated_at",
}

export const Audiences: FC = () => {
  const { appPriorityLists } = useFlags();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [search, setSearch] = useQueryState("search");

  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const [addingLabels, setAddingLabels] = useState(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [loading, setLoading] = useState(true);
  const { selectedFolder, setSelectedFolder, setMovingToFolder, movingToFolder, header, nestedFolders, refetchFolders } =
    useFolderState({
      search,
      resourceType: "models",
      folderType: "audiences",
    });

  const { hasPermission: userCanDelete } = useHasPermission([
    { resource: "audience", grants: [ResourcePermissionGrant.Delete] },
  ]);
  const { hasPermission: userCanUpdate } = useHasPermission([
    { resource: "audience", grants: [ResourcePermissionGrant.Update] },
  ]);

  const { limit, offset, orderBy, page, setPage, onSort } = useTableConfig<SegmentsOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SortKeys),
  });

  const { mutateAsync: addLabels, isLoading: loadingAddLabels } = useAddLabelsToAudiencesMutation();
  const { mutateAsync: bulkDelete, isLoading: loadingBulkDelete } = useDeleteModelsMutation();
  const { data: audienceFilterData, isLoading: filtersLoading } = useAudienceFiltersQuery();

  const {
    data: { definitions: destinationDefinitions },
  } = useDestinations();

  const allAudiences = useMemo(
    () =>
      audienceFilterData?.segments?.map((audience) => ({
        ...audience,
        syncs: audience.syncs.map((sync) => ({
          ...sync,
          destination: {
            ...sync.destination,
            definition: sync.destination ? destinationDefinitions?.[sync.destination.type] : undefined,
          },
        })),
      })),
    [destinationDefinitions, audienceFilterData],
  );

  const memodLabelFilterConfig = useMemo(() => labelFilterConfig(allAudiences || []), [allAudiences]);
  const {
    selectedOptions: selectedLabelFilters,
    setSelectedOptions: setSelectedLabelFilters,
    options: labelFilterOptions,
  } = useFilter({
    configResult: memodLabelFilterConfig,
    queryParamName: "labels",
  });

  const memodDestinationStatusFilterConfig = useMemo(
    () => syncStatusFilterConfig(allAudiences?.flatMap((audience) => audience.syncs) || []),
    [allAudiences],
  );
  const {
    selectedOptions: selectedDestinationStatusSyncStatusFilters,
    setSelectedOptions: setSelectedDestinationStatusFilters,
    options: destinationStatusFilterOptions,
  } = useFilter({
    configResult: memodDestinationStatusFilterConfig,
    queryParamName: "status",
  });

  const memodCreatedByFilterConfig = useMemo(() => createdByFilterConfig(allAudiences || []), [allAudiences]);

  const {
    selectedOptions: selectedCreatedByFilters,
    setSelectedOptions: setSelectedCreatedByFilters,
    options: createdByFilterOptions,
  } = useFilter({
    configResult: memodCreatedByFilterConfig,
    queryParamName: "created_by",
  });

  const hasuraFilters = useMemo(() => {
    const labelsFilter: SyncsBoolExp = {
      _or: selectedLabelFilters.map((filter) => {
        const key = filter.id.split(":")[0];
        const value = filter.id.split(":")[1];
        const obj = {};
        obj[key!] = value;
        return {
          tags: { _contains: obj },
        };
      }),
    };

    // Only show audiences with no labels if they haven't modified the labels filter section.
    if (selectedLabelFilters.length === labelFilterOptions.length) {
      labelsFilter._or!.push({
        tags: { _contained_in: {} },
      });
    }

    const hasuraFilters: SegmentsBoolExp = {
      _and: [
        {
          query_type: { _eq: "visual" },
          folder_id: { _eq: selectedFolder?.id },
          _or: [
            {
              created_by: { _in: selectedCreatedByFilters.map((filter) => filter.id) },
            },
            {
              created_by: { _is_null: true },
            },
          ],
          ...(selectedDestinationStatusSyncStatusFilters.length !== destinationStatusFilterOptions.length
            ? {
                destination_instances: {
                  status: {
                    _in: selectedDestinationStatusSyncStatusFilters.map((filter) => filter.id),
                  },
                },
              }
            : {}),
        },
        {
          _or: labelsFilter._or,
        },
      ],
    };

    if (search) {
      hasuraFilters.name = { _ilike: `%${search}%` };
    }

    return hasuraFilters;
  }, [
    search,
    selectedLabelFilters,
    selectedCreatedByFilters,
    selectedDestinationStatusSyncStatusFilters,
    labelFilterOptions,
    selectedFolder,
    destinationStatusFilterOptions,
  ]);

  const fullAudiencesQuery = useAudiencesQuery(
    {
      filters: hasuraFilters,
      offset,
      limit,
      orderBy,
    },
    {
      enabled: true,
      refetchInterval: 3000,
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const incrementalAudiences = useIncrementalQuery<MinimalAudiencesQuery, AudiencesQuery>(
    useMinimalAudiencesQuery(
      {
        offset,
        limit,
        filters: hasuraFilters,
        orderBy,
      },
      {
        keepPreviousData: true,
        notifyOnChangeProps: "tracked",
        enabled: !filtersLoading,
      },
    ),
    fullAudiencesQuery,
  );

  const { labels } = useLabels();

  const audiences = incrementalAudiences?.data?.segments ?? [];
  const audiencesCount = incrementalAudiences?.data?.segments_aggregate?.aggregate?.count ?? 0;

  const actions: MenuOption[] = [];
  if (userCanUpdate) {
    actions.push(
      { label: "Move to folder", onClick: () => setMovingToFolder(true) },
      { label: "Add labels", onClick: () => setAddingLabels(true) },
    );
  }

  if (userCanDelete) {
    actions.push({
      label: "Delete",
      onClick: () => setConfirmingDelete(true),
      sx: { color: "red", ":hover::not(:disabled)": { backgroundColor: "reds.0" } },
    });
  }

  const bulkDeleteAudiences = async () => {
    if (userCanDelete) {
      await bulkDelete({ ids: selectedRows.map(String) });
      onRowSelect([]);
    }
  };

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        sortDirection: orderBy?.name,
        onClick: () => onSort(SortKeys.Name),
        cell: ({ name, labels }) => (
          <Row sx={{ alignItems: "center" }}>
            <TextWithTooltip sx={{ maxWidth: "350px" }} text={name}>
              {name}
            </TextWithTooltip>
            <LabelsCell labels={labels} />
          </Row>
        ),
      },
      {
        name: "Folder",
        cell: ({ folder }) => {
          if (folder) {
            return folder.name;
          }
          return "--";
        },
      },
      {
        name: "Size",
        key: "query_runs.[0].size",
        max: "max-content",
        cell: (size) => (size ? <Text>{abbreviateNumber(size)}</Text> : <Text sx={{ color: "base.4" }}>--</Text>),
      },
      {
        name: "Syncs",
        sortDirection: orderBy?.syncs_aggregate?.count,
        onClick: () => onSort(SortKeys.NumSyncs),
        max: "max-content",
        disabled: ({ syncs }) => Boolean(syncs?.length),
        cell: ({ syncs }) => {
          return <SyncsCell definitions={destinationDefinitions ?? []} syncs={syncs} />;
        },
      },
      {
        ...LastUpdatedColumn,
        sortDirection: orderBy?.updated_at,
        onClick: () => onSort(SortKeys.UpdatedAt),
      },
    ],
    [destinationDefinitions, orderBy],
  );

  const placeholder = useMemo(
    () => ({
      image: searchPlaceholder,
      title: "No audiences found",
      error: "Audiences failed to load, please try again.",
    }),
    [],
  );

  const onRowClick = useCallback(({ id }, event) => openUrl(`/audiences/${id}`, navigate, event), [navigate]);

  const error = Boolean(incrementalAudiences.fullQueryError || incrementalAudiences.minimalQueryError);

  useEffect(() => {
    setPage(0);
  }, [hasuraFilters]);

  useEffect(() => {
    onRowSelect([]);
  }, [page]);

  // Both effects are required for handling loading state when using polling query
  useEffect(() => {
    setLoading(true);
    // Provide all dependencies of the query. Make sure dependencies are properly memoized
  }, [limit, offset, orderBy, hasuraFilters]);
  useEffect(() => {
    if (audiences || error) {
      setLoading(false);
    }
  }, [audiences]);

  return (
    <>
      <PermissionProvider permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Update] }]}>
        <Page
          sidebar={
            <>
              <Row px={5}>
                <SearchInput
                  placeholder="Search all audiences..."
                  value={search ?? ""}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </Row>
              <Folders
                audiencesCount={audienceFilterData?.segments.length || 0}
                audiencesRootName="All audiences"
                nestedFolders={nestedFolders || []}
                refetchFolders={refetchFolders}
                rootFolder="audiences"
                selectedFolder={selectedFolder}
                setRootFolder={() => undefined}
                setSelectedFolder={setSelectedFolder}
                viewType="models"
              />
              <Filters
                filters={[
                  {
                    title: "Sync status",
                    options: destinationStatusFilterOptions,
                    setSelectedOptions: setSelectedDestinationStatusFilters,
                    selectedOptions: selectedDestinationStatusSyncStatusFilters,
                  },
                  {
                    title: "Created by",
                    options: createdByFilterOptions,
                    setSelectedOptions: setSelectedCreatedByFilters,
                    selectedOptions: selectedCreatedByFilters,
                  },
                  {
                    title: "Labels",
                    options: labelFilterOptions,
                    setSelectedOptions: setSelectedLabelFilters,
                    selectedOptions: selectedLabelFilters,
                  },
                ]}
              />
            </>
          }
        >
          <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 4, px: 4, gap: 4 }}>
            <Row overflow="hidden" sx={{ h2: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" } }}>
              <Heading>{header}</Heading>
            </Row>
            <Row flexShrink={0} gap={3}>
              {selectedRows.length > 0 && (
                <Permission permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Update] }]}>
                  <Row align="center" flexShrink={0} gap={2}>
                    <Text as="span" sx={{ color: "base.5" }}>{`${pluralize(
                      "audience",
                      selectedRows.length,
                      true,
                    )} selected`}</Text>

                    <Menu options={actions}>
                      <Button isLoading={loadingBulkDelete}>Select action</Button>
                    </Menu>
                  </Row>
                </Permission>
              )}
              {appPriorityLists && (
                <PermissionedLinkButton
                  href="/audiences/priority-lists"
                  permissions={[{ resource: "audience_schema", grants: [ResourcePermissionGrant.Preview] }]}
                >
                  Priority lists
                </PermissionedLinkButton>
              )}
              <PermissionedLinkButton
                href="/audiences/setup/parent-models"
                icon={Cog6ToothIcon}
                permissions={[{ resource: "audience_schema", grants: [ResourcePermissionGrant.Update] }]}
              >
                Setup
              </PermissionedLinkButton>
              <PermissionedLinkButton
                href="/audiences/new"
                permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Create] }]}
                variant="primary"
                onClick={() => {
                  analytics.track("Add Audience Clicked");
                }}
              >
                Add audience
              </PermissionedLinkButton>
            </Row>
          </Row>

          <Table
            columns={columns}
            data={audiences}
            error={error}
            loading={loading}
            placeholder={placeholder}
            selectedRows={selectedRows}
            onRowClick={onRowClick}
            onSelect={onRowSelect}
          />

          <Pagination
            count={audiencesCount}
            label="audiences"
            page={page}
            rowsPerPage={limit}
            setPage={setPage}
            sx={{ pr: 4 }}
          />
        </Page>
      </PermissionProvider>

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="audience"
        onClose={() => setConfirmingDelete(false)}
        onDelete={bulkDeleteAudiences}
      />
      <EditLabels
        description="You can label audiences that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={addingLabels}
        loading={loadingAddLabels}
        saveLabel={`Apply to ${selectedRows.length} ${pluralize("audience", selectedRows.length)}`}
        title="Add labels"
        onClose={() => setAddingLabels(false)}
        onSave={async (labels) => {
          const labelCount = Object.keys(labels).length;
          await addLabels({ ids: selectedRows.map(String), labels });
          setAddingLabels(false);

          toast({
            id: "save-audience-labels",
            title: `Added ${labelCount} ${pluralize("label", labelCount)} to ${selectedRows.length} ${pluralize(
              "audience",
              selectedRows.length,
            )}`,
            variant: "success",
          });

          onRowSelect([]);
        }}
      />

      {movingToFolder && (
        <MoveFolder
          folder={null}
          folderType="audiences"
          modelIds={selectedRows.map((id) => id.toString())}
          viewType="models"
          onClose={() => {
            setMovingToFolder(false);
            fullAudiencesQuery.refetch();
            onRowSelect([]);
          }}
        />
      )}
    </>
  );
};

const Loader = () => {
  const { resources } = useUser();
  const { data: entitlementsData } = useEntitlements(false);
  const audiencesEnabled = entitlementsData.entitlements.audiences;

  if (!audiencesEnabled) {
    return <AudiencesDemo />;
  }

  if (resources?.audience) {
    return <Audiences />;
  }

  let props;

  if (!resources?.source) {
    props = pageAlertProps.source;
  } else if (!resources?.parentModel) {
    props = pageAlertProps.parentModel;
  }

  return (
    <Page fullWidth outsideTopbar={props ? <PageAlert {...props} /> : null}>
      <Row align="center" justify="space-between" mb={8}>
        <Heading size="xl">Audiences</Heading>
        <PermissionedLinkButton
          href="/audiences/setup/parent-models"
          icon={Cog6ToothIcon}
          permissions={[{ resource: "audience_schema", grants: [ResourcePermissionGrant.Update] }]}
        >
          Setup
        </PermissionedLinkButton>
      </Row>

      <Placeholder
        content={{
          image: audiencePlaceholder,
          title: "No audiences in this workspace",
          body: "An audience is a list of customers defined using a visual segment builder. This feature allows marketers to query the data warehouse and define experiments tailored to their campaigns using a no-code interface that doesn't require SQL knowledge.",
          button: props ? null : (
            <PermissionedLinkButton
              href="/audiences/new"
              permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Create] }]}
              variant="primary"
            >
              Add audience
            </PermissionedLinkButton>
          ),
        }}
      />
    </Page>
  );
};

export default Loader;

const pageAlertProps = {
  source: {
    title: "First, you need to configure a data source",
    description:
      "Hightouch must be connected to least one data source before you can create an audience. Your source can be a data warehouse, spreadsheet, or other data system.",
    button: (
      <PermissionedLinkButton
        href="/sources/new"
        permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Create] }]}
        variant="primary"
      >
        Configure data source
      </PermissionedLinkButton>
    ),
    href: "/sources/new",
  },
  parentModel: {
    title: "First, you need to configure a parent model",
    description:
      "A parent model typically defines users, companies, or anything else that represents a customer. Later, when creating an audience, you'll filter a parent model to include only customers who have certain attributes or have performed specific actions.",
    button: (
      <PermissionedLinkButton
        href="/audiences/setup/parent-models/new"
        permissions={[{ resource: "audience_schema", grants: [ResourcePermissionGrant.Create] }]}
        variant="primary"
      >
        Configure parent model
      </PermissionedLinkButton>
    ),
    href: "/audiences/setup/parent-models/new",
  },
};
