import { FC, useMemo, useState } from "react";

import { Image } from "theme-ui";

import { LabelsCell } from "src/components/labels/labels-cell";
import { ResourceSelect } from "src/components/resource-select";
import { DestinationsOrderBy, DestinationsBoolExp, DestinationQuery } from "src/graphql";
import { Row } from "src/ui/box";
import { TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { TextWithTooltip } from "src/ui/text";
import { useDestinations } from "src/utils/destinations";

enum SortKeys {
  Name = "name",
  Type = "type",
  UpdatedAt = "updated_at",
}

export type Destination = DestinationQuery["destinations_by_pk"];

interface Props {
  onSelect: (Destination) => void;
  allowedDestinations?: string[] | null;
  loading?: boolean;
}

export const DestinationSelect: FC<Readonly<Props>> = ({ allowedDestinations, onSelect, loading }) => {
  const [search, setSearch] = useState("");

  const { onSort, orderBy } = useTableConfig<DestinationsOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SortKeys),
  });

  const hasuraFilters: DestinationsBoolExp = useMemo(() => {
    if (search) {
      return { _or: [{ name: { _ilike: `%${search}%` } }, { type: { _ilike: `%${search}%` } }] };
    }
    return {};
  }, [search]);

  const {
    data: { destinations, definitions },
    loading: loadingDestinations,
    error,
    isRefetching,
  } = useDestinations({
    filters: hasuraFilters,
    destinationsOrderBy: orderBy,
  });

  const filteredDestinations = useMemo(() => {
    if (loading) {
      return undefined;
    }
    if (allowedDestinations) {
      return destinations?.filter(({ type }) => {
        const definition = definitions?.find((definition) => definition.type === type);
        return !definition || allowedDestinations.includes(type);
      });
    }
    return destinations;
  }, [loading, destinations, allowedDestinations]);

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        sortDirection: orderBy?.name,
        onClick: () => onSort(SortKeys.Name),
        cell: ({ name, tags, type }) => {
          const definition = definitions?.find((definition) => definition.type === type);

          return (
            <Row sx={{ alignItems: "center", gap: 4 }}>
              <Image
                alt={definition?.name}
                src={definition?.icon}
                sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", flexShrink: 0 }}
              />
              <TextWithTooltip sx={{ fontWeight: "bold", maxWidth: "32vw", color: "black" }} text={name || definition?.name}>
                {name || definition?.name}
              </TextWithTooltip>
              <LabelsCell labels={tags} />
            </Row>
          );
        },
      },
      {
        name: "Type",
        sortDirection: orderBy?.type,
        onClick: () => onSort(SortKeys.Type),
        max: "300px",
        cell: ({ type }) => {
          const definition = definitions?.find((definition) => definition.type === type);
          return (
            <TextWithTooltip disabled={!definition?.name} text={definition?.name ?? ""}>
              {definition?.name}
            </TextWithTooltip>
          );
        },
      },
      {
        ...LastUpdatedColumn,
        max: "300px",
        sortDirection: orderBy?.updated_at,
        onClick: () => onSort(SortKeys.UpdatedAt),
      },
    ],
    [filteredDestinations, definitions],
  );

  return (
    <ResourceSelect
      columns={columns}
      data={filteredDestinations}
      error={Boolean(error)}
      label="destination"
      loading={loadingDestinations || loading || isRefetching}
      search={search}
      onSearch={setSearch}
      onSelect={(destination) => {
        // TODO: Refactor destinations query to use definition remote schema and clean this up
        onSelect({ destination, definition: definitions?.find(({ type }) => type === destination?.type) });
      }}
    />
  );
};
