import { FC } from "react";

import { Box } from "theme-ui";

import { colors } from "../../../../design/colors";

type ChangeButtonProps = {
  onClick: () => void;
};

export const ChangeButton: FC<ChangeButtonProps> = ({ onClick }) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // Prevent triggering of tile onClick handler in the parent
    event.preventDefault();
    event.stopPropagation();
    onClick();
  };
  return (
    <Box
      className="changeButton"
      role="button"
      sx={{
        fontWeight: "400",
        fontSize: "14px",
        display: "none",
        color: colors.base[7],
        ":hover": {
          color: colors.base[5],
          cursor: "pointer",
        },
      }}
      onClick={handleClick}
    >
      change
    </Box>
  );
};
