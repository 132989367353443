import { ThemeUICSSObject } from "theme-ui";
import { colors } from "./colors";

export const button: ThemeUICSSObject = {
  cursor: "pointer",
  outline: "none !important",
  fontSize: 1,
  fontFamily: "inter",
  fontWeight: 600,
  borderRadius: "6px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: 0,
  flexGrow: 0,
  px: 3,
  whiteSpace: "nowrap",
  userSelect: "none",
  minWidth: "52px",
  height: "36px",
  justifySelf: "flex-start",
};

export const buttons = {
  primary: {
    ...button,
    bg: "forest",
    ":hover:not(:disabled)": {
      bg: "forest.dark",
    },
    ":active": {
      bg: "forest.darker"
    },
    ":disabled": {
      color: "whites.3",
      svg: {
        fill: "whites.3",
    },
      bg: 'forest.light',
      cursor: "not-allowed",
    },
  },
  secondary: {
    ...button,
    bg: "white",
    border: "small",
    borderColor: "gray.400",
    color: "gray.700",
    ":hover:not(:disabled)": {
      bg: "gray.50",
    },
    ":disabled": {
      color: "gray.300",
      cursor: "default",
      svg: {
        fill: "gray.300",
      },
    },
  },
  soft: {
    ...button,
    color: "primaries.9",
    bg: "primaries.0",
    svg: {
      fill: "primaries.9",
    },
    ":hover:not(:disabled)": {
      bg: "primaries.2",
      color: "primary",
      svg: {
        fill: "primary !important",
      },
    },
    ":disabled": {
      color: "dark.0",
      cursor: "default",
    },
  },
  purple: {
    ...button,
    border: "small",
    borderColor: "primaries.3",
    color: "primaries.9",
    bg: "primaries.0",
    ":hover:not(:disabled)": {
      bg: "primaries.3",
    },
    ":disabled": {
      color: "dark.0",
      cursor: "default",
    },
  },
  dark: {
    ...button,
    bg: "space",
    ":hover:not(:disabled)": {
      bg: "black",
    },
    ":disabled": {
      color: "whites.3",
      cursor: "not-allowed",
    },
  },
  white: {
    ...button,
    bg: "white",
    border: "small",
    borderColor: "gray.400",
    color: "gray.900",
    ":hover:not(:disabled)": {
      bg: "gray.100",
    },
    ":disabled": {
      color: "gray.400",
      cursor: "default",
    },
  },
  red: {
    ...button,
    bg: "red",
    color: "white",
    ":hover:not(:disabled)": {
      bg: "darkRed",
    },
    ":disabled": {
      bg: "base.2",
      color: "dark.0",
      cursor: "default",
    },
  },
  redOutline: {
    ...button,
    bg: "transparent",
    border: "small",
    borderColor: "reds.1",
    color: "red",
    ":hover:not(:disabled)": {
      opacity: 0.8,
    },
    ":disabled": {
      color: "dark.0",
      cursor: "default",
    },
  },
  amberOutline: {
    ...button,
    bg: "transparent",
    border: "small",
    borderColor: "ambers.1",
    color: "amber",
    ":hover:not(:disabled)": {
      opacity: 0.8,
    },
    ":disabled": {
      color: "dark.0",
      cursor: "default",
    },
  },
  plain: {
    ...button,
    height: "unset",
    minWidth: "unset",
    bg: "transparent",
    p: 0,
  },
  "soft-border": {
    ...button,
    color: "secondary",
    bg: "primaries.0",
    ":hover:not(:disabled)": {
      bg: "primaries.3",
    },
    ":disabled": {
      color: "dark.0",
      cursor: "default",
    },
    border: "small",
    borderColor: "secondaries.3",
  },
  gradient: {
    ...button,
    color: "secondary",
    background: `linear-gradient(${colors.base[1]!.concat(
      "00",
    )}, ${colors.base[1]!.concat("FF")});`,
    ":hover:not(:disabled)": {
      background: `linear-gradient(${colors.base[1]!.concat(
        "00",
      )}, ${colors.base[2]!.concat("FF")});`,
    },
    ":focus:not(:disabled)": {
      background: `linear-gradient(${colors.base[1]!.concat(
        "00",
      )}, ${colors.base[2]!.concat("FF")});`,
    },
    border: "small",
    borderColor: "secondaries.3",
  },
  icon: {
    ...button,
    fontSize: 1,
    p: 2,
    height: "max-content",
    bg: "white",
    border: "small",
    borderColor: "secondaries.3",
    color: "black",
    ":hover:not(:disabled)": {
      bg: "secondaries.0",
    },
    minWidth: "unset",
  },
  "text-primary": {
    ...button,
    bg: "transparent",
    color: "primary",
    px: 2,
    ":hover:not(:disabled)": {
      bg: "primaries.0",
    },
    ":disabled": {
      color: "gray.300",
      cursor: "default",
      svg: {
        fill: "gray.300",
      },
    },
    svg: {
      fill: "primary",
    },
  },
  "text-secondary": {
    ...button,
    bg: "transparent",
    color: "base.6",
    px: 2,
    ":hover:not(:disabled)": {
      bg: "base.1",
    },
    ":disabled": {
      color: "gray.300",
      cursor: "default",
      svg: {
        fill: "gray.300",
      },
    },
    svg: {
      fill: "base.6",
    },
  },
  gray: {
    ...button,
    bg: "secondaries.0",
    color: "secondaries.10",
    px: 2,
    ":hover:not(:disabled)": {
      bg: "secondaries.1",
    },
    ":disabled": {
      color: "secondaries.5",
      cursor: "default",
      svg: {
        fill: "secondaries.5",
      },
    },
    svg: {
      fill: "secondaries.8",
    },
  },
  indigo: {
    ...button,
    bg: "indigos.1",
    color: "white",
    px: 2,
    ":hover:not(:disabled)": {
      bg: "indigos.2",
    },
    ":disabled": {
      color: "secondaries.5",
      cursor: "default",
      svg: {
        fill: "secondaries.5",
      },
    },
    svg: {
      fill: "white",
    },
  },
};
