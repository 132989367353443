import { FC, ReactNode } from "react";

import { ThemeUIStyleObject, Heading as ThemedHeading } from "theme-ui";

export interface HeadingProps {
  children: ReactNode;
  sx?: ThemeUIStyleObject;
  variant?: "h1" | "h2" | "h3" | "h4";
}

export const Heading: FC<Readonly<HeadingProps>> = ({ children, variant = "h1", sx }) => {
  return (
    <ThemedHeading as={variant} sx={sx} variant={`styles.${variant}`}>
      {children}
    </ThemedHeading>
  );
};
