import { FC, ReactNode } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { Row } from "src/ui/box";

export interface ObjectBadgeProps {
  children: ReactNode;
  sx?: ThemeUIStyleObject;
}

export const ObjectBadge: FC<Readonly<ObjectBadgeProps>> = ({ children, sx = {} }) => (
  <Row
    sx={{
      flexShrink: 0,
      alignItems: "center",
      justifyContent: "center",
      border: "small",
      borderRadius: 2,
      borderColor: "gray.300",
      color: "ocean",
      height: "24px",
      bg: "white",
      px: 2,
      fontSize: 0,
      fontWeight: "semi",
      textTransform: "capitalize",
      ...sx,
    }}
  >
    {children}
  </Row>
);
