import { FC, useState } from "react";

import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Button, Box } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { Text } from "theme-ui";

import { Modal } from "src/ui/modal";

type SaveSplitsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => Promise<void>;
};

export const SaveSplitsModal: FC<SaveSplitsModalProps> = ({ isOpen, onClose, onSave }) => {
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    try {
      setIsSaving(true);
      await onSave();
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      bodySx={{ pb: 0 }}
      footer={
        <>
          <Button size="lg" variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button isDisabled={isSaving} isLoading={isSaving} size="lg" variant="primary" onClick={handleSave}>
            Save and refresh
          </Button>
        </>
      }
      footerSx={{ gridTemplateColumns: "1fr 1fr", border: "none", pt: 0 }}
      headerSx={{ display: "none" }}
      isOpen={isOpen}
      sx={{ width: "400px" }}
      onClose={onClose}
    >
      <>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "Center" }}>
          <Box
            sx={{
              background: "#D1E9FF",
              borderRadius: "full",
              height: "48px",
              width: "48px",
              padding: "15px",
            }}
          >
            <ArrowPathIcon color="#2E90FA" />
          </Box>
          <Text
            sx={{
              fontSize: "18px",
              fontWeight: 600,
              mt: "16px",
              mb: "4px",
            }}
          >
            Refresh all syncs?
          </Text>
          <Text
            sx={{
              color: "gray.700",
              fontWeight: 400,
              mb: "32px",
              px: "8px",
              textAlign: "center",
            }}
          >
            Changing split assignments requires that all syncs are run again.
          </Text>
        </Box>
      </>
    </Modal>
  );
};
