import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const PlusSmallIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon viewBox="-6 -6 36 36" {...props}>
      <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
    </Icon>
  );
};
