import { useState, FC } from "react";

import { DraftSubmissionModal } from "src/components/modals/draft-submission-modal";
import { useUser } from "src/contexts/user-context";
import { PageSpinner } from "src/ui/loading";
import { Wizard } from "src/ui/wizard";
import { useNavigate } from "src/utils/navigate";

import { DraftOperation, ResourceToPermission } from "../../../graphql";
import { useCreateSyncWizard } from "./use-create-sync-wizard";

export const CreateSync: FC = () => {
  const navigate = useNavigate();
  const { workspace } = useUser();
  const [submitDraftModalOpen, setSubmitDraftModalOpen] = useState<boolean>(false);

  const goToSync = (id: string) => {
    navigate(`/syncs/${id}`);
  };

  const { createSync, setStep, step, steps, loading } = useCreateSyncWizard({});

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <>
      <DraftSubmissionModal
        draft={{
          _set: {
            draft: false, // the draft change is to set the column `draft` to false.
          },
        }}
        getResourceId={async () => {
          const model = await createSync();
          return model?.id;
        }}
        open={submitDraftModalOpen}
        operation={DraftOperation.Create}
        resource={ResourceToPermission.Sync}
        onClose={() => setSubmitDraftModalOpen(false)}
        onSubmit={(id) => {
          goToSync(id);
        }}
      />

      <Wizard
        setStep={setStep}
        step={step}
        steps={steps}
        title="New sync"
        onCancel={() => {
          navigate("/syncs");
        }}
        onSubmit={async () => {
          if (workspace?.approvals_required) {
            setSubmitDraftModalOpen(true);
          } else {
            const sync = await createSync();
            if (sync?.id) {
              goToSync(sync?.id);
            }
          }
        }}
      />
    </>
  );
};
