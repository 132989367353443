import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const SyncCircleIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M13.7,2.8c4.1,0.7,7.1,4.1,7.6,8.1l1-1.2c0.4-0.4,0.9-0.5,1.3-0.1c0.4,0.4,0.5,0.9,0.1,1.3L21,14.1
	c-0.4,0.4-0.9,0.5-1.3,0.1l-3.2-2.7c-0.4-0.4-0.5-0.9-0.1-1.3c0.4-0.4,0.9-0.5,1.3-0.1l1.7,1.4c-0.1-2-1.1-3.9-2.6-5.3
	C13.7,3.6,9,4.1,6.3,7.2C5.9,7.7,5.4,7.7,5,7.4C4.6,7,4.5,6.5,4.9,6.1C7.1,3.5,10.4,2.2,13.7,2.8z M4.3,9.8l3.2,2.7
	c0.4,0.4,0.5,0.9,0.1,1.3c-0.4,0.4-0.9,0.5-1.3,0.1l-1.7-1.4c0.1,2,1.1,3.9,2.6,5.3c3.1,2.6,7.9,2.2,10.5-1c0.4-0.4,0.9-0.5,1.3-0.1
	c0.4,0.4,0.5,0.9,0.1,1.3c-3.3,4-9.2,4.5-13.1,1.2c-1.8-1.6-3-3.7-3.2-6.1l-1.1,1.3c-0.4,0.4-0.9,0.5-1.3,0.1
	c-0.4-0.4-0.5-0.9-0.1-1.3L3,9.9C3.3,9.5,3.9,9.5,4.3,9.8z"
      />
    </Icon>
  );
};
