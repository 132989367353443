import React from "react";

import { Box, Button, ButtonGroup, Column, Link, Row, Spinner, StatusIndicator, Text, useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { formatRelative, parseISO } from "date-fns";

import { ScheduleType } from "src/components/schedule/types";
import { useExtensionDependentSyncsQuery } from "src/graphql";
import { Modal } from "src/ui/modal";
import { Table } from "src/ui/table";

interface DependentSyncsModalProps {
  scheduleType: ScheduleType;
  open: boolean;
  onClose: () => void;
  deleteExtension: () => void;
  isDeleting: boolean;
  extensionName: string;
}
/**
 * For displaying a modal to delete an extension that shows any syncs with a *schedule* dependent on it.
 */
export const DependentSyncsModal = ({
  open,
  onClose,
  scheduleType,
  isDeleting,
  deleteExtension,
  extensionName,
}: DependentSyncsModalProps): JSX.Element => {
  const {
    data: dependentSyncs,
    isLoading: loadingDependencies,
    error: dependentSyncError,
  } = useExtensionDependentSyncsQuery({ schedule: { type: scheduleType } }, { select: (data) => data.syncs });

  const { toast } = useToast();

  const syncColumns = [
    {
      name: "sync",
      key: "",
      cell: ({ slug, id }) => <Link href={`/syncs/${id}`}>{slug}</Link>,
    },
    {
      name: "Model",
      key: "segment",
      cell: ({ name, id }: { name: string; id: string }) => <Link href={`/models${id}`}>{name}</Link>,
    },
    {
      name: "Destination",
      key: "destination",
      cell: ({ name, id }) => <Link href={`/destinations/${id}`}>{name}</Link>,
    },
  ];

  let content = (
    <Box mb={4}>
      <Text>You currently have no syncs triggered by {extensionName}.</Text>
    </Box>
  );
  if (dependentSyncError || (dependentSyncs && dependentSyncs.length > 0)) {
    content = (
      <Column>
        <Text fontWeight="semibold">
          Disconnecting {extensionName} will cause the following syncs to no longer be triggered. They can still be triggered
          manually.
        </Text>
        <Box m={4}>
          <Table
            columns={syncColumns}
            data={dependentSyncs}
            error={!!dependentSyncError}
            placeholder={{ error: "Dependent Syncs failed to load, please refresh this page." }}
            sx={{ maxHeight: "500px" }}
          />
        </Box>
      </Column>
    );
  }

  return (
    <Modal
      footer={
        <ButtonGroup>
          <Button isDisabled={isDeleting || loadingDependencies} onClick={onClose}>
            Cancel
          </Button>
          <Button
            isDisabled={isDeleting || loadingDependencies}
            isLoading={isDeleting}
            variant="danger"
            onClick={async () => {
              try {
                await deleteExtension();
                toast({
                  id: "extension-disconnect",
                  title: "Successfully disconnected",
                  message: `${extensionName} has been disconnected from your Hightouch workspace.`,
                  variant: "success",
                });
                onClose();
              } catch (error) {
                Sentry.captureException(error);
                toast({
                  id: "extension-disconnect",
                  title: "Failed to disconnect",
                  message: `${extensionName} could not be disconnected from your Hightouch workspace. Please try again.`,
                  variant: "error",
                });
              }
            }}
          >
            Disconnect
          </Button>
        </ButtonGroup>
      }
      footerSx={{ justifyContent: "right" }}
      isOpen={open}
      sx={{ maxWidth: "700px" }}
      title="Disconnect Extension"
      onClose={onClose}
    >
      {loadingDependencies ? (
        <Spinner />
      ) : (
        <>
          <Row mb={4}>
            <Text>
              Disconnecting {extensionName} from your Hightouch workspace will remove the ability to trigger syncs that depend
              on this extension. You can re-connect {extensionName} at any time.
            </Text>
          </Row>
          {content}
        </>
      )}
    </Modal>
  );
};

export type ExtensionTestStatus = "failed" | "loading" | "success" | "error";

interface ConnectedExtensionProps<Credentials> {
  credentials: Credentials & { created_at: string };
  testStatus: ExtensionTestStatus;
  children: React.ReactNode;
}

export const ConnectedExtension = <T extends object>({ credentials, testStatus, children }: ConnectedExtensionProps<T>) => {
  if (!credentials) {
    return null;
  }
  return (
    <Box
      alignItems="center"
      border="1px"
      borderColor="gray.300"
      borderRadius="md"
      display="flex"
      justifyContent="space-between"
      px={4}
      py={4}
      width="100%"
    >
      <Column gap={1}>{children}</Column>
      <Column>
        <StatusIndicator variant={testStatus === "loading" ? "processing" : testStatus === "failed" ? "error" : "success"}>
          {testStatus === "loading" ? "Testing..." : testStatus === "failed" ? "Connection Failed" : "Connected"}
        </StatusIndicator>
      </Column>

      <Column>
        <Text>Created {formatRelative(parseISO(credentials?.created_at), new Date())}</Text>
      </Column>
    </Box>
  );
};
