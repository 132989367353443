import { FC, useState, useEffect, ReactNode } from "react";

import { useToast } from "@hightouchio/ui";
import { Box, Text, Textarea } from "theme-ui";

import { DestinationDefinitionFragment as DestinationDefinition } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Button } from "src/ui/button";
import { LockIcon, SendMonoIcon, PersonAddIcon } from "src/ui/icons";

export type DestinationAccessCardProps = {
  category: string;
  definition: DestinationDefinition | null;
  onSelect?: (destination: DestinationDefinition | null, hit: any) => void;
};

export const DestinationAccessCard: FC<Readonly<DestinationAccessCardProps>> = ({ category, definition, onSelect }) => {
  const [formVisible, setFormVisible] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    if (definition?.name) {
      setFormVisible(false);
    }
  }, [definition?.name]);

  const setDestinationRequestIntent = () => {
    analytics.track("Destination Catalog Request Clicked", {
      destination_name: definition?.name,
      destination_slug: definition?.type,
      destination_status: definition?.status,
      current_category: category,
    });
    setFormVisible(true);
  };

  const submitDestinationRequest = (event) => {
    event.preventDefault();
    analytics.track("Destination Catalog Request Form Submitted", {
      destination_name: definition?.name,
      destination_slug: definition?.type,
      destination_status: definition?.status,
      current_category: category,
      request_form_text: event.target.elements?.usageRequest?.value,
    });
    // close sidebar and deselect definition
    if (onSelect) {
      onSelect(null, null);
    }

    toast({
      id: "submit-destination-request",
      title: `Thank you for your interest in ${definition?.name}! We will follow up with you shortly.`,
      variant: "success",
    });
  };

  if (definition?.status === "coming_soon") {
    return (
      <ThemedCard>
        <Text sx={{ mb: 5 }}>
          This destination is <strong>coming soon</strong>.
        </Text>
        {!formVisible && (
          <ThemedButton type="button" onClick={setDestinationRequestIntent}>
            <PersonAddIcon color="base.4" size={16} />
            Notify me when it's available
          </ThemedButton>
        )}
        {formVisible && (
          <Box as="form" onSubmit={(event) => submitDestinationRequest(event)}>
            <Textarea
              aria-label="Tell us about your use case. (optional)"
              my={6}
              name="usageRequest"
              placeholder="Tell us about your use case. (optional)"
              rows={6}
              sx={{ borderColor: "base.3" }}
            />
            <ThemedButton type="submit">Join waitlist</ThemedButton>
          </Box>
        )}
      </ThemedCard>
    );
  }

  if (definition?.status === "alpha") {
    return (
      <ThemedCard>
        <Text>
          This destination is in <strong>private preview</strong>.
        </Text>
        <Text sx={{ my: 5 }}>We are working with a small group of users to verify functionality.</Text>
        {!formVisible && (
          <ThemedButton type="button" onClick={setDestinationRequestIntent}>
            <LockIcon color="base.4" size={16} />
            Request early access
          </ThemedButton>
        )}
        {formVisible && (
          <Box as="form" onSubmit={(event) => submitDestinationRequest(event)}>
            <Textarea
              aria-label={`Tell us about your ${definition?.name} use case. Please be specific about what you hope to accomplish.`}
              my={6}
              name="usageRequest"
              placeholder={`Tell us about your ${definition?.name} use case. Please be specific about what you hope to accomplish.`}
              rows={6}
              sx={{ borderColor: "base.3" }}
            />
            <ThemedButton type="submit">
              <SendMonoIcon color="base.4" size={16} />
              Submit request
            </ThemedButton>
          </Box>
        )}
      </ThemedCard>
    );
  }

  return null;
};

const ThemedCard: FC<Readonly<{ children: ReactNode }>> = ({ children }) => (
  <Box
    sx={{
      bg: "base.0",
      border: "small",
      borderColor: "base.3",
      borderRadius: 1,
      color: "base.8",
      p: 5,
    }}
  >
    {children}
  </Box>
);

type ButtonProps = {
  children: ReactNode;
  type?: "button" | "submit";
  onClick?: () => void;
};

const ThemedButton: FC<Readonly<ButtonProps>> = ({ children, ...props }) => (
  <Button sx={{ alignItems: "center", bg: "white", gap: 2, fontSize: 1, width: "100%" }} variant="secondary" {...props}>
    {children}
  </Button>
);
