import { FC } from "react";

import { Image } from "theme-ui";

import { Box, Row } from "src/ui/box";

interface TagProps {
  iconUrl: string;
  label?: string;
  ringColor: string;
}

export const Tag: FC<Readonly<TagProps>> = ({ iconUrl, label, ringColor }) => {
  return (
    <Box sx={{ bg: "gray.300", borderRadius: "36px", my: "2px", height: "24px" }}>
      <Row sx={{ alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "24px",
            width: "24px",
            bg: "white",
            borderRadius: "36px",
            border: "2px solid",
            borderColor: ringColor,
          }}
        >
          <Image src={iconUrl} sx={{ height: "16px", width: "16px" }} />
        </Box>
        <Box sx={{ pl: 1, pr: 2, lineHeight: "20px", fontWeight: 500 }}>{label}</Box>
      </Row>
    </Box>
  );
};
