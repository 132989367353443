import { Grid } from "theme-ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { FromIdField } from "../from-id-field";
import { MappingsField } from "../mappings-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";

export const veroValidationSchema = Yup.object().shape({
  type: Yup.string().required().default("objects"),
  object: Yup.string().required(),
  fromId: Yup.mixed().required(),
  fromEmail: Yup.mixed().when("object", {
    is: "contact",
    then: Yup.mixed().required(),
    otherwise: Yup.mixed().notRequired(),
  }),
  eventName: Yup.string().when("object", {
    is: "event",
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired(),
  }),
  mappings: COMMON_SCHEMAS.mappings,
});

const OBJECTS = [
  { label: "User", value: "user" },
  { label: "Event", value: "event" },
];

const FIELDS = {
  user: [
    { label: "Email", value: "email" },
    { label: "Channels", value: "channels" },
  ],
};

export const VeroForm = () => {
  const { config, setConfig, hightouchColumns, reloadModel, loadingModel, errors } = useDestinationForm();

  const modes = config?.object === "event" ? [{ label: "Insert", value: "insert" }] : [{ label: "Upsert", value: "upsert" }];

  return (
    <>
      <ObjectField options={OBJECTS} onChange={(object) => setConfig({ type: config?.type, object })} />

      {config?.object && (
        <>
          <ModeField options={modes} value={modes[0]?.value} />

          <Section>
            <Grid gap={8}>
              <FromIdField fieldName="Vero User ID" />

              {config.object === "event" && (
                <Field error={errors?.fromEmail} label="Which column contains the Email that the user belongs to?">
                  <Select
                    isError={errors?.fromEmail}
                    isLoading={loadingModel}
                    options={hightouchColumns}
                    placeholder="Select a column..."
                    reload={reloadModel}
                    value={config?.fromEmail}
                    width="340px"
                    onChange={(selected) => {
                      const val = selected.value;
                      setConfig({ ...config, fromEmail: val });
                    }}
                  />
                </Field>
              )}

              {config.object === "event" && (
                <Field error={errors?.eventName} label="What is the Vero event name?">
                  <Input
                    defaultValue={config?.eventName}
                    error={errors?.eventName}
                    placeholder="Enter event name..."
                    sx={{ width: "340px" }}
                    onChange={(value) => {
                      setConfig({
                        ...config,
                        eventName: value,
                      });
                    }}
                  />
                </Field>
              )}
            </Grid>
          </Section>

          {config.object === "user" && (
            <Section>
              <MappingsField options={FIELDS[config?.object]} />
            </Section>
          )}

          <Section>
            <MappingsField isCustom />
          </Section>
        </>
      )}
    </>
  );
};

export default { form: VeroForm, validation: veroValidationSchema };
