import { CSSProperties, Fragment, useState, FC, useEffect, useMemo } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  TagInput,
  ButtonGroup,
  Button,
  IconButton,
  Paragraph,
  ConfirmationDialog,
  Slider,
  Switch,
  useToast,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty, sortBy, uniq } from "lodash";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Grid, Text, ThemeUIStyleObject } from "theme-ui";
import * as Yup from "yup";

import { DefaultPageContainerPadding } from "src/components/layout/page-container";
import { Permission } from "src/components/permission";
import { SaveSplitsModal } from "src/components/splits";
import { usePermission } from "src/contexts/permission-context";
import { ResourcePermissionGrant, useStartSyncRunsForSegmentMutation, useUpdateAudienceSplitsMutation } from "src/graphql";
import { Audience, VisualQueryFilter } from "src/types/visual";
import { Badge } from "src/ui/badge";
import { Box, Row } from "src/ui/box";
import { Field, FieldError } from "src/ui/field";
import { Input } from "src/ui/input";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select/select";
import { disambiguateSyncs } from "src/utils/syncs";

import { Column, AudienceSplit, SplitTestDefinition, NewSplitTestDefinition } from "../../../../backend/lib/query/visual/types";
import { Indices } from "../../../../design";
import { NAV_WIDTHS_PER_BREAKPOINT } from "../layout/nav";
import { constructUpdateSplitsMutationPayload } from "./utils";

type Props = {
  audience: Audience;
  data: VisualQueryFilter["splitTestDefinition"];
  onAddSync: () => void;
  onSave: (data: SplitTestDefinition | undefined) => Promise<void>;
};

export const badgeStyles: CSSProperties = {
  position: "relative",
  right: -6,
};

export const tooltipStyles: ThemeUIStyleObject = {
  bg: "white",
  color: "secondary",
  overflow: "visible",
  boxShadow: "large",
};

export const caretStyles: ThemeUIStyleObject = {
  fontSize: 0,
  lineHeight: 2,
  px: 3,
  ":hover": {
    "::before": {
      content: "''",
      position: "absolute",
      top: "-14px",
      left: "16px",
      width: "10px",
      height: "10px",
      bg: "white",
      transform: "rotate(45deg)",
      zIndex: Indices.Modal,
    },
  },
};

const STICKY_FOOTER_HEIGHT = "96px";

function getTotalPercentage(splitPercentages: number[]): number {
  return splitPercentages.reduce((sum, percentage) => sum + percentage, 0);
}

const validationSchema = Yup.object().shape({
  groupColumnName: Yup.string().required("Column name required"),
  samplingType: Yup.string().required(),
  splits: Yup.array().of(
    Yup.object().shape({
      friendly_name: Yup.string().required("Split group name required"),
      percentage: Yup.number()
        .typeError("Split percentage must be a number")
        .min(1, "Split percentages must be between 1 and 99")
        .max(99, "Split percentages must be between 1 and 99")
        .required("Split group percentage required"),
      destination_instance_ids: Yup.array().of(Yup.number()),
    }),
  ),
  stratificationVariables: Yup.array().of(
    Yup.mixed()
      .transform((variableObj) => (Object.keys(variableObj).length ? variableObj : undefined))
      .required("Stratification variable required"),
  ),
});

const transformToLegacyDataModel = (splitTestDefinition) => ({
  ...splitTestDefinition,
  splits: splitTestDefinition.splits.map((split) => ({
    groupValue: split.friendly_name,
    percentage: split.percentage,
  })),
});

export const Splits: FC<Readonly<Props>> = ({ audience, data: splitTestDefinition, onAddSync, onSave }) => {
  const { toast } = useToast();
  const [cancelConfirmationVisible, setCancelConfirmationVisible] = useState(false);
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [splitsEnabled, setSplitsEnabled] = useState(Boolean(splitTestDefinition));

  const updateAudienceSplits = useUpdateAudienceSplitsMutation();
  const startSyncsRunsForSegment = useStartSyncRunsForSegmentMutation();

  const audienceSplits: AudienceSplit[] = (audience?.splits ?? []).map((split) => ({
    id: split?.id,
    friendly_name: split?.friendly_name,
    column_value: split?.column_value,
    percentage: split?.percentage,
    destination_instance_ids:
      split?.destination_instance_splits.map(({ destination_instance_id }) => destination_instance_id) ?? [],
  }));

  const syncs = disambiguateSyncs(audience?.syncs);

  const syncOptions = useMemo(() => {
    const options = syncs.map((sync) => ({
      label: sync?.name,
      value: sync.id,
      destinationIcon: sync?.destination?.definition.icon,
    }));

    return sortBy(options, "label");
  }, [syncs]);

  const formDefaultValues = {
    groupColumnName: splitTestDefinition?.groupColumnName || "",
    samplingType: splitTestDefinition?.samplingType || "non-stratified",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular type problem with Column[]
    stratificationVariables: splitTestDefinition?.stratificationVariables || [],
    splits: audienceSplits.length
      ? audienceSplits
      : [
          { friendly_name: "", percentage: 50, destination_instance_ids: [] },
          { friendly_name: "", percentage: 50, destination_instance_ids: [] },
        ],
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted, isSubmitSuccessful },
    reset,
    setValue,
    getValues,
    watch,
  } = useForm<NewSplitTestDefinition>({
    resolver: yupResolver(validationSchema),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular type problem with Column[]
    defaultValues: formDefaultValues,
  });

  const { fields: stratificationVariableFields, append: stratificationVariableAppend, remove: stratificationVariableRemove } =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - no circular types until react-hook-form v8
    useFieldArray({
      control,
      name: "stratificationVariables",
    });

  const {
    fields: splitGroups,
    append: splitGroupAppend,
    remove: splitGroupRemove,
  } = useFieldArray({
    control,
    name: "splits",
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - no circular types until react-hook-form v8
  const groupColumnName = watch("groupColumnName");
  const samplingType = watch("samplingType");
  const splits = watch("splits");
  const stratificationVariables = watch("stratificationVariables");

  // After a successful submit reset the form to to a pristine state (so isDirty === false).
  // The only time we don't want to keep the values showing in the frontend if the user disabled splits.
  // That way, if the user decides to re-enable splits (without refreshing the page), they won't see the old values.
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: splitsEnabled });
    }
  }, [isSubmitSuccessful, reset, splitsEnabled]);

  // If the user turns on stratified splits, scroll the page down to hint that new form fields are now displayed.
  // Otherwise it's not obvious that there's new content visible due to the sticky footer.
  useEffect(() => {
    if (samplingType === "stratified") {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }
  }, [samplingType]);

  // TODO: This is a manual validation hack. Was unable to get consistent
  // error behavior while throwing this error with react-hook-form + yup validation.
  const totalPercentage = getTotalPercentage(splits?.map((split) => split?.percentage || 0) || []);
  const isValidCurrentPercentage = totalPercentage === 100;

  const splitGroupNames = splits?.map((split: AudienceSplit) => split.friendly_name) || [];
  const isUniqueSplitGroupNames = uniq(splitGroupNames).length === splitGroupNames.length;

  const disableSplits = async () => {
    // Remove existing splits for this audience
    try {
      await updateAudienceSplits.mutateAsync({ addObjects: [], removeIds: audienceSplits.map((split) => String(split.id)) });

      // Remove from old data model
      await onSave?.(undefined);
    } catch (error) {
      toast({
        id: "split-sync-assignment-error",
        title: "There was an error saving your splits configuration.",
        variant: "error",
      });

      throw error;
    }
  };

  const updateSplits = async (data: NewSplitTestDefinition) => {
    const newSplitsPayload = data?.splits ?? [];

    const { addPayload, removePayload } = constructUpdateSplitsMutationPayload({
      audienceId: audience?.id,
      addSplits: newSplitsPayload,
      removeSplits: audienceSplits,
    });

    try {
      // Remove existing splits for this audience and then re-insert them.
      // This is a temporary hack until we upgrade Hasura to v2.10.0
      // which lets us update multiple rows at once.
      await updateAudienceSplits.mutateAsync({ addObjects: addPayload, removeIds: removePayload });

      // Temporarily saving splits to the old model as well
      // in case we have to rollback, so we don't lose any data
      await onSave?.(transformToLegacyDataModel(data));
    } catch (error) {
      toast({
        id: "split-sync-assignment-error",
        title: "There was an error saving your splits configuration.",
        variant: "error",
      });

      throw error;
    }

    try {
      // Splits may have moved across syncs, so we need to refresh the audience's syncs to reflect this
      await startSyncsRunsForSegment.mutateAsync({ segment_id: String(audience?.id), full_resync: false });
    } catch (error) {
      toast({
        id: "split-sync-assignment-error",
        title: "Split assignments were successfully updated, but syncs could not be refreshed.",
        variant: "warning",
      });

      throw error;
    }

    toast({
      id: "split-sync-assignment-success",
      title: "Successfully updated split group assignments.",
      message: "Syncs have been scheduled to re-run.",
      variant: "success",
    });
  };

  const saveSplitsConfig = async (data: NewSplitTestDefinition | undefined) => {
    if (!data || isEmpty(data)) {
      // Empty or null data submission means the user has decided to disable splits for this audience.
      await disableSplits();
    } else {
      await updateSplits(data);
    }
  };

  const handleFormErrors = () => {
    toast({
      id: "save-splits-config",
      title: "Couldn't save splits configuration",
      variant: "error",
    });
  };

  const handleSave = () => {
    if (!isValidCurrentPercentage || !isUniqueSplitGroupNames) {
      handleFormErrors();
    } else {
      setSaveModalVisible(true);
    }
  };

  const resetForm = () => {
    reset(formDefaultValues);
    setSplitsEnabled(Boolean(splitTestDefinition));
  };

  const showRemoveSplitButton = Array.isArray(splits) && splits.length > 2;

  // User can click save if either:
  //  - the form has been changed (but not yet saved)
  //  - the form does not have changes, but the user disables splits
  const isSaveEnabled = isDirty || (!splitsEnabled && audienceSplits.length);

  const updatePermission = usePermission();

  return (
    <Row
      sx={{
        alignItems: "start",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
        mb: STICKY_FOOTER_HEIGHT, // prevent fixed footer from covering any content when scrolled to bottom
      }}
    >
      <Grid gap={6} sx={{ maxWidth: "800px" }}>
        <Box>
          <Row gap={4} sx={{ alignItems: "center" }}>
            <Text sx={{ fontSize: "16px", fontWeight: 500 }}>Enable splits</Text>
            <Switch isChecked={splitsEnabled} isDisabled={updatePermission.unauthorized} onChange={setSplitsEnabled} />
          </Row>
          <Text sx={{ mt: 2 }}>
            With splits, you may divide the audience into multiple defined groups based on specific percentages. These groups
            can be stratified based on multiple variables to ensure that certain subgroups are equally represented in the
            splits.
          </Text>
        </Box>
        {splitsEnabled && (
          <>
            <Section divider>
              <Field
                description="This column will be created to define which split group a particular row belongs to."
                error={errors.groupColumnName && errors.groupColumnName.message}
                label="Column name"
                labelSx={{ fontWeight: 500 }}
                size="large"
              >
                <Input
                  {...register("groupColumnName" as const)}
                  error={Boolean(errors.groupColumnName)}
                  placeholder="Enter a name for your group column (e.g. test_group)..."
                  sx={{ width: "360px" }}
                />
              </Field>
            </Section>
            <Section divider>
              <Field
                description="This defines how your groups should be split in terms of quantity and percentages. For accurate splits, Hightouch recommends a minimum audience size of 100 members."
                label="Split groups"
                labelSx={{ fontWeight: 500 }}
                size="large"
              >
                {Boolean(splits?.length) && (
                  <Grid
                    gap={2}
                    sx={{
                      mb: 4,
                      display: "grid",
                      gridTemplateColumns: `256px minmax(min-content, max-content) minmax(min-content, max-content) ${
                        showRemoveSplitButton ? "20px" : ""
                      }`,
                    }}
                  >
                    {["Groups", "Splits", "Syncs", showRemoveSplitButton && " "].filter(Boolean).map((columnName, index) => (
                      <Text
                        key={`${columnName}-${index}`}
                        sx={{
                          fontSize: "12px",
                          fontWeight: 600,
                          textTransform: "uppercase",
                        }}
                      >
                        {columnName}
                      </Text>
                    ))}

                    {splitGroups.map(({ id }, index) => (
                      <Fragment key={id}>
                        <Box>
                          <Input
                            {...register(`splits.${index}.friendly_name` as const)}
                            error={Boolean(errors.splits?.[index]?.friendly_name)}
                            placeholder="Enter a group value name..."
                            sx={{ height: "36px", mr: 2 }}
                          />
                          {errors.splits?.[index]?.friendly_name && (
                            <FieldError error={errors.splits?.[index]?.friendly_name?.message} />
                          )}
                        </Box>
                        <Box>
                          <Controller
                            control={control}
                            name={`splits.${index}.percentage` as const}
                            render={({ field }) => (
                              <Grid
                                sx={{ gridTemplateColumns: "52px 1fr", columnGap: "8px", alignItems: "center", height: "36px" }}
                              >
                                <Grid
                                  gap="6px"
                                  sx={{
                                    border: "1px solid #E9ECF5",
                                    borderRadius: "4px",
                                    display: "flex",
                                    alignItems: "center",
                                    px: "8px",
                                  }}
                                >
                                  <Input
                                    error={Boolean(errors.splits?.[index]?.percentage)}
                                    min="1"
                                    sx={{ border: "none", px: 0 }}
                                    type="number"
                                    value={String(field.value)}
                                    onChange={(value) => field.onChange(Number(value))}
                                  />
                                  <Text color="gray.500" sx={{ justifySelf: "center" }}>
                                    %
                                  </Text>
                                </Grid>
                                <Box sx={{ width: "128px", mx: "8px" }}>
                                  <Slider
                                    aria-label="Split percentage"
                                    max={99}
                                    min={1}
                                    value={field.value}
                                    onChange={(value) => field.onChange(value)}
                                  />
                                </Box>
                              </Grid>
                            )}
                          />
                          {errors.splits?.[index]?.percentage && (
                            <FieldError error={errors.splits?.[index]?.percentage?.message} />
                          )}
                        </Box>
                        <Box>
                          <Controller
                            control={control}
                            name={`splits.${index}.destination_instance_ids` as const}
                            render={({ field }) => (
                              <TagInput
                                optionAccessory={(option) => ({
                                  type: "image",
                                  url: option.destinationIcon,
                                })}
                                options={syncOptions}
                                placeholder="Select sync..."
                                value={field.value ?? []}
                                width="lg"
                                onChange={(v) => field.onChange(v)}
                                onCreateOption={() => Promise.resolve()} // marked required by TagInput. TODO: make required only if `supportsCreatableOptions` is true.
                              />
                            )}
                          />
                          {errors.splits?.[index]?.destination_instance_ids && (
                            <FieldError error={errors.splits?.[index]?.destination_instance_ids?.message} />
                          )}
                        </Box>
                        {showRemoveSplitButton && (
                          <IconButton
                            aria-label="Remove split group"
                            icon={XMarkIcon}
                            onClick={() => splitGroupRemove(index)}
                          />
                        )}
                      </Fragment>
                    ))}
                    <Box>
                      {isSubmitted && !isValidCurrentPercentage && <FieldError error="Percentages must add up to 100%" />}
                      {isSubmitted && !isUniqueSplitGroupNames && <FieldError error="Split names must be unique" />}
                    </Box>
                    <Box sx={{ gridColumnStart: 1 }}>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          splitGroupAppend({
                            friendly_name: "",
                            percentage: 1,
                            destination_instance_ids: [],
                          });
                        }}
                      >
                        Add split group
                      </Button>
                    </Box>
                    <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
                      <Box sx={{ gridColumnStart: 3 }}>
                        <Button variant="secondary" onClick={onAddSync}>
                          Add sync
                        </Button>
                      </Box>
                    </Permission>
                  </Grid>
                )}
              </Field>
            </Section>
            <Section>
              <Row sx={{ alignItems: "center", mb: 4 }}>
                <Text sx={{ fontSize: "16px", fontWeight: 500 }}>Stratified Sampling (Advanced)</Text>
                <Box style={badgeStyles}>
                  <Badge
                    sx={caretStyles}
                    tooltip="This feature is currently in beta. Any feedback is greatly appreciated."
                    tooltipSx={tooltipStyles}
                    variant="blue"
                  >
                    Beta
                  </Badge>
                </Box>

                <Switch
                  isChecked={samplingType === "stratified"}
                  isDisabled={updatePermission.unauthorized}
                  ml={4}
                  onChange={(enabled) => {
                    setValue("stratificationVariables", []);

                    if (enabled) {
                      stratificationVariableAppend({} as Column);
                    }

                    setValue("samplingType", enabled ? "stratified" : "non-stratified");
                  }}
                />
              </Row>
              <Text>
                Stratified sampling allows users to stratify their groups such that each group will contain an equal allocation
                of values from specified stratification variables.{" "}
                <i>Please note that stratified sampling can only be used with one-time syncs.</i>
              </Text>
              {samplingType === "stratified" && (
                <Field
                  description="The below columns are used to stratify each group. For example, you may want to make sure that your groups are
                  stratified along the age factor."
                  label="Stratification variables"
                  labelSx={{ fontWeight: 500 }}
                  size="large"
                  sx={{ mt: 5 }}
                >
                  {Boolean(stratificationVariables.length) && (
                    <Grid gap={2} sx={{ mb: 4 }}>
                      {stratificationVariableFields.map(({ id }, index) => (
                        <Controller
                          key={id}
                          control={control}
                          name={`stratificationVariables.${index}`}
                          render={({ field }) => (
                            <>
                              <Row>
                                <Select
                                  isError={Boolean(errors.stratificationVariables?.[index])}
                                  options={audience?.parent?.filterable_audience_columns.map((filterableColumn) => ({
                                    label: filterableColumn.name,
                                    value: filterableColumn.column_reference,
                                  }))}
                                  placeholder="Select a column..."
                                  sx={{ maxWidth: "360px", mr: 2 }}
                                  value={field.value}
                                  onChange={(option) => field.onChange(option.value)}
                                />
                                {stratificationVariables.length > 1 && (
                                  <IconButton
                                    aria-label="Remove stratification variable"
                                    icon={XMarkIcon}
                                    onClick={() => stratificationVariableRemove(index)}
                                  />
                                )}
                              </Row>
                              {errors.stratificationVariables?.[index] && (
                                <FieldError error={errors.stratificationVariables?.[index]?.["message"]} />
                              )}
                            </>
                          )}
                        />
                      ))}
                    </Grid>
                  )}
                  <Button
                    variant="secondary"
                    onClick={() => {
                      stratificationVariableAppend({} as Column);
                    }}
                  >
                    Add variable
                  </Button>
                </Field>
              )}
            </Section>
          </>
        )}
      </Grid>
      <Row
        sx={{
          position: "fixed",
          left: NAV_WIDTHS_PER_BREAKPOINT, // put the fixed footer next to the global sidebar nav
          right: 0,
          bottom: 0,
          height: STICKY_FOOTER_HEIGHT,
          pr: DefaultPageContainerPadding.X,
          bg: "white",
          justifyContent: "right",
          borderTop: "2px solid",
          borderColor: "base.3",
          zIndex: Indices.Modal,
        }}
      >
        <Row sx={{ justifyContent: "center", alignItems: "center" }}>
          <ButtonGroup>
            <Button isJustified isDisabled={!isSaveEnabled} size="lg" onClick={() => setCancelConfirmationVisible(true)}>
              Cancel
            </Button>
            <Button
              isJustified
              isDisabled={!isSaveEnabled}
              size="lg"
              variant="primary"
              onClick={handleSubmit(handleSave, handleFormErrors)}
            >
              Save changes
            </Button>
          </ButtonGroup>
        </Row>
      </Row>

      <ConfirmationDialog
        confirmButtonText="Clear changes"
        isOpen={cancelConfirmationVisible}
        title="Are you sure?"
        variant="warning"
        onClose={() => setCancelConfirmationVisible(false)}
        onConfirm={() => {
          resetForm();
          setCancelConfirmationVisible(false);
        }}
      >
        <Paragraph>Your progress will be lost.</Paragraph>
      </ConfirmationDialog>

      <SaveSplitsModal
        isOpen={saveModalVisible}
        onClose={() => setSaveModalVisible(false)}
        onSave={async () => {
          await saveSplitsConfig(getValues());
          setSaveModalVisible(false);
        }}
      />
    </Row>
  );
};
