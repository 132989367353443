import { FC, useState } from "react";

import { Alert, Button, Column, FormField, Paragraph, Radio, RadioGroup, Select, TextInput, useToast } from "@hightouchio/ui";
import { Image } from "theme-ui";

import { useFolders } from "src/components/folders/use-folders";
import { useCreateFolderMutation, useUpdateModelsMutation } from "src/graphql";
import { Modal } from "src/ui/modal";

import DisabledMove from "./disabled-move.svg";

interface Folder {
  id: string;
  name: string;
  parent_id: string | null;
}

interface Props {
  modelIds: string[];
  folder: Folder | null;
  onClose: () => void;
  viewType: "syncs" | "models";
  folderType: "models" | "audiences";
  disabled?: boolean;
}

export const MoveFolder: FC<Readonly<Props>> = ({ onClose, modelIds, folder, viewType, folderType, disabled }) => {
  const { flattenedFolders } = useFolders({
    folderType,
    viewType,
  });
  const { mutateAsync: updateModels } = useUpdateModelsMutation();
  const { mutateAsync: addFolder } = useCreateFolderMutation();
  const { toast } = useToast();
  const [existingFolder, setExistingFolder] = useState(true);
  const [newFolderName, setNewFolderName] = useState("");

  const [selectedFolder, setSelectedFolder] = useState(folder?.id);

  const onClick = async () => {
    if (disabled) {
      onClose();
      return;
    }

    try {
      if (existingFolder) {
        await updateModels({
          ids: modelIds,
          input: {
            folder_id: selectedFolder || null,
          },
        });
      } else {
        const res = await addFolder({
          name: newFolderName,
          parent_id: selectedFolder,
          type: folderType,
        });
        await updateModels({
          ids: modelIds,
          input: {
            folder_id: res.insert_folders_one?.id,
          },
        });
      }
      onClose();
      toast({
        id: "folder-updated-toast",
        title: `Your resource has been moved to the new folder`,
        variant: "success",
      });
    } catch (err) {
      toast({
        id: "folder-failed-toast",
        title: `Failed to move your resource`,
        variant: "error",
      });
    }
  };

  return (
    <Modal
      isOpen
      footer={
        <>
          {!disabled && (
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          )}
          <Button
            isDisabled={!disabled && !selectedFolder && !newFolderName}
            isLoading={false}
            variant="primary"
            onClick={onClick}
          >
            {disabled ? "Got it" : "Move"}
          </Button>
        </>
      }
      sx={{ width: "500px" }}
      title="Move to folder"
      onClose={onClose}
    >
      {disabled ? (
        <Column gap="6">
          <Paragraph>
            Your selection contains a combination of model syncs and audience syncs. Hightouch organizes models and audiences
            into separate parent folders by default. Moving both types of syncs into a single folder is not allowed. Try
            limiting your selection.
          </Paragraph>
          <Image src={DisabledMove} />
        </Column>
      ) : (
        <>
          <Column gap="4">
            <RadioGroup
              value={existingFolder ? "existing" : "new"}
              onChange={(value) => setExistingFolder(value === "existing")}
            >
              <Radio label="Existing folder" value="existing" />
              <Radio label="New folder" value="new" />
            </RadioGroup>
            {!existingFolder && (
              <FormField label="Folder name">
                <TextInput
                  placeholder="Enter a folder name..."
                  value={newFolderName}
                  onChange={(e) => setNewFolderName(e.target.value)}
                />
              </FormField>
            )}
            <FormField label={existingFolder ? "Folder" : "Parent folder"}>
              <Select
                isClearable
                optionLabel={(folder) => folder.path.replaceAll("/", " / ")}
                optionValue={(folder) => folder.id}
                options={flattenedFolders || []}
                placeholder="Select a folder..."
                value={selectedFolder}
                width="lg"
                onChange={(folder) => {
                  setSelectedFolder(folder);
                }}
              />
            </FormField>
          </Column>
          <Alert
            message={`Moving syncs will also move their ${folderType} to the same folder`}
            mt="8"
            title={`Your ${folderType} will also be moved`}
            variant="info"
          />
        </>
      )}
    </Modal>
  );
};
