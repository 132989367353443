import { FC, useEffect, useState } from "react";

import { EditableHeading, Heading } from "@hightouchio/ui";
import { Image, Box } from "theme-ui";

import { usePermission } from "src/contexts/permission-context";
import { Row } from "src/ui/box";

type Props = {
  title: string;
  icon?: string;
  rightToolbar?: JSX.Element[];
  onNameChange?: (name: string) => void;
};

export const Header: FC<Readonly<Props>> = ({ title: defaultTitle, icon, rightToolbar, onNameChange }) => {
  const [title, setTitle] = useState(defaultTitle);

  useEffect(() => {
    setTitle(defaultTitle);
  }, [defaultTitle]);

  const permission = usePermission();

  return (
    <Row sx={{ mb: 8, alignItems: "center" }}>
      <Row sx={{ flexGrow: 1 }}>
        {icon && <Image alt={title} mr={4} src={icon} width="24px" />}
        {onNameChange ? (
          <EditableHeading
            isDisabled={permission?.unauthorized}
            size="lg"
            value={title}
            onChange={setTitle}
            onSubmit={onNameChange}
          />
        ) : (
          <Heading size="lg">{title}</Heading>
        )}
      </Row>
      {rightToolbar && (
        <Row sx={{ alignItems: "center" }}>
          {rightToolbar.map((each, index) => {
            return (
              <Box key={index} ml={4}>
                {each}
              </Box>
            );
          })}
        </Row>
      )}
    </Row>
  );
};
