import { FC, ReactNode } from "react";

import { CheckIcon } from "@heroicons/react/24/outline";
import { Box, Text } from "@hightouchio/ui";

export interface CheckListProps {
  children?: ReactNode;
  "aria-labeledby"?: string;
}

export interface CheckListItemProps {
  children?: ReactNode;
}

export const CheckList: FC<Readonly<CheckListProps>> = ({ children, ...props }) => {
  return (
    <Box as="ul" display="flex" flexDirection="column" gap={3} mx="auto" my={6} {...props}>
      {children}
    </Box>
  );
};

export const CheckListItem: FC<Readonly<CheckListItemProps>> = ({ children }) => {
  return (
    <Box as="li" display="flex" gap={2}>
      <Box
        alignItems="center"
        bg="green.200"
        borderRadius="full"
        boxSize={5}
        display="flex"
        flex="none"
        justifyContent="center"
        position="relative"
        top="1px"
      >
        <Box as={CheckIcon} boxSize={3} color="green.800" />
      </Box>

      <Text>{children}</Text>
    </Box>
  );
};
