import { FC } from "react";

import { Text } from "theme-ui";

import { SelectComponentProps } from "../types";
import { Container } from "./container";

export const Value: FC<SelectComponentProps> = (props) => {
  return (
    <Container {...props}>
      {props.selectedOption?.render ? (
        props.selectedOption.render({})
      ) : (
        <Text sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{props.selectedOption?.label}</Text>
      )}
    </Container>
  );
};
