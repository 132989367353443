import { forwardRef, ReactNode } from "react";

import { Text, Flex, Grid, ThemeUIStyleObject } from "theme-ui";

export type Props = {
  title?: string;
  header?: ReactNode;
  footer?: ReactNode;
  sx?: ThemeUIStyleObject;
  bodySx?: ThemeUIStyleObject;
  children?: ReactNode;
  divider?: boolean;
};

export const Section = forwardRef<HTMLDivElement, Props>(
  ({ title, header, footer, children, sx = {}, bodySx, divider }, ref) => (
    <Flex ref={ref} sx={{ flexDirection: "column", pb: divider ? 10 : 0, borderBottom: divider ? "small" : undefined, ...sx }}>
      {(header || title) && (
        <Flex
          sx={{
            alignItems: "center",
            mb: 6,
          }}
        >
          {header ? header : <Text sx={{ fontSize: 3, fontWeight: "semi" }}>{title}</Text>}
        </Flex>
      )}
      <Flex
        sx={{
          flexDirection: "column",
          flexGrow: 1,
          ...bodySx,
        }}
      >
        {children}
      </Flex>
      {footer && (
        <Grid
          gap={3}
          sx={{
            mt: 6,
            gridAutoFlow: "column",
            gridAutoColumns: "max-content",
            alignItems: "center",
          }}
        >
          {footer}
        </Grid>
      )}
    </Flex>
  ),
);

Section.displayName = "Section";
