import { FC } from "react";

import { Box, CSSProperties } from "theme-ui";

import { OrderBy } from "src/graphql";

import { ArrowDownIcon, ArrowUpIcon } from "../icons";

const defaultSize = 28;

type SortIcon = {
  className?: string;
  sx?: CSSProperties;
  sortDirection?: OrderBy | null;
};

export const SortIcon: FC<SortIcon> = ({ sx, sortDirection, ...props }) => {
  return (
    <Box sx={{ ...backgroundStyles, ...sx }} {...props}>
      <ArrowUpIcon
        size={10}
        sx={{
          mb: "1px",
          opacity: sortDirection ? (sortDirection?.startsWith("desc") ? 0.2 : 1) : 1,
        }}
      />
      <ArrowDownIcon
        size={10}
        sx={{
          ml: "-2px",
          mt: "1px",
          opacity: sortDirection ? (sortDirection?.startsWith("asc") ? 0.2 : 1) : 1,
        }}
      />
    </Box>
  );
};

const backgroundStyles: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: `${defaultSize}px`,
  width: `${defaultSize}px`,
  minHeight: `${defaultSize}px`,
  minWidth: `${defaultSize}px`,
  marginLeft: "12px",
  borderRadius: "50%",
};
