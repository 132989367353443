import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const PlayIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M19.5 11.134C20.1667 11.5189 20.1667 12.4811 19.5 12.866L9 18.9282C8.33333 19.3131 7.5 18.832 7.5 18.0622L7.5 5.93782C7.5 5.16802 8.33333 4.6869 9 5.0718L19.5 11.134Z" />
    </Icon>
  );
};
