import { FC } from "react";

import ReactMarkdown from "react-markdown";
import { ReactMarkdownOptions } from "react-markdown/lib/react-markdown";
import { Text } from "theme-ui";

import { Link } from "src/ui/link";

export const Markdown: FC<Readonly<ReactMarkdownOptions>> = ({ children, ...props }) => {
  return (
    <ReactMarkdown
      components={{
        strong: ({ children }) => (
          <Text
            as="strong"
            sx={{
              display: "inline",
              fontWeight: "bold",
            }}
          >
            {children}
          </Text>
        ),
        a: ({ href, children }) => (
          <Link newTab to={href}>
            {children}
          </Link>
        ),
        code: ({ children }) => (
          <Text
            as="code"
            sx={{
              fontFamily: "code",
              display: "inline",
              backgroundColor: "base.1",
              px: 1,
            }}
          >
            {children}
          </Text>
        ),
      }}
      {...props}
    >
      {children}
    </ReactMarkdown>
  );
};
