import { FC, ReactElement, ReactNode } from "react";

import { Text } from "theme-ui";

import { Column, Row } from "src/ui/box";
import { FieldError } from "src/ui/field";
import { ChevronDownIcon } from "src/ui/icons";
import { Spinner } from "src/ui/loading";
import { NewSelect, SelectProps, PlaceholderComponentProps, SelectComponentProps } from "src/ui/new-select";

export function AttributeSelect<Value>({
  error,
  ...props
}: Omit<SelectProps<Value>, "error"> & { error?: ReactNode }): ReactElement {
  return (
    <Column>
      <NewSelect
        {...props}
        searchable
        components={{ Value, Placeholder }}
        error={Boolean(error)}
        sx={{ maxWidth: "fit-content", ...props.sx }}
      />
      {error && <FieldError error={error} sx={{ fontWeight: "semi", fontSize: 0 }} />}
    </Column>
  );
}

const Placeholder: FC<PlaceholderComponentProps> = ({ children, disabled, error, isOpen, loading }) => (
  <Row
    sx={{
      position: "relative",
      alignItems: "center",
      px: 3,
      border: "small",
      borderColor: error ? "red" : isOpen ? "primary" : "primaries.8",
      borderRadius: 1,
      height: "32px",
      justifyContent: "space-between",
      transition: "100ms border-color",
      bg: disabled ? "base.1" : "primaries.1",
      color: "base.5",
      width: "fit-content",
      whiteSpace: "nowrap",
    }}
  >
    {children}
    <Row sx={{ ml: 2, width: "24px", alignItems: "center", justifyContent: "center" }}>
      {loading ? <Spinner size={12} /> : <ChevronDownIcon color={isOpen ? "primary" : "primaries.5"} />}
    </Row>
  </Row>
);

const Value: FC<SelectComponentProps> = ({ error, selectedGroup, selectedOption, disabled, isOpen }) => (
  <Row
    sx={{
      position: "relative",
      alignItems: "center",
      px: 3,
      border: "small",
      borderColor: error ? "red" : isOpen ? "primary" : undefined,
      borderRadius: 1,
      height: "32px",
      background: disabled ? "base.1" : "linear-gradient(180deg, #FFFFFF 0%, #EFF0FF 100%)",
      color: disabled ? "base.5" : undefined,
      width: "fit-content",
    }}
  >
    {selectedOption?.render ? (
      selectedOption?.render({})
    ) : (
      <Text sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{selectedOption?.label}</Text>
    )}
    {selectedGroup && (
      <Text sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: "base.5", ml: 2 }}>
        {selectedGroup}
      </Text>
    )}
  </Row>
);
