import { FC, ReactNode } from "react";

import { Grid } from "theme-ui";

export const Form: FC<{ children: ReactNode; compact?: boolean; disableBorder?: boolean }> = ({
  children,
  compact = false,
  disableBorder = false,
}) => {
  return (
    <Grid
      gap={compact ? 3 : 12}
      sx={{
        "& > div:not(:last-of-type)": { pb: compact ? 3 : 12, borderBottom: disableBorder ? undefined : "small" },
        width: "100%",
      }}
    >
      {children}
    </Grid>
  );
};
