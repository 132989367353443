import { FC } from "react";

import { Image, Text } from "theme-ui";

import { DestinationAccessCard } from "src/components/destinations/catalog/destination-access-card";
import { DestinationDefinitionFragment as DestinationDefinition } from "src/graphql";
import { Row, Column } from "src/ui/box";
import { CheckList, CheckListItem } from "src/ui/checklist";
import { Heading } from "src/ui/heading";

interface Props {
  category: string;
  definition: DestinationDefinition | null;
  onSelect: (destination: DestinationDefinition | null, hit: any) => void;
}

export const DestinationDetails: FC<Readonly<Props>> = ({ category, definition, onSelect }) => {
  return (
    <>
      <Row sx={{ alignItems: "center", mb: 6, height: "24px" }}>
        <Image src={`${definition?.icon}?h=48`} sx={{ mr: 2, height: "100%", width: "24px", objectFit: "contain" }} />
        <Heading sx={{ fontSize: 3, fontWeight: "bold" }} variant="h3">
          {definition?.name}
        </Heading>
      </Row>
      <Column sx={{ gap: 4 }}>
        {(definition?.status === "alpha" || definition?.status === "coming_soon") && (
          <DestinationAccessCard category={category} definition={definition} onSelect={onSelect} />
        )}
        {definition?.shortPitch && (
          <Column>
            <Text sx={{ fontSize: 0, color: "base.5", fontWeight: "bold", mb: 2 }}>ABOUT</Text>
            <Text sx={{ fontWeight: "semi" }}>{definition?.shortPitch}</Text>
          </Column>
        )}
        {definition?.longPitch && (
          <Column>
            <Text sx={{ fontSize: 0, color: "base.5", fontWeight: "bold", mb: 2 }}>WHY?</Text>
            <Text sx={{ fontWeight: "semi" }}>{definition?.longPitch}</Text>
          </Column>
        )}
        {definition?.features && definition?.features?.length > 0 && (
          <Column>
            <Text sx={{ fontSize: 0, color: "base.5", fontWeight: "bold", mb: -2 }}>USE CASES</Text>
            <CheckList>
              {definition?.features.map((value) => (
                <CheckListItem key={value}>
                  <Text sx={{ fontWeight: "semi", color: "base.7" }}>{value}</Text>
                </CheckListItem>
              ))}
            </CheckList>
          </Column>
        )}
      </Column>
    </>
  );
};
