import { Dispatch, SetStateAction, useMemo, useState } from "react";

import { set } from "lodash";
import { useSearchParams } from "react-router-dom";

import { OrderBy } from "src/graphql";

export type TableConfigOptions = {
  limit?: number;
  page?: number;
  defaultSortKey?: string | null;
  sortOptions?: Array<string>;
};

export type SortState = {
  sortKey: string | null;
  sortDirection: OrderBy;
};

type TableConfigResult<TOrderByType> = {
  page: number;
  limit: number;
  offset: number;
  orderBy: TOrderByType | undefined;
  sortKey: string | null;
  sortDirection: OrderBy;
  setPage: Dispatch<SetStateAction<number>>;
  onSort(sortKey: string): void;
};

/**
 * Determines next sort direction for a column.
 *
 * If no previous sort direction, it will derive the default sort direction.
 *
 * @param sortKey Path that will be sorted on.
 * @param prevSortDirection Previous sort direction
 * @returns {OrderBy} The direction to sort
 */
const getNextSortDirection = (prevSortDirection?: OrderBy | null) => {
  if (!prevSortDirection) {
    return "desc";
  }

  return prevSortDirection?.startsWith("asc") ? "desc" : "asc";
};

export const useTableConfig = <TOrderByType>({
  defaultSortKey = null,
  sortOptions = [],
  ...options
}: TableConfigOptions = {}): TableConfigResult<TOrderByType> => {
  // pagination
  const [page, setPage] = useState<number>(options?.page ?? 0);

  const limit = options?.limit ?? 50;
  const offset = limit * page;

  // sorting
  const [searchParams, setSearchParams] = useSearchParams();

  // check if key is allowed to be sorted on
  const sortKeyFromParams = searchParams.get("sortKey");
  const sortKey = sortKeyFromParams && sortOptions.includes(sortKeyFromParams) ? sortKeyFromParams : defaultSortKey;
  const sortDirection = (searchParams.get("sortDirection") as OrderBy | null) || "desc";

  const orderBy = useMemo(() => {
    const key = sortKey || defaultSortKey;

    if (!key) {
      return undefined;
    }

    return set<TOrderByType>({}, key, sortDirection);
  }, [sortKey, defaultSortKey, sortDirection]);

  const onSort = (newSortKey: string) => {
    setSearchParams({
      sortKey: newSortKey,
      sortDirection: newSortKey !== sortKey ? "desc" : getNextSortDirection(sortDirection),
    });

    setPage(0);
  };

  return { page, limit, offset, orderBy, sortKey, sortDirection, setPage, onSort };
};
