import { FC, MouseEvent, ReactNode } from "react";

import { Badge as ThemedBadge, Text, ThemeUIStyleObject } from "theme-ui";

import { Popout } from "../popout";

export type BadgeProps = {
  children: ReactNode;
  sx?: ThemeUIStyleObject;
  variant?:
    | "primary"
    | "blue"
    | "green"
    | "red"
    | "yellow"
    | "base"
    | "baseCircle"
    | "baseDark"
    | "baseDarker"
    | "indigo"
    | "orange"
    | "purple"
    | "pink";
  tooltip?: string;
  tooltipSx?: ThemeUIStyleObject;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
};

export const Badge: FC<Readonly<BadgeProps>> = ({
  sx = {},
  children,
  variant = "primary",
  tooltip,
  tooltipSx = {},
  onClick,
}) => {
  if (tooltip) {
    return (
      <Popout
        content={<Text sx={{ fontSize: 0, whiteSpace: "pre-line", maxWidth: "280px" }}>{tooltip}</Text>}
        contentSx={{
          bg: "base.0",
          borderRadius: 1,
          maxWidth: "280px",
          p: 2,
          border: "small",
          boxShadow: "xs",
          ...tooltipSx,
        }}
        placement="top-start"
        strategy="fixed"
      >
        {({ open, close }) => (
          <ThemedBadge
            sx={{ cursor: "pointer", ...sx }}
            variant={variant}
            onMouseEnter={() => {
              open();
            }}
            onMouseLeave={() => {
              close();
            }}
          >
            {children}
          </ThemedBadge>
        )}
      </Popout>
    );
  }

  return (
    <ThemedBadge sx={onClick ? { cursor: "pointer", ...sx } : sx} variant={variant} onClick={onClick}>
      {children}
    </ThemedBadge>
  );
};
