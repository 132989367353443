import { FC, ReactNode, useMemo, useCallback } from "react";

import { Checkbox } from "@hightouchio/ui";
import { Box } from "theme-ui";

import { Cell } from "./cells";
import { RowClickHandler } from "./table";

type RowProps = {
  children: ReactNode;
  selected?: boolean;
  disabled?: boolean;
  clickable?: boolean;
  row: any;
  onClick?: RowClickHandler<any>;
  onSelect: ((value: number | string) => void) | undefined;
  primaryKey: string;
};

export const Row: FC<Readonly<RowProps>> = ({
  selected,
  children,
  disabled,
  clickable,
  onSelect,
  row,
  primaryKey,
  onClick,
}) => {
  const handleClick = useCallback(
    (event: any) => {
      if (onClick) {
        onClick(row, event);
      }
    },
    [onClick, row],
  );

  const handleSelect = useCallback(() => {
    if (typeof onSelect === "function") {
      const value = row[primaryKey];

      onSelect(value);
    }
  }, [onSelect, row, primaryKey]);

  const style = useMemo(
    () => ({
      display: "contents",
      cursor: clickable ? "pointer" : "auto",
      bg: selected ? "gray.100" : "initial",
      td: {
        opacity: disabled ? 0.6 : 1,
      },
      ":hover td": {
        bg: selected ? "gray.100" : clickable ? "gray.100" : undefined,
      },
    }),
    [selected, disabled],
  );

  return (
    <Box as="tr" sx={style} onClick={handleClick}>
      {onSelect && (
        <Cell>
          <Box onClick={stopPropagation}>
            <Checkbox isChecked={Boolean(selected)} isDisabled={disabled} onChange={handleSelect} />
          </Box>
        </Cell>
      )}
      {children}
    </Box>
  );
};

const stopPropagation = (event) => {
  event.stopPropagation();
};
