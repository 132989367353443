import { FC, ReactNode } from "react";

import { Badge, Button, Box, Column, Row } from "@hightouchio/ui";
import { Paragraph, Image, Text } from "theme-ui";

import { CheckList, CheckListItem } from "src/ui/checklist";

import { Heading } from "../../../ui/heading";

// Disabled CTA buttons show a tooltip and should have a tooltipText field
export type PricingCardProps = {
  plan?: string;
  sku: string;
  ctaText: string;
  ctaDisabled?: boolean;
  ctaUrl?: string;
  ctaLoading?: boolean;
  onCtaClick: (sku: string) => void;
  price?: string;
  priceUnit?: string;
  priceHint?: string;
  checkList?: (string | ReactNode)[];
  checkListHeading?: string;
  partnerLogos: { img: string; alt: string }[];
};

export const PricingCard: FC<Readonly<PricingCardProps>> = ({
  plan,
  sku,
  ctaText,
  ctaDisabled,
  onCtaClick,
  ctaLoading,
  partnerLogos,
  price,
  priceHint,
  priceUnit,
  checkList,
  checkListHeading,
}) => {
  function ctaClick() {
    onCtaClick(sku);
  }

  return (
    <Box
      backgroundColor="white"
      border="1px solid"
      borderColor="gray.400"
      borderRadius="12px"
      flex="1"
      minHeight="50vh"
      position="relative"
      py={6}
      width="280px"
    >
      <Column height="100%" justify="space-between">
        <Box mx={4} position="relative">
          {/* heading */}
          <Row alignItems="center" gap={2} mb={6}>
            <Heading sx={{ fontSize: 5, weight: "semi" }} variant="h2">
              {plan}
            </Heading>
            {plan === "Enterprise" ? <Badge color="blue">Business tier</Badge> : <Badge color="green">Self-serve</Badge>}
          </Row>

          {/* pricing */}
          <Paragraph sx={{ mb: 4, display: "flex", alignItems: "center", gap: 1, fontSize: 3, fontWeight: "bold" }}>
            <Text sx={{ color: priceUnit ? undefined : "base.4" }}>{price}</Text>
            {priceUnit && <Text sx={{ color: "base.4" }}>{priceUnit}</Text>}
          </Paragraph>
          {
            /* pricing hint */
            priceHint && <Text sx={{ mb: 4 }}>{priceHint}</Text>
          }

          <Button isDisabled={ctaDisabled} isLoading={ctaLoading} variant="primary" onClick={ctaClick}>
            {ctaText}
          </Button>

          {/* checklist */}
          <CheckList>
            <Text sx={{ fontWeight: "bold" }}>{checkListHeading}</Text>
            {checkList?.map((listItem, index) => {
              return <CheckListItem key={index}>{listItem}</CheckListItem>;
            })}
          </CheckList>
        </Box>
        {/* partner logos */}
        <Box p={4} pb={0} textAlign="center">
          <Text sx={{ color: "base.5" }}>Powering companies like</Text>
          {/* TODO: insert image slideshow here */}
          <Row align="center" justify="center" mt={2}>
            {partnerLogos.map((logo) => {
              return (
                <Image
                  key={logo.alt}
                  alt={logo.alt}
                  src={logo.img}
                  sx={{ filter: "brightness(0)", height: "40px", maxWidth: "160px", width: "50%" }}
                />
              );
            })}
          </Row>
        </Box>
      </Column>
    </Box>
  );
};

PricingCard.displayName = "Pricing card";
