import { FC, FormEvent, useCallback, useEffect, useState } from "react";

import {
  Box,
  Alert,
  Button,
  FormField,
  Heading,
  Link,
  MultiSelect,
  Select,
  Switch,
  TextInput,
  Tooltip,
  useToast,
  Column,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";

import { FeatureField } from "src/components/feature-gates";
import { Settings } from "src/components/settings";
import { useUser } from "src/contexts/user-context";
import {
  useIsWorkspaceSlugAvailableQuery,
  useUpdateWorkspaceDomainsMutation,
  useUpdateWorkspaceMutation,
  useVerifiedDomainsQuery,
  useWorkspaceQuery,
} from "src/graphql";
import { roleDisabled } from "src/pages/settings/roles";
import { Section } from "src/ui/section";
import { useNavigate } from "src/utils/navigate";

export const Workspace: FC = () => {
  return (
    <Settings route="workspace">
      <Column gap={20}>
        <General />
        <Authorized />
      </Column>
    </Settings>
  );
};

const regionOptions = [
  {
    label: "United States (us-east-1)",
    value: "us-east-1",
  },
  {
    label: "European Union (eu-west-1)",
    value: "eu-west-1",
  },
];

const General: FC = () => {
  const { workspace } = useUser();
  const { toast } = useToast();
  const navigate = useNavigate();

  const [name, setName] = useState<string>(workspace?.name ?? "");
  const [slug, setSlug] = useState<string>(workspace?.slug ?? "");
  const [approvalsRequired, setApprovalsRequired] = useState<boolean | undefined>(workspace?.approvals_required);
  const [useDefaultRoleSso, setUseDefaultRoleSso] = useState<boolean | undefined>(workspace?.assign_default_role_to_sso);
  const { appAllowRoles, appAllowApprovalFlows, multiRegionEnabled } = useFlags();

  const [defaultRoleId, setDefaultRoleId] = useState(workspace?.default_role_id);

  const isBusinessTier = workspace?.organization?.plan?.sku === "business_tier";
  const approvalFlowsDisabled = !isBusinessTier && !appAllowApprovalFlows;

  const { data: slugData, isLoading: loadingSlug } = useIsWorkspaceSlugAvailableQuery(
    {
      slug,
    },
    { enabled: Boolean(slug) },
  );
  const { isLoading: updating, mutateAsync: updateWorkspace } = useUpdateWorkspaceMutation();

  const available = workspace?.slug === slug || slugData?.isWorkspaceSlugAvailable;

  const update = async () => {
    try {
      await updateWorkspace({
        id: workspace?.id,
        input: {
          name,
          slug,
          default_role_id: defaultRoleId,
          approvals_required: approvalsRequired,
          assign_default_role_to_sso: useDefaultRoleSso,
        },
      });

      if (slug !== workspace?.slug) {
        navigate(`/${slug}/settings/workspace`, { replace: true, slug: false });
      }

      toast({
        id: "workspace-settings",
        title: "Workspace settings updated",
        variant: "success",
      });
    } catch (error: unknown) {
      toast({
        id: "workspace-settings",
        title: "Something went wrong",
        message: "Failed to update workspace settings, please try again.",
        variant: "error",
        actionText: "Try again",
        onAction: update,
      });
    }
  };

  const submit = (event: FormEvent) => {
    event.preventDefault();
    void update();
  };

  return (
    <Section
      footer={
        <Tooltip
          isDisabled={!loadingSlug && available}
          message={loadingSlug ? "Checking workspace slug availability" : "The workspace slug you chose is unavailable"}
        >
          <Button
            form="workspace-settings"
            isDisabled={loadingSlug || !available}
            isLoading={updating}
            type="submit"
            variant="primary"
          >
            Save
          </Button>
        </Tooltip>
      }
      header={<Heading>Workspace settings</Heading>}
    >
      <Column as="form" gap={6} id="workspace-settings" onSubmit={submit}>
        <FormField label="Workspace name">
          <TextInput value={name} onChange={(event) => setName(event.target.value)} />
        </FormField>

        <FormField
          error={!loadingSlug && !available ? "This slug is not available" : undefined}
          label="Workspace slug"
          tip={`https://app.hightouch.com/${slug}`}
        >
          <TextInput placeholder="your-workspace-slug" value={slug} onChange={(event) => setSlug(event.target.value)} />
        </FormField>

        {workspace?.organization && (
          <FormField label="Billing organization">
            <TextInput isReadOnly value={workspace.organization.name} />
          </FormField>
        )}

        {multiRegionEnabled && (
          <FormField label="Workspace region" tip="Workspace region can't be changed after workspace is created.">
            <Select isDisabled options={regionOptions} value={workspace?.region ?? undefined} onChange={() => {}} />
          </FormField>
        )}

        <FormField description="The role assigned to people that join this workspace." label="Default role">
          <Select
            isDisabled={!approvalFlowsDisabled}
            isOptionDisabled={(role) => roleDisabled(appAllowRoles, workspace, role)}
            optionLabel={(role) => role.name}
            optionValue={(role) => role.id}
            options={workspace?.roles ?? []}
            placeholder="Select role..."
            value={defaultRoleId}
            onChange={setDefaultRoleId}
          />
        </FormField>

        {workspace?.organization?.auth0_connections && (
          <FormField
            description="By turning this off, single sign on users must have their group mapped to a Hightouch role in order to be a member of this workspace."
            label="Use default role for SSO users"
            tip={isBusinessTier ? undefined : "Only available for Business Tier workspaces."}
          >
            <Switch isChecked={Boolean(useDefaultRoleSso)} onChange={setUseDefaultRoleSso} />
          </FormField>
        )}

        <FeatureField
          description={
            <>
              By turning on this feature, users with the Workspace Draft Contributor role will be required to receive approval
              before publishing draft changes to models or syncs.
              <br />
              Go to the <Link href="/settings/members">Members</Link> tab to manage user roles.
            </>
          }
          enabled={!approvalFlowsDisabled}
          featureDetails={{
            pitch: "Approval flows for updating models and syncs in production",
            description:
              "Hightouch can require specific users (Workspace Draft Contributors) to get approval before publishing changes to models and syncs, or creating new ones.",
            bullets: [
              "Require users to get approvals from admins before publishing changes",
              "Provide admins with a changelog of edits to streamline reviews",
              "Save temporary resources as draft, before they are ready for approval",
            ],
            image: {
              src: "https://cdn.sanity.io/images/pwmfmi47/production/c28a70cae8685236c2d29e6f65331d09bc1e5e72-1246x838.png",
            },
          }}
          featureName="approval flows"
          label="Require approvals"
        >
          <Switch isChecked={Boolean(approvalsRequired)} isDisabled={approvalFlowsDisabled} onChange={setApprovalsRequired} />
        </FeatureField>

        {/* Support submitting form by pressing Enter */}
        <Box display="none">
          <input type="submit" />
        </Box>
      </Column>
    </Section>
  );
};

const Authorized: FC = () => {
  const { workspace: _workspace } = useUser();
  const { data: workspaceData } = useWorkspaceQuery({ workspaceId: _workspace?.id }, { enabled: Boolean(_workspace) });
  const { data: domainData, isLoading: domainLoading } = useVerifiedDomainsQuery();

  const workspace = workspaceData?.workspaces_by_pk;
  const domains = domainData?.getVerifiedDomains ?? [];

  const [visibleTo, setVisibleTo] = useState<string[]>(() => {
    return domains.filter((domain) => workspace?.visible_to?.includes(domain));
  });

  const [joinableTo, setJoinableTo] = useState<string[]>(() => {
    return domains.filter((domain) => workspace?.joinable_to?.includes(domain));
  });

  useEffect(() => {
    setVisibleTo(domains.filter((domain) => workspace?.visible_to?.includes(domain)));
    setJoinableTo(domains.filter((domain) => workspace?.joinable_to?.includes(domain)));
  }, [workspace, domains]);

  const { isLoading: updating, mutateAsync: updateDomains } = useUpdateWorkspaceDomainsMutation();
  const { toast } = useToast();

  const save = useCallback(async () => {
    try {
      await updateDomains({ visibleTo, joinableTo });

      toast({
        id: "workspace-authorization",
        title: "Workspace authorization settings updated",
        variant: "success",
      });
    } catch (error: unknown) {
      toast({
        id: "workspace-authorization",
        title: "Something went wrong",
        message: "Failed to update workspace authorization settings, please try again.",
        variant: "error",
        actionText: "Try again",
        onAction: save,
      });
    }
  }, [visibleTo, joinableTo]);

  const submit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      void save();
    },
    [save],
  );

  const navigate = useNavigate();

  if (!workspace?.organization?.can_invite_users) {
    return (
      <Section header={<Heading>Authorized domains</Heading>}>
        <Alert
          actionText="View SSO settings"
          message="Joining the workspace by email domain is disabled in this workspace. Please use the SSO identity provider connected to your organization. You can change this setting on your SSO tab."
          title="Unavailable feature"
          variant="info"
          onAction={() => {
            navigate("/settings/sso");
          }}
        />
      </Section>
    );
  }

  return (
    <Section
      footer={
        <Button form="workspace-authorization" isLoading={updating} type="submit" variant="primary">
          Save
        </Button>
      }
      header={<Heading>Workspace authorization</Heading>}
    >
      <Box as="form" display="flex" flexDirection="column" gap={6} id="workspace-authorization" onSubmit={submit}>
        <FormField
          description="Users with these domains will be able to see this workspace and request to join."
          label="Which domains can see this workspace?"
        >
          <MultiSelect
            isLoading={domainLoading}
            optionLabel={(domain) => domain}
            optionValue={(domain) => domain}
            options={domains}
            placeholder="Select domains..."
            value={visibleTo}
            width="sm"
            onChange={setVisibleTo}
          />
        </FormField>

        <FormField
          description="Users with these domains will be able to see and join this workspace without approval."
          label="Which domains can auto-join this workspace?"
        >
          <MultiSelect
            isLoading={domainLoading}
            optionLabel={(domain) => domain}
            optionValue={(domain) => domain}
            options={domains}
            placeholder="Select domains..."
            value={joinableTo}
            width="sm"
            onChange={setJoinableTo}
          />
        </FormField>
      </Box>
    </Section>
  );
};
