import { FC, useState } from "react";

import { PlusIcon } from "@heroicons/react/24/solid";
import { Column, Row, Text } from "@hightouchio/ui";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { ResourcePermissionGrant, useUpdateFoldersMutation } from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import { AudienceIcon, ModelIcon } from "src/ui/icons";

import { PermissionedButton } from "../permissioned-button";
import { AddFolder } from "./add-folder";
import { IndividualFolder } from "./folder";
import { Folder, FolderType, FolderViewType } from "./types";

interface FoldersProps {
  selectedFolder: Folder | null;
  setSelectedFolder: (folder: string | null) => void;
  rootFolder: FolderType | undefined;
  setRootFolder: (type: FolderType | undefined) => void;
  modelsCount?: number;
  audiencesCount?: number;
  viewType: FolderViewType;
  modelsRootName?: string;
  audiencesRootName?: string;
  nestedFolders: Folder[];
  refetchFolders: () => void;
}

export const Folders: FC<FoldersProps> = ({
  rootFolder,
  selectedFolder,
  setSelectedFolder,
  setRootFolder,
  modelsCount,
  audiencesCount,
  viewType,
  modelsRootName,
  audiencesRootName,
  nestedFolders,
  refetchFolders,
}) => {
  const [addFolderOpen, setAddFolderOpen] = useState(false);

  return (
    <DndProvider backend={HTML5Backend}>
      <Column>
        <Row align="center" height={9} justify="space-between" px={5}>
          <Text fontWeight="semibold" size="sm" textTransform="uppercase">
            Folders
          </Text>
          <PermissionedButton
            icon={PlusIcon}
            permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}
            size="sm"
            onClick={() => setAddFolderOpen(true)}
          >
            New
          </PermissionedButton>
        </Row>

        {modelsRootName && (
          <RootFolder
            folderType="models"
            folders={nestedFolders || []}
            icon={<ModelIcon color={rootFolder === "models" ? "gray.900" : "gray.600"} size={18} />}
            name={modelsRootName}
            refetchFolders={refetchFolders}
            rootCount={modelsCount}
            rootFolder={rootFolder}
            selectedFolder={selectedFolder}
            setRootFolder={setRootFolder}
            setSelectedFolder={setSelectedFolder}
          />
        )}
        {audiencesRootName && (
          <RootFolder
            folderType="audiences"
            folders={nestedFolders || []}
            icon={<AudienceIcon color={rootFolder === "audiences" ? "gray.900" : "gray.600"} size={18} />}
            name={audiencesRootName}
            refetchFolders={refetchFolders}
            rootCount={audiencesCount}
            rootFolder={rootFolder}
            selectedFolder={selectedFolder}
            setRootFolder={setRootFolder}
            setSelectedFolder={setSelectedFolder}
          />
        )}
      </Column>
      {addFolderOpen && (
        <AddFolder
          folderType={rootFolder || "models"}
          toggleDisabled={viewType !== "syncs" || !audiencesRootName}
          viewType={viewType}
          onClose={(id) => {
            setAddFolderOpen(false);
            id && setSelectedFolder(id);
          }}
        />
      )}
    </DndProvider>
  );
};

const RootFolder: FC<{
  name: string;
  folders: Folder[];
  setSelectedFolder: (folder: string | null) => void;
  setRootFolder: (folder: FolderType | undefined) => void;
  rootFolder: FolderType | undefined;
  selectedFolder: Folder | null;
  folderType: FolderType;
  refetchFolders: () => void;
  rootCount: number | undefined;
  icon: JSX.Element;
}> = ({
  name,
  rootFolder,
  folders,
  setSelectedFolder,
  selectedFolder,
  folderType,
  setRootFolder,
  refetchFolders,
  rootCount,
  icon,
}) => {
  const isOpen = folderType == rootFolder;

  return (
    <Column>
      <IndividualFolder
        count={rootCount}
        depth={-1}
        icon={icon}
        isSelected={isOpen && !selectedFolder}
        name={name}
        setSelectedFolder={setSelectedFolder}
        onClick={() => {
          if (rootFolder === folderType && selectedFolder === null) {
            setRootFolder(undefined);
            setSelectedFolder(null);
          } else {
            setRootFolder(folderType);
            setSelectedFolder(null);
          }
        }}
      />
      {isOpen &&
        (folders.length === 0 ? (
          <Row ml={5} my={2} sx={{ span: { color: "gray.600" } }}>
            <Text>No folders</Text>
          </Row>
        ) : (
          folders.map((folder) => (
            <DraggableFolder
              key={folder.id}
              folder={folder}
              refetchFolders={refetchFolders}
              rootFolder={rootFolder}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
            />
          ))
        ))}
    </Column>
  );
};

export const isChild = (folder: Folder, maybeChild: Folder): boolean => {
  return (
    maybeChild.id === folder.id || maybeChild.parentId === folder.id || folder.children.some((f) => isChild(f, maybeChild))
  );
};

const DraggableFolder = ({
  folder,
  setSelectedFolder,
  selectedFolder,
  refetchFolders,
  rootFolder,
  parentFolder,
}: {
  folder: Folder;
  setSelectedFolder: (folder: string | null) => void;
  selectedFolder: Folder | null;
  refetchFolders: () => void;
  rootFolder: FolderType;
  parentFolder?: Folder | undefined;
}) => {
  const { hasPermission: userCanUpdate } = useHasPermission([
    { resource: "workspace", grants: [ResourcePermissionGrant.Update] },
  ]);
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: "folder",
      item: { folder },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    [],
  );
  const { mutateAsync: updateFolder } = useUpdateFoldersMutation();
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: "folder",
    drop: async (item: { folder: Folder }, monitor) => {
      const didDrop = monitor.didDrop();
      const oldParent = item.folder?.parentId;
      // Only process the drop once the highest level drop target has handled it.
      if (didDrop) {
        return;
      }

      // Don't do anything if the folder is dropped on its parent.
      if (folder.id === oldParent) {
        return;
      }

      userCanUpdate &&
        (await updateFolder({
          ids: [item.folder.id],
          object: {
            parent_id: folder.id,
          },
        }));
      refetchFolders();
    },
    canDrop: (item: { folder: Folder }) => {
      if (!userCanUpdate) {
        return false;
      }
      const newParent = folder.id;

      // Don't do anything if the folder is dropped on itself.
      if (newParent === item.folder.id) {
        return false;
      }

      // We can't assign a folder to a current child; that would create a circular reference.
      if (isChild(item.folder, folder)) {
        return false;
      }

      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <div ref={drop}>
      <div ref={dragRef}>
        <Column key={folder.id} style={{ opacity }}>
          <IndividualFolder
            bg={isOver && canDrop ? "gray.200" : undefined}
            count={folder.count}
            depth={folder.depth - 1}
            folder={folder}
            isOpen={Boolean(selectedFolder && isChild(folder, selectedFolder))}
            isSelected={selectedFolder?.id == folder.id}
            name={folder.name}
            parentFolder={parentFolder}
            setSelectedFolder={setSelectedFolder}
            onClick={() => setSelectedFolder(folder.id)}
          />
          <Column sx={{ width: "100%" }}>
            {folder.children.map((childFolder) => (
              <DraggableFolder
                key={childFolder.id}
                folder={childFolder}
                parentFolder={folder}
                refetchFolders={refetchFolders}
                rootFolder={rootFolder}
                selectedFolder={selectedFolder}
                setSelectedFolder={setSelectedFolder}
              />
            ))}
          </Column>
        </Column>
      </div>
    </div>
  );
};
