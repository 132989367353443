import { useMemo } from "react";

import * as Yup from "yup";

import { Mapping, MappingType } from "src/formkit/components/types";
import {
  useDestinationDefinitionsQuery,
  useDestinationDefinitionQuery,
  useDestinationQuery,
  useDestinationsQuery,
  useDestinationStrippedConfigurationQuery,
  DestinationsOrderBy,
  DestinationQuery,
  DestinationsQuery,
  DestinationStrippedConfigurationQuery,
  DestinationDefinitionQuery,
  DestinationsBoolExp,
} from "src/graphql";
import {
  ArrayIcon,
  BooleanIcon,
  CalendarIcon,
  ClockIcon,
  EmailIcon,
  LinkIcon,
  ListIcon,
  NumberIcon,
  ObjectIcon,
  QuestionIcon,
  StringIcon,
} from "src/ui/icons";

// OBJECT

export const DEFAULT_OBJECT_LABEL = "Which object would you like to sync data to?";

export const OBJECT_LABEL = {
  marketo: {
    objects: "Which object would you like to sync data to?",
    activities: "Which activity would you like to sync data to?",
  },
};

export const DEFAULT_OBJECT_DESCRIPTION = "";

export const OBJECT_DESCRIPTION = {};

export const DEFAULT_OBJECT_RELOAD_TOOLTIP = "Click to refresh objects available in the destination";

export const OBJECT_RELOAD_TOOLTIP = {
  marketo: {
    activities: "Click to refresh activities available in the destination",
  },
};

// MODE

export const DEFAULT_MODE_LABEL = "How do you want to update the destination?";

export const MODE_LABEL = { slack: "How do you want to format your updates?" };

export const DEFAULT_MODE_DESCRIPTION = "";

export const MODE_DESCRIPTION = {};

export const DEFAULT_MODE_DESCRIPTIONS = {
  insert: "Pushes new records and does not update the records as they change in your source.",
  upsert: "Pushes new records and updates records that change in your source.",
  update: "Updates particular fields on existing records.",
  archive: "Archives records that are present in the source.",
  mirror: "Pushes new records, updates changed records, deletes records that have been removed in the source.",
};

export const MODE_DESCRIPTIONS = {
  google: {
    CustomerMatchUserList:
      "Creates a customer list for the Customer Match feature of Google Ads. Segments are automatically created in Google Ads based on the name of the model.",
    ClickConversions: "Sends click conversion tracking information to Google Ads for each record in the query results.",
    ClickConversionAdjustments: "Sends click conversion adjustments to Google Ads for each record in the query results.",
    CallConversions: "Sends call conversion tracking information to Google Ads for each record in the query results.",
    OfflineStoreConversions:
      "Sends offline store sales conversion tracking information to Google Ads for each record in the query results.",
  },
  slack: {
    message:
      "Sends individual messages for each record that was added or removed since the last sync. There will be no messages for the initial run.",
    batchMessage:
      "Sends a formatted message or multiple messages with plaintext or Slack block kit of all the records in the query results.",
    table: "Sends a formatted table of all records in the query results for the each sync as a single message.",
    csv: "Sends a CSV file of all records in the query results for the each sync attached to a single message.",
  },
  onesignal: {
    update: "Updates tags on existing users based on external User ID.",
  },
  marketo: {
    activities: {
      insert: "Creates a new custom activity for each record in the query results.",
    },
  },
  asana: {
    tasks: {
      upsert: "Creates new tasks and updates existing tasks based on an external ID.",
      update:
        "Updates existing or newly created tasks based on the Asana GID. The update mode can also update tasks created with Hightouch using the external ID.",
    },
    projects: {
      update: "Updates existing projects based on the Asana GID.",
    },
  },
};

export const DEFAULT_TYPE_LABEL = "What would you like Hightouch to send to your destination?";

export const TYPE_LABEL = {};

export const DEFAULT_TYPE_DESCRIPTION = "";

export const TYPE_DESCRIPTION = {};

export const DEFAULT_TYPE_DESCRIPTIONS = {
  enrichment: "Sync records to a generic destination table, often a custom table or a lookup table.",
  object: "Syncs records to objects such as users or organizations in your destination.",
  segment: "Creates, syncs, and keeps up-to-date the query result as a segment in your destination.",
  event: "Syncs records as events to your destination, this is often in the form of a track call.",
  catalog: "Sync records as catalog items in your destinations.",
};

export const TYPE_DESCRIPTIONS = {
  customerio: {
    attribute: "Syncs records to objects such as users or organizations in your destination.",
    segment:
      "Creates, syncs, and keeps up-to-date the query result as a segment in your destination. This sync won't work if your workspace uses both email and ID as identifiers.",
  },
  mparticle: {
    object: "Syncs records as user attributes without an event to mParticle.",
    event: "Syncs records as events, such as custom events and commerce events, to mParticle.",
  },
  salesforce: {
    object: "Syncs records as Salesforce objects such as accounts or contacts in your destination.",
    metadata: "Syncs records as picklist value metadata to Salesforce object's custom picklist fields.",
  },
  salesforceSandbox: {
    object: "Syncs records as Salesforce objects such as accounts or contacts in your destination.",
    metadata: "Syncs records as picklist value metadata to Salesforce object's custom picklist fields.",
  },
  braze: {
    segment: "Creates, syncs, and keeps up-to-date the query result as a cohort in Braze.",
  },
};

// ID MAPPING / FROM ID

export const DEFAULT_ID_MAPPING_LABEL = "How should records between query results and destination be matched?";

export const ID_MAPPING_LABEL = {
  marketo: { activities: "Which column should be synced to the primary attribute of the activity?" },
  sfmc: { journeys: "Which column contains the keys of the contacts you want to add to the journey?" },
  braze: { event: "How should events in the query results be matched to users in Braze?" },
};

export const DEFAULT_ID_MAPPING_DESCRIPTION =
  "Records in the query results and destination are matched when the ID value is equivalent.";

export const ID_MAPPING_DESCRIPTION = {
  salesforceSandbox: {
    upsert:
      "Records in the query results and destination are matched when the ID value is equivalent. If you don't see the expected Salesforce field here, make sure it is set as a unique field.",
    update:
      "Records in the query results and destination are matched when the ID value is equivalent. Matching records by Salesforce ID will have better performance. If the field you select is not unique, Hightouch will only update one of the records.",
    insert: "Records in the query results and destination are matched when the ID value is equivalent.",
  },
  salesforce: {
    upsert:
      "Records in the query results and destination are matched when the ID value is equivalent. Matching records by recommended unique custom fields will have better performance. If the field you select is not unique, Hightouch will only update one of the records.",
    update:
      "Records in the query results and destination are matched when the ID value is equivalent. Matching records by Salesforce ID will have better performance. If the field you select is not unique, Hightouch will only update one of the records.",
    insert: "Records in the query results and destination are matched when the ID value is equivalent.",
    all: "Records in the query results and destination are matched when the Label value is equivalent.",
  },
  marketo: { activities: "This defines which column is mapped to the Marketo custom activity primary attribute." },
  sfmc: { journeys: "This defines which contacts will enter and exit the journey." },
  braze: {
    event:
      "This defines how events will be attached to users in Braze. If you chose to only track events for users that don't exist, you can also match based on Braze ID and user alias object.",
  },
};

// DELETE MODE
export const DELETE_MODE_LABELS = {};

export const DELETE_MODE_DESCRIPTIONS = {};

export const DEFAULT_DELETE_MODE_LABELS = {
  archive: "Archive destination record associated with source record, but do not delete",
  clear: "Clear fields that are being synced to in the destination record, but do not delete",
  delete: "Delete destination record associated with source record",
};

export const DEFAULT_DELETE_MODE_DESCRIPTION = {
  delete: undefined,
  clear: undefined,
  archive: undefined,
};
// MAPPINGS

export const MAPPINGS_DESCRIPTION = {
  asana: {
    tasks: {
      customMappings:
        "You can only set a custom field on a task if that task is in a project that has that custom field (or is about to be created in a project that has that custom field).",
    },
  },
  mparticle: {
    object: {
      externalIdMappings:
        "Records in the query results and destination are matched when the ID value is equivalent. Select one or more user or device identifiers.",
    },
    event: {
      externalIdMappings:
        "This defines how events will be attached to users in mParticle. Select one or more user or device identifiers.",
      userMappings:
        "Configure how the columns in your query results should be mapped to user attributes in mParticle. These columns will be sent to the user_attributes property in the payload.",
      customUserMappings:
        "Custom user attribute fields are usually used for extra information or metadata. These columns will be sent to the user_attributes property in the payload.",
    },
  },
  hubspot: {
    associationMappings: `Associations listed below represent relationships where the current syncing object is the 'fromObject' side. 
    Note that custom objects' association names may be formatted differently than the other associations.`,
  },
};

export const DEFAULT_MAPPINGS_DESCRIPTION = {
  mappings: "Configure how the columns in your query results should be mapped to fields in your destinations.",
  customMappings: "Custom fields are usually created by your team and used for extra information or metadata.",
};

export const MAPPINGS_LABEL = {
  mparticle: {
    object: {
      externalIdMappings: "How should records between query results and destination be matched?",
    },
    event: {
      externalIdMappings: "How should events in the query results be matched to users in mParticle?",
      mappings: "Which columns would you like to sync to destination event fields?",
      customMappings: "Which columns would you like to sync to destination custom event properties?",
      userMappings: "Which columns would you like to sync to the associated user attribute fields?",
      customUserMappings: "Which columns would you like to sync to the associated custom user attribute fields?",
    },
  },
  hubspot: {
    associationMappings: "Which associations would you like to add from the current object?",
  },
};

export const DEFAULT_MAPPINGS_LABEL = {
  mappings: "Which columns would you like to sync to destination fields?",
  customMappings: "Which columns would you like to sync to custom destination fields?",
};

// SYNC VALIDATION

const yupToFormikError = (error) => {
  const newError = {};
  for (const e of error?.inner || []) {
    newError[e?.path] = e?.message;
  }
  return newError;
};

export const validate = async (config, destinationSchema, customValidation) => {
  if (customValidation?.validate) {
    const validation = await customValidation?.validate(config);
    const yupError = validation?.yupError;
    const otherError = validation?.otherError;
    if (yupError) {
      return yupToFormikError(yupError);
    }
    if (otherError) {
      return true;
    }
  } else {
    try {
      await destinationSchema.validate(config, {
        abortEarly: false,
      });
    } catch (err) {
      console.log(err);
      return yupToFormikError(err);
    }
  }
  return false;
};

// YUP SCHEMAS
export const standardMappingSchema = Yup.object().shape({
  from: Yup.mixed().nullable().required(),
  to: Yup.string().nullable().required(),
  type: Yup.string().equals([MappingType.STANDARD]),
});

const referenceMappingSchema = Yup.object().shape({
  lookup: Yup.object().shape({
    from: Yup.mixed().nullable().required(),
    by: Yup.string().nullable().required(),
    object: Yup.string().nullable().required(),
    byType: Yup.string().nullable().notRequired(),
  }),
  to: Yup.string().nullable().required(),
  object: Yup.string().nullable().notRequired(),
  type: Yup.string().equals(["reference"]),
});

const staticMappingSchema = Yup.object().shape({
  to: Yup.string().nullable().required(),
  value: Yup.mixed().nullable().required(),
  valueType: Yup.string().required(),
  type: Yup.string().equals([MappingType.STATIC]),
});

const variableMappingSchema = Yup.object().shape({
  to: Yup.string().nullable().required(),
  variable: Yup.string().required(),
  type: Yup.string().equals([MappingType.VARIABLE]),
});

const freeformMappingSchema = Yup.object().shape({
  from: Yup.mixed().nullable().required(),
  to: Yup.string().nullable().required(),
});

const templateMappingSchema = Yup.object().shape({
  template: Yup.string().nullable().required(),
  to: Yup.string().nullable().required(),
  type: Yup.string().equals([MappingType.TEMPLATE]),
});

export const COMMON_SCHEMAS = {
  standardMapping: standardMappingSchema,
  referenceMapping: referenceMappingSchema,
  staticMapping: staticMappingSchema,
  variableMapping: variableMappingSchema,
  freeformMapping: freeformMappingSchema,
  templateMapping: templateMappingSchema,
  standardOrStaticMapping: Yup.lazy((val: Partial<Mapping> | undefined) =>
    val?.["type"] === "static" || val?.["value"] !== undefined ? staticMappingSchema : standardMappingSchema,
  ),
  mappings: Yup.array()
    .of(
      Yup.lazy((val) => {
        const v = val as any;
        if (v?.type === "reference" || typeof v?.lookup === "object") {
          return referenceMappingSchema;
        } else if (v?.type === "template" || typeof v?.template === "string") {
          return templateMappingSchema;
        } else if (v?.type === "static" || v?.value !== undefined) {
          return staticMappingSchema;
        } else if (v?.type === "variable" || typeof v?.variable === "string") {
          return variableMappingSchema;
        } else if (v?.type === "standard") {
          return standardMappingSchema;
        } else {
          return freeformMappingSchema;
        }
      }),
    )
    .notRequired()
    .default([]),
  externalIdMapping: freeformMappingSchema.required().default(undefined),

  columnOrConstant: Yup.lazy((val) =>
    typeof val !== "string"
      ? Yup.object().shape({ column: Yup.mixed().nullable().required() }).required().default(undefined)
      : Yup.mixed().required(),
  ),
};

// RECONCILE TYPES

export enum StandardFieldType {
  STRING = "STRING",
  ENUM = "ENUM",
  NUMBER = "NUMBER",
  BOOLEAN = "BOOLEAN",
  DATE = "DATE",
  DATETIME = "DATETIME",
  EMAIL = "EMAIL",
  OBJECT = "OBJECT",
  ARRAY = "ARRAY",
  REFERENCE = "REFERENCE",
  UNKNOWN = "UNKNOWN",
}

export const ICON_MAP = {
  [StandardFieldType.STRING]: StringIcon,
  [StandardFieldType.ENUM]: ListIcon,
  [StandardFieldType.NUMBER]: NumberIcon,
  [StandardFieldType.BOOLEAN]: BooleanIcon,
  [StandardFieldType.DATE]: CalendarIcon,
  [StandardFieldType.DATETIME]: ClockIcon,
  [StandardFieldType.EMAIL]: EmailIcon,
  [StandardFieldType.OBJECT]: ObjectIcon,
  [StandardFieldType.ARRAY]: ArrayIcon,
  [StandardFieldType.REFERENCE]: LinkIcon,
  [StandardFieldType.UNKNOWN]: QuestionIcon,
};

export const convertType = (type: string, map: any) => {
  const defaultMap = {
    string: StandardFieldType.STRING,
    enum: StandardFieldType.ENUM,
    number: StandardFieldType.NUMBER,
    boolean: StandardFieldType.BOOLEAN,
    date: StandardFieldType.DATE,
    datetime: StandardFieldType.DATETIME,
    email: StandardFieldType.EMAIL,
    object: StandardFieldType.OBJECT,
    array: StandardFieldType.ARRAY,
    reference: StandardFieldType.REFERENCE,
  };

  return defaultMap?.[map?.[type]] || defaultMap?.[type] || StandardFieldType.UNKNOWN;
};

export const schemaToOptions = (schema, model) => {
  const columns = schema
    ?.map((f) => f.label)
    ?.filter((f) => {
      // remove this when we support selecting aggregate/related columns etc
      // but for now underscore columns are probably from filters
      if (model.type === "object") return !f.startsWith("_");
      return true;
    });

  const options = columns?.map((htC) => ({
    label: htC,
    value: htC,
  }));

  return options;
};

export type Destinations = NonNullable<DestinationsQuery["destinations"]>;

export interface UseDestinationResult {
  data: {
    destination:
      | (NonNullable<DestinationQuery["destinations_by_pk"]> & {
          config: DestinationStrippedConfigurationQuery["getDestinationStrippedConfiguration"];
        })
      | null;
    definition: DestinationDefinitionQuery["getDestinationDefinition"] | undefined | null;
  };
  loading: boolean;
  error: Error | undefined | null;
  refetch: () => void;
}

export function useDestination(id: string, { pause }: { pause?: boolean } = {}): UseDestinationResult {
  const {
    data: destinationData,
    isLoading: destinationLoading,
    error: destinationError,
    refetch: refetchDestination,
  } = useDestinationQuery(
    {
      id: String(id),
    },
    {
      enabled: !pause && !!id,
    },
  );

  const destination = destinationData?.destinations_by_pk;

  const {
    data: configData,
    isLoading: configLoading,
    error: configError,
    refetch: refetchConfig,
  } = useDestinationStrippedConfigurationQuery(
    {
      destinationId: String(id),
    },
    {
      enabled: !pause,
    },
  );

  const config = configData?.getDestinationStrippedConfiguration;

  const {
    data: definitionData,
    isLoading: definitionLoading,
    error: definitionError,
  } = useDestinationDefinitionQuery(
    {
      slug: destination?.type ?? "",
    },
    {
      enabled: Boolean(destination),
    },
  );

  const destinationWithConfig = useMemo(() => (destination ? { ...destination, config } : null), [destination, config]);

  return {
    data: {
      destination: destinationWithConfig,
      definition: definitionData?.getDestinationDefinition,
    },
    loading: destinationLoading || definitionLoading || configLoading,
    error: destinationError || definitionError || configError,
    refetch: () => {
      refetchDestination();
      refetchConfig();
    },
  };
}

export function useDestinations({
  filters = {},
  destinationsOrderBy,
  limit = 10000,
  pause,
}: { filters?: DestinationsBoolExp; destinationsOrderBy?: DestinationsOrderBy; limit?: number; pause?: boolean } = {}) {
  const {
    data: destinationsData,
    isLoading: destinationsLoading,
    error: destinationsError,
    isRefetching: isRefetchingDestinations,
    refetch: refetchDestinations,
  } = useDestinationsQuery(
    { filters, limit, orderBy: destinationsOrderBy },
    {
      enabled: !pause,
      keepPreviousData: true,
      retry: 2,
    },
  );

  const {
    data: definitionsData,
    isLoading: definitionsLoading,
    error: definitionsError,
    isRefetching: isRefetchingDefinitions,
    refetch: refetchDestinationDefinitions,
  } = useDestinationDefinitionsQuery(undefined, {
    enabled: !pause,
    keepPreviousData: true,
  });

  const refetch = () => {
    refetchDestinations();
    refetchDestinationDefinitions();
  };

  return {
    data: { destinations: destinationsData?.destinations, definitions: definitionsData?.getDestinationDefinitions },
    loading: destinationsLoading || definitionsLoading,
    error: destinationsError || definitionsError,
    isRefetching: isRefetchingDestinations || isRefetchingDefinitions,
    refetch,
  };
}
