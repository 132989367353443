import { FC } from "react";

import { upperCase } from "lodash";

import { SelectCredential } from "src/components/credentials";
import { Field } from "src/ui/field";

type ProviderSectionProps = {
  provider: "gcp" | "aws";
  credentialId?: any;
  setCredentialId?: any;
};

export const ProviderSection: FC<Readonly<ProviderSectionProps>> = ({ provider, credentialId, setCredentialId }) => {
  return (
    <Field label={`${upperCase(provider)} credentials`}>
      <SelectCredential provider={provider} value={credentialId} onChange={(value) => setCredentialId(value)} />
    </Field>
  );
};
