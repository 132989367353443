import { useEffect, useState, FC } from "react";

import { ModelQuery, ObjectQuery } from "src/graphql";
import { Select } from "src/ui/select";
import { QueryType, useModelRun, useQueryState, useUpdateQuery } from "src/utils/models";

type Props = {
  model: ModelQuery["segments_by_pk"] | ObjectQuery["segments_by_pk"];
  value: string;
  columns?: { name: string }[];
  onChange: (value: string) => void;
};

export const ColumnSelect: FC<Readonly<Props>> = ({ model, columns, onChange, value }) => {
  const { queryState, initQueryState } = useQueryState();
  const [loading, setLoading] = useState<boolean>(false);

  const type = model?.query_type;
  const source = model?.connection;
  const cols = columns || model?.columns;
  const columnOptions = cols?.map(({ name }) => ({ value: name, label: name }));

  const update = useUpdateQuery({ logUpdate: false });

  const { runQuery } = useModelRun(type as QueryType | undefined, undefined, {
    modelId: model?.id,
    variables: { sourceId: source?.id, ...queryState },
    onCompleted: async ({ columns }, error) => {
      if (!error) {
        await update({ model, columns });
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    initQueryState(model);
  }, [model]);

  return (
    <Select
      isLoading={loading}
      options={columnOptions}
      reload={async () => {
        setLoading(true);
        await runQuery(true);
      }}
      value={value}
      onChange={({ value }) => {
        onChange(value);
      }}
    />
  );
};
