import emailIcon from "src/components/logos/email.svg";
import pagerdutyIcon from "src/components/logos/pagerduty.svg";
import slackIcon from "src/components/logos/slack.svg";
import smsIcon from "src/components/logos/sms.svg";

import { EmailForm, emailValidator } from "./email";
import { PagerdutyForm } from "./pagerduty";
import { SlackForm, slackValidator } from "./slack";
import { SmsForm, smsValidator } from "./sms";

export const alertTypes = {
  email: {
    name: "Email",
    icon: emailIcon,
    form: EmailForm,
    validator: emailValidator,
  },
  sms: {
    name: "SMS",
    icon: smsIcon,
    form: SmsForm,
    validator: smsValidator,
  },
  slack: {
    name: "Slack",
    icon: slackIcon,
    form: SlackForm,
    validator: slackValidator,
  },
  pagerduty: {
    name: "PagerDuty",
    icon: pagerdutyIcon,
    form: PagerdutyForm,
  },
};
