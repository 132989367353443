import { ReactElement, useEffect } from "react";

import { Grid } from "theme-ui";
import * as Yup from "yup";

import { Field } from "src/ui/field";
import { RadioGroup } from "src/ui/radio/radio-group";

import { CustomQuery, CustomQueryFormProps, CustomQueryViewProps } from "../custom-query";
import * as questions from "./questions";
import * as tables from "./tables";

function QueryForm({ source, query, onChange, error, setError }: Readonly<CustomQueryFormProps>): ReactElement<any, any> {
  const renderForm = (type: string) => {
    switch (type) {
      case "question":
        return <questions.QueryForm error={error} query={query} setError={setError} source={source} onChange={onChange} />;
      case "table":
        return <tables.QueryForm error={error} query={query} setError={setError} source={source} onChange={onChange} />;
      default:
        return `Type ${type} not valid for Metabase source`;
    }
  };

  useEffect(() => {
    if (!query?.type) {
      onChange({ type: "question" });
    }
  }, [query]);

  return (
    <Grid gap={10}>
      <Field description="Select the data source." label="What do you want to query?" size="large">
        <RadioGroup
          options={[
            { label: "Question", value: "question", description: "Results from a question" },
            { label: "Table", value: "table", description: "Results from a table" },
          ]}
          value={query?.type || "question"}
          onChange={(type) => onChange({ type: type as string })}
        />
      </Field>

      {query?.type && renderForm(query.type)}
    </Grid>
  );
}

function QueryView(props: Readonly<CustomQueryViewProps>): ReactElement<any, any> | null {
  switch (props.query.type) {
    case "question":
      return <questions.QueryView {...props} />;
    case "table":
      return <tables.QueryView {...props} />;
    default:
      props.setError(new Error(`Type ${props.query.type} not valid for Metabase source`));
      return null;
  }
}

export default {
  QueryForm,
  QueryView,
  querySchema: Yup.lazy<CustomQuery | undefined>((query) => {
    switch (query?.["type"]) {
      case "question":
        return questions.querySchema;
      case "table":
        return tables.querySchema;
      default:
        return Yup.object().shape({ type: Yup.string().required() });
    }
  }),
};
