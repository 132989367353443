import { FC } from "react";

import { Flex, Text } from "theme-ui";

import { useOrganizationMqrsQuery } from "src/graphql";
import { Box, Row } from "src/ui/box";
import { CaretDownIcon, CaretUpIcon } from "src/ui/icons";
import { Spinner } from "src/ui/loading";
import { Table } from "src/ui/table";
import { commaNumber } from "src/utils/numbers";

export interface OrganizationMqrMetrics {
  usage_period: string;
  workspaces: { name: string; monthly_queried_records: number }[];
  total_monthly_queried_records: number;
  percentage_change: number;
}

type Props = {
  organizationWorkspaces?: {
    name: string;
    id: number;
    slug: string;
  }[];
};

const placeholder = {
  title: "No MQR data",
  error: "MQR data failed to load, please try again.",
};

export const OrganizationMqrTable: FC<Props> = ({ organizationWorkspaces }) => {
  const { data: mqrData, isLoading } = useOrganizationMqrsQuery();
  const mqrMetrics = mqrData?.getOrganizationMqrs || [];

  const mqrColumns = [
    {
      name: "Month",
      cell: ({ usage_period }) => {
        return <Text>{usage_period}</Text>;
      },
    },
    ...getWorkspaceMqrColumns(organizationWorkspaces || []),
    {
      name: "Total MQR",
      cell: ({ total_monthly_queried_records }) => {
        return <Text>{total_monthly_queried_records ? commaNumber(total_monthly_queried_records) : "--"}</Text>;
      },
    },
    {
      name: "% Change",
      cell: ({ percentage_change }) => {
        return (
          <Row sx={{ alignItems: "center" }}>
            {percentage_change !== 0 ? (
              <>
                {percentage_change > 0 ? (
                  <CaretUpIcon color="primaries.7" size={18} sx={{ mb: 1, mr: 2 }}></CaretUpIcon>
                ) : (
                  <CaretDownIcon color="red" size={18} sx={{ mb: 1, mr: 2 }}></CaretDownIcon>
                )}
                <Text>{`${percentage_change.toFixed(2)} %`}</Text>
              </>
            ) : (
              <Text>--</Text>
            )}
          </Row>
        );
      },
    },
  ];

  return (
    <Box>
      {isLoading ? (
        <Flex sx={{ flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "space-between", p: 6 }}>
          <Spinner size={64} />
        </Flex>
      ) : (
        <Table columns={mqrColumns} data={mqrMetrics} placeholder={placeholder} sx={{ width: "100%" }} />
      )}
    </Box>
  );
};

// Not all workspaces have MQR data, but we want to give a big picture view of the billing for the organziation.
function getWorkspaceMqrColumns(allWorkspaces: { id: number; name: string; slug: string }[]) {
  return allWorkspaces.map(({ id, name }) => ({
    name: name,
    cell: ({ workspaces: mqrWorkspaces }) => {
      const workspace = mqrWorkspaces.find((workspace) => workspace.id === id);
      const monthlyQueriedRecords = workspace?.monthly_queried_records;
      return <Text>{monthlyQueriedRecords ? commaNumber(monthlyQueriedRecords) : "--"}</Text>;
    },
  }));
}
