import { FC } from "react";

import { Box, ThemeUIStyleObject, Text } from "theme-ui";

import { newIntercomMessage } from "src/lib/intercom";
import { Row } from "src/ui/box";
import { HelpIcon } from "src/ui/icons";
import { Link } from "src/ui/link";

type HelpProps = {
  docs?: string;
  label?: string;
  sx?: ThemeUIStyleObject;
};

export const Help: FC<Readonly<HelpProps>> = ({ docs, label, sx = {} }) => (
  <Box
    sx={{
      ...sx,
    }}
  >
    <Row sx={{ alignItems: "center", mb: 1 }}>
      <HelpIcon color="base.4" size={18} />
      <Text sx={{ fontWeight: "semi", ml: 2 }}>Need help?</Text>
    </Row>

    {docs && label ? (
      <Text sx={{ fontSize: 0, color: "dark.2" }}>
        Read our{" "}
        <Link newTab to={docs}>
          docs
        </Link>{" "}
        or{" "}
        <Link
          onClick={() => {
            newIntercomMessage(`Could you help me with ${label}?`);
          }}
        >
          send us a message
        </Link>
      </Text>
    ) : (
      <Link
        sx={{ fontSize: 0, fontWeight: "semi" }}
        onClick={() => {
          newIntercomMessage(`Could you help me with ${label}?`);
        }}
      >
        Send us a message
      </Link>
    )}
  </Box>
);
