import { FC, useEffect } from "react";

import * as Yup from "yup";

import { useMixpanelListCohortsQuery, useMixpanelListProjectsQuery } from "src/graphql";
import { Checkbox } from "src/ui/checkbox";
import { Field } from "src/ui/field";
import { Spinner } from "src/ui/loading";
import { Select } from "src/ui/select";

import { CustomQueryViewProps, CustomQueryFormProps } from "../custom-query";

export const QueryForm: FC<Readonly<CustomQueryFormProps>> = ({ source, query, onChange, setError }) => {
  const {
    data: projects,
    isLoading: projectsLoading,
    error: projectsError,
    refetch: listProjects,
  } = useMixpanelListProjectsQuery({ connectionId: String(source.id) });

  const {
    data: cohorts,
    isLoading: cohortsLoading,
    error: cohortsError,
    refetch: listCohorts,
  } = useMixpanelListCohortsQuery(
    { connectionId: String(source.id), projectId: Number(query?.projectId) },
    { enabled: Boolean(query?.projectId) },
  );

  useEffect(() => {
    if (projectsError || cohortsError) {
      setError(projectsError || cohortsError);
    }
  }, [projectsError, cohortsError]);

  return (
    <>
      <Field description="Select a Mixpanel project where the cohorts live." label="What is the project?" size="large">
        <Select
          isError={Boolean(projectsError)}
          isLoading={projectsLoading}
          options={projects?.mixpanelListProjects?.map((project) => {
            return { label: project.name, value: project.id };
          })}
          placeholder="Select a Mixpanel project"
          reload={listProjects}
          sx={{ maxWidth: "280px" }}
          value={projects?.mixpanelListProjects?.find((project) => project.id === query?.projectId)?.id || null}
          width="280px"
          onChange={(selected) => onChange({ ...query, projectId: selected?.value })}
        />
      </Field>
      <Field description="Select a Mixpanel Cohort to query the users from." label="What is the cohort?" size="large">
        <Select
          isError={Boolean(cohortsError)}
          isLoading={cohortsLoading}
          options={cohorts?.mixpanelListCohorts?.map((cohort) => {
            return { label: cohort.name, value: cohort.id };
          })}
          placeholder="Select a Mixpanel cohort"
          reload={listCohorts}
          sx={{ maxWidth: "280px" }}
          value={cohorts?.mixpanelListCohorts?.find((cohort) => cohort.id === query?.cohortId)?.id || null}
          width="280px"
          onChange={(selected) => onChange({ ...query, cohortId: selected.value })}
        />
      </Field>
      <Field label="Should we include users without profile data?" size="large">
        <Checkbox
          label="Include all users"
          sx={{ mt: 2 }}
          value={Boolean(query?.includeAllUsers)}
          onChange={(includeAllUsers) => onChange({ ...query, includeAllUsers })}
        />
      </Field>
    </>
  );
};

export const QueryView: FC<Readonly<CustomQueryViewProps>> = ({ source, query, setError }) => {
  const {
    data: projects,
    isLoading: projectsLoading,
    error: projectsError,
  } = useMixpanelListProjectsQuery({ connectionId: String(source.id) });

  const {
    data: cohorts,
    isLoading: cohortsLoading,
    error: cohortsError,
  } = useMixpanelListCohortsQuery({ connectionId: String(source.id), projectId: query.projectId as number });

  useEffect(() => {
    if (projectsError || cohortsError) {
      setError(projectsError || cohortsError);
    }
  }, [projectsError, cohortsError]);

  if (projectsLoading || cohortsLoading) {
    return <Spinner />;
  }

  const project = projects?.mixpanelListProjects?.find((project) => project.id === query.projectId);
  const projectName = project ? `${project.name} (${project.id})` : `Unknown project with id ${query.projectId}`;
  const cohort = cohorts?.mixpanelListCohorts?.find((cohort) => cohort.id === query?.cohortId);
  const cohortName = cohort
    ? `${cohort.name} (${cohort.id}) with ${cohort.count} users`
    : `Unknown cohort with id ${query.cohortId}`;

  // This only gets rendered when the loading has finished.
  return (
    <>
      <Field label="Users from a Mixpanel Cohort" size="large" />
      <Field inline label="Project">
        {projectName}
      </Field>
      <Field inline label="Cohort">
        {cohortName}
      </Field>
      <Field inline label="Include users without profile data">
        {query.includeAllUsers ? "Yes" : "No"}
      </Field>
    </>
  );
};

export const querySchema = Yup.object().shape({
  type: Yup.string().required().equals(["cohort"]),
  projectId: Yup.number().required(),
  cohortId: Yup.number().required(),
  includeAllUsers: Yup.boolean().required().default(false),
});
