import { FolderType } from "src/components/folders/types";
import { flattenFolders, nestFolders } from "src/components/folders/utils";
import { useFoldersQuery } from "src/graphql";

export const useFolders = ({ folderType, viewType }: { folderType: FolderType | undefined; viewType: "syncs" | "models" }) => {
  const { data, refetch, isLoading } = useFoldersQuery(
    { type: folderType },
    {
      select: (data) => {
        const nestedFolders = nestFolders(data.folders || [], viewType);
        const flattenedFolders = flattenFolders(nestedFolders);
        return {
          flattenedFolders,
          nestedFolders,
        };
      },
    },
  );

  return { ...(data || {}), refetchFolders: refetch, loadingFolders: isLoading };
};
