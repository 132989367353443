import { FC } from "react";

import { Text } from "theme-ui";

import { useContentVisibility } from "src/hooks/use-content-visibility";
import { TooltipProps, Tooltip } from "src/ui/tooltip";

export const TextWithTooltip: FC<Readonly<TooltipProps>> = ({ children, disabled, sx = {}, portal, ...props }) => {
  const { isVisible, ref } = useContentVisibility<HTMLDivElement>();

  return (
    <Tooltip disabled={isVisible || disabled} portal={portal} {...props}>
      <Text ref={ref} sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", ...sx }} {...props}>
        {children}
      </Text>
    </Tooltip>
  );
};
