import { FC } from "react";

import { useToast } from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { Grid } from "theme-ui";
import { object, string } from "yup";

import { useCreateIntegrationRequestMutation } from "src/graphql";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Input, TextArea } from "src/ui/input";
import { Modal } from "src/ui/modal";
import { RadioGroup } from "src/ui/radio";

type Props = {
  onClose: () => void;
};

const validationSchema = object().shape({
  type: string().required("An integration type is required."),
  name: string().required("An integration name is required."),
  description: string().required("A message is required."),
});

export const IntegrationRequestForm: FC<Readonly<Props>> = ({ onClose }) => {
  const { toast } = useToast();

  const { control, register, handleSubmit } = useForm<{
    type: string;
    name: string;
    description: string;
  }>({
    resolver: yupResolver(validationSchema),
  });

  const { isLoading: creating, mutateAsync: createIntegrationRequest } = useCreateIntegrationRequestMutation();

  const onSubmit = async ({ type, name, description }) => {
    await createIntegrationRequest({ type, name, description });

    onClose();

    toast({
      id: "send-integration-request",
      title: "Integration request sent, thank you!",
      variant: "success",
    });
  };

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button loading={creating} onClick={handleSubmit(onSubmit)}>
            Send
          </Button>
        </>
      }
      sx={{ maxWidth: "568px", width: "100%" }}
      title="Submit an integration request"
      onClose={onClose}
    >
      <Grid gap={8}>
        <Field label="Integration type">
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <RadioGroup
                {...field}
                options={[
                  { label: "Source", value: "Source" },
                  { label: "Destination", value: "Destination" },
                ]}
              />
            )}
            rules={{ required: true }}
          />
        </Field>
        <Field label="Integration name">
          <Input {...register("name")} placeholder="Enter a name..." sx={{ width: "100%" }} />
        </Field>
        <Field label="Message">
          <TextArea
            {...register("description")}
            placeholder="Help us understand your use case, including any specific requirements
you may have."
            rows={6}
          />
        </Field>
      </Grid>
    </Modal>
  );
};
