import { forwardRef } from "react";

import slugify from "@sindresorhus/slugify";
import { Text, Flex } from "theme-ui";

import { CheckCircleIcon, XCircleIcon } from "src/ui/icons";
import { Input } from "src/ui/input";

type SlugProps = {
  loading: boolean;
  value: string | undefined | null;
  onChange: (value: string) => void;
  available: boolean;
  placeholder?: string;
};

export const Slug = forwardRef<HTMLInputElement, Readonly<SlugProps>>(
  ({ loading, value, onChange, available, placeholder }, ref) => {
    return (
      <Flex sx={{ alignItems: "center", position: "relative" }}>
        <Input
          ref={ref}
          placeholder={placeholder}
          sx={{ pr: 23 }}
          value={value}
          onChange={(value) => onChange(slugify(value, { preserveTrailingDash: true }))}
        />
        <Flex sx={{ position: "absolute", right: 2, fontSize: 0, alignItems: "center" }}>
          {!value ? null : loading ? (
            "Searching"
          ) : available ? (
            <>
              <CheckCircleIcon color="green" size={12} />
              <Text sx={{ ml: 1 }}>Available!</Text>
            </>
          ) : (
            <>
              <XCircleIcon color="red" size={12} />
              <Text sx={{ ml: 1, color: "red" }}>Unavailable</Text>
            </>
          )}
        </Flex>
      </Flex>
    );
  },
);

Slug.displayName = "Slug";
