import { useState, useEffect, useReducer, Dispatch } from "react";

import { Resources } from "src/contexts/user-context";
import {
  DestinationDefinition,
  DestinationQuery,
  InitialQuery,
  ModelQuery,
  SourceDefinition,
  SyncQuery,
  useDestinationQuery,
  useModelQuery,
  useSampleDataSourceDefinitionsQuery,
  useSourceDefinitionsQuery,
  useSyncQuery,
  useUpdateWorkspaceMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { useDestinations } from "src/utils/destinations";
import { UseSourceResult, useSource } from "src/utils/sources";

type Source = NonNullable<UseSourceResult["data"]>;

export interface OnboardingData {
  declareSource: SourceDefinition | undefined;
  declareDestination: DestinationDefinition | undefined;
  declareComplete: boolean;
  onboardingSource: Source | undefined;
  onboardingDestination?: DestinationQuery["destinations_by_pk"];
  onboardingModel?: ModelQuery["segments_by_pk"];
  onboardingSync?: SyncQuery["syncs"][0];
  currentStep: number;
}

export interface OnboardingLoading {
  source: boolean;
  destination: boolean;
  model: boolean;
  rest: boolean;
  initial: boolean;
  all: boolean;
}

interface OnboardingDataStorage {
  declareSource?: string;
  declareDestination?: string;
  declareComplete: boolean;
  onboardingSource?: string;
  onboardingDestination?: string;
  onboardingModel?: string;
  onboardingSync?: string;
}

export type OnboardingActions =
  | {
      type: "set.declareSource";
      source: SourceDefinition | undefined;
    }
  | {
      type: "set.declareDestination";
      destination: DestinationDefinition;
    }
  | {
      type: "set.declareComplete";
      complete: boolean;
    }
  | {
      type: "set.onboardingSource";
      sourceId: string | undefined;
      definition: { type: string | undefined } | undefined;
    }
  | {
      type: "set.onboardingDestination";
      destinationId: string | undefined;
      definition: { type: string | undefined };
    }
  | {
      type: "set.onboardingModel";
      modelId: string;
    }
  | {
      type: "set.onboardingSync";
      syncId: string;
    }
  | {
      type: "set.switchSource";
      definitionName: string;
    }
  | {
      type: "clear.onboardingSource";
    }
  | {
      type: "clear.onboardingDestination";
    }
  | {
      type: "clear.onboardingModel";
    }
  | {
      type: "clear.onboardingSync";
    };

const reducer = (prev: OnboardingDataStorage, action: OnboardingActions): OnboardingDataStorage => {
  switch (action.type) {
    case "set.declareSource":
      return { ...prev, declareSource: action.source?.type, onboardingSource: undefined };
    case "set.switchSource":
      return { ...prev, declareSource: action.definitionName, onboardingSource: undefined };
    case "set.declareDestination":
      return { ...prev, declareDestination: action.destination.type, onboardingDestination: undefined };
    case "set.declareComplete":
      return { ...prev, declareComplete: action.complete };
    case "set.onboardingSource":
      return { ...prev, onboardingSource: action.sourceId, declareSource: action.definition?.type, onboardingModel: undefined };
    case "set.onboardingDestination":
      return {
        ...prev,
        onboardingDestination: action.destinationId,
        declareDestination: action.definition.type,
      };
    case "set.onboardingModel":
      return { ...prev, onboardingModel: action.modelId };
    case "set.onboardingSync":
      return { ...prev, onboardingSync: action.syncId };

    case "clear.onboardingSource":
      return { ...prev, onboardingSource: undefined };
    case "clear.onboardingDestination":
      return { ...prev, onboardingDestination: undefined };
    case "clear.onboardingModel":
      return { ...prev, onboardingModel: undefined };
    case "clear.onboardingSync":
      return { ...prev, onboardingSync: undefined };

    default:
      throw new Error("Invalid action type");
  }
};

const useGetHydratedOnboardingData = (onboardingData: OnboardingDataStorage, dispatch: Dispatch<OnboardingActions>) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const externalValue: OnboardingData = {
    ...onboardingData,
    onboardingSource: undefined,
    onboardingDestination: undefined,
    onboardingModel: undefined,
    onboardingSync: undefined,
    declareSource: undefined,
    declareDestination: undefined,
    currentStep: 0,
  };
  const { data: sourceDefinitions, isLoading: sourceDefinitionsLoading } = useSourceDefinitionsQuery();
  const { data: sampleDataSources, isLoading: sampleDataSourcesLoading } = useSampleDataSourceDefinitionsQuery();
  const { data: destinations, loading: destinationsLoading } = useDestinations();
  if (!sourceDefinitionsLoading && !sampleDataSourcesLoading && onboardingData.declareSource) {
    let definition = sourceDefinitions?.getSourceDefinitions?.find(
      (sourceDefinition) => sourceDefinition.type === onboardingData.declareSource,
    );
    if (!definition) {
      definition = sampleDataSources?.getSampleDataSourceDefinitions?.find(
        (sampleDataSource) => sampleDataSource.type === onboardingData.declareSource,
      );
    }

    if (definition) {
      externalValue.declareSource = definition;
    }
  }
  if (!destinationsLoading && onboardingData.declareDestination) {
    const val = destinations?.definitions?.find((def) => def.type === onboardingData.declareDestination);
    if (val) {
      externalValue.declareDestination = val;
    }
  }
  const { data: source, loading: sourceLoading } = useSource(onboardingData.onboardingSource ?? "", {
    pause: !onboardingData.onboardingSource,
  });
  if (!sourceLoading && onboardingData.onboardingSource) {
    if (source) {
      externalValue.onboardingSource = source;
    } else {
      dispatch({
        type: "clear.onboardingSource",
      });
    }
  }

  const { data: destinationData, isLoading: destinationLoading } = useDestinationQuery(
    {
      id: onboardingData.onboardingDestination ?? "",
    },
    { enabled: Boolean(onboardingData.onboardingDestination) },
  );

  if (!destinationLoading && onboardingData.onboardingDestination) {
    if (destinationData?.destinations_by_pk) {
      externalValue.onboardingDestination = destinationData?.destinations_by_pk;
    } else {
      dispatch({
        type: "clear.onboardingDestination",
      });
    }
  }

  const { data: modelData, isLoading: modelLoading } = useModelQuery(
    {
      id: onboardingData.onboardingModel ?? "",
    },
    { enabled: Boolean(onboardingData.onboardingModel) },
  );
  if (!modelLoading && onboardingData.onboardingModel) {
    if (modelData?.segments_by_pk) {
      externalValue.onboardingModel = modelData?.segments_by_pk;
      if (String(externalValue.onboardingModel.connection?.id) !== String(onboardingData.onboardingSource)) {
        analytics.track("Onboarding model overriding source", {
          old_source: onboardingData.onboardingSource,
          old_source_type: onboardingData.declareSource,
          new_source: externalValue.onboardingModel.connection?.id,
          new_source_type: externalValue.onboardingModel.connection?.type,
          model: externalValue.onboardingModel.id,
        });
        dispatch({
          type: "set.onboardingSource",
          sourceId: externalValue.onboardingModel.connection?.id,
          definition: { type: externalValue.onboardingModel.connection?.type },
        });
      }
    } else {
      dispatch({
        type: "clear.onboardingModel",
      });
    }
  }

  const { data: syncData, isLoading: syncLoading } = useSyncQuery(
    {
      id: onboardingData.onboardingSync ?? "",
    },
    { enabled: Boolean(onboardingData.onboardingSync) },
  );
  if (!syncLoading && onboardingData.onboardingSync) {
    if (syncData?.syncs && syncData?.syncs[0]) {
      externalValue.onboardingSync = syncData?.syncs[0];
      if (externalValue.onboardingSync.segment?.id !== onboardingData.onboardingModel) {
        analytics.track("Onboarding sync overriding model", {
          old_model: onboardingData.onboardingModel,
          new_model: externalValue.onboardingSync.segment?.id,
          sync: externalValue.onboardingSync.id,
        });
        dispatch({
          type: "set.onboardingModel",
          modelId: externalValue.onboardingSync.segment?.id,
        });
      }
      if (externalValue.onboardingSync.destination?.id !== onboardingData.onboardingDestination) {
        analytics.track("Onboarding sync overriding destination", {
          old_destination: onboardingData.onboardingDestination,
          old_destination_type: onboardingData.declareDestination,
          new_destination: externalValue.onboardingSync.destination?.id,
          new_destination_type: externalValue.onboardingSync.destination?.type,
          sync: externalValue.onboardingSync.id,
        });
        dispatch({
          type: "set.onboardingDestination",
          destinationId: externalValue.onboardingSync.destination?.id,
          definition: { type: externalValue.onboardingSync.destination?.type },
        });
      }
    } else {
      dispatch({
        type: "clear.onboardingSync",
      });
    }
  }

  externalValue.currentStep = calculateCurrentStep(externalValue);

  const onboardingAllLoading =
    sourceLoading || destinationLoading || modelLoading || syncLoading || sourceDefinitionsLoading || destinationsLoading;
  if (!onboardingAllLoading && initialLoading) {
    setInitialLoading(false);
  }
  const onboardingLoading: OnboardingLoading = {
    all: onboardingAllLoading,
    source: sourceLoading,
    destination: destinationLoading,
    model: modelLoading,
    rest: syncLoading || sourceDefinitionsLoading || destinationsLoading,
    initial: initialLoading,
  };

  return [externalValue, onboardingLoading] as const;
};

const initialState = (workspace: InitialQuery["workspaces"][0], initialOnboardingData: Resources | undefined) => {
  const savedValue: OnboardingDataStorage | undefined = workspace.onboarding;
  const ret = {
    declareSource: undefined,
    declareDestination: undefined,
    declareComplete: false,
    onboardingSource: initialOnboardingData?.source?.id,
    onboardingDestination: initialOnboardingData?.destination?.id,
    onboardingModel: initialOnboardingData?.model?.id,
    onboardingSync: initialOnboardingData?.sync?.id,
    ...savedValue,
  };
  if (!savedValue?.onboardingSource && initialOnboardingData?.source?.id) {
    ret.declareSource = initialOnboardingData?.source?.type;
  }
  if (!savedValue?.onboardingDestination && initialOnboardingData?.destination?.id) {
    ret.declareDestination = initialOnboardingData?.destination?.type;
  }
  return ret;
};

export const useOnboardingData = (workspace: InitialQuery["workspaces"][0], initialOnboardingData: Resources | undefined) => {
  const { mutateAsync: updateWorkspace } = useUpdateWorkspaceMutation();
  const [onboardingData, dispatch] = useReducer(reducer, initialState(workspace, initialOnboardingData));
  const [externalValue, onboardingLoading] = useGetHydratedOnboardingData(onboardingData, dispatch);
  useEffect(() => {
    const variables = {
      id: workspace.id,
      input: { onboarding: onboardingData },
    };
    updateWorkspace(variables);
  }, [workspace.id, onboardingData]);

  return [externalValue, dispatch, onboardingLoading] as const;
};

const calculateCurrentStep = (onboardingData: OnboardingData) => {
  if (onboardingData.declareComplete) {
    if (!onboardingData.onboardingSource) {
      return 1;
    }
    if (!onboardingData.onboardingModel) {
      return 2;
    }
    if (!onboardingData.onboardingDestination) {
      return 3;
    }
    if (!onboardingData.onboardingSync) {
      return 4;
    } else {
      return 5;
    }
  } else {
    return 0;
  }
};
