import { FC } from "react";

import { Grid } from "theme-ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Field } from "src/ui/field";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { FromIdField } from "../from-id-field";
import { MappingsField } from "../mappings-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";

export const validation = Yup.object().shape({
  mode: Yup.string().required("upsert"),
  object: Yup.string().required(),
  fromId: Yup.mixed().required(),
  accountFromId: Yup.mixed().when("object", {
    is: "users",
    then: Yup.mixed().required(),
    otherwise: Yup.mixed().notRequired(),
  }),
  mappings: COMMON_SCHEMAS.mappings,

  customMappings: COMMON_SCHEMAS.mappings,
});

const USERS_OPTIONS = [
  { label: "Name", value: "name" },
  { label: "First Name", value: "First Name" },
  { label: "Last Name", value: "Last Name" },
  { label: "Email", value: "Email" },
  { label: "Title", value: "Title" },
];

const OPTIONS = [
  { label: "Name", value: "name" },
  { label: "Type", value: "type" },
  { label: "Status", value: "status" },
  { label: "Company Size", value: "Company Size" },
  { label: "Contract Value", value: "Contract Value" },
  { label: "Cancellation Date", value: "Cancellation Date" },
  { label: "Contract Renewal Date", value: "Contract Renewal Date" },
  { label: "Contract Start Date", value: "Contract Start Date" },
];

const MODES = [{ label: "Upsert", value: "upsert" }];

const OBJECTS = [
  { label: "Accounts", value: "accounts" },
  { label: "Account Users", value: "users" },
];

export const TotangoForm: FC = () => {
  const { hightouchColumns, config, setConfig, errors, reloadModel, loadingModel } = useDestinationForm();

  return (
    <>
      <ObjectField options={OBJECTS} />

      <ModeField options={MODES} onChange={(mode) => setConfig({ object: config?.object, mode })} />
      {config?.object && (
        <Section>
          <Grid gap={8}>
            <FromIdField fieldName={`Totango ${config?.object === "users" ? "User" : "Account"} ID`} />

            {config?.object === "users" && (
              <Field error={errors?.accountFromId} label="Which column contains the account ID that the user belongs to?">
                <Select
                  isError={errors?.accountFromId}
                  isLoading={loadingModel}
                  options={hightouchColumns}
                  placeholder="Select a column..."
                  reload={reloadModel}
                  value={config?.accountFromId}
                  width="340px"
                  onChange={(selected) => {
                    const val = selected.value;
                    setConfig({ ...config, accountFromId: val });
                  }}
                />
              </Field>
            )}
          </Grid>
        </Section>
      )}
      {config?.object && (
        <>
          <Section>
            <MappingsField options={config?.object === "users" ? USERS_OPTIONS : OPTIONS} />
          </Section>
          <Section>
            <MappingsField isCustom />
          </Section>
        </>
      )}
    </>
  );
};

export default {
  form: TotangoForm,
  validation,
};
