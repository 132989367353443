import { FC } from "react";

import { Text } from "theme-ui";

import { Column, Row } from "src/ui/box";
import { Checkbox } from "src/ui/checkbox";
import { Input } from "src/ui/input";

import { StaticType, STATIC_OPTIONS, STATIC_ICONS, FormProps } from "./types";

export const StaticInput: FC<Readonly<FormProps>> = ({ value, onChange }) => {
  const active = {
    bg: "primaries.0",
    color: "primary",
    ":hover": {},
  };

  const getInput = () => {
    switch (value.valueType) {
      case StaticType.STRING:
        return <Input placeholder="Enter string" value={value.value} onChange={(val) => onChange({ ...value, value: val })} />;
      case StaticType.NUMBER:
        return (
          <Input
            placeholder="Enter number"
            type="number"
            value={value.value}
            onChange={(val) => onChange({ ...value, value: val })}
          />
        );
      case StaticType.BOOLEAN:
        return (
          <Row
            sx={{
              borderRadius: 1,
              border: "small",
              borderColor: "primaries.2",
              fontWeight: "semi",
              fontSize: 0,
              cursor: "pointer",
            }}
          >
            <Text
              sx={{
                px: 3,
                py: 2,
                ":hover": { bg: "base.0" },
                ...(value.value === true ? active : {}),
              }}
              onClick={() => onChange({ ...value, value: true })}
            >
              True
            </Text>
            <Text
              sx={{
                px: 3,
                py: 2,
                ":hover": { bg: "base.0" },
                ...(value.value === false ? active : {}),
              }}
              onClick={() => onChange({ ...value, value: false })}
            >
              False
            </Text>
          </Row>
        );
      case StaticType.NULL:
        return (
          <Checkbox
            label="Send a null value to your destination"
            value={value.value === null}
            onChange={(val) => onChange({ ...value, value: val ? null : undefined })}
          />
        );
      default:
        return undefined;
    }
  };

  return (
    <Row sx={{ flex: 1 }}>
      <Column sx={{ p: 3, borderRight: "small", width: "180px" }}>
        {STATIC_OPTIONS.map(({ label, type }) => {
          const active = type === value.valueType;
          const Icon = STATIC_ICONS[type];

          return (
            <Row
              key={label}
              sx={{
                fontWeight: "semi",
                p: 2,
                borderRadius: 1,
                cursor: "pointer",
                minHeight: "32px",
                alignItems: "center",
                fontSize: 0,
                flex: 0,
                px: 2,
                bg: active ? "primaries.0" : undefined,
                ":hover": { bg: active ? undefined : "base.0" },
              }}
              onClick={() => onChange({ type: "static", valueType: type })}
            >
              <Icon color="base.5" size={18} sx={{ mr: 2 }} />
              <Text sx={{ color: active ? "primary" : "base.5" }}>{label}</Text>
            </Row>
          );
        })}
      </Column>
      <Column sx={{ flex: 1, p: 3 }}>
        <Row>{getInput()}</Row>
      </Column>
    </Row>
  );
};
