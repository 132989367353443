import { FC, useState } from "react";

import { Text, Grid } from "theme-ui";

import { Button } from "src/ui/button";
import { Editor } from "src/ui/editor";
import { Field } from "src/ui/field";
import { Modal } from "src/ui/modal";
import { Strike } from "src/utils/strike";

type Look = {
  id: string;
  title: string;

  // description is optional on a Look
  description: string | null;

  sql: string | null;
};

type Props = {
  look: Look;
  oldLook?: Look;
};

export const LookerQuery: FC<Readonly<Props>> = ({ look, oldLook }) => {
  const [showSql, setShowSql] = useState<boolean>(false);

  return (
    <Grid gap={3}>
      <Field inline label="Title">
        <Text>
          <Strike _new={look?.title} old={oldLook?.title} />
        </Text>
      </Field>

      <Field inline label="ID">
        <Text>
          <Strike _new={look?.id} old={oldLook?.id} />
        </Text>
      </Field>

      {look?.description && (
        <Field inline label="Description">
          <Strike _new={look?.description} old={oldLook?.description || ""} />
        </Field>
      )}

      {look?.sql && <Button label="View SQL" variant="secondary" onClick={() => setShowSql(true)} />}

      <Modal
        info
        bodySx={{ p: 0 }}
        isOpen={showSql}
        sx={{ maxWidth: "800px", width: "90%", height: "90%" }}
        title="SQL"
        onClose={() => setShowSql(false)}
      >
        <Editor code={look?.sql ?? ""} language="sql" sx={{ height: "100%", width: "100%" }} />
      </Modal>
    </Grid>
  );
};
