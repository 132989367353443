import { BooleanIcon, CalendarIcon, NullIcon, NumberIcon, StringIcon } from "src/ui/icons";

export type TemplateInfo = {
  name: string;
  description: string;
  placeholders?: string[];
};

export type FormProps = {
  value: any;
  templates: TemplateInfo[];
  onChange: (value: any) => void;
};

export type Props = {
  isError?: boolean;
  placeholder?: string;
} & FormProps;

export type Mapping = StandardMapping | StaticMapping | VariableMapping | TemplateMapping;

export interface StandardMapping {
  type: MappingType.STANDARD;
  from: unknown;
  to: string;
}

export interface StaticMapping {
  type: MappingType.STATIC;
  to: string;
  value: unknown;
  valueType: string;
}

export interface VariableMapping {
  type: MappingType.VARIABLE;
}

export interface TemplateMapping {
  type: MappingType.TEMPLATE;
}

export enum MappingType {
  STANDARD = "standard",
  STATIC = "static",
  VARIABLE = "variable",
  TEMPLATE = "template",
}

export enum StaticType {
  STRING = "STRING",
  NUMBER = "NUMBER",
  BOOLEAN = "BOOLEAN",
  DATETIME = "DATETIME",
  NULL = "Null",
}

export const STATIC_OPTIONS = [
  {
    label: "String",
    type: StaticType.STRING,
  },
  {
    label: "Number",
    type: StaticType.NUMBER,
  },
  {
    label: "Boolean",
    type: StaticType.BOOLEAN,
  },
  // TODO: Implement new design for timestamp input
  // {
  //   label: "Timestamp",
  //   type: StaticType.TIMESTAMP,
  // },
  {
    label: "Null",
    type: StaticType.NULL,
  },
];

export const STATIC_ICONS = {
  [StaticType.STRING]: StringIcon,
  [StaticType.NUMBER]: NumberIcon,
  [StaticType.BOOLEAN]: BooleanIcon,
  [StaticType.DATETIME]: CalendarIcon,
  [StaticType.NULL]: NullIcon,
};
