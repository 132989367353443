import { FC } from "react";

import { Box, Heading, Image, Text } from "theme-ui";

import { Button } from "src/ui/button";
import { ArrowRightIcon, CheckCircleIcon } from "src/ui/icons";
import { Modal } from "src/ui/modal";

import { colors } from "../../../../design/colors";

type EducationModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  sourceIcon: string | undefined;
  sourceTitle: string | undefined;
  destinationIcon: string | undefined;
  destinationTitle: string | undefined;
};

export const EducationModal: FC<EducationModalProps> = ({
  isOpen,
  onCancel,
  onConfirm,
  sourceIcon,
  sourceTitle,
  destinationIcon,
  destinationTitle,
}) => {
  return (
    <Modal
      bodySx={{ background: "white", pb: 3 }}
      footer={
        <Button size="large" sx={{ mx: "auto", width: "250px" }} onClick={onConfirm}>
          Get started
        </Button>
      }
      footerSx={{ border: "none", display: "block", pt: 0, pb: 8 }}
      header={
        <Box sx={{ width: "100%", mt: 6, mb: 0 }}>
          <Heading sx={{ fontWeight: 600, fontSize: "26px", textAlign: "center" }} variant="h2">
            In the upcoming steps, you will...
          </Heading>
        </Box>
      }
      headerSx={{ border: "none" }}
      isOpen={isOpen}
      title="Here's what's coming up..."
      onClose={onCancel}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", px: 4, pb: 6 }}>
        <Box sx={{ maxWidth: "215px", mr: 6 }}>
          <Text sx={{ fontSize: "16px", fontWeight: 600 }}>1. Connect your source</Text>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              borderRadius: 8,
              border: `2px solid ${colors.base[3]}`,
              p: 4,
              width: "100%",
              my: 4,
              position: "relative",
            }}
          >
            <Image alt={sourceTitle} src={sourceIcon} width={36} />
            <Text sx={{ fontWeight: 600, ml: 4, fontSize: "14px", flexGrow: 1 }}>{sourceTitle}</Text>
            <CheckCircleIcon
              color={colors.green}
              sx={{ bg: colors.white, position: "absolute", top: "-10px", right: "-10px" }}
            />
          </Box>
        </Box>
        <Box sx={{ maxWidth: "215px", mx: 6 }}>
          <Text sx={{ fontSize: "16px", fontWeight: 600 }}>2. Set up a model</Text>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              borderRadius: 8,
              border: `2px solid ${colors.base[3]}`,
              height: "72px",
              width: "100%",
              my: 4,
              position: "relative",
            }}
          >
            <Box
              sx={{
                height: "100%",
                bg: colors.base[1],
                width: "32px",
                borderRadius: "8px 0 0 8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ bg: colors.forest, textAlign: "center", width: "100%", color: colors.white }}>1</Box>
            </Box>
            <Text sx={{ mx: 4, fontSize: "14px", color: colors.base[7], flexGrow: 1 }}>
              <strong>select</strong> * from users;
            </Text>
            <CheckCircleIcon
              color={colors.green}
              sx={{ bg: colors.white, position: "absolute", top: "-10px", right: "-10px" }}
            />
          </Box>
        </Box>
        <Box sx={{ maxWidth: "215px", mx: 6 }}>
          <Text sx={{ fontSize: "16px", fontWeight: 600 }}>3. Connect your destination</Text>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              borderRadius: 8,
              border: `2px solid ${colors.base[3]}`,
              p: 4,
              width: "100%",
              my: 4,
              position: "relative",
            }}
          >
            <Image alt={destinationTitle} src={destinationIcon} width={36} />
            <Text sx={{ fontWeight: 600, ml: 4, fontSize: "14px" }}>{destinationTitle}</Text>
            <CheckCircleIcon
              color={colors.green}
              sx={{ bg: colors.white, position: "absolute", top: "-10px", right: "-10px" }}
            />
          </Box>
        </Box>
        <Box sx={{ maxWidth: "215px", ml: 6 }}>
          <Text sx={{ fontSize: "16px", fontWeight: 600 }}>4. Configure the sync</Text>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              my: 4,
              position: "relative",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                borderRadius: 8,
                border: `2px solid ${colors.base[3]}`,
                p: 4,
                maxWidth: "72px",
              }}
            >
              <Image alt={sourceTitle} src={sourceIcon} width={36} />
            </Box>
            <ArrowRightIcon color={colors.base[3]} width={24} />
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                borderRadius: 8,
                border: `2px solid ${colors.base[3]}`,
                p: 4,
                maxWidth: "72px",
              }}
            >
              <Image alt={destinationTitle} src={destinationIcon} width={36} />
            </Box>
            <CheckCircleIcon
              color={colors.green}
              sx={{ bg: colors.white, position: "absolute", top: "-10px", right: "-10px" }}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
