import { useEffect, useState, useMemo, FC, ReactElement } from "react";

import moment from "moment";
import { useQueryClient } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { Flex, Text } from "theme-ui";
import { isPresent } from "ts-extras";

import { Page } from "src/components/layout";
import { SyncRequestErrorModal } from "src/components/syncs/error-modals";
import { SyncName } from "src/components/syncs/sync-name";
import { useUser } from "src/contexts/user-context";
import {
  useAttemptedRowsByPrimaryKeyQuery,
  useAttemptedRowsQuery,
  useSyncAttemptQuery,
  useSyncQuery,
  SyncOp,
  AttemptedRowsQuery,
} from "src/graphql";
import { SyncRequestErrorInfo } from "src/types/sync-errors";
import { Badge, SquareBadge } from "src/ui/badge";
import { Row, Column } from "src/ui/box";
import { Button } from "src/ui/button";
import { Checkbox } from "src/ui/checkbox";
import { ChevronDownIcon } from "src/ui/icons";
import { SearchInput } from "src/ui/input";
import { PageSpinner } from "src/ui/loading";
import { Menu } from "src/ui/menu";
import { Modal } from "src/ui/modal";
import { Table, SimplePagination, TableColumn } from "src/ui/table";
import { Tabs } from "src/ui/tabs";
import { useDestination } from "src/utils/destinations";
import { downloadJson, downloadCsv } from "src/utils/download";
import { useSource } from "src/utils/sources";
import { SyncStatus, SyncStatusBadge, getSyncAttemptDiff, DEPRECATED_ERROR } from "src/utils/syncs";
import * as time from "src/utils/time";
import { openUrl } from "src/utils/urls";

import ShipImage from "../../components/syncs/ship.svg";

type AttemptedRow = NonNullable<AttemptedRowsQuery["getAttemptedRows"]["rows"][0]>;

enum Tab {
  SUCCESSFUL = "Successful",
  REJECTED = "Rejected",
}

export const Run: FC = () => {
  const client = useQueryClient();
  const navigate = useNavigate();
  const { run_id: runId, sync_id: syncId } = useParams<{ run_id: string; sync_id: string }>();

  const [tab, setTab] = useState<Tab>(Tab.SUCCESSFUL);
  const [page, setPage] = useState<number>(0);
  const [pageKeys, setPageKeys] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [showAdded, setShowAdded] = useState<boolean>(true);
  const [showChanged, setShowChanged] = useState<boolean>(true);
  const [showRemoved, setShowRemoved] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [nextLoading, setNextLoading] = useState<boolean>(false);
  const [previousLoading, setPreviousLoading] = useState<boolean>(false);
  const [runError, setRunError] = useState<SyncRequestErrorInfo>();
  const [rowError, setRowError] = useState<string>("");
  const [showErrorExport, setShowErrorExport] = useState<boolean>(false);
  const [showExport, setShowExport] = useState<boolean>(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [showUnsupported, setShowUnsupported] = useState<boolean>(false);

  // XXX: As a hack, we disable showing row-level information for regular
  // viewers for one of our customers. This is not meant to be secure, and
  // just prevents accidentally viewing PII data. We should remove this
  // once we have first-class support for this via our permissioning
  // system.
  const { user } = useUser();
  const userId = user?.id;

  const { data: syncData, isLoading: syncLoading } = useSyncQuery(
    {
      id: syncId ?? "",
    },
    { enabled: Boolean(syncId) },
  );

  const sync = syncData?.syncs?.[0];
  const model = sync?.segment;

  const {
    data: { destination, definition: destinationDefinition },
    loading: destinationLoading,
  } = useDestination(sync?.destination?.id, { pause: !sync });

  const { data: source, loading: sourceLoading } = useSource(String(model?.connection?.id), { pause: !sync });

  const isDataProtected =
    // We don't populate the results-index for clean room sources anyway,
    // but we can show a better UI for those sources.
    Boolean(source?.definition.cleanRoomType) ||
    // This is a hack for a specific customer.
    // See https://github.com/hightouchio/hightouch/pull/2887.
    (syncId === "27126" && userId !== 63366);

  const { data: attemptData, isLoading: syncAttemptLoading } = useSyncAttemptQuery(
    {
      syncRequestId: runId ?? "",
    },
    {
      refetchInterval: (attemptData) => {
        if (attemptData?.sync_attempts?.[0]?.finished_at) {
          // Stop polling if this sync attempt is finished.
          return false;
        }

        return 3000;
      },
    },
  );

  const showSuccessful = tab === Tab.SUCCESSFUL;
  const showRejected = tab === Tab.REJECTED;

  const attempt = attemptData?.sync_attempts?.[0];
  const syncRequest = attempt?.sync_request;
  const syncError = syncRequest?.error ?? (attempt?.error ? { message: attempt.error } : undefined);
  const primaryKey = syncRequest?.sync?.segment?.primary_key;
  const resyncReason = syncRequest?.resync_reason;
  const plannerType = syncRequest?.planner_type;

  const diff = getSyncAttemptDiff(attempt);
  const added = (syncRequest?.add_executed ?? 0) - (diff?.rejected?.add ?? 0);
  const changed = (syncRequest?.change_executed ?? 0) - (diff?.rejected?.change ?? 0);
  const removed = (syncRequest?.remove_executed ?? 0) - (diff?.rejected?.remove ?? 0);

  const successfulRows = added + changed + removed;
  const rejectedRows = (diff?.rejected?.add ?? 0) + (diff?.rejected?.change ?? 0) + (diff?.rejected?.remove ?? 0);

  const addedRows: number | undefined | null = showSuccessful ? added : diff?.rejected?.add;
  const changedRows: number | undefined | null = showSuccessful ? changed : diff?.rejected?.change;
  const removedRows: number | undefined | null = showSuccessful ? removed : diff?.rejected?.remove;

  let totalRows = 0;
  const opTypes: SyncOp[] = [];

  if (showAdded) {
    totalRows += addedRows ?? 0;
    opTypes.push(SyncOp.Added);
  }
  if (showChanged) {
    totalRows += changedRows ?? 0;
    opTypes.push(SyncOp.Changed);
  }
  if (showRemoved) {
    totalRows += removedRows ?? 0;
    opTypes.push(SyncOp.Removed);
  }

  const limit = 10;
  const pages = Math.ceil(totalRows / limit);
  const hasRows = Number(addedRows || 0) + Number(changedRows || 0) + Number(removedRows || 0) > 0;

  let {
    data: attemptedRowsData,
    isLoading: attemptedRowsLoading,
    error: attemptedRowsQueryError,
  } = useAttemptedRowsQuery(
    {
      destinationInstanceId: Number(syncId),
      syncRequestId: Number(runId),
      onlyRejected: showRejected,
      onlySuccessful: showSuccessful,
      pageKey: pageKeys.slice(-1)[0],
      opTypes,
      limit,
    },
    {
      keepPreviousData: true,

      // When disabled, this will return previous data.
      enabled: hasRows,
    },
  );
  if (!hasRows) {
    // Don't use previous data if attemptedRowsQuery is disabled.
    attemptedRowsData = {
      getAttemptedRows: {
        rows: [],
        nextPageKey: null,
      },
    };
    attemptedRowsLoading = false;
    attemptedRowsQueryError = null;
  }

  const { data: attemptedRowsByPKData } = useAttemptedRowsByPrimaryKeyQuery(
    {
      destinationInstanceId: Number(syncId),
      syncRequestId: Number(runId),
      id: search,
    },
    { enabled: Boolean(search) },
  );

  const data = search ? attemptedRowsByPKData?.getAttemptedRowsByPrimaryKey?.rows : attemptedRowsData?.getAttemptedRows?.rows;

  const rows = useMemo(
    () =>
      (data || []).filter(isPresent).map(({ id, opType, rejectionReason, fields, batchId, requestInfoKeys, isBatchError }) => ({
        hightouchRowId: id,
        opType,
        rejectionReason,
        batchId,
        requestInfoKeys,
        isBatchError,
        fields,
        ...JSON.parse(fields),
      })),
    [data],
  );

  // Rows are no longer available when the run is older than a week
  const isRowsDataExpired = useMemo(() => {
    if (!syncRequest || rows.length > 0) {
      return false;
    }

    const weekAgo = moment().subtract(1, "week");
    return moment(syncRequest.created_at).isBefore(weekAgo);
  }, [syncRequest, rows]);

  const columns: TableColumn[] = [
    {
      key: "opType",
      name: "Type",
      max: "120px",
      cell: (opType) => getOpTypeBadge(opType),
    },
  ];

  if (showRejected) {
    columns.push({
      key: "rejectionReason",
      name: "Error",
      max: "150px",
      cell: (rejectionReason: string) =>
        rejectionReason ? (
          <Button
            propagate={false}
            size="small"
            variant="secondary"
            onClick={(event) => {
              event.preventDefault();
              setRowError(rejectionReason);
            }}
          >
            View error
          </Button>
        ) : null,
    });
  }

  const row = data?.[0];

  if (row) {
    const fields = JSON.parse(row.fields);
    Object.keys(fields).forEach((key, i) => {
      columns.push({
        key,
        name: key,
        cell: (value) => (
          <Text sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "600px" }}>
            {typeof value === "object" ? JSON.stringify(value) : String(value)}
          </Text>
        ),
        divider: i === 0,
      });
    });
  }

  const resetPagination = () => {
    setPageKeys([]);
    setPage(0);
  };

  useEffect(resetPagination, [tab]);
  useEffect(resetPagination, [showAdded, showChanged, showRemoved]);

  const downloadErrors = async (format, rejectedOnly) => {
    let pageKey;
    let rows: AttemptedRow[] = [];
    setExportLoading(true);

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const variables = {
        destinationInstanceId: Number(syncId),
        syncRequestId: Number(runId),
        opTypes: [SyncOp.Added, SyncOp.Changed, SyncOp.Removed],
        onlyRejected: rejectedOnly,
        onlySuccessful: false,
        pageKey,
        limit: 100,
      };

      try {
        const { getAttemptedRows } = await client.fetchQuery(
          useAttemptedRowsQuery.getKey(variables),
          useAttemptedRowsQuery.fetcher(variables),
        );

        if (getAttemptedRows) {
          rows = [...rows, ...getAttemptedRows.rows].filter(isPresent);
          pageKey = getAttemptedRows.nextPageKey;
        }

        if (!pageKey || !getAttemptedRows) {
          break;
        }
      } catch (e) {
        break;
      }
    }

    if (rows.length && format === "json") {
      const rowsToDownload = rows.map(({ opType, rejectionReason, fields }) => ({
        type: opType,
        error: rejectionReason,
        fields: JSON.parse(fields),
      }));

      downloadJson(rowsToDownload, `export-${moment(syncRequest?.created_at).format()}.json`);
    }

    if (rows.length && format === "csv") {
      const rowsToDownload = rows.map(({ opType, rejectionReason, fields }) => ({
        __ht_type: opType,
        __ht_error: rejectionReason,
        ...JSON.parse(fields),
      }));

      downloadCsv(rowsToDownload, `export-${moment(syncRequest?.created_at).format()}.csv`);
    }

    setExportLoading(false);
    setShowErrorExport(false);
    setShowExport(false);
  };

  const redirectBackToSync = () => {
    navigate(`/syncs/${syncId}`);
  };

  useEffect(() => {
    if (nextLoading) {
      setPage((page) => page + 1);
      setNextLoading(false);
    }
    if (previousLoading) {
      setPage((page) => page - 1);
      setPreviousLoading(false);
    }
  }, [rows, setPage, setNextLoading, setPreviousLoading]);

  if (destinationLoading || sourceLoading || syncLoading || syncAttemptLoading) {
    return <PageSpinner />;
  }

  return (
    <>
      <Page
        crumbs={[
          { label: "Syncs", link: "/syncs" },
          { label: "Sync", link: `/syncs/${syncId}` },
          {
            label: "Run",
          },
        ]}
      >
        <Row sx={{ justifyContent: "space-between", mb: 5, width: "100%", borderBottom: "small", pb: 2 }}>
          <SyncName
            destination={destination}
            destinationDefinition={destinationDefinition}
            model={model}
            source={source}
            sync={sync}
          />
        </Row>

        <Row gap={8} sx={{ alignSelf: "flex-start", mb: 6 }}>
          <Column sx={{ pr: 10 }}>
            <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 1 }}>Status</Text>
            <SyncStatusBadge request={syncRequest} />
          </Column>

          <Column sx={{ pl: 3, pr: 10, borderLeft: "small" }}>
            <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 1 }}>Started at</Text>
            <Text sx={{ textTransform: "capitalize", fontWeight: "semi" }}>{time.formatDatetime(syncRequest?.created_at)}</Text>
          </Column>
          {attempt?.finished_at && (
            <Column sx={{ pl: 3, pr: 10, borderLeft: "small" }}>
              <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 1 }}>Duration</Text>
              <Text sx={{ textTransform: "capitalize", fontWeight: "semi" }}>
                {time.diff(attempt?.created_at, attempt?.finished_at)}
              </Text>
            </Column>
          )}
          <Column sx={{ pl: 3, borderLeft: "small" }}>
            <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 1 }}>Run ID</Text>
            <Text sx={{ textTransform: "capitalize", fontWeight: "semi" }}>{runId}</Text>
          </Column>
          {resyncReason && (
            <Column sx={{ pl: 3, borderLeft: "small" }}>
              <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 1 }}>
                Resync reason
              </Text>
              <Text sx={{ textTransform: "capitalize", fontWeight: "semi" }}>
                {{
                  "explicit-request": "Triggered by user",
                  "added-fields": "Fields were added",
                  "changed-config": "Configuration was changed",
                  "changed-mappings": "Mappings changed",
                  "changed-source-types": "Source types changed",
                }[resyncReason] ?? resyncReason}
              </Text>
            </Column>
          )}
        </Row>

        <Tabs
          setTab={(tab) => setTab(tab as Tab)}
          tab={tab}
          tabs={[
            {
              render: () => (
                <Row sx={{ alignItems: "center" }}>
                  <Text sx={{ mr: 2 }}>Successful</Text>
                  <SquareBadge>{successfulRows}</SquareBadge>
                </Row>
              ),
              value: Tab.SUCCESSFUL,
            },
            {
              render: () => (
                <Row sx={{ alignItems: "center" }}>
                  <Text sx={{ mr: 2 }}>Rejected</Text>
                  <SquareBadge>{rejectedRows}</SquareBadge>
                </Row>
              ),
              value: Tab.REJECTED,
            },
          ]}
        />
        <Row sx={{ alignItems: "center", justifyContent: "space-between", width: "100%", flexWrap: "wrap", mb: 3 }}>
          <Row gap={8} sx={{ mt: 4, mr: 4 }}>
            <Checkbox
              disabled={!addedRows}
              label={
                <Row sx={{ alignItems: "center" }}>
                  <Text sx={{ mr: 2 }}>Added</Text>
                  <SquareBadge>{addedRows}</SquareBadge>
                </Row>
              }
              value={addedRows ? showAdded : false}
              onChange={setShowAdded}
            />
            <Checkbox
              disabled={!changedRows}
              label={
                <Row sx={{ alignItems: "center" }}>
                  <Text sx={{ mr: 2 }}>Changed</Text>
                  <SquareBadge>{changedRows}</SquareBadge>
                </Row>
              }
              value={changedRows ? showChanged : false}
              onChange={setShowChanged}
            />
            <Checkbox
              disabled={!removedRows}
              label={
                <Row sx={{ alignItems: "center" }}>
                  <Text sx={{ mr: 2 }}>Removed</Text>
                  <SquareBadge>{removedRows}</SquareBadge>
                </Row>
              }
              value={removedRows ? showRemoved : false}
              onChange={setShowRemoved}
            />
          </Row>
          {!isDataProtected && (
            <Flex sx={{ alignItems: "center", mt: 4 }}>
              {syncError && ![DEPRECATED_ERROR, "Error: " + DEPRECATED_ERROR].includes(syncError.message) && (
                <Button sx={{ mr: 4 }} variant="red" onClick={() => setRunError(syncError)}>
                  View run error
                </Button>
              )}
              <Menu
                options={[
                  { label: "Export rows", onClick: () => setShowExport(true) },
                  { label: "Export row errors", onClick: () => setShowErrorExport(true) },
                ]}
              >
                <Button propagate={true} variant="secondary">
                  <Text sx={{ mr: 2 }}>Export</Text>
                  <ChevronDownIcon color="base.5" size={16} />
                </Button>
              </Menu>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  setSearch(searchInput);
                }}
              >
                <Flex sx={{ alignItems: "center", ml: 2 }}>
                  <SearchInput
                    placeholder={primaryKey ? `Search by ${primaryKey}` : ""}
                    value={searchInput}
                    onChange={(value) => {
                      setSearchInput(value);
                      if (value === "") {
                        setSearch("");
                      }
                    }}
                  />

                  <Button sx={{ ml: 2 }} type="submit" variant="secondary">
                    Search
                  </Button>
                </Flex>
              </form>
            </Flex>
          )}
        </Row>
        <Table
          columns={columns}
          data={isDataProtected ? [] : rows}
          error={!!attemptedRowsQueryError}
          loading={attemptedRowsLoading}
          placeholder={{
            image: isRowsDataExpired ? ShipImage : undefined,
            title:
              plannerType === "all"
                ? "No rows stored for this sync"
                : search
                ? `No rows match your search`
                : isRowsDataExpired
                ? "Sorry, that ship has sailed"
                : isDataProtected
                ? "The run data is protected"
                : `No rows`,
            body:
              plannerType === "all" ? (
                "Unfortunately, we don't store the row data when the sync is processed without diffing and the entire result set is sent"
              ) : search ? (
                <Text>
                  No rows with <strong>{primaryKey}</strong> equal to <strong>{search}</strong>
                </Text>
              ) : isRowsDataExpired ? (
                `We don't store row data for sync runs older than 7 days`
              ) : undefined,
            button: isRowsDataExpired ? (
              <Button variant="secondary" onClick={redirectBackToSync}>
                Go back to sync
              </Button>
            ) : undefined,
            error: attemptedRowsQueryError?.message,
          }}
          onRowClick={(row, event) =>
            !row.requestInfoKeys || !row.requestInfoKeys?.length
              ? setShowUnsupported(true)
              : openUrl(`/syncs/${syncId}/runs/${runId}/debug/${row?.hightouchRowId}`, navigate, event)
          }
        />
        <SimplePagination
          nextLoading={nextLoading}
          page={page}
          pages={pages}
          previousLoading={previousLoading}
          onNext={() => {
            setNextLoading(true);
            setPageKeys((pageKeys) => [...pageKeys, attemptedRowsData?.getAttemptedRows?.nextPageKey].filter(isPresent));
          }}
          onPrevious={() => {
            setPreviousLoading(true);
            setPageKeys((pageKeys) => {
              if (page === 1) {
                return [];
              } else {
                return pageKeys.slice(0, -1);
              }
            });
          }}
        />
      </Page>

      {/* Modal for sync-level errors */}
      <SyncRequestErrorModal
        isOpen={Boolean(runError)}
        syncRequestError={runError}
        syncStatus={sync?.status as SyncStatus}
        onClose={() => setRunError(undefined)}
      />

      {/* Modal for row errors */}
      <Modal
        footer={
          <Button variant="secondary" onClick={() => setRowError("")}>
            Close
          </Button>
        }
        isOpen={Boolean(rowError)}
        title="Error"
        onClose={() => setRowError("")}
      >
        <Text sx={{ maxWidth: "800px" }}>{rowError}</Text>
      </Modal>

      <Modal
        footer={
          <>
            <Button variant="secondary" onClick={() => setShowErrorExport(false)}>
              Close
            </Button>
            <Button
              loading={exportLoading}
              onClick={() => {
                downloadErrors("json", true);
              }}
            >
              Export as JSON
            </Button>
            <Button
              loading={exportLoading}
              onClick={() => {
                downloadErrors("csv", true);
              }}
            >
              Export as CSV
            </Button>
          </>
        }
        isOpen={showErrorExport}
        title="Export errors"
        onClose={() => setShowErrorExport(false)}
      >
        <Text>This is an experimental feature to allow for custom analysis of sync errors per row.</Text>
      </Modal>
      <Modal
        footer={
          <>
            <Button variant="secondary" onClick={() => setShowExport(false)}>
              Close
            </Button>
            <Button
              loading={exportLoading}
              onClick={() => {
                downloadErrors("json", false);
              }}
            >
              Export as JSON
            </Button>
            <Button
              loading={exportLoading}
              onClick={() => {
                downloadErrors("csv", false);
              }}
            >
              Export as CSV
            </Button>
          </>
        }
        isOpen={showExport}
        title="Export rows"
        onClose={() => setShowExport(false)}
      >
        <Text>This is an experimental feature to allow for custom analysis of synced rows.</Text>
      </Modal>
      <Modal info isOpen={showUnsupported} title="Run debugger unsupported" onClose={() => setShowUnsupported(false)}>
        <Text>Run debugger is not supported for this destination, or no requests were made for this row.</Text>
      </Modal>
    </>
  );
};

export const getOpTypeBadge = (opType: string): ReactElement | null => {
  switch (opType) {
    case "ADDED":
      return <Badge variant="primary">Added</Badge>;
    case "CHANGED":
      return <Badge variant="indigo">Changed</Badge>;
    case "REMOVED":
      return <Badge variant="red">Removed</Badge>;
    default:
      return null;
  }
};
