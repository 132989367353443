import { FC } from "react";

import { useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import * as diff from "diff";
import { useNavigate } from "react-router-dom";
import { Text, Grid } from "theme-ui";

import { Diff } from "src/components/diff";
import { DraftPage } from "src/components/drafts/draft-page";
import { Schedule } from "src/components/schedule";
import {
  DestinationDefinition,
  DraftOperation,
  DraftsQuery,
  ResourceToPermission,
  SyncQuery,
  UpdateSyncMutationVariables,
  useMigrateConfigQuery,
  useSubmitDraftSyncMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Field } from "src/ui/field";

interface Props {
  sync: SyncQuery["syncs"][0];
  draft: DraftsQuery["drafts"][0];
  destinationDefinition: DestinationDefinition;
  scheduleType: string;
}

export const SyncDraftPage: FC<Readonly<Props>> = ({ sync, destinationDefinition, draft, scheduleType }) => {
  const { mutateAsync: submitSync, isLoading: withdrawing } = useSubmitDraftSyncMutation();
  const { toast } = useToast();

  const { data: migrateData } = useMigrateConfigQuery(
    { draftId: draft?.id },
    { enabled: draft.operation !== DraftOperation.Create && Boolean(draft?.id) },
  );

  const migratedDraftConfig = migrateData?.migrateConfig;

  const newConfig = draft.operation === DraftOperation.Create ? sync.config : migratedDraftConfig;
  const oldConfig = sync.config;

  const model = sync?.segment;
  const newResource = draft.new_resource._set as Partial<UpdateSyncMutationVariables["object"]>;

  const oldSchedule = draft.operation === DraftOperation.Create ? sync.schedule : undefined;
  const newSchedule = draft.operation === DraftOperation.Create ? sync.schedule : newResource.schedule;

  const navigate = useNavigate();

  const dependentResource = sync.segment?.id
    ? {
        resourceId: sync.segment.id,
        resourceType: ResourceToPermission.Model,
      }
    : undefined;

  return (
    <DraftPage
      dependentResource={dependentResource}
      draft={draft}
      link={`/syncs/${sync.id}`}
      resourceId={sync.id}
      resourceType={ResourceToPermission.Sync}
      title={
        draft.operation === DraftOperation.Create
          ? `Initial draft of ${destinationDefinition?.name} sync`
          : `Draft changes of ${destinationDefinition?.name} sync`
      }
      withdrawing={withdrawing}
      onApprove={() => {
        analytics.track(`Sync Edited`, {
          sync_id: sync.id,
          destination_type: destinationDefinition.name,
          schedule_type: scheduleType,
          source_type: model?.connection?.type,
          through_draft: true,
        });
        navigate(`/syncs/${sync.id}`);
      }}
      onWithdraw={async () => {
        try {
          await submitSync({
            resourceId: sync.id.toString(),
            approverIds: [],
          });

          toast({
            id: "withdraw-sync",
            title: "Sync was withdrawn",
            variant: "success",
          });
        } catch (err) {
          toast({
            id: "withdraw-sync",
            title: "Couldn't withdraw sync",
            variant: "error",
          });

          Sentry.captureException(err);
        }
        navigate(`/syncs/${sync.id}?editing=true`);
      }}
    >
      <Field label="Configuration" size="large">
        {newConfig ? <Diff diffs={diff.diffJson(oldConfig, newConfig)} /> : <Text>No changes</Text>}
      </Field>
      <Field label="Schedule" size="large">
        <Grid gap={2}>
          {newSchedule !== undefined ? (
            <>
              {oldSchedule === undefined && (
                <Text sx={{ textDecoration: "line-through" }}>
                  <Schedule schedule={sync.schedule} />
                </Text>
              )}
              <Schedule schedule={newSchedule} />
            </>
          ) : (
            <Text>No changes</Text>
          )}
        </Grid>
      </Field>
    </DraftPage>
  );
};
