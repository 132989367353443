import { FC, useEffect, useState } from "react";

import { Button, Link, Box, Column, Text, TextInput } from "@hightouchio/ui";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { AuthLayout } from "src/components/auth/auth-layout";
import { Terms } from "src/components/auth/terms";
import { useAuthState } from "src/components/auth/use-auth-state";
import { useAuth0ConnectionsByOrganizationQuery } from "src/graphql";
import { PartnerConnectHeader } from "src/pages/partner-connect";
import { Card } from "src/ui/card";

export const SSO: FC = () => {
  const client = useQueryClient();
  const { returnTo, partnerInformation, error: queryError } = useAuthState();

  const { org } = useParams<{ org: string }>();
  const [organization, setOrganization] = useState<string | undefined>(org);
  const [_error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const getAuth0AuthUrl = (organizationSlug: string, connectionName: string) => {
    const auth0AuthUrl = new URL(`${import.meta.env.VITE_API_BASE_URL}/auth0/oauth/${organizationSlug}/${connectionName}`);
    if (returnTo) {
      auth0AuthUrl.searchParams.append("returnTo", returnTo);
    }
    return auth0AuthUrl;
  };

  const getAuth0Connections = async () => {
    if (!organization) {
      return;
    }

    setLoading(true);
    const variables = {
      slug: organization,
    };
    try {
      const { getAuth0ConnectionsByOrganization } = await client.fetchQuery(
        useAuth0ConnectionsByOrganizationQuery.getKey(variables),
        useAuth0ConnectionsByOrganizationQuery.fetcher(variables),
      );

      const connection = getAuth0ConnectionsByOrganization.auth0Connections[0];

      if (!connection) {
        setError("No organization with that identifier was found.");
        return;
      }

      const url = getAuth0AuthUrl(organization, connection.name).toString();
      window.open(url);
    } finally {
      setLoading(false);
    }
  };

  const error = queryError || _error;

  useEffect(() => {
    setError("");
  }, [organization]);

  return (
    <AuthLayout heading="Enter your organization identifier">
      <Column>
        <Card>
          {partnerInformation && <PartnerConnectHeader partnerInformation={partnerInformation} />}
          <Column
            as="form"
            gap={3}
            mb={8}
            sx={{ input: { width: "100%", color: "gray.900" } }}
            onSubmit={(event) => {
              event.preventDefault();
              getAuth0Connections();
            }}
          >
            <TextInput
              autoFocus
              isInvalid={Boolean(error)}
              placeholder="Organization identifier..."
              value={organization || ""}
              onChange={(event) => {
                setOrganization(event.target.value);
              }}
            />

            {error && (
              <Box sx={{ color: "red", maxWidth: "60ch" }}>
                <Text>{error}</Text>
              </Box>
            )}

            <Button isDisabled={!organization} isLoading={loading} size="lg" type="submit" variant="primary">
              Continue with SSO
            </Button>
          </Column>

          <Terms variant="light" />
        </Card>
        <Box
          mt={8}
          sx={{
            alignSelf: "flex-start",
            a: { color: "white", fontWeight: "medium", ":hover": { opacity: 0.7, color: "white" } },
          }}
        >
          <Link href={returnTo ? `/login?returnTo=${returnTo}` : "/login"}>{`<- Other options`}</Link>
        </Box>
      </Column>
    </AuthLayout>
  );
};
