const inter = `'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`;
const code = `'Source Code Pro', monospace`;

export const fonts = {
  inter,
  heading: inter,
  body: inter,
  code,
};

export const fontSizes = [12, 14, 16, 18, 21, 24, 28, 32, 48, 64, 96];

export const fontWeights = {
  thin: 300,
  body: 400,
  semi: 400,
  bold: 500,
};

export const lineHeights = {
  body: 1.4,
  heading: 1.125,
};
