import { useEffect, useState } from "react";

import { EllipsisHorizontalIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  Box,
  Heading,
  Link,
  Paragraph,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  StatusIndicator,
} from "@hightouchio/ui";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";

import { Settings } from "src/components/settings";
import { CreateNormalTunnelForm, CreateReverseTunnelForm } from "src/components/tunnels/create-tunnel";
import { DeleteTunnel } from "src/components/tunnels/delete-tunnel";
import { EditTunnelForm } from "src/components/tunnels/edit-tunnel";
import { useTestTunnelQuery, useTunnelsQuery } from "src/graphql";
import { Table } from "src/ui/table";

import { Modal } from "../../ui/modal";

enum TunnelType {
  REVERSE = "reverse",
  NORMAL = "normal",
}

export const Tunnels = () => {
  const navigate = useNavigate();
  const params = useParams<{ modal_type?: string }>();
  const modal = params?.modal_type;
  const [editTunnel, setEditTunnel] = useState();
  const [deleteTunnel, setDeleteTunnel] = useState<any>();
  const [tunnelError, setTunnelError] = useState<string | undefined | null>();

  const { data, refetch } = useTunnelsQuery();

  const startColumns = [
    {
      name: "Name",
      key: "tunnel",
      cell: ({ name }) => <Text fontWeight="medium">{name}</Text>,
    },
    {
      name: "Status",
      key: "tunnel",
      max: "120px",
      cell: ({ id }) => {
        const [polling, setPolling] = useState<boolean>(false);

        const { data, isLoading } = useTestTunnelQuery({ id }, { refetchInterval: polling ? 3000 : undefined });

        const error = data?.checkTunnel?.error;

        useEffect(() => {
          if (error) {
            setPolling(true);
          }
        }, [error]);

        if (isLoading) {
          return null;
        }

        return (
          <StatusIndicator variant={data?.checkTunnel?.success ? "success" : "error"}>
            {data?.checkTunnel?.success ? "Connected" : "Error"}
          </StatusIndicator>
        );
      },
    },
  ];

  const createdAtColumn = {
    name: "Created at",
    key: "tunnel",
    max: "260px",
    cell: ({ createdAt }) => moment(createdAt).calendar(),
  };

  const normalTunnelColumns = [
    ...startColumns,
    {
      name: "SSH host",
      key: "tunnel",
      max: "140px",
      cell: ({ sshHost, sshPort }) => `${sshHost}:${sshPort}`,
    },
    {
      name: "Service host",
      key: "tunnel",
      max: "140px",
      cell: ({ serviceHost, servicePort }) => `${serviceHost}:${servicePort}`,
    },
    createdAtColumn,
    {
      max: "40px",
      cell: ({ tunnel }) => {
        return (
          <Menu>
            <MenuButton icon={EllipsisHorizontalIcon}>Actions</MenuButton>

            <MenuList>
              <MenuItem icon={PencilSquareIcon} onClick={() => setEditTunnel(tunnel)}>
                Edit
              </MenuItem>
              <MenuDivider />
              <MenuItem icon={TrashIcon} variant="danger" onClick={() => setDeleteTunnel(tunnel)}>
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        );
      },
    },
  ];

  const reverseTunnelColumns = [
    ...startColumns,
    {
      name: "Tunnel port",
      key: "tunnel.tunnelPort",
      max: "120px",
    },
    createdAtColumn,
    {
      max: "40px",
      cell: ({ tunnel }) => {
        return (
          <Menu>
            <MenuButton icon={EllipsisHorizontalIcon}>Actions</MenuButton>

            <MenuList>
              <MenuItem icon={PencilSquareIcon} onClick={() => setEditTunnel(tunnel)}>
                Edit
              </MenuItem>
              <MenuDivider />
              <MenuItem icon={TrashIcon} variant="danger" onClick={() => setDeleteTunnel(tunnel)}>
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        );
      },
    },
  ];

  const tunnels = data?.getTunnels?.filter(({ type }) => type === TunnelType.NORMAL);
  const reverseTunnels = data?.getTunnels?.filter(({ type }) => type === TunnelType.REVERSE);

  return (
    <>
      <Settings route="tunnels">
        <Box display="flex" justifyContent="space-between">
          <Box maxWidth="2xl">
            <Heading>Standard tunnels</Heading>
            <Paragraph mt={1}>
              Access services within a private network by configuring Hightouch to open an SSH connection to your bastion, then
              open a port forwarding connection from your bastion to the private service. Learn more in our{" "}
              <Link href="https://hightouch.com/docs/security/ssh-tunneling">docs</Link>.
            </Paragraph>
          </Box>

          <Button
            variant="primary"
            onClick={() => {
              navigate("/settings/tunnels/create");
            }}
          >
            Add standard tunnel
          </Button>
        </Box>

        <Box mt={6}>
          <Table
            columns={normalTunnelColumns}
            data={tunnels}
            placeholder={{
              title: "No tunnels",
              error: "Tunnels failed to load, please try again.",
            }}
            sx={{ maxHeight: "600px" }}
          />
        </Box>

        <Box display="flex" justifyContent="space-between" mt={20}>
          <Box maxWidth="2xl">
            <Heading>Reverse tunnels</Heading>
            <Paragraph mt={1}>
              Access services within a private network by connecting as a client to an SSH server managed by Hightouch, then
              forwarding a port. Learn more in our <Link href="https://hightouch.com/docs/security/ssh-tunneling">docs</Link>.
            </Paragraph>
          </Box>

          <Button
            variant="primary"
            onClick={() => {
              navigate("/settings/tunnels/create-reverse");
            }}
          >
            Add reverse tunnel
          </Button>
        </Box>

        <Box mt={6}>
          <Table
            columns={reverseTunnelColumns}
            data={reverseTunnels}
            placeholder={{
              title: "No reverse tunnels",
              error: "Tunnels failed to load, please try again.",
            }}
            sx={{ maxHeight: "600px" }}
          />
        </Box>
      </Settings>

      {modal === "create" && (
        <CreateNormalTunnelForm
          onClose={() => {
            navigate("/settings/tunnels");
          }}
        />
      )}
      {modal === "create-reverse" && <CreateReverseTunnelForm onClose={() => navigate("/settings/tunnels")} />}
      {deleteTunnel && (
        <DeleteTunnel
          id={deleteTunnel?.id}
          name={deleteTunnel?.name}
          refetch={() => refetch()}
          onClose={() => setDeleteTunnel(undefined)}
        />
      )}
      {editTunnel && <EditTunnelForm tunnel={editTunnel} onClose={() => setEditTunnel(undefined)} />}
      <Modal info bodySx={{ bg: "white" }} isOpen={tunnelError ? true : false} title="Error" onClose={() => setTunnelError("")}>
        <Box maxWidth="2xl">
          <Paragraph>{tunnelError}</Paragraph>
        </Box>
      </Modal>
    </>
  );
};
