import { FC, useState } from "react";

import { ArrowsRightLeftIcon, ArrowRightIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import { Heading, Checkbox, Button, Text, Row, Box, Column, Link, CheckboxGroup } from "@hightouchio/ui";
import { Navigate, useParams } from "react-router-dom";
import { Image } from "theme-ui";

import { AuthLayout } from "src/components/auth/auth-layout";
import {
  useAvailableWorkspacesQuery,
  useJoinWorkspaceWithAutoJoinMutation,
  usePartnerConnectGetConnectionQuery,
  useWorkspacesQuery,
} from "src/graphql";
import { Logo } from "src/ui/brand";
import { Card } from "src/ui/card";
import { useNavigate } from "src/utils/navigate";
import { switchWorkspace } from "src/utils/workspaces";

const PartnerConnect: FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ uuid: string }>();
  const uuid = params.uuid ?? "";

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [hasAcceptedSharing, setHasAcceptedSharing] = useState(false);

  const { data: partnerConnection, error: connectionError } = usePartnerConnectGetConnectionQuery(
    { uuid },
    {
      select: (data) => data.partnerConnectGetConnection,
      enabled: Boolean(uuid),
      cacheTime: 0,
      suspense: true,
      useErrorBoundary: false,
    },
  );

  const { data: workspaces } = useWorkspacesQuery(undefined, {
    select: (data) => data.workspaces,
    cacheTime: 0,
    suspense: true,
  });
  const { data: joinableWorkspaces } = useAvailableWorkspacesQuery(undefined, {
    select: (data) => data.getAvailableWorkspaces.joinable,
    suspense: true,
    cacheTime: 0,
  });
  const { mutateAsync: joinWithAutojoin, isLoading: joiningWorkspace } = useJoinWorkspaceWithAutoJoinMutation();

  const linkedWorkspace = workspaces?.find((w) => w?.id === partnerConnection?.workspaceId);
  const joinableLinkedWorkspace = joinableWorkspaces?.find(({ id }) => id === partnerConnection?.workspaceId);

  if (connectionError) {
    return <Navigate state={{ error: connectionError?.message }} to="/unauthorized" />;
  }

  if (partnerConnection?.workspaceId) {
    if (!linkedWorkspace && !joinableLinkedWorkspace) {
      return (
        <Navigate
          state={{
            partnerConnection,
            error: `You are not authorized to access the workspace ${partnerConnection.partnerName} is connected to.`,
          }}
          to="/unauthorized"
        />
      );
    }

    return (
      <Card sx={{ mx: "auto", mt: 24, maxWidth: "540px", width: "100%" }}>
        <Column align="center" gap={12}>
          <PartnerConnectLogo logo={partnerConnection.partnerLogo} name={partnerConnection.partnerName} />
          <Heading size="2xl">{partnerConnection.partnerName} connected!</Heading>

          <Text>
            Your Hightouch workspace <strong>"{(linkedWorkspace || joinableLinkedWorkspace)?.name}"</strong> is connected to{" "}
            {partnerConnection.partnerName}.
          </Text>

          <Button
            isLoading={joiningWorkspace}
            size="lg"
            variant="primary"
            onClick={async () => {
              if (joinableLinkedWorkspace) {
                await joinWithAutojoin({ workspaceId: String(joinableLinkedWorkspace.id) });
              }

              if (partnerConnection.workspaceId) {
                switchWorkspace(partnerConnection.workspaceId, `/${(linkedWorkspace || joinableLinkedWorkspace)?.slug}`);
              }
            }}
          >
            Go to workspace
          </Button>
        </Column>
      </Card>
    );
  }

  if (!partnerConnection) {
    return null;
  }

  return (
    <AuthLayout heading={`Connect Hightouch to ${partnerConnection.partnerName}`}>
      <Card>
        <Column align="center" gap={12}>
          <PartnerConnectLogo logo={partnerConnection.partnerLogo} name={partnerConnection.partnerName} />

          <CheckboxGroup>
            <Checkbox
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              description={
                <>
                  I agree to Hightouch's{" "}
                  <Link href={`${import.meta.env.VITE_WEBSITE_URL}/terms-of-service/`} rel="noreferrer" target="_blank">
                    terms of service
                  </Link>{" "}
                  related to its usage in conjunction with {partnerConnection.partnerName}.
                </>
              }
              isChecked={hasAcceptedTerms}
              label="Terms of service"
              onChange={(event) => setHasAcceptedTerms(event.target.checked)}
            />
            <Checkbox
              description={`I acknowledge that Hightouch will share data on its usage with ${partnerConnection.partnerName}`}
              isChecked={hasAcceptedSharing}
              label="Usage sharing"
              onChange={(event) => setHasAcceptedSharing(event.target.checked)}
            />
          </CheckboxGroup>

          <Button
            directionIcon={ArrowRightIcon}
            isDisabled={!(hasAcceptedSharing && hasAcceptedTerms)}
            size="lg"
            variant="primary"
            onClick={() => {
              const workspaceCount = workspaces?.length ?? 0;
              navigate(workspaceCount > 0 ? "/workspaces" : "/workspaces/new", { slug: false, state: { partnerConnection } });
            }}
          >
            Continue
          </Button>
        </Column>
      </Card>
    </AuthLayout>
  );
};

export default PartnerConnect;

export const PartnerConnectLogo: FC<Readonly<{ name: string; logo: string }>> = ({ name, logo }) => (
  <Row align="center" gap={4} mx="auto">
    <Logo size="48px" />
    <Box color="gray.500">
      <ArrowsRightLeftIcon fill="currentColor" width="36px" />
    </Box>
    <Image alt={name} src={logo} sx={{ width: "48px", objectFit: "contain" }} />
  </Row>
);

export const PartnerConnectHeader: FC<Readonly<{ partnerInformation: any }>> = ({ partnerInformation }) => {
  return (
    <Column align="center" color="gray.700" gap={8} mb={8}>
      <PartnerConnectLogo logo={partnerInformation.partnerLogo} name={partnerInformation.partnerName} />

      <Column gap={2}>
        {partnerInformation.resources.map((resource) => (
          <Row key={resource} align="center" gap={2}>
            <Box color="forest">
              <CheckCircleIcon fill="currentColor" width="24px" />
            </Box>
            <Text fontWeight="medium">Connect your {resource.split(" ").slice(1).join(" ")}</Text>
          </Row>
        ))}
      </Column>
    </Column>
  );
};
