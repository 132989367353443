import { FC, ReactNode, useMemo, useState } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { Grid, Text } from "theme-ui";

import { ChooseTunnelForm, CreateNormalTunnelForm, CreateReverseTunnelForm } from "src/components/tunnels/create-tunnel";
import { Form as FormkitForm } from "src/formkit/components/form";
import { processFormNode } from "src/formkit/formkit";
import { SourceDefinition, useCloudCredentialsV2Query, useTestTunnelQuery, useTunnelsQuery } from "src/graphql";
import { Badge } from "src/ui/badge";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Circle } from "src/ui/circle";
import { Code } from "src/ui/code";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { LightningIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { Link } from "src/ui/link";
import { Spinner } from "src/ui/loading";
import { Markdown } from "src/ui/markdown";
import { Message } from "src/ui/message";
import { NewSelect } from "src/ui/new-select";
import { RadioGroup } from "src/ui/radio";

import { Context, DEFAULT_PERMISSIONS, Permissions, PERMISSIONS } from "./constants";
import { ProviderSection } from "./providers";

export type FormMethodProps = {
  definition: SourceDefinition;
  config: Record<string, unknown> | undefined;
  setupMethods: Record<string, any>;
  credentialId: string | undefined;
  setCredentialId: (credential: string) => void;
  tunnelId: string | null | undefined;
  setTunnelId: (tunnel: string | null | undefined) => void;
  isSetup: boolean;
} & SyncEngineProps;

export enum Section {
  AuthMethod = "authMethod",
  Tunneling = "tunneling",
  Providers = "providers",
  Form = "form",
  Diffing = "diffing",
  CredentialsForm = "credentials",
}

export const FormMethod: FC<Readonly<FormMethodProps>> = ({
  definition,
  config,
  setupMethods,
  tunnelId,
  setTunnelId,
  lightningEnabled,
  setLightningEnabled,
  plannerDatabase,
  setPlannerDatabase,
  credentialId,
  setCredentialId,
  hasSetupLightning,
}) => {
  const { sourceSnowflakeEnableTunnel } = useFlags();
  const matchingSource = setupMethods?.find((o) => o.key === config?.methodKey);

  const Form = useMemo(
    () =>
      matchingSource?.form && (
        <FormkitForm compact={true} disableBorder={true}>
          {processFormNode(matchingSource.form)}
        </FormkitForm>
      ),
    [matchingSource?.key],
  );

  const CredentialsForm = useMemo(
    () =>
      matchingSource?.credentialsForm && (
        <FormkitForm compact={true} disableBorder={true}>
          {processFormNode(matchingSource.credentialsForm)}
        </FormkitForm>
      ),
    [matchingSource?.key],
  );

  const showTunneling =
    // Does the source form support tunneling?
    matchingSource?.tunneling &&
    // If the source is Snowflake, check that the user has the matching feature flag.
    (definition.type !== "snowflake" || (definition.type === "snowflake" && sourceSnowflakeEnableTunnel));

  const sections: Section[] = useMemo(() => {
    const steps: Section[] = [];
    if (showTunneling) {
      steps.push(Section.Tunneling);
    }

    if (["gcp", "aws"].includes(matchingSource?.provider)) {
      steps.push(Section.Providers);
    }

    if (matchingSource?.form) {
      steps.push(Section.Form);
    }

    if (definition.supportsInWarehouseDiffing) {
      steps.push(Section.Diffing);
    }

    if (matchingSource?.credentialsForm) {
      steps.push(Section.CredentialsForm);
    }

    return steps;
  }, [matchingSource, definition.supportsInWarehouseDiffing, showTunneling]);

  const hasMultipleSetupMethods = setupMethods.length > 1;

  return (
    <>
      <Grid gap={12}>
        <Step
          description={`Hightouch can connect directly to ${definition.name} if it's exposed to the internet. However, if you need to open a connection within a private network or VPC, you will need to set up an SSH tunnel.`}
          hasMultipleSetupMethods={hasMultipleSetupMethods}
          section={Section.Tunneling}
          sections={sections}
          title="Choose your connection type"
        >
          <Tunnel name={definition.name} value={tunnelId} onChange={setTunnelId} />
        </Step>
        <Step
          hasMultipleSetupMethods={hasMultipleSetupMethods}
          section={Section.Providers}
          sections={sections}
          title="Configure your service account"
        >
          <ProviderSection credentialId={credentialId} provider={matchingSource.provider} setCredentialId={setCredentialId} />
        </Step>
        <Step
          hasMultipleSetupMethods={hasMultipleSetupMethods}
          section={Section.Form}
          sections={sections}
          title={`Configure your ${definition.name} source`}
        >
          {Form}
        </Step>
        <Step
          description={
            "For optimal sync performance, Hightouch tracks incremental changes to your data model and only processes rows which have been added, changed, or removed since the last sync run. You can choose whether this tracking should be performed in Hightouch's infrastructure or natively in your data warehouse."
          }
          hasMultipleSetupMethods={hasMultipleSetupMethods}
          section={Section.Diffing}
          sections={sections}
          title="Choose your sync engine"
        >
          <SyncEngine
            config={config}
            credentialId={credentialId}
            definition={definition}
            hasSetupLightning={hasSetupLightning}
            lightningEnabled={lightningEnabled}
            plannerDatabase={plannerDatabase}
            setLightningEnabled={setLightningEnabled}
            setPlannerDatabase={setPlannerDatabase}
            supportsPlannerDatabase={definition.supportsCrossDbReference}
          />
        </Step>
        <Step
          hasMultipleSetupMethods={hasMultipleSetupMethods}
          section={Section.CredentialsForm}
          sections={sections}
          title={`Provide your ${definition.name} credentials`}
        >
          {CredentialsForm}
        </Step>
      </Grid>
    </>
  );
};

export type SyncEngineProps = {
  hasSetupLightning: boolean;
  lightningEnabled: boolean | undefined;
  setLightningEnabled: (enabled: boolean | undefined) => void;
  plannerDatabase: string | undefined;
  setPlannerDatabase: (database: string | undefined) => void;
};

const SyncEngine: FC<
  SyncEngineProps & {
    supportsPlannerDatabase: boolean;
    definition: SourceDefinition;
    config: Record<string, unknown> | undefined;
    credentialId: string | undefined;
  }
> = ({
  lightningEnabled,
  setLightningEnabled,
  plannerDatabase,
  setPlannerDatabase,
  supportsPlannerDatabase,
  definition,
  config,
  credentialId,
  hasSetupLightning,
}) => {
  const name = definition.name;
  const { data } = useCloudCredentialsV2Query({ id: String(credentialId) }, { enabled: Boolean(credentialId) });

  const ctx = { definitionName: name, configuration: config, credential: data?.getCloudCredentials?.[0], plannerDatabase };

  const lightningPermissions = PERMISSIONS[definition.type]?.["lightning"] || DEFAULT_PERMISSIONS["lightning"];
  const standardPermissions = PERMISSIONS[definition.type]?.["default"] || DEFAULT_PERMISSIONS["default"];

  return (
    <>
      {hasSetupLightning ? (
        // TODO(ziari): This styling is really messy and should be cleaned up once we adopt Hightouch UI.
        // I did this so that the Lightning engine callout would be styled the same as the IP address callout.
        <Message hideIcon sx={{ mt: 4, maxWidth: "100%", bg: "base.1", border: `1px solid #E9ECF5` }}>
          <Text sx={{ fontWeight: 400, fontSize: 13, color: "base.6" }}>
            This source is configured to use the Lightning sync engine, which uses the compute power of your database to
            calculate incremental changes to your data. Once enabled, the Lightning sync engine cannot be disabled.
          </Text>
        </Message>
      ) : (
        <RadioGroup
          disabled={hasSetupLightning}
          options={[
            {
              label: (
                <>
                  <Row sx={{ alignItems: "center" }}>
                    <Text sx={{ mr: 2 }}>Standard sync engine</Text>
                    <Badge variant="base">Easier to set up</Badge>
                  </Row>
                </>
              ),
              description: `This engine is easier to set up because it only requires ***read access*** to ${definition.name}. It works by running a query in your database, reading its full results, and then calculating incremental changes.`,
              value: false,
            },
            {
              label: (
                <>
                  <Row sx={{ alignItems: "center" }}>
                    <Text sx={{ mr: 2 }}>Lightning sync engine</Text>
                    <LightningIcon color="base.5" size={18} />
                  </Row>
                </>
              ),
              description: `This engine is more difficult to configure but results in faster syncs. It requires ***read and write access*** to ${name}. It works by storing previously-synced data in a separate schema in your database. During a sync, Hightouch uses the compute power of your database to calculate incremental changes.`,
              value: true,
            },
          ]}
          sx={{ mt: 4 }}
          value={lightningEnabled}
          onChange={(v) => {
            setLightningEnabled(v);
          }}
        />
      )}

      {lightningEnabled && supportsPlannerDatabase && (
        <Field
          optional
          description="The vast majority of users can safely skip this question. In exceptionally rare cases, you may require the Lightning sync engine to store previously-synced records in a different location than the database or project used to run queries. Leave blank to skip."
          label="Override location of Lightning sync engine cache?"
          sx={{ my: 8 }}
        >
          <Input
            disabled={hasSetupLightning}
            placeholder="Enter a different database or project..."
            value={plannerDatabase}
            onChange={(value) => {
              setPlannerDatabase(value);
            }}
          />
        </Field>
      )}

      {!lightningEnabled && standardPermissions && !hasSetupLightning && (
        <AdditionalPermissions ctx={ctx} permissions={standardPermissions} />
      )}
      {lightningEnabled && lightningPermissions && !hasSetupLightning && (
        <AdditionalPermissions ctx={ctx} permissions={lightningPermissions} />
      )}
    </>
  );
};

const AdditionalPermissions: FC<
  Readonly<{ ctx: Context; permissions: NonNullable<Permissions["default"] | Permissions["lightning"]> }>
> = ({ permissions, ctx }) => {
  return (
    <Message hideIcon sx={{ mt: 4, maxWidth: "100%" }} variant="warning">
      <Column sx={{ gap: 1 }}>
        <Text sx={{ fontWeight: "bold" }}>Additional permissions are required. Please modify and run the snippet below.</Text>
        <Text sx={{ fontWeight: "semi", color: "base.6", whiteSpace: "pre-wrap" }}>
          <Markdown>{permissions.reason(ctx)}</Markdown>
        </Text>

        {Boolean(permissions.code.length) &&
          permissions.code.map((block, idx) => (
            <Code key={idx} sx={{ mb: 0 }} title={permissions.code.length > 1 ? block.title(ctx) : undefined}>
              <Text sx={{ whiteSpace: "pre-wrap" }}>{block.code(ctx)}</Text>
            </Code>
          ))}
      </Column>
    </Message>
  );
};

type TunnelProps = {
  name: string;
  value: string | null | undefined;
  onChange: (tunnel: string | null | undefined) => void;
};

const Tunnel: FC<TunnelProps> = ({ name, value, onChange }) => {
  const [setupStep, setSetupStep] = useState<"choose" | "normal" | "reverse" | undefined>();
  const [tunnelType, setTunnelType] = useState<"normal" | "reverse" | undefined>("normal");

  const { data: tunnelsData, isFetching: tunnelsLoading, refetch: refetchOptions } = useTunnelsQuery();
  const tunnelsOptions =
    tunnelsData?.getTunnels?.map(({ type, tunnel: { name, id } }) => ({ label: `${name} (${type})`, value: id ?? "" })) ?? [];

  const {
    data: tunnelTest,
    isFetching: tunnelTestLoading,
    refetch: refetchTest,
  } = useTestTunnelQuery(
    {
      id: value ?? "",
    },
    {
      enabled: Boolean(value),
    },
  );

  const onCreate = async (tunnel) => {
    if (tunnel.tunnel.id) {
      onChange(tunnel.tunnel.id);
      await refetchOptions();
    }
  };

  const hasExistingTunnels = tunnelsOptions?.length > 0;
  return (
    <>
      <RadioGroup
        options={[
          {
            label: (
              <>
                <Row sx={{ alignItems: "center" }}>
                  <Text sx={{ mr: 2 }}>Connect directly to {name}</Text>
                  <Badge variant="base">Most common</Badge>
                </Row>
              </>
            ),
            value: false,
          },
          { label: `Connect via SSH tunnel`, value: true },
        ]}
        size="large"
        sx={{ mt: 4 }}
        value={value !== undefined}
        onChange={(v) => {
          onChange(v ? null : undefined);
        }}
      />
      {value !== undefined && (
        <Column sx={{ mt: 4, alignItems: "start" }}>
          {hasExistingTunnels ? (
            <>
              <Row sx={{ alignItems: "sstandard center" }}>
                <NewSelect
                  loading={tunnelsLoading}
                  options={tunnelsOptions}
                  placeholder="Select an existing tunnel..."
                  reload={async () => {
                    await refetchOptions();
                    if (value) {
                      await refetchTest();
                    }
                  }}
                  sx={{ width: "360px" }}
                  value={value}
                  width={360}
                  onChange={onChange}
                />

                {tunnelTestLoading && (
                  <Row sx={{ ml: 4, alignItems: "center" }}>
                    <Spinner size={16} />
                    <Text sx={{ ml: 2, fontSize: 0 }}>Testing...</Text>
                  </Row>
                )}

                {!tunnelTestLoading && tunnelTest?.checkTunnel.success && (
                  <Row sx={{ ml: 4, alignItems: "center" }}>
                    <Circle color="green" radius="8px" />
                    <Text sx={{ ml: 2, fontSize: 0 }}>Active</Text>
                  </Row>
                )}

                {!tunnelTestLoading && tunnelTest?.checkTunnel.success === false && (
                  <Row sx={{ ml: 4, alignItems: "center" }}>
                    <Circle color="red" radius="8px" />
                    <Text sx={{ ml: 2 }}>
                      Disconnected. Go to{" "}
                      <Link newTab to="/settings/tunnels">
                        tunnel settings
                      </Link>{" "}
                      to reconnect.
                    </Text>
                  </Row>
                )}
              </Row>

              <Text sx={{ mt: 2, fontSize: 0 }}>
                Alternatively, <Link onClick={() => setSetupStep("choose")}>create a new tunnel.</Link>
              </Text>
            </>
          ) : (
            <>
              <Button
                sx={{ mt: 2 }}
                variant="primary"
                onClick={() => {
                  setSetupStep("choose");
                }}
              >
                Set up your tunnel
              </Button>
            </>
          )}
        </Column>
      )}
      {setupStep === "choose" && (
        <ChooseTunnelForm
          setTunnelType={setTunnelType}
          tunnelType={tunnelType}
          onClose={() => {
            setSetupStep(undefined);
          }}
          onContinue={() => {
            setSetupStep(tunnelType);
          }}
        />
      )}
      {setupStep === "normal" && <CreateNormalTunnelForm onClose={() => setSetupStep(undefined)} onCreate={onCreate} />}
      {setupStep === "reverse" && <CreateReverseTunnelForm onClose={() => setSetupStep(undefined)} onCreate={onCreate} />}
    </>
  );
};

export const Step = ({
  title,
  sections,
  section,
  children,
  description,
  hasMultipleSetupMethods,
}: {
  sections: Section[];
  section: Section;
  title: string;
  description?: string;
  children: ReactNode;
  hasMultipleSetupMethods: boolean;
}) => {
  const index = sections.indexOf(section);
  if (index === -1) {
    return null;
  }

  return (
    <>
      <Column sx={{ ":not(:last-child)": { borderBottom: "small" }, pb: 12 }}>
        <Column sx={{ mb: 4, gap: 2 }}>
          {(hasMultipleSetupMethods || sections.length > 1) && (
            <>
              <Text sx={{ fontWeight: "bold", textTransform: "uppercase", color: "base.5", fontSize: 0 }}>
                Step {index + 1 + Number(hasMultipleSetupMethods)}
              </Text>
              <Heading variant="h3">{title}</Heading>
            </>
          )}
          {description && (
            <Text sx={{ color: "base.5" }}>
              <Markdown>{description}</Markdown>
            </Text>
          )}
        </Column>
        <Column>{children}</Column>
      </Column>
    </>
  );
};
