import { Image, Text } from "theme-ui";

import { Column } from "src/ui/box";

import unsupportedIcon from "./unsupported.svg";

export const Mobile = () => (
  <Column
    sx={{
      display: ["flex", "none"],
      p: 4,
      position: "absolute",
      width: "100vw",
      height: "100vh",
      bg: "base.0",
      alignItems: "center",
      pointerEvents: "none",
      zIndex: 9999,
    }}
  >
    <Image src={unsupportedIcon} />
    <Text sx={{ textAlign: "center", fontWeight: "bold", fontSize: 3 }}>
      Hightouch doesn’t support small screen sizes... yet.
    </Text>
  </Column>
);
