import { ReactElement, useEffect } from "react";

import * as Yup from "yup";

import { useGooglesheetsSourceListSheetsQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Spinner } from "src/ui/loading";
import { Select } from "src/ui/select";

import { CustomQueryViewProps, CustomQueryFormProps } from "../custom-query";

export function QueryForm({ source, query, onChange, setError }: Readonly<CustomQueryFormProps>): ReactElement<any, any> {
  const {
    data: sheets,
    isLoading: sheetsLoading,
    error: sheetsError,
    refetch: listSheets,
  } = useGooglesheetsSourceListSheetsQuery({ connectionId: String(source.id) });

  useEffect(() => {
    if (sheetsError) {
      setError(sheetsError);
    }
  }, [sheetsError]);

  return (
    <Field label="Sheet name" size="large">
      <Select
        isError={Boolean(sheetsError)}
        isLoading={sheetsLoading}
        options={sheets?.googlesheetsSourceListSheets?.map((name) => {
          return { label: name ?? "", value: name };
        })}
        placeholder="Select a sheet..."
        reload={listSheets}
        sx={{ maxWidth: "280px" }}
        value={query?.name || null}
        width="280px"
        onChange={(selected) => onChange({ ...query, name: selected?.value })}
      />
    </Field>
  );
}

export function QueryView({ source, query, setError }: Readonly<CustomQueryViewProps>): ReactElement<any, any> {
  const {
    data: sheets,
    isLoading: sheetsLoading,
    error: sheetsError,
  } = useGooglesheetsSourceListSheetsQuery({ connectionId: String(source.id) });

  useEffect(() => {
    setError(sheetsError);
  }, [sheetsError]);

  const sheet = sheets?.googlesheetsSourceListSheets?.find((name) => name === query.name);
  const sheetName = sheet || `Unknown sheet ${query.name}`;

  return (
    <Field inline label="Sheet">
      {sheetsLoading ? <Spinner /> : sheetName}
    </Field>
  );
}

export const querySchema = Yup.object().shape({
  type: Yup.string().required().equals(["sheet"]),
  name: Yup.string().required(),
});
