import { useEffect, useState } from "react";

import { Box, Select, useToast } from "@hightouchio/ui";
import { useLocation } from "react-router-dom";

import { Explore } from "src/components/explore/explore";
import { QueryTypeSelect } from "src/components/explore/query-type-select";
import { AddFolder } from "src/components/folders/add-folder";
import { useFolders } from "src/components/folders/use-folders";
import { PermissionedButton } from "src/components/permissioned-button";
import { SourceSelect, Source } from "src/components/sources/source-select";
import { useUser } from "src/contexts/user-context";
import { ModelQuery, ResourcePermissionGrant, useCreateModelMutation } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Column } from "src/ui/box";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { Input } from "src/ui/input";
import { Step } from "src/ui/wizard/wizard";
import { QueryType, useModelRun, useModelState, useQueryState } from "src/utils/models";
import { ResourceType, useResourceSlug } from "src/utils/slug";
import { useSource } from "src/utils/sources";
import { useQueryString } from "src/utils/use-query-string";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

type CreateModelWizardArgs = {
  initialStep?: number;
  model?: ModelQuery["segments_by_pk"];
  source?: Source;
  onSubmit?: ({ id, type }: { id: string; type: string | undefined }) => void;
};

export const useCreateModelWizard = ({
  initialStep = 0,
  model: preselectedModel,
  source: preselectedSource,
  onSubmit,
}: CreateModelWizardArgs) => {
  const location = useLocation();
  const { toast } = useToast();
  const {
    queryState,
    initQueryState,
    resetQueryState,
    isQueryDefined,
    setSQL,
    setTable,
    setDBTModel,
    setLookerLook,
    setCustomQuery,
  } = useQueryState();
  const { user, workspace } = useUser();
  const { modelState, setName, setPrimaryKey } = useModelState();
  const [type, setType] = useState<QueryType | undefined>();
  const [source, setSource] = useState<Source | undefined>();
  const [hasQueryColumns, setHasQueryColumns] = useState(false);
  const { data: params } = useQueryString();
  const [step, setStep] = useWizardStepper(initialStep);
  const { getSlug } = useResourceSlug(ResourceType.Model);
  const { flattenedFolders, refetchFolders } = useFolders({ folderType: "models", viewType: "models" });
  const [selectedFolder, setSelectedFolder] = useState<string>();
  const [addFolderOpen, setAddFolderOpen] = useState(false);

  const { data: initialSource } = useSource(params?.source?.toString() || preselectedModel?.connection?.id);

  useEffect(() => {
    setSource(initialSource as Source);
  }, [initialSource]);

  const { mutateAsync: createModel } = useCreateModelMutation();

  const {
    runQuery,
    cancelQuery,
    getSchema,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
  } = useModelRun(type, undefined, {
    variables: { sourceId: source?.id, dbtModelId: queryState?.dbtModel?.id, ...queryState },
  });

  const create = async () => {
    const slug = await getSlug(modelState?.name);

    const data = await createModel({
      input: {
        slug,
        query_type: type,
        name: modelState?.name,
        primary_key: modelState?.primaryKey,
        connection_id: source?.id,
        created_by: user?.id != null ? String(user?.id) : undefined,
        query_dbt_model_id: queryState?.dbtModel?.id,
        query_looker_look_id: queryState?.lookerLook?.id,
        query_table_name: queryState?.table,
        query_raw_sql: queryState?.sql,
        custom_query: queryState?.customQuery,
        columns: { data: columns },
        destination_instances: { data: [] },
        git_sync_metadata:
          type === "dbt"
            ? {
                git_sync_config_id: queryState?.dbtModel?.git_sync_config?.id,
                file_path: queryState?.dbtModel?.original_file_path,
                dbt_model_id: queryState?.dbtModel?.id,
              }
            : null,
        draft: workspace?.approvals_required,
        folder_id: selectedFolder,
      },
    });

    if (!data) {
      return;
    }

    const id = data.insert_segments_one?.id;

    if (!workspace?.approvals_required) {
      toast({
        id: "create-model",
        title: `Model "${modelState?.name}" was created`,
        variant: "success",
      });
    }

    analytics.track("Model Created", {
      workspace_id: workspace?.id,
      workspace_slug: workspace?.slug,
      model_id: id,
      model_name: modelState?.name,
      source_id: source?.id,
      source_type: source?.type,
      query_type: type,
      origin_page: location.pathname,
    });

    onSubmit?.({ id, type });

    return { id, type };
  };

  const steps: Step[] = [
    {
      title: "Select source",
      continue: "Click on a source to continue",
      header: <Heading>Select a data source</Heading>,
      render: () => <SourceSelect onSelect={setSource} />,
    },
    {
      title: "Define model",
      disabled:
        !isQueryDefined(type) || Boolean(queryError) || (source?.definition?.supportsResultSchema ? false : !hasQueryColumns),
      onContinue: async () => {
        if (source?.definition?.supportsResultSchema && !hasQueryColumns) {
          await getSchema();
        }
        setStep((step) => step + 1);
      },
      header: type ? null : <Heading>Select a modeling method</Heading>,
      continue: type ? "" : "Click on a modeling method to continue",
      render: () =>
        type ? (
          <Explore
            cancelQuery={cancelQuery}
            columns={columns}
            isResultTruncated={Boolean(isResultTruncated)}
            numRowsWithoutLimit={numRowsWithoutLimit}
            rows={rows}
            runQuery={runQuery}
            source={source}
            type={type}
            {...queryState}
            error={queryError}
            errorAtLine={queryErrorAtLine}
            isQueryDefined={isQueryDefined}
            loading={queryLoading}
            rowsPerPage={15}
            onCustomQueryChange={setCustomQuery}
            onDBTModelChange={setDBTModel}
            onLookerLookChange={setLookerLook}
            onSQLChange={setSQL}
            onTableChange={setTable}
            onTypeChange={setType}
          />
        ) : (
          <QueryTypeSelect selected={type} source={source!} onChange={setType} />
        ),
    },
    {
      title: "Finalize model",
      disabled: !modelState?.primaryKey || !modelState?.name,
      header: <Heading>Finalize settings for this model</Heading>,
      render: () => {
        const columnOptions = columns?.map(({ name }) => ({ value: name, label: name }));
        return (
          <Column sx={{ gap: 8, maxWidth: "600px" }}>
            <Field description="Including details about the model's contents and business purpose" label="Model name">
              <Input value={modelState?.name} onChange={(name) => setName(name)} />
            </Field>
            <Field
              description="This is the column that uniquely identifies each row (e.g., customer ID, email address, invoice number)"
              label="Primary key"
            >
              <Select
                optionLabel={(co) => co.label}
                optionValue={(co) => co.value}
                options={columnOptions as { value: string; label: string }[]}
                placeholder="Select a column..."
                value={columnOptions?.find((co) => co.value === modelState?.primaryKey)?.value}
                onChange={(column) => {
                  setPrimaryKey(column);
                }}
              />
            </Field>
            <Field optional label="Move to folder">
              <Select
                isClearable
                optionLabel={(folder) => folder.path.replaceAll("/", " / ")}
                optionValue={(folder) => folder.id}
                options={flattenedFolders || []}
                placeholder="Select a folder..."
                value={selectedFolder}
                onChange={(folder) => {
                  setSelectedFolder(folder);
                }}
              />
              <Box mt="2">
                <PermissionedButton
                  permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}
                  size="sm"
                  onClick={() => setAddFolderOpen(true)}
                >
                  New Folder
                </PermissionedButton>
              </Box>
              {addFolderOpen && (
                <AddFolder
                  toggleDisabled
                  folderType="models"
                  viewType="models"
                  onClose={() => {
                    setAddFolderOpen(false);
                    refetchFolders();
                  }}
                />
              )}
            </Field>
          </Column>
        );
      },
    },
  ];

  // update type and source if a model is passed in
  useEffect(() => {
    if (preselectedModel) {
      setType(preselectedModel.query_type as QueryType);
      initQueryState(preselectedModel);
    }
  }, [preselectedModel]);

  useEffect(() => {
    setSource(preselectedSource);
  }, [preselectedSource]);

  useEffect(() => {
    if (source) {
      setStep(1);
    }
  }, [source]);

  useEffect(() => {
    if (source && !preselectedModel) {
      resetQueryState();
      analytics.track("Add Model Source Selected", {
        workspace_id: workspace?.id,
        workspace_slug: workspace?.slug,
        source_type: source?.type,
        source_name: source?.name,
      });
    }
  }, [source]);

  useEffect(() => {
    if (type) {
      analytics.track("Add Model Query Mode Selected", {
        workspace_id: workspace?.id,
        workspace_slug: workspace?.slug,
        source_type: source?.type,
        source_name: source?.name,
        query_mode: type,
      });
    }
  }, [type]);

  useEffect(() => {
    setHasQueryColumns(false);
  }, [queryState]);

  useEffect(() => {
    if (columns?.length && !queryError) {
      setHasQueryColumns(true);
    }
  }, [rows, columns]);

  return { createModel: create, setStep, step, steps };
};
