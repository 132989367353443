import { useNavigate, useParams } from "react-router-dom";

import { SyncDraftPage } from "src/components/drafts/sync-draft";
import { Page } from "src/components/layout";
import { useDraftsQuery, useSyncQuery, DraftOperation } from "src/graphql";
import { PageSpinner } from "src/ui/loading";
import { useDestination } from "src/utils/destinations";
import { useSource } from "src/utils/sources";

export const SyncDraft = () => {
  const { sync_id: id } = useParams<{ sync_id: string }>();
  const navigate = useNavigate();

  const { data: syncData, isLoading: syncLoading } = useSyncQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
    },
  );
  const sync = syncData?.syncs?.[0];
  const model = sync?.segment;

  const { data: source, loading: sourceLoading } = useSource(model?.connection?.id, { pause: !sync });
  const {
    data: { destination, definition: destinationDefinition },
    loading: destinationLoading,
  } = useDestination(sync?.destination?.id, { pause: !sync });

  const { data: drafts } = useDraftsQuery(
    {
      resourceId: id?.toString() || "",
      resourceType: "sync",
      status: "pending",
    },
    {
      enabled: Boolean(id),
    },
  );
  const draft = drafts?.drafts?.[0];

  const loading = syncLoading || destinationLoading || sourceLoading;
  if (loading || !destination || !destinationDefinition) {
    return <PageSpinner />;
  }

  if (!draft) {
    navigate(`/syncs/${id}`);
    return null;
  }

  if (!sync || !source || !destination) {
    navigate("/syncs");
    return null;
  }

  const link = `/syncs/${sync.id}`;

  return (
    <Page
      crumbs={[
        { label: "Syncs", link: "/syncs" },
        { label: "Sync", link: draft.operation === DraftOperation.Create ? link : `${link}?editing=true` },
        { label: "Draft request" },
      ]}
      sync={sync}
    >
      <SyncDraftPage
        destinationDefinition={destinationDefinition}
        draft={draft}
        scheduleType={sync?.schedule ? sync?.schedule : { type: "manual" }}
        sync={sync}
      />
    </Page>
  );
};
