import { FC } from "react";

import { Grid } from "theme-ui";
import * as Yup from "yup";

import { IdMappingField } from "src/components/destinations/id-mapping-field";
import { MappingsField } from "src/components/destinations/mappings-field";
import { ModeField } from "src/components/destinations/mode-field";
import { useDestinationForm } from "src/contexts/destination-form-context";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS, convertType } from "src/utils/destinations";

import { ColumnOrConstantField } from "../column-or-constant-field";
import { DeleteField } from "../delete-field";
import { ObjectField } from "../object-field";
import { TypeField } from "../type-field";

const EXTERNAL_ID_FIELDS = [
  { label: "Amplitude User ID", value: "user_id" },
  { label: "Amplitude Device ID", value: "device_id" },
];

const GROUP_EXTERNAL_ID_FIELDS = [{ label: "Group Value", value: "group_value" }];

const OBJECT_FIELDS = [
  { label: "Language", value: "language", type: "string" },
  { label: "Paying", value: "paying", type: "string" },
  { label: "App Version", value: "app_version", type: "string" },
  { label: "Groups", value: "groups", type: "object" },
  { label: "Start Version", value: "start_version", type: "string" },
  { label: "Platform", value: "platform", type: "string" },
  { label: "OS Name", value: "os_name", type: "string" },
  { label: "OS Version", value: "os_version", type: "string" },
  { label: "Device Brand", value: "device_brand", type: "string" },
  { label: "Device Manufacturer", value: "device_manufacturer", type: "string" },
  { label: "Device Model", value: "device_model", type: "string" },
  { label: "Carrier", value: "carrier", type: "string" },
  { label: "Country", value: "country", type: "string" },
  { label: "Region", value: "region", type: "string" },
  { label: "City", value: "city", type: "string" },
  { label: "DMA", value: "dma", type: "string" },
];

const FIELDS = [
  { label: "User Properties", value: "user_properties", type: "object" },
  { label: "Groups", value: "groups", type: "object" },
  { label: "App Version", value: "app_version", type: "string" },
  { label: "Platform", value: "platform", type: "string" },
  { label: "OS Name", value: "os_name", type: "string" },
  { label: "OS Version", value: "os_version", type: "string" },
  { label: "Device Brand", value: "device_brand", type: "string" },
  { label: "Device Manufacturer", value: "device_manufacturer", type: "string" },
  { label: "Device Model", value: "device_model", type: "string" },
  { label: "Carrier", value: "carrier", type: "string" },
  { label: "Country", value: "country", type: "string" },
  { label: "Region", value: "region", type: "string" },
  { label: "City", value: "city", type: "string" },
  { label: "Dma", value: "dma", type: "string" },
  { label: "Language", value: "language", type: "string" },
  { label: "Price", value: "price", type: "float" },
  { label: "Quantity", value: "quantity", type: "integer" },
  { label: "Revenue", value: "revenue", type: "float" },
  { label: "ProductId", value: "productId", type: "string" },
  { label: "RevenueType", value: "revenueType", type: "string" },
  { label: "Location Latitude", value: "location_lat", type: "float" },
  { label: "Location Longitude", value: "location_lng", type: "float" },
  { label: "IP Address", value: "ip", type: "string" },
  { label: "iOS ID for Advertiser", value: "idfa", type: "string" },
  { label: "iOS ID for Vendor", value: "idfv", type: "string" },
  { label: "Android Ad ID", value: "adid", type: "string" },
  { label: "Android ID", value: "android_id", type: "string" },
  { label: "Event ID", value: "event_id", type: "int" },
  { label: "Session ID", value: "session_id", type: "long" },
  { label: "Insert ID", value: "insert_id", type: "string" },
];

const OBJECTS = [
  { label: "Users", value: "user" },
  { label: "Groups", value: "group" },
];

export const validation = Yup.object().shape(
  {
    type: Yup.string().required().default("object"),
    object: Yup.string().when("type", {
      is: "event",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required().default("user"),
    }),
    externalIdMapping: Yup.object().when("type", {
      is: "event",
      then: Yup.object().notRequired(),
      otherwise: COMMON_SCHEMAS.externalIdMapping,
    }),
    eventName: Yup.mixed().when("type", {
      is: "event",
      then: COMMON_SCHEMAS.columnOrConstant,
      otherwise: Yup.string().notRequired(),
    }),
    timestampFrom: Yup.mixed().notRequired(),
    groupType: Yup.string().when("object", {
      is: "group",
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),

    userIdFrom: Yup.mixed().when("type", {
      is: "event",
      then: Yup.mixed().when("deviceIdFrom", {
        is: (v) => v,
        then: Yup.mixed().notRequired(),
        otherwise: Yup.mixed().required("Either device ID or user ID column must be selected"),
      }),
      otherwise: Yup.string().notRequired(),
    }),
    deviceIdFrom: Yup.mixed().when("type", {
      is: "event",
      then: Yup.mixed().when("userIdFrom", {
        is: (v) => v,
        then: Yup.mixed().notRequired(),
        otherwise: Yup.mixed().required("Either device ID or user ID column must be selected"),
      }),
      otherwise: Yup.mixed().notRequired(),
    }),

    mappings: COMMON_SCHEMAS.mappings,
    customMappings: COMMON_SCHEMAS.mappings,
    deleteMode: Yup.string().notRequired(),
    skipUserProperty: Yup.boolean().notRequired(),
    minIdLength: Yup.number().min(1).max(255).notRequired(),
  },
  [["deviceIdFrom", "userIdFrom"]],
);

const TYPES = [
  { label: "Object", value: "object" },
  { label: "Event", value: "event" },
];

export const AmplitudeForm: FC = () => {
  const { config, setConfig, errors, hightouchColumns } = useDestinationForm();

  const options = config?.type === "object" ? OBJECT_FIELDS : FIELDS;

  const typedOpts = options?.map((o) => ({
    label: o?.label,
    value: o?.value,
    type: convertType(o?.type, { float: "number", integer: "number", int: "number", long: "number" }),
  }));

  return (
    <>
      <TypeField error={errors?.mode} options={TYPES} />

      {config?.type === "object" && (
        <ObjectField options={OBJECTS} onChange={(object) => setConfig({ type: config?.type, object })} />
      )}
      {config?.type === "object" && config?.object && (
        <ModeField options={[{ label: "Upsert", value: "upsert" }]} value="upsert" />
      )}
      {config?.type === "event" && <ModeField options={[{ label: "Insert", value: "insert" }]} value="insert" />}
      {config?.type === "object" && config?.object && (
        <Section>
          <Grid gap={8}>
            <IdMappingField options={config?.object === "user" ? EXTERNAL_ID_FIELDS : GROUP_EXTERNAL_ID_FIELDS} />
            {config?.object === "group" && (
              <Field error={errors?.groupType} label="What is the Amplitude group type?">
                <Input
                  defaultValue={config?.groupType}
                  error={errors?.groupType}
                  placeholder="Enter group key..."
                  sx={{ width: "340px" }}
                  onChange={(value) => {
                    setConfig({
                      ...config,
                      groupType: value,
                    });
                  }}
                />
              </Field>
            )}
          </Grid>
        </Section>
      )}
      {config?.type === "event" && (
        <Section>
          <Grid gap={8}>
            <ColumnOrConstantField
              columnLabel="Which column contains the event name?"
              constantInput={
                <Input
                  defaultValue={config?.eventName}
                  error={errors?.eventName}
                  placeholder="Enter event name..."
                  sx={{ width: "240px" }}
                  onChange={(value) => {
                    setConfig({
                      ...config,
                      eventName: value,
                    });
                  }}
                />
              }
              constantLabel="What is the event name?"
              property="eventName"
            />

            <Field
              description="Either device ID or user ID column must be selected."
              error={errors?.userIdFrom}
              label="Which column contains the Amplitude user ID?"
            >
              <Select
                isClearable
                isError={errors?.userIdFrom}
                options={hightouchColumns}
                placeholder="Select a column..."
                value={config?.userIdFrom}
                width="240px"
                onChange={(selected) => {
                  const val = selected?.value;
                  setConfig({ ...config, userIdFrom: val });
                }}
              />
            </Field>

            <Field
              description="Either device ID or user ID column must be selected."
              error={errors?.deviceIdFrom}
              label="Which column contains the device ID?"
            >
              <Select
                isClearable
                isError={errors?.deviceIdFrom}
                options={hightouchColumns}
                placeholder="Select a column..."
                value={config?.deviceIdFrom}
                width="240px"
                onChange={(selected) => {
                  const val = selected?.value;
                  setConfig({ ...config, deviceIdFrom: val });
                }}
              />
            </Field>

            <Field
              optional
              description="If this is empty, Amplitude will use the time the event arrives at the server."
              label="Which column contains the event timestamp?"
            >
              <Select
                isClearable
                options={hightouchColumns}
                placeholder="Select a column..."
                value={config?.timestampFrom}
                width="240px"
                onChange={(selected) => {
                  const val = selected?.value;
                  setConfig({ ...config, timestampFrom: val });
                }}
              />
            </Field>
          </Grid>
        </Section>
      )}
      {(config?.type === "event" || (config?.type === "object" && config?.object)) && (
        <>
          <Section>
            <MappingsField options={config?.type === "event" || config?.object === "user" ? typedOpts : undefined} />
          </Section>
          {(config?.type === "event" || config?.object === "user") && (
            <Section>
              <MappingsField isCustom />
            </Section>
          )}
        </>
      )}
      {config?.type === "event" && (
        <>
          <Section>
            <Field
              description="This is commonly used in data backfills, where Amplitude should not overwrite newer user properties associated to events."
              label="Would you like Amplitude to skip user properties being synced?"
              size="large"
            >
              <RadioGroup
                options={[
                  { label: "No, sync user properties normally", value: undefined },
                  { label: "Yes, skip user properties sync", value: true },
                ]}
                value={config?.skipUserProperty}
                onChange={(skipUserProperty) => {
                  setConfig({ ...config, skipUserProperty });
                }}
              />
            </Field>
          </Section>
          <Section>
            <Field
              description={`By default, Amplitude requires a minimum ID length
                            of 5 characters and warns that shorter IDs may indicate data
                            quality issues.`}
              label="Would you like to override Amplitude's default minimum ID length?"
              size="large"
            >
              <Input
                defaultValue={config?.minIdLength as string}
                error={errors?.minIdLength}
                max="255"
                min="1"
                placeholder="5"
                type="number"
                value={config?.minIdLength}
                onChange={(minIdLength) => {
                  setConfig({ ...config, minIdLength: minIdLength ? Number(minIdLength) : undefined });
                }}
              />
            </Field>
          </Section>
        </>
      )}

      {config?.type === "object" && config?.object && <DeleteField modes={["clear"]} />}
    </>
  );
};

export default {
  form: AmplitudeForm,
  validation,
};
