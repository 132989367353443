import { CloudCredentialsV2Query } from "src/graphql";

import { BIGQUERY_USER_ROLE, BIGQUERY_DATAVIEWER_ROLE } from "./bigquery";
import { DEFAULT_LIGHTNING_CODE_TITLE, DEFAULT_LIGHTNING_REASON } from "./default";

export type Context = {
  definitionName: string;
  configuration: Record<string, unknown> | undefined;
  credential: CloudCredentialsV2Query["getCloudCredentials"][0] | undefined;
  plannerDatabase: string | undefined;
};

export type Code = { title: (ctx: Context) => string; code: (ctx: Context) => string };

export type Permissions = {
  default?: { reason: (ctx: Context) => string; code: Code[] };
  lightning?: { reason: (ctx: Context) => string; code: Code[] };
};

export const DEFAULT_PERMISSIONS: Permissions = {
  lightning: {
    reason: DEFAULT_LIGHTNING_REASON,
    code: [
      {
        code: () =>
          "CREATE USER hightouch_user WITH PASSWORD '********';\nCREATE SCHEMA IF NOT EXISTS hightouch_audit;\nCREATE SCHEMA IF NOT EXISTS hightouch_planner;\nGRANT CREATE, USAGE ON SCHEMA hightouch_audit TO hightouch_user;\nGRANT CREATE, USAGE ON SCHEMA hightouch_planner TO hightouch_user;",
        title: DEFAULT_LIGHTNING_CODE_TITLE,
      },
    ],
  },
};

export const PERMISSIONS: Record<string, Permissions> = {
  snowflake: {
    lightning: {
      reason: DEFAULT_LIGHTNING_REASON,
      code: [
        {
          code: () =>
            "CREATE USER hightouch_user WITH PASSWORD '********';\nCREATE SCHEMA IF NOT EXISTS HIGHTOUCH_AUDIT;\nCREATE SCHEMA IF NOT EXISTS HIGHTOUCH_PLANNER;\nGRANT OWNERSHIP ON SCHEMA HIGHTOUCH_AUDIT TO hightouch_user;\nGRANT OWNERSHIP ON SCHEMA HIGHTOUCH_PLANNER TO hightouch_user;",
          title: DEFAULT_LIGHTNING_CODE_TITLE,
        },
      ],
    },
  },
  databricks: {
    lightning: {
      reason: () =>
        "This SQL snippet provisions two schemas (`hightouch_planner` and `hightouch_audit`) for storing logs of previously-synced data. Then, it grants the necessary permissions to your Databricks user or service principal. Before running the snippet, make sure to include your own username, which can be found in the top right corner of the Databricks web console. Alternatively, you can create an API-only service principal by following [these instructions](https://docs.databricks.com/administration-guide/users-groups/service-principals.html).",
      code: [
        {
          code: () =>
            "CREATE SCHEMA IF NOT EXISTS hightouch_audit;\nCREATE SCHEMA IF NOT EXISTS hightouch_planner;\nGRANT ALL PRIVILEGES ON SCHEMA hightouch_audit TO <YOUR_USER>;\nGRANT ALL PRIVILEGES ON SCHEMA hightouch_planner TO <YOUR_USER>;",
          title: DEFAULT_LIGHTNING_CODE_TITLE,
        },
      ],
    },
  },
  bigquery: {
    lightning: {
      reason: () =>
        `By default, your GCP service account does not have permission to read data from BigQuery. This access needs to be granted by assigning the \`bigquery.user\` and \`bigquery.dataViewer\` roles to the service account. You can do this in the Google Cloud web console or by running these snippets in the Cloud Shell. After doing so, run the last SQL snippet to provision the \`hightouch_planner\` and \`hightouch_audit\` schemas, which are used for storing logs of previously-synced data.`,
      code: [
        BIGQUERY_USER_ROLE,
        BIGQUERY_DATAVIEWER_ROLE,
        {
          code: ({ credential, plannerDatabase, configuration }) => {
            let database = plannerDatabase || configuration?.project || "";
            if (database) {
              database += ".";
            }

            return `CREATE SCHEMA IF NOT EXISTS \`${database}hightouch_audit\`;\nCREATE SCHEMA IF NOT EXISTS \`${database}hightouch_planner\`;\nGRANT \`roles/bigquery.dataViewer\`, \`roles/bigquery.dataEditor\` ON SCHEMA \`${database}hightouch_planner\` TO "serviceAccount:${
              credential?.stripped_config.client_email || "<YOUR_SERVICE_ACCOUNT>"
            }";\nGRANT \`roles/bigquery.dataViewer\`, \`roles/bigquery.dataEditor\` ON SCHEMA \`${database}hightouch_audit\` TO "serviceAccount:${
              credential?.stripped_config.client_email || "<YOUR_SERVICE_ACCOUNT>"
            }";`;
          },
          title: () => "Create schemas for Lightning sync engine and grant permission to read/write data",
        },
      ],
    },
    default: {
      reason: () =>
        `By default, your GCP service account does not have permission to read data from BigQuery. This access needs to be granted by assigning the \`bigquery.user\` and \`bigquery.dataViewer\` roles to the service account. You can do this in the Google Cloud web console or by running these snippets in the Cloud Shell.`,
      code: [BIGQUERY_USER_ROLE, BIGQUERY_DATAVIEWER_ROLE],
    },
  },
};
