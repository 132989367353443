import { useState, FC, useEffect } from "react";

import { Tabs, TabList, Tab, TabPanels, TabPanel, Box } from "@hightouchio/ui";
import { useParams } from "react-router-dom";

import { Page } from "src/components/layout";
import { PermissionProvider } from "src/contexts/permission-context";
import { ResourcePermissionGrant } from "src/graphql";
import { useNavigate } from "src/utils/navigate";

import { AudienceObjects } from "./objects";
import { SyncTemplates } from "./sync-templates";

enum AudienceSetupSlug {
  ParentModels = "parent-models",
  RelatedModels = "related-models",
  Events = "events",
  SyncTemplates = "sync-templates",
}

const SLUG_TO_TAB_INDEX = {
  [AudienceSetupSlug.ParentModels]: 0,
  [AudienceSetupSlug.RelatedModels]: 1,
  [AudienceSetupSlug.Events]: 2,
  [AudienceSetupSlug.SyncTemplates]: 3,
};

enum NavigationTab {
  PARENT_MODELS = "Parent models",
  RELATED_MODELS = "Related models",
  EVENTS = "Events",
  SYNC_TEMPLATES = "Sync templates",
}

const TABS = [
  {
    label: NavigationTab.PARENT_MODELS,
    path: `/audiences/setup/${AudienceSetupSlug.ParentModels}`,
  },
  {
    label: NavigationTab.RELATED_MODELS,
    path: `/audiences/setup/${AudienceSetupSlug.RelatedModels}`,
  },
  {
    label: NavigationTab.EVENTS,
    path: `/audiences/setup/${AudienceSetupSlug.Events}`,
  },
  {
    label: NavigationTab.SYNC_TEMPLATES,
    path: `/audiences/setup/${AudienceSetupSlug.SyncTemplates}`,
  },
];

export const SetupAudiences: FC = () => {
  const { object } = useParams<{ object: string }>();
  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState<number>(SLUG_TO_TAB_INDEX[object!] || 0);

  useEffect(() => {
    navigate(TABS[tabIndex]!.path);
  }, [navigate, tabIndex]);

  const isSyncTemplates = object === AudienceSetupSlug.SyncTemplates;

  return (
    <PermissionProvider
      permissions={[
        { resource: isSyncTemplates ? "sync_template" : "audience_schema", grants: [ResourcePermissionGrant.Create] },
      ]}
    >
      <Page crumbs={[{ label: "Audiences", link: "/audiences" }, { label: "Setup" }]}>
        <Box sx={{ width: "100%" }}>
          <Tabs index={tabIndex} onChange={setTabIndex}>
            <TabList>
              {TABS.map((tab, index) => (
                <Tab key={index}>{tab.label}</Tab>
              ))}
            </TabList>
            <TabPanels>
              {TABS.map((_, index) => (
                <TabPanel key={index}>{isSyncTemplates ? <SyncTemplates /> : <AudienceObjects />}</TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Box>
      </Page>
    </PermissionProvider>
  );
};
