import { FC } from "react";

import { ArrowsRightLeftIcon } from "@heroicons/react/24/solid";
import { Button, Text, Box, Column, Row, Heading } from "@hightouchio/ui";
import { Image } from "theme-ui";

import { Logo } from "src/ui/brand";
import { Card } from "src/ui/card";

interface Props {
  icon: string;
  name: string;
  href: string;
  onAuthorize: () => void | undefined;
}

export const AuthorizeConnection: FC<Readonly<Props>> = ({ icon, name, onAuthorize, href }) => {
  return (
    <Card>
      <Column sx={{ alignItems: "center", gap: 4 }}>
        <Row sx={{ alignItems: "center", gap: 4, mb: 4 }}>
          <Logo sx={{ width: "60px", mr: 2, objectFit: "contain", flexShrink: 0 }} />

          <Box sx={{ flexShrink: 0, width: "48px", color: "base.4" }}>
            <ArrowsRightLeftIcon fill="currentColor" />
          </Box>

          <Image alt={name} src={icon} sx={{ width: "60px", mr: 2, objectFit: "contain", flexShrink: 0 }} />
        </Row>
        <Heading size="lg">Authorize connection</Heading>
        <Box maxWidth="64ch" mb={4} textAlign="center">
          <Text>
            Hightouch requires limited access to your {name} account. Your credentials will be encrypted, and authorization can
            be revoked at any time.
          </Text>
        </Box>
        <Button
          size="lg"
          onClick={() => {
            onAuthorize();
            window.location.href = href;
          }}
        >
          <Image alt={name} src={icon} sx={{ width: "24px", objectFit: "contain", mr: 2 }} />
          Log in to {name}
        </Button>
      </Column>
    </Card>
  );
};

export const ReauthorizeConnection: FC<Props> = ({ onAuthorize, href, name, icon }) => {
  return (
    <Box>
      <Heading mb={2} size="md">
        Reauthorize connection
      </Heading>
      <Button
        onClick={() => {
          onAuthorize();
          window.location.href = href;
        }}
      >
        <Image alt={name} src={icon} sx={{ width: "18px", objectFit: "contain", mr: 2 }} />
        Log in to {name}
      </Button>
    </Box>
  );
};
