import { forwardRef, ReactNode, useEffect, useRef } from "react";

import { Box, ThemeUIStyleObject } from "theme-ui";

import { Button } from "src/ui/button";
import { Overlay } from "src/ui/overlay";

import { Column, Row } from "../box";
import { Heading } from "../heading";

export type ModalProps = {
  info?: boolean;
  isOpen?: boolean;
  title?: string;
  header?: ReactNode;
  subtitle?: ReactNode;
  footer?: ReactNode;
  footerContent?: ReactNode;
  onClose: () => void;
  sx?: ThemeUIStyleObject;
  bodySx?: ThemeUIStyleObject;
  children?: ReactNode;
  card?: boolean;
  fullscreen?: boolean;
  headerSx?: ThemeUIStyleObject;
  footerSx?: ThemeUIStyleObject;
};

export const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      children,
      isOpen = true,
      onClose,
      title,
      header,
      subtitle,
      footer,
      footerContent,
      card = true,
      fullscreen = false,
      info,
      bodySx = {},
      sx = {},
      headerSx = {},
      footerSx = {},
    },
    ref,
  ) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (isOpen && (event.key === "Esc" || event.key === "Escape")) {
          onClose();
        }
      };

      window.addEventListener("keydown", handleKeyDown, false);
      return () => {
        window.removeEventListener("keydown", handleKeyDown, false);
      };
    }, [ref, isOpen]);

    useEffect(() => {
      if (isOpen && document) {
        document.body.style.overflowY = "hidden";
      }

      return () => {
        if (document) {
          document.body.style.overflowY = "auto";
        }
      };
    }, [isOpen]);

    if (!isOpen && !fullscreen) {
      return null;
    }

    return (
      <Overlay fullscreen={fullscreen} isOpen={isOpen}>
        {card ? (
          <Column
            ref={containerRef}
            sx={{
              bg: "white",
              borderRadius: 4,
              overflow: "hidden",
              maxHeight: "100%",
              ...sx,
            }}
          >
            <Row
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                px: 5,
                py: 6,
                borderBottom: "small",
                flexShrink: 0,
                ...headerSx,
              }}
            >
              {header || <Heading variant="h2">{title}</Heading>}
            </Row>
            {subtitle && (
              <Row
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  px: 5,
                  borderBottom: "small",
                  flexShrink: 0,
                  ...headerSx,
                }}
              >
                {subtitle}
              </Row>
            )}
            <Box ref={ref} sx={{ width: "100%", flex: 1, px: 5, pt: 6, pb: 6, overflow: "auto", ...bodySx }}>
              {children}
            </Box>
            {(info || footer) && (
              <Row
                gap={4}
                sx={{
                  alignItems: "center",
                  justifyContent: "flex-end",
                  borderTop: "small",
                  flexShrink: 0,
                  p: 5,
                  ...footerSx,
                }}
              >
                {info ? (
                  <Button variant="primary" onClick={onClose}>
                    Close
                  </Button>
                ) : (
                  footer
                )}
              </Row>
            )}
            {footerContent && (
              <Row
                sx={{
                  alignItems: "center",
                  borderTop: "small",
                  flexShrink: 0,
                  p: 5,
                  ...footerSx,
                }}
              >
                {footerContent}
              </Row>
            )}
          </Column>
        ) : (
          <Row sx={{ width: "100%", height: "100%", ...bodySx }}>{children}</Row>
        )}
      </Overlay>
    );
  },
);

Modal.displayName = "Modal";
