import { FC } from "react";

import { Text } from "theme-ui";

import { Strike } from "src/utils/strike";

type Props = {
  table: string;
  oldTable?: string;
};

export const TableQuery: FC<Readonly<Props>> = ({ table, oldTable }) => (
  <Text sx={{ fontWeight: "semi" }}>
    Select all rows from{" "}
    <strong>
      <Strike _new={table} old={oldTable} />
    </strong>
  </Text>
);
