import { FC, ReactNode } from "react";

import { BrowserRouter } from "react-router-dom";

import { SluglessRouter } from "./slugless-router";
import { WorkspaceRouter } from "./workspace-router";

interface Props {
  children: ReactNode;
}

export const Router: FC<Props> = ({ children }) => {
  return (
    <BrowserRouter>
      <SluglessRouter />
      <WorkspaceRouter />
      {children}
    </BrowserRouter>
  );
};
