import { basicApiEndpoint, isFailedResponse, regionalApiEndpoints, responseToError } from "./fetcher";

export const switchWorkspace = async (id: string | number, to?: string): Promise<boolean> => {
  try {
    await fetch(`${import.meta.env.VITE_API_BASE_URL}/auth/switch-workspace`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ workspaceId: Number(id) }),
    });
    window.location.href = to || "/";
    return true;
  } catch (error) {
    console.error(error);
  }
  return false;
};

export const verifyWorkspace = async (): Promise<void> => {
  const headers = {
    "Content-Type": "application/json",
  };

  const apiRegion = window.Hightouch?.apiRegion;
  const workspaceId = window.Hightouch?.workspaceId;
  const apiEndpoint = apiRegion ? regionalApiEndpoints[apiRegion] : basicApiEndpoint;

  if (workspaceId) {
    headers["X-Hightouch-Requested-Workspace-Id"] = String(workspaceId);
  }

  const res = await fetch(`${apiEndpoint}/verify-workspace`, {
    method: "GET",
    headers,
    credentials: "include",
  });

  if (isFailedResponse(res)) {
    throw await responseToError(res);
  }
};
