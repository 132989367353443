import { FC } from "react";

import { Box, Flex } from "theme-ui";

import { SyncCircleIcon, ChevronUpIcon } from "src/ui/icons";

export const DiagramIllustrations: FC = () => {
  return (
    <Flex sx={{ flexGrow: 1, alignItems: "center", flexDirection: "column", mx: "20%" }}>
      <Box
        sx={{
          width: "100%",
          border: `2px dotted`,
          borderColor: "base.3",
          borderTop: "none",
          height: "60px",
          borderRadius: "0 0 14px 14px",
          display: "flex",
        }}
      >
        <Box
          sx={{
            mt: "-2px",
            ml: "-4px",
            width: "6px",
            height: "6px",
            borderRadius: "50%",
            border: `2px solid`,
            borderColor: "base.3",
          }}
        ></Box>
        <Flex sx={{ flexGrow: 1, justifyContent: "right", mr: "-13px", mt: -2 }}>
          <ChevronUpIcon color="base.3" />
        </Flex>
      </Box>
      <Box sx={{ mt: -7, background: "white", border: `2px solid`, borderColor: "base.3", p: 2, borderRadius: "50%" }}>
        <SyncCircleIcon color="base.3" height={32} width={32} />
      </Box>
    </Flex>
  );
};
