import { FC, useEffect } from "react";

import * as Yup from "yup";

import { useMetabaseListTablesQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Spinner } from "src/ui/loading";
import { Select } from "src/ui/select";

import { CustomQueryViewProps, CustomQueryFormProps } from "../custom-query";

export const QueryForm: FC<Readonly<CustomQueryFormProps>> = ({ source, query, onChange, setError }) => {
  const {
    data: tables,
    isLoading: tablesLoading,
    error: tablesError,
    refetch: listTables,
  } = useMetabaseListTablesQuery({ connectionId: String(source.id) });

  useEffect(() => {
    setError(tablesError);
  }, [tablesError]);

  return (
    <Field description="Select a table to retrieve the results." label="What is the table" size="large">
      <Select
        isError={Boolean(tablesError)}
        isLoading={tablesLoading}
        options={tables?.metabaseListTables?.map((t) => {
          return { label: t.name, value: t.id };
        })}
        placeholder="Select a table"
        reload={listTables}
        sx={{ maxWidth: "280px" }}
        value={query?.id || null}
        width="280px"
        onChange={(selected) => {
          if (selected) {
            const table = tables?.metabaseListTables?.find((t) => t.id === selected.value);

            if (table) {
              onChange({ ...query, id: table.id, databaseId: table.databaseId });
            }
          } else {
            onChange({ ...query, id: undefined, databaseId: undefined });
          }
        }}
      />
    </Field>
  );
};

export const QueryView: FC<Readonly<CustomQueryViewProps>> = ({ source, query, setError }) => {
  const {
    data: tables,
    isLoading: tablesLoading,
    error: tablesError,
  } = useMetabaseListTablesQuery({ connectionId: String(source.id) });

  useEffect(() => {
    setError(tablesError);
  }, [tablesError]);

  const table = tables?.metabaseListTables?.find((t) => t.id === query.id);
  const tableName = table?.name || `Unknown table with id ${query.id}`;

  return (
    <Field inline label="Table">
      {tablesLoading ? <Spinner /> : tableName}
    </Field>
  );
};

export const querySchema = Yup.object().shape({
  type: Yup.string().required().equals(["table"]),
  id: Yup.number().required(),
  databaseId: Yup.number().required(),
});
