import { FC, useEffect, useMemo, useState } from "react";

import { ConfirmationDialog, Paragraph, useToast } from "@hightouchio/ui";
import _, { get } from "lodash";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, Image, Text } from "theme-ui";
import { isPresent } from "ts-extras";

import { SetupForm } from "src/components/destinations/destination-form";
import { TestDestinationBadge, TestResult, TestUpdatedDestinationButton } from "src/components/destinations/test-destination";
import { EditLabels } from "src/components/labels/edit-labels";
import { Labels } from "src/components/labels/labels";
import { Page } from "src/components/layout";
import { SaveWarning } from "src/components/modals/save-warning";
import { Header, SidebarForm } from "src/components/page";
import { Permission } from "src/components/permission";
import { DisplaySlug } from "src/components/slug/display-slug";
import { Warning } from "src/components/warning";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  DestinationInstancesOrderBy,
  DestinationSyncsQuery,
  OrderBy,
  ResourcePermissionGrant,
  useDeleteDestinationMutation,
  useDestinationSyncsQuery,
  useUpdateDestinationV2Mutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { Avatar } from "src/ui/avatar";
import { SquareBadge } from "src/ui/badge";
import { Row, Column } from "src/ui/box";
import { Button } from "src/ui/button";
import { ChevronDownIcon, PlusIcon } from "src/ui/icons";
import { PageSpinner } from "src/ui/loading";
import Logo from "src/ui/Logo";
import { Popout } from "src/ui/popout";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import { Tabs } from "src/ui/tabs";
import { useDestination } from "src/utils/destinations";
import { getDifference } from "src/utils/get-difference";
import { formatDatetime } from "src/utils/time";

import { useLabels } from "../../components/labels/use-labels";
import placeholderImage from "../../components/models/sync-placeholder.png";

enum Tab {
  CONFIGURATION = "Configuration",
  SYNCS = "Syncs",
}

type Config = Record<string, unknown>;
type Sync =
  | NonNullable<DestinationSyncsQuery["destinations_by_pk"]>["destination_instances"][0]
  | DestinationSyncsQuery["sync_templates"][0]["destination_instances"][0];
type SyncCompareFunction = (args: { syncA: Sync; syncB: Sync; sortKey: string; sortDirection: OrderBy }) => number;

enum SortKeys {
  ModelName = "segment.name",
  CreatedAt = "created_at",
}

/**
 * A comparing function used to sort syncs.
 *
 * @param arguments arguments
 * @param arguments.syncA The first destination to compare
 * @param arguments.destinationb The second destination to compare
 * @param arguments.sortKey The path to the value to sort on
 * @param arguments.sortDirection The sort direction
 * @returns
 */
const syncCompareFunction: SyncCompareFunction = ({ syncA, syncB, sortKey, sortDirection }) => {
  if (!sortDirection || (!sortDirection.startsWith("asc") && !sortDirection.startsWith("desc"))) {
    return 0;
  }

  let result;
  let aValue = get(syncA, sortKey);
  let bValue = get(syncB, sortKey);

  switch (sortKey) {
    // string sorting
    case "status":
    case "segment.name":
    case "destination.name":
      result = aValue.trim().localeCompare(bValue.trim());
      break;
    // date sorting
    case "created_at":
      aValue = new Date(aValue);
      bValue = new Date(bValue);
      result = aValue - bValue;
      break;
    default:
      // syncs columns are all string based except for date columns
      return 0;
  }

  return sortDirection.startsWith("asc") ? result : -result;
};

export const Destination: FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  // `user.id` should be a string but fixing that is a larger change
  const userId = user?.id != null ? String(user?.id) : undefined;

  const { destination_id: id } = useParams<{ destination_id?: string }>();
  const idAsInt = id ? parseInt(id, 10) : undefined;
  const { toast } = useToast();
  const [config, setConfig] = useState<Config | undefined>();
  const [credentialId, setCredentialId] = useState<string | undefined>();
  const [name, setName] = useState<string | undefined>();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [tab, setTab] = useState<Tab>(Tab.CONFIGURATION);
  const [isEditLabelModalOpen, setIsEditLabelModalOpen] = useState(false);

  const { isLoading: updating, mutateAsync: updateDestination } = useUpdateDestinationV2Mutation();
  const { isLoading: deleting, mutateAsync: deleteDestination } = useDeleteDestinationMutation();

  const [testing, setTesting] = useState(false);
  const [testResult, setTestResult] = useState<TestResult>(TestResult.Unknown);
  const [testError, setTestError] = useState<Error | null>(null);

  const { limit, offset, page, orderBy, sortKey, sortDirection, setPage, onSort } = useTableConfig<DestinationInstancesOrderBy>(
    {
      defaultSortKey: "updated_at",
      sortOptions: Object.values(SortKeys),
    },
  );

  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText = destinationOverageText + " To create a sync, upgrade your plan.";

  const {
    data: { destination, definition },
    loading: destinationLoading,
    refetch,
  } = useDestination(id ?? "", { pause: !id });

  const { labels: destinationLabels } = useLabels();

  const {
    data: syncsData,
    isLoading: syncsLoading,
    isRefetching: syncsRefetching,
  } = useDestinationSyncsQuery(
    {
      destinationId: id ?? "",
      sameDestinationId: Number(idAsInt),
    },
    {
      keepPreviousData: true,
    },
  );

  const labels = destination?.tags ?? {};
  const labelKeys = Object.keys(labels);

  // Need both syncs from a destination and syncs from sync_templates
  const destinationSyncs = getDifference<Sync>(
    syncsData?.destinations_by_pk?.destination_instances,
    syncsData?.sync_templates.flatMap(({ destination_instances }) => destination_instances),
  );

  const sortedSyncs = useMemo(() => {
    if (!sortKey || !sortDirection) {
      return destinationSyncs;
    }

    const sortedSyncs = [...destinationSyncs];

    sortedSyncs.sort((syncA, syncB) => syncCompareFunction({ syncA, syncB, sortKey, sortDirection }));

    return sortedSyncs;
  }, [destinationSyncs, sortKey, sortDirection]);

  const paginatedSyncs = sortedSyncs.slice(offset, offset + limit);

  useEffect(() => {
    setName(destination?.name ?? "");
    setConfig(destination?.config ?? {});
    setCredentialId(destination?.credential_id);
  }, [destination]);

  const onUpdate = () => {
    refetch();

    toast({
      id: "update-destination",
      title: "Destination was updated",
      variant: "success",
    });
  };

  const updateName = async (name: string) => {
    if (!id) {
      return;
    }

    await updateDestination({
      id,
      object: {
        name,
        updated_by: userId,
      },
    });

    onUpdate();
  };

  const update = async () => {
    if (!id) {
      return;
    }

    await updateDestination({
      id,
      object: {
        credential_id: credentialId,
        updated_by: userId,
        config: {
          ...destination?.config,
          ...config,
        },
      },
    });

    onUpdate();
  };

  const updateLabels = async (labels: Record<string, string | number>) => {
    if (!id) {
      return;
    }

    try {
      await updateDestination({
        id: id,
        object: {
          tags: labels,
          config: {
            ...destination?.config,
            ...config,
          },
        },
      });

      onUpdate();
      setIsEditLabelModalOpen(false);
    } catch (error) {
      toast({
        id: "update-destination-labels",
        title: "Couldn't update labels",
        message: error.message,
        variant: "error",
      });
    }
  };

  const dirty =
    name !== destination?.name ||
    !_.isEqual(config, destination?.config) ||
    !_.isEqual(credentialId, destination?.credential_id);

  useEffect(() => {
    if (status === "error") {
      setTestResult(TestResult.Failed);
    } else if (status === "success") {
      setTestResult(TestResult.Success);
    } else {
      setTestResult(TestResult.Unknown);
    }
  }, [status]);

  function setConfigDirty(config: Config) {
    setConfig(config);
    setTestResult(TestResult.Unknown);
  }

  const TABS = [
    Tab.CONFIGURATION,
    {
      render: () => (
        <Row sx={{ alignItems: "center" }}>
          <Text>Syncs</Text>
          {destinationSyncs?.length > 0 && <SquareBadge sx={{ ml: 2 }}>{destinationSyncs?.length}</SquareBadge>}
        </Row>
      ),
      value: Tab.SYNCS,
    },
  ].filter(Boolean);

  const columns = [
    {
      name: "Model",
      sortDirection: orderBy?.segment?.name,
      onClick: () => onSort(SortKeys.ModelName),
      cell: ({ segment }) => {
        const name = segment?.name;
        const definition = segment?.connection?.definition;

        return (
          <Row sx={{ alignItems: "center" }}>
            <Image alt={definition?.name} src={definition?.icon} sx={{ width: "18px", flexShrink: 0, mr: 2 }} />

            <Text sx={{ fontWeight: "semi", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
              {name || definition?.name}
            </Text>
          </Row>
        );
      },
    },
    {
      name: "Created at",
      sortDirection: orderBy?.created_at,
      onClick: () => onSort(SortKeys.CreatedAt),
      cell: ({ created_at }) => <Text sx={{ fontWeight: "semi" }}>{created_at ? formatDatetime(created_at) : "-"}</Text>,
    },
  ];

  if (destinationLoading) {
    return <PageSpinner />;
  }

  if (!destinationLoading && !destination) {
    return <Warning subtitle="It may have been deleted" title="Destination not found" />;
  }

  const updatedByUsername = destination?.updated_by_user?.name || destination?.created_by_user?.name;
  return (
    <>
      <PermissionProvider permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Update] }]}>
        <Page
          crumbs={[{ label: "Destinations", link: "/destinations" }, { label: destination?.name || definition?.name || "" }]}
        >
          <Column sx={{ width: "100%", mb: 6 }}>
            <Header
              rightToolbar={[
                definition?.testConnection ? <TestDestinationBadge key={0} result={testResult} testing={testing} /> : undefined,
                <Permission
                  key={1}
                  permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Delete], resource_id: id }]}
                >
                  <Button
                    label="Delete"
                    variant="secondary"
                    onClick={() => {
                      setDeleteModal(true);
                    }}
                  />
                </Permission>,
              ].filter(isPresent)}
              title={destination?.name ?? ""}
              onNameChange={updateName}
            />
            <Row sx={{ alignItems: "center", mt: -6 }}>
              <Row sx={{ alignItems: "center", height: "100%", borderRight: "small", pr: 4, mr: 4 }}>
                <Logo logoUrl={definition?.icon} name={definition?.name ?? ""} />
                <Text sx={{ ml: 2, fontWeight: "semi" }}>{definition?.name}</Text>
              </Row>
              <Row sx={{ alignItems: "center" }}>
                <Text sx={{ mr: 1, color: "base.6" }}>Last updated:</Text>
                <Text sx={{ mr: 1 }}>
                  {moment(destination?.updated_at || destination?.created_at).calendar()}
                  {updatedByUsername && " by"}
                </Text>
                {updatedByUsername && <Avatar name={updatedByUsername} />}
              </Row>
              <Row sx={{ borderLeft: "small", ml: 4, pl: 4, alignItems: "center" }}>
                <Text sx={{ mr: 1, color: "base.7" }}>Slug:</Text>
                <DisplaySlug currentSlug={destination?.slug} />
              </Row>
              {labelKeys.length > 0 ? (
                <Row sx={{ alignItems: "center", pl: 4, ml: 4, height: "100%", borderLeft: "small" }}>
                  <Popout
                    content={({ close }) => (
                      <>
                        <Labels labels={labels} />

                        <Permission
                          permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                        >
                          <Button
                            sx={{ mt: 4 }}
                            variant="secondary"
                            onClick={() => {
                              setIsEditLabelModalOpen(true);
                              close();
                            }}
                          >
                            Edit labels
                          </Button>
                        </Permission>
                      </>
                    )}
                    contentSx={{ p: 3, minWidth: "90px" }}
                    placement="bottom-start"
                  >
                    <Text sx={{ mr: 1 }}>Labels</Text>
                    <SquareBadge>{Object.keys(labels || {}).length}</SquareBadge>
                    <ChevronDownIcon size={16} sx={{ ml: 2 }} />
                  </Popout>
                </Row>
              ) : (
                <Row sx={{ pl: 4, ml: 4, borderLeft: "small" }}>
                  <Button
                    iconBefore={<PlusIcon color="gray.700" size={14} />}
                    size="small"
                    variant="secondary"
                    onClick={() => {
                      setIsEditLabelModalOpen(true);
                    }}
                  >
                    Add labels
                  </Button>
                </Row>
              )}
            </Row>
          </Column>
          <Row sx={{ alignItems: "flex-start" }}>
            <Column sx={{ width: "100%" }}>
              <Tabs setTab={(tab) => setTab(tab as Tab)} sx={{ mb: 8 }} tab={tab} tabs={TABS} />

              {tab === Tab.CONFIGURATION && (
                <Row sx={{ alignItems: "flex-start" }}>
                  <Grid gap={8} sx={{ flexGrow: 1, mr: 8 }}>
                    <Permission
                      permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                    >
                      {definition && (
                        <SetupForm
                          config={config}
                          credentialId={credentialId}
                          definition={definition}
                          destination={destination}
                          error={testError}
                          isSetup={false}
                          setConfig={setConfigDirty}
                          setCredentialId={setCredentialId}
                        />
                      )}
                    </Permission>
                  </Grid>
                  <SidebarForm
                    buttons={
                      <>
                        {definition?.testConnection ? (
                          <Permission
                            permissions={[
                              { resource: "destination", grants: [ResourcePermissionGrant.Update], resource_id: id },
                            ]}
                          >
                            <TestUpdatedDestinationButton
                              credentialId={credentialId}
                              destinationId={destination?.id?.toString()}
                              newConfiguration={config}
                              onError={setTestError}
                              onLoading={setTesting}
                              onResult={setTestResult}
                            />
                          </Permission>
                        ) : null}
                        <Permission
                          permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                        >
                          <Button
                            disabled={!dirty}
                            label="Save changes"
                            loading={updating}
                            sx={{ width: "100%" }}
                            onClick={update}
                          />
                        </Permission>
                      </>
                    }
                    docsUrl={definition?.docs ?? ""}
                    name={definition?.name ?? ""}
                  />
                </Row>
              )}

              {tab === Tab.SYNCS && (
                <>
                  <Table
                    columns={columns}
                    data={paginatedSyncs}
                    loading={syncsLoading || syncsRefetching}
                    placeholder={{
                      custom: (
                        <Column sx={{ alignItems: "center" }}>
                          <Image src={placeholderImage} sx={{ mb: 6 }} width="187px" />
                          <Text sx={{ fontWeight: "bold", fontSize: 3, mb: 1 }}>No models are synced to this destination</Text>
                          <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
                            <Text sx={{ mb: 4, color: "base.6" }}>Still working on this? Add a sync when you’re ready</Text>
                            <Button
                              disabled={overageLockout}
                              tooltip={overageLockout ? overageText : undefined}
                              variant="secondary"
                              onClick={() => {
                                navigate("/syncs/new");
                              }}
                            >
                              Add a sync
                            </Button>
                          </Permission>
                        </Column>
                      ),
                    }}
                    onRowClick={({ id }) => navigate(`/syncs/${id}`)}
                  />
                  <Pagination count={destinationSyncs.length} label="syncs" page={page} rowsPerPage={limit} setPage={setPage} />
                </>
              )}
            </Column>
          </Row>
        </Page>
      </PermissionProvider>

      <ConfirmationDialog
        confirmButtonText="Delete destination"
        isOpen={deleteModal}
        title="Delete destination"
        variant="danger"
        onClose={() => {
          setDeleteModal(false);
        }}
        onConfirm={async () => {
          if (!id) {
            return;
          }

          const { delete_destinations_by_pk } = await deleteDestination({ id });

          if (delete_destinations_by_pk) {
            analytics.track("Destination Deleted", {
              destination_id: destination?.id,
              destination_name: destination?.name,
              destination_type: definition?.name,
            });

            toast({
              id: "delete-destination",
              title: `Destination ${destination?.name || definition?.name} was deleted`,
              variant: "success",
            });

            navigate("/destinations");
          } else {
            toast({
              id: "delete-destination",
              title: "Couldn't delete this destination",
              variant: "error",
            });
          }
        }}
      >
        <Paragraph>Are you sure you want to delete this destination? You won't be able to undo this.</Paragraph>
      </ConfirmationDialog>

      <EditLabels
        description="You can label destinations that have similar properties"
        existingLabelOptions={destinationLabels}
        hint="Example keys: team, project, region, env."
        isOpen={isEditLabelModalOpen}
        labels={labels ?? {}}
        loading={updating}
        saveLabel="Save"
        title="Edit labels"
        onClose={() => setIsEditLabelModalOpen(false)}
        onSave={updateLabels}
      />

      <SaveWarning dirty={dirty && !deleting} />
    </>
  );
};
