import { FC, useState } from "react";

import { Controller, useForm, useFieldArray, NestedValue } from "react-hook-form";
import { Grid } from "theme-ui";

import {
  initialPropertyCondition,
  PropertyCondition,
  RelatedColumn,
  TraitColumn,
  TraitDefinition,
  AudienceParent,
  AdditionalColumn,
} from "src/types/visual";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Modal } from "src/ui/modal";
import { NewSelect } from "src/ui/new-select";

import { VStack } from "../explore/query-builder";
import { PlusButton } from "../explore/visual/condition";
import { PropertyFilter } from "../explore/visual/property-filter";

export const AudienceTraitForm: FC<
  Readonly<{
    title?: string;
    alias?: string;
    trait: TraitDefinition | undefined;
    conditions?: PropertyCondition[] | null;
    parent: AudienceParent | undefined | null;
    traits?: TraitDefinition[];
    onClose: () => void;
    onSubmit: (value: AdditionalColumn) => Promise<void>;
  }>
> = (props) => {
  const { title, onSubmit, traits, parent, onClose } = props;

  const [submitting, setSubmitting] = useState(false);

  const { handleSubmit, control, register, watch } = useForm<{
    alias: string | undefined;
    trait: TraitDefinition | undefined;
    conditions: NestedValue<PropertyCondition[]>;
  }>({
    defaultValues: {
      alias: props.alias,
      trait: props.trait,
      conditions: (props.conditions || []) as NestedValue<PropertyCondition[]>,
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - no circular types until react-hook-form v8
  const { fields, append, remove } = useFieldArray({ name: "conditions", control });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - no circular types until react-hook-form v8
  const trait = watch("trait");

  const conditions = watch("conditions");

  const traitOptions = traits?.map((trait) => ({ label: trait.name, value: trait }));

  const submit = async ({ alias, trait, conditions }) => {
    setSubmitting(true);

    const traitColumn: TraitColumn = {
      type: "trait",
      traitDefinitionId: trait?.id,
      conditions,
    };
    const relatedColumn: RelatedColumn = {
      type: "related",
      path: [String(trait.relationship.id)],
      column: traitColumn,
    };

    await onSubmit({ alias, column: relatedColumn });

    setSubmitting(false);

    onClose();
  };

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button loading={submitting} onClick={handleSubmit(submit)}>
            {title ? "Save" : "Add"}
          </Button>
        </>
      }
      sx={{ maxWidth: "568px", width: "100%" }}
      title={title}
      onClose={onClose}
    >
      <Grid gap={8}>
        <Field label="Name">
          <Input {...register("alias")} />
        </Field>
        <Field label="Parent trait">
          {typeof traits === "undefined" ? (
            <Input disabled readOnly value={trait?.name} />
          ) : (
            <Controller
              control={control}
              name="trait"
              render={({ field }) => <NewSelect options={traitOptions} {...field} />}
            />
          )}
        </Field>
        <Field label="Conditions">
          <VStack gap={2}>
            {fields.map(({ id }, index) => {
              const condition = conditions[index];
              return (
                <Controller
                  key={id}
                  control={control}
                  name={`conditions.${index}`}
                  render={({ field }) => (
                    <PropertyFilter
                      audience={undefined}
                      columns={trait?.relationship.to_model.filterable_audience_columns}
                      condition={field.value as PropertyCondition}
                      events={undefined}
                      parent={parent}
                      relationships={undefined}
                      traits={undefined}
                      onChange={(value) => {
                        field.onChange({ ...condition, ...value });
                      }}
                      onRemove={() => remove(index)}
                    />
                  )}
                />
              );
            })}
            <PlusButton disabled={!trait} onClick={() => append(initialPropertyCondition)}>
              property
            </PlusButton>
          </VStack>
        </Field>
      </Grid>
    </Modal>
  );
};
