import { FC } from "react";

import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { Box, Column, Heading, LinkButton, Text } from "@hightouchio/ui";

import { Card } from "src/ui/card";

type Props = {
  title: string;
  subtitle?: string;
  linkText?: string;
  to?: string;
};

export const Warning: FC<Props> = ({ title, subtitle, linkText, to }) => {
  return (
    <Card sx={{ maxWidth: "540px", width: "100%", mx: "auto", mt: 24 }}>
      <Column align="center" gap={8} sx={{ color: "gray.900", textAlign: "center" }}>
        <Box color="grass">
          <ExclamationCircleIcon fill="currentColor" width="64px" />
        </Box>
        <Heading size="xl">{title}</Heading>
        <Text>{subtitle}</Text>
        {to && <LinkButton href={to}>{linkText || ""}</LinkButton>}
      </Column>
    </Card>
  );
};
