const prefix = (key: string) => `hightouch-${key}`;

export const save = (key: string, value: any) => {
  localStorage.setItem(prefix(key), JSON.stringify(value));
};

export const load = (key: string) => {
  const value = localStorage.getItem(prefix(key));

  if (value) {
    return JSON.parse(value);
  }

  return null;
};
