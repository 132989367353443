import { FC } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { Badge } from "src/ui/badge";

type DraftBadgeProps = { sx?: ThemeUIStyleObject; text?: string };

export const DraftBadge: FC<DraftBadgeProps> = ({ sx = {}, text = "Draft" }) => (
  <Badge sx={sx} variant="yellow">
    {text}
  </Badge>
);
