import { FC } from "react";

import { DestinationDefinition, DestinationQuery, ModelQuery } from "src/graphql";
import { Wizard } from "src/ui/wizard";

import { useCreateSyncWizard } from "../syncs/create/use-create-sync-wizard";

type Props = {
  destination?: DestinationQuery["destinations_by_pk"];
  destinationDefinition?: DestinationDefinition;
  model?: ModelQuery["segments_by_pk"];
  onSubmit?: ({ id }: { id: string }) => void;
  onCancel: () => void;
};

export const OnboardingCreateSync: FC<Readonly<Props>> = ({
  destination,
  destinationDefinition,
  model,
  onSubmit,
  onCancel,
}) => {
  const { createSync, setStep, step, steps } = useCreateSyncWizard({
    initialStep: 2,
    model,
    destination,
    destinationDefinition,
    onSubmit,
  });

  return <Wizard setStep={setStep} step={step} steps={steps} title="New sync" onCancel={onCancel} onSubmit={createSync} />;
};
