import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const LockIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M14.4192 5.39613V6.92947C16.1416 7.46708 17.3965 9.02626 17.3965 10.8885V15.8254C17.3965 18.1309 15.4851 20.0001 13.1287 20.0001H4.66528C2.30784 20.0001 0.396484 18.1309 0.396484 15.8254V10.8885C0.396484 9.02626 1.65243 7.46708 3.37377 6.92947V5.39613C3.38393 2.41491 5.85315 0.00012207 8.88124 0.00012207C11.95 0.00012207 14.4192 2.41491 14.4192 5.39613ZM8.90157 1.73917C10.9643 1.73917 12.641 3.37884 12.641 5.39613V6.71383H5.15201V5.37625C5.16217 3.3689 6.8388 1.73917 8.90157 1.73917ZM9.78561 14.4551C9.78561 14.942 9.38931 15.3295 8.8914 15.3295C8.40366 15.3295 8.00736 14.942 8.00736 14.4551V12.249C8.00736 11.772 8.40366 11.3844 8.8914 11.3844C9.38931 11.3844 9.78561 11.772 9.78561 12.249V14.4551Z"
        fillRule="evenodd"
      />
    </Icon>
  );
};
