import { FC, useEffect, useState, Fragment } from "react";

import { EditableHeading, useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { isEqual } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { Text, Grid, Image } from "theme-ui";

import { DestinationForm } from "src/components/destinations/sync-form";
import { Page } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { Permission } from "src/components/permission";
import { ScheduleManager } from "src/components/schedule";
import { Schedule } from "src/components/schedule/types";
import { SyncAlerts } from "src/components/syncs/sync-alerts";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  ResourcePermissionGrant,
  useSyncTemplateQuery,
  useUpdateSyncTemplateMutation,
  useDeleteSyncTemplateMutation,
  useSyncsByTemplateIdQuery,
  UpdateSyncTemplateMutationVariables,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import { Row, Column } from "src/ui/box";
import { Button } from "src/ui/button";
import { ExternalLinkIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { PageSpinner } from "src/ui/loading";
import { Message } from "src/ui/message";
import { Modal } from "src/ui/modal";
import { Tabs } from "src/ui/tabs";
import { useDestinations } from "src/utils/destinations";
import { useSource } from "src/utils/sources";

enum Tab {
  CONFIGURATION = "Configuration",
  SCHEDULE = "Schedule",
  ALERTS = "Alerts",
}

const TABS = [Tab.CONFIGURATION, Tab.SCHEDULE, Tab.ALERTS];

type Config = UpdateSyncTemplateMutationVariables["input"]["config"];

export const SyncTemplate: FC = () => {
  const { sync_template_id } = useParams<{ sync_template_id?: string }>();
  const id = Number(sync_template_id);
  const { toast } = useToast();
  const navigate = useNavigate();

  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const [tab, setTab] = useState<Tab>(Tab.CONFIGURATION);
  const [name, setName] = useState<string>();
  const [schedule, setSchedule] = useState<Schedule | null>(null);
  const [updatedConfig, setUpdatedConfig] = useState<Config | undefined>();
  const [pendingUpdate, setPendingUpdate] = useState<Tab | undefined>();

  const { data: syncTemplateData, isLoading: syncTemplateLoading } = useSyncTemplateQuery(
    {
      id,
    },
    { enabled: Boolean(id) },
  );

  const syncTemplate = syncTemplateData?.sync_templates_by_pk;
  const syncAlerts = syncTemplate?.alert_instances;
  const destination = syncTemplate?.destination;

  const { data: dependentSyncsData, isLoading: dependentSyncsLoading } = useSyncsByTemplateIdQuery(
    { templateId: id },
    { enabled: Boolean(id) },
  );

  const dependentSyncs = dependentSyncsData?.syncs;

  const { data: source, loading: sourceLoading } = useSource(syncTemplate?.segment?.connection?.id, { pause: !syncTemplate });

  const {
    data: { definitions: destinationDefinitions },
    loading: destinationsLoading,
  } = useDestinations();

  const destinationDefinition = destinationDefinitions?.find(({ type }) => type === destination?.type);

  const { mutateAsync: updateSyncTemplate, isLoading: updating } = useUpdateSyncTemplateMutation();
  const { mutateAsync: deleteSyncTemplate } = useDeleteSyncTemplateMutation();

  const loading =
    sourceLoading ||
    !source ||
    destinationsLoading ||
    !destinationDefinitions ||
    !destinationDefinition ||
    syncTemplateLoading ||
    !syncTemplate ||
    !destination ||
    dependentSyncsLoading ||
    !dependentSyncs;

  const onUpdate = () => {
    //const w = window as any;
    // w.rudderanalytics.track("Sync Template Updated", {
    //   model_id: object?.id,
    //   model_type: type,
    //   model_name: object?.name,
    //   source_id: source?.id,
    //   source_type: source?.type,
    // });

    toast({
      id: "update-sync-template",
      title: `${name} was updated`,
      variant: "success",
    });
  };

  const updatePermission = useHasPermission([{ resource: "sync", grants: [ResourcePermissionGrant.Update] }]);

  const updateConfig = async () => {
    await updateSyncTemplate({
      id,
      input: {
        config: { ...updatedConfig, configVersion: syncTemplate?.config?.configVersion },
      },
    });

    setUpdatedConfig(undefined);

    onUpdate();
  };

  const updateSchedule = async () => {
    await updateSyncTemplate({
      id,
      input: {
        schedule: schedule?.type === "manual" ? null : schedule,
      },
    });

    onUpdate();
  };

  const updateName = async () => {
    await updateSyncTemplate({
      id,
      input: {
        name,
      },
    });

    onUpdate();
  };

  const onDelete = async () => {
    try {
      await deleteSyncTemplate({
        id,
      });

      // const w = window as any;
      // w.rudderanalytics.track("Sync Template Deleted", {
      //   model_id: object?.id,
      //   model_type: type,
      //   model_name: object?.name,
      //   source_id: source?.id,
      //   source_type: source?.type,
      // });

      navigate(`/audiences/setup/sync-templates`);
    } catch (error) {
      toast({
        id: "delete-sync-template",
        title: "Couldn't delete this sync template",
        variant: "error",
      });

      Sentry.captureException(error);
      throw error;
    }
  };

  useEffect(() => {
    if (syncTemplate?.schedule || syncTemplate?.schedule === null) {
      setSchedule(syncTemplate?.schedule ? syncTemplate?.schedule : { type: "manual" });
    }

    setName(syncTemplate?.name);
  }, [syncTemplate]);

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <>
      <PermissionProvider permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Update] }]}>
        <Page
          crumbs={[
            {
              label: "Audiences",
              link: "/audiences",
            },
            {
              label: "Sync templates",
              link: `/audiences/setup/sync-templates`,
            },
            {
              label: syncTemplate.name,
            },
          ]}
        >
          <Row sx={{ justifyContent: "space-between", alignItems: "center", mb: 5, width: "100%" }}>
            <EditableHeading
              isDisabled={!updatePermission.hasPermission}
              size="lg"
              value={name ?? ""}
              onChange={setName}
              onSubmit={updateName}
            />

            <Row sx={{ ml: 8 }}>
              <Permission>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setConfirmingDelete(true);
                  }}
                >
                  Delete
                </Button>
              </Permission>
            </Row>
          </Row>

          <Tabs setTab={(tab) => setTab(tab as Tab)} sx={{ mb: 8 }} tab={tab} tabs={TABS} />

          {tab === Tab.CONFIGURATION && (
            <DestinationForm
              destination={destination}
              destinationDefinition={destinationDefinition}
              model={syncTemplate.segment}
              slug={destinationDefinition.type}
              sourceDefinition={source.definition}
              syncConfig={syncTemplate.config}
              onSubmit={async (config) => {
                setUpdatedConfig(config);
                setPendingUpdate(Tab.CONFIGURATION);
              }}
            />
          )}

          {tab === Tab.SCHEDULE && (
            <Row sx={{ width: "100%", justifyContent: "space-between" }}>
              <Column>
                <ScheduleManager schedule={schedule} setSchedule={setSchedule} />
                <Message sx={{ mt: 12 }}>
                  <Text>
                    You can also trigger this sync via Airflow. The ID for this sync is: <strong>{id}</strong>.
                  </Text>
                  <Text sx={{ mt: 3 }}>
                    For more information view our{" "}
                    <Link newTab to={`${import.meta.env.VITE_DOCS_URL}/integrations/airflow`}>
                      Airflow Operator docs
                    </Link>
                    . If you need an API key you can create one <Link to="/settings/api-keys">here.</Link>
                  </Text>
                </Message>
              </Column>
              <Permission>
                <Button
                  disabled={syncTemplate.schedule ? isEqual(schedule, syncTemplate.schedule) : schedule?.type === "manual"}
                  loading={updating}
                  sx={{ ml: 4, width: "208px", position: "sticky", top: 24 }}
                  onClick={() => setPendingUpdate(Tab.SCHEDULE)}
                >
                  Save
                </Button>
              </Permission>
            </Row>
          )}

          {tab === Tab.ALERTS && (
            <SyncAlerts
              alerts={syncAlerts}
              rowThresholdAttempted={syncTemplate.row_threshold_attempted}
              rowThresholdTotal={syncTemplate.row_threshold_total}
              templateId={id}
            />
          )}
        </Page>
      </PermissionProvider>

      <DeleteConfirmationModal
        isOpen={confirmingDelete}
        label="sync template"
        onClose={() => {
          setConfirmingDelete(false);
        }}
        onDelete={onDelete}
      />

      <Modal
        footer={
          <>
            <Button
              variant="secondary"
              onClick={() => {
                setPendingUpdate(undefined);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (pendingUpdate === Tab.SCHEDULE) {
                  updateSchedule();
                } else {
                  updateConfig();
                }
                setPendingUpdate(undefined);
              }}
            >
              Continue
            </Button>
          </>
        }
        isOpen={Boolean(pendingUpdate)}
        sx={{ width: "600px" }}
        title="Confirm changes"
        onClose={() => setPendingUpdate(undefined)}
      >
        {dependentSyncs.length > 0 ? (
          <>
            <Text sx={{ mb: 6, fontWeight: "semi", color: "base.7" }}>The following audience syncs will be affected:</Text>
            <Grid columns={2} gap={0}>
              <Text
                sx={{
                  fontWeight: "semi",
                  fontSize: 0,
                  color: "base.7",
                  textTransform: "uppercase",
                  borderBottom: "small",
                  p: 2,
                }}
              >
                Audience
              </Text>
              <Text
                sx={{
                  fontWeight: "semi",
                  fontSize: 0,
                  color: "base.7",
                  textTransform: "uppercase",
                  borderBottom: "small",
                  p: 2,
                }}
              >
                Destination
              </Text>
              {dependentSyncs.map(({ id, segment, destination }) => {
                const definition = destinationDefinitions.find((d) => d.type === destination?.type);
                return (
                  <Fragment key={id}>
                    <Text sx={{ p: 2, borderBottom: "small" }}>{segment?.name}</Text>
                    <Row sx={{ alignItems: "center", borderBottom: "small", p: 2 }}>
                      <Image src={definition?.icon} width={16} />
                      <Text sx={{ ml: 2, mr: "auto" }}>{destination?.name}</Text>
                      <Link to={`/syncs/${id}`}>
                        <ExternalLinkIcon color="base.4" size={16} />
                      </Link>
                    </Row>
                  </Fragment>
                );
              })}
            </Grid>
          </>
        ) : (
          <Text>Are you sure you want to make these changes?</Text>
        )}
      </Modal>
    </>
  );
};
