import { FC } from "react";

import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { Box, Column, Text, Heading } from "@hightouchio/ui";

import { AuthLayout } from "src/components/auth/auth-layout";
import { Card } from "src/ui/card";

export const Expired: FC = () => {
  return (
    <AuthLayout>
      <Card sx={{ maxWidth: "400px", mt: 24 }}>
        <Column align="center" gap={8} sx={{ color: "gray.900", textAlign: "center" }}>
          <Box color="grass">
            <ExclamationCircleIcon fill="currentColor" width="64px" />
          </Box>
          <Heading size="xl">Expired invitation</Heading>
          <Text>
            Check that your account email is the same as the invite recipient or contact your workspace administrator.
          </Text>
        </Column>
      </Card>
    </AuthLayout>
  );
};
