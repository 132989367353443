import { FC, useEffect } from "react";

import { useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { Controller, useForm } from "react-hook-form";
import { Outlet, Routes, Route, useOutletContext, useLocation } from "react-router-dom";
import { Grid } from "theme-ui";

import lookerImage from "src/components/extensions/assets/looker.png";
import { Overview } from "src/components/extensions/overview";
import { Page } from "src/components/layout";
import { SidebarForm } from "src/components/page";
import { Permission } from "src/components/permission";
import { TunnelSelect } from "src/components/tunnels/tunnel-select";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  useLookerCredentialsQuery,
  useCreateLookerCredentialMutation,
  useUpdateLookerCredentialMutation,
  LookerCredentialsQuery,
  ResourcePermissionGrant,
} from "src/graphql";
import { Container, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { LookerIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { PageSpinner } from "src/ui/loading";
import { SensitiveField } from "src/ui/sensitive-field";
import { Tabs } from "src/ui/tabs";
import { useNavigate } from "src/utils/navigate";

enum Tab {
  Overview = "Overview",
  Configuration = "Configuration",
}

const TABS = [Tab.Overview, Tab.Configuration];

export const Looker: FC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          element={
            <Overview
              description="When you create a Hightouch model backed by a Look, Hightouch converts that Look to SQL and runs it against your data source."
              icon={LookerIcon}
              image={lookerImage}
              subtitle="Import models from Looker"
              title="Looker"
            />
          }
          path="/"
        />
        <Route element={<Configuration />} path="configuration" />
      </Route>
    </Routes>
  );
};

export const Layout: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.split("/").pop();
  const tab = path === "configuration" ? Tab.Configuration : Tab.Overview;

  const { data: credentials, isLoading: loading } = useLookerCredentialsQuery(undefined, {
    select: (data) => data.looker_credentials?.[0],
  });

  return (
    <Page crumbs={[{ label: "Extensions", link: "/extensions" }, { label: "Looker" }]}>
      <Tabs
        setTab={(tab) => {
          if (tab === Tab.Overview) {
            navigate("/extensions/looker");
          } else {
            navigate("configuration");
          }
        }}
        sx={{ mb: 10 }}
        tab={tab}
        tabs={TABS}
      />
      <Outlet context={{ credentials, loading }} />
    </Page>
  );
};

type LookerCredentials = LookerCredentialsQuery["looker_credentials"][0];

interface OutletContext {
  loading: boolean;
  credentials: LookerCredentials;
}

const Configuration: FC = () => {
  const { loading, credentials } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const {
    reset,
    handleSubmit,
    control,
    watch,
    register,
    formState: { isDirty, isSubmitting },
  } = useForm();

  const isTunnel = Boolean(watch("tunnel_id"));

  const { mutateAsync: create } = useCreateLookerCredentialMutation();
  const { mutateAsync: update } = useUpdateLookerCredentialMutation();

  const submit = async (data) => {
    if (!data.tunnel_id) {
      delete data.tunnel_id;
    }

    try {
      if (credentials?.id) {
        await update({
          id: credentials.id,
          credentials: data,
        });
      } else {
        await create({
          credentials: data,
        });
      }

      toast({
        id: "save-looker",
        title: "Configuration was saved",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "save-looker",
        title: "There was an error saving your configuration",
        variant: "error",
      });
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    reset({
      tunnel_id: credentials?.tunnel_id ?? "",
      url: credentials?.url ?? "",
      client_id: credentials?.client_id ?? "",
      client_secret: "",
    });
  }, [credentials]);

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <PermissionProvider permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}>
      <Row sx={{ justifyContent: "space-between" }}>
        <Container center={false} size="small">
          <Grid gap={8}>
            <Heading>Looker configuration</Heading>

            <Controller
              control={control}
              name="tunnel_id"
              render={({ field }) => (
                <TunnelSelect
                  value={field.value ? { id: field.value } : undefined}
                  onChange={(tunnel) => {
                    field.onChange(tunnel?.id);
                  }}
                />
              )}
            />

            <Field label="URL">
              <Input disabled={isTunnel} {...register("url")} />
            </Field>

            <Field label="Client ID">
              <Input {...register("client_id")} />
            </Field>

            <Field label="Client secret">
              <Controller
                control={control}
                name="client_secret"
                render={({ field }) => (
                  <SensitiveField
                    hideSecret={Boolean(credentials?.client_secret)}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </Field>
          </Grid>
        </Container>
        <SidebarForm
          buttons={
            <Permission>
              <Button disabled={!isDirty} loading={isSubmitting} sx={{ width: "100%" }} onClick={handleSubmit(submit)}>
                Save
              </Button>
            </Permission>
          }
          docsUrl="models/looker-models"
          name="Looker"
        />
      </Row>
    </PermissionProvider>
  );
};
