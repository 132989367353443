import { FC } from "react";

import { BillingOrganizationQuery } from "src/graphql";
import { Modal } from "src/ui/modal";

import { PricingDisplay } from "../pricing/pricing-display";

export type PricingModalProps = {
  organization: BillingOrganizationQuery["getBillingOrganization"] | undefined;
  isOpen: boolean;
  onClose: () => void;
};

export const PricingModal: FC<Readonly<PricingModalProps>> = ({ isOpen, onClose, organization }) => {
  return (
    <Modal
      bodySx={{ position: "relative", pt: 0, pb: 8, px: 14, bg: "gray.100" }}
      headerSx={{ display: "none" }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <PricingDisplay organization={organization} onClose={onClose} />
    </Modal>
  );
};
