import { FC } from "react";

import { Box } from "theme-ui";

import { colors } from "../../../../design/colors";

type SubtitleProps = {
  text: string;
};

export const Subtitle: FC<SubtitleProps> = ({ text }) => {
  return (
    <Box
      sx={{
        fontWeight: 500,
        fontSize: "12px",
        color: colors.base[4],
        mb: 2,
        textTransform: "uppercase",
      }}
    >
      {text}
    </Box>
  );
};
