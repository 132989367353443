import { Text, ThemeUIStyleObject } from "theme-ui";

import { useUser } from "src/contexts/user-context";
import { DraftOperation, DraftsQuery, ResourceToPermission, useDeleteDraftMutation } from "src/graphql";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { DotsIcon } from "src/ui/icons";
import { Menu } from "src/ui/menu";
import { formatDatetime } from "src/utils/time";

import { DraftBadge } from "./draft-badge";

interface Props {
  sx?: ThemeUIStyleObject;
  draft: DraftsQuery["drafts"][0];
  editingDraft: boolean;
  resourceType: ResourceToPermission;
  onViewDraft: () => void;
  setEditingDraft: (boolean) => void;
}

export const EditingDraftWarning = ({ draft, editingDraft, resourceType, setEditingDraft, onViewDraft }: Props) => {
  const { user } = useUser();
  const { mutateAsync: deleteMutation, isLoading: deleting } = useDeleteDraftMutation();

  const requestedFrom = draft.created_by_user?.name;

  let message: string;
  let title: string;

  const createdByMe = draft.created_by_user?.id === user?.id;
  const from = createdByMe ? "You" : requestedFrom;

  if (draft.operation === DraftOperation.Create) {
    if (draft.approval_requests.length === 0) {
      message = `${from} last saved a draft of this ${resourceType} on ${formatDatetime(draft.created_at)}.`;
      title = "Currently editing draft";
    } else {
      message = `${from} requested approval to publish this new ${resourceType} on ${formatDatetime(draft.created_at)}.`;
      title = `Approval requested for new ${resourceType}`;
    }
  } else {
    if (draft.approval_requests.length === 0) {
      title = "Draft changes available";
      message = `${from} made draft changes to this ${resourceType} on ${formatDatetime(draft.created_at)}.`;
    } else {
      title = "Approval requested for draft changes";
      message = `${from} requested approval for changes to this ${resourceType} on ${formatDatetime(draft.created_at)}.`;
    }
    if (editingDraft) {
      title = "Editing draft changes";
      message = `You are currently editing draft changes for this ${resourceType}.`;
    }
  }

  return (
    <Row
      sx={{
        alignItems: "center",
        px: 8,
        py: 3,
        bg: "base.2",
        width: "100%",
        justifyContent: "space-between",
        borderBottom: "small",
      }}
    >
      <Row sx={{ alignItems: "center", gap: 4 }}>
        <DraftBadge text={draft.approval_requests.length === 0 ? "Draft" : "Pending"} />
        <Text sx={{ fontWeight: "bold" }}>{title}</Text>
        <Text sx={{ color: "base.6" }}>{message}</Text>
      </Row>

      <Row sx={{ gap: 4, alignItems: "center" }}>
        {!editingDraft && draft.operation !== DraftOperation.Create && (
          <Button variant="secondary" onClick={() => setEditingDraft(true)}>
            Go to draft
          </Button>
        )}
        {editingDraft && draft.operation !== DraftOperation.Create && (
          <Button variant="secondary" onClick={() => setEditingDraft(false)}>
            Go to production
          </Button>
        )}
        <Button onClick={onViewDraft}>{draft.approval_requests.length === 0 ? "Request approval" : "View request"}</Button>
        <Menu
          options={[
            {
              disabled: deleting,
              label: "Delete draft",
              onClick: async () => await deleteMutation({ draftId: draft.id }),
            },
          ]}
          placement="bottom-start"
        >
          <DotsIcon sx={{ transform: "rotate(90deg)", color: "base.5" }} />
        </Menu>
      </Row>
    </Row>
  );
};
