import { FC, useEffect, useState } from "react";

import { useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";

import { ExploreProps, Explore } from "./explore";

type ExploreWithSaveProps = Omit<ExploreProps, "onSave" | "saveTooltip" | "saveDisabled" | "saveLoading"> & {
  onSave: (data?: { rows: any; columns: any }) => Promise<void>;
  getSchema: () => Promise<any>;
  saveLabel: string;
};

export const ExploreWithSave: FC<Readonly<ExploreWithSaveProps>> = (props: ExploreWithSaveProps) => {
  const { toast } = useToast();
  const { sql, dbtModel, lookerLook, table, customQuery, source, getSchema, onSave: modelSave, rows, columns, error } = props;

  const [hasQueryColumns, setHasQueryColumns] = useState<boolean>(false);

  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const supportsResultSchema = source?.definition?.supportsResultSchema;

  const saveDisabled = supportsResultSchema ? false : !hasQueryColumns;
  const saveTooltip = saveDisabled ? "This source requires previewing your query results before saving the model" : undefined;

  const onSave = async () => {
    setSaveLoading(true);
    if (supportsResultSchema && !hasQueryColumns) {
      try {
        const { data, error } = await getSchema();
        if (error) {
          toast({
            id: "query-schema-error",
            title: "Error previewing query schema",
            variant: "error",
          });
          Sentry.captureException(error);
        } else if (data?.columns?.length) {
          await modelSave(data);
        }
      } catch (error) {
        toast({
          id: "model-save-error",
          title: "Error saving model",
          variant: "error",
        });
        Sentry.captureException(error);
      }
    } else {
      try {
        await modelSave();
      } catch (error) {
        toast({
          id: "model-save-error",
          title: "Error saving model",
          variant: "error",
        });
      }
    }

    setSaveLoading(false);
  };

  const saveProps = { saveDisabled, saveLoading, saveTooltip, onSave };

  useEffect(() => {
    setHasQueryColumns(false);
  }, [sql, dbtModel, lookerLook, table, customQuery]);

  useEffect(() => {
    if (columns?.length && !error) {
      setHasQueryColumns(true);
    }
  }, [rows, columns]);

  return <Explore {...props} {...saveProps} />;
};
