import { FC } from "react";

import { Text } from "theme-ui";

import { Button } from "src/ui/button";
import { Modal } from "src/ui/modal";

const warnings = {
  changeSource: {
    title: "Change source?",
    text: "Are you sure you want to change your source? All progress with adding and connecting your source will be lost.",
  },
  changeDestination: {
    title: "Change destination?",
    text: "Are you sure you want to change your destination? All progress with adding and connecting your destination will be lost.",
  },
  changeModel: {
    title: "Change model?",
    text: "Are you sure you want to change your model?",
  },
  changeSourceWithModel: {
    title: "Change source?",
    text: "Are you sure you want to change your source? All progress with adding and connecting your Source and setting up your model will be lost.",
  },
};
type WarningModalProps = {
  currentWarning: keyof typeof warnings;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const WarningModal: FC<WarningModalProps> = ({ currentWarning, isOpen, onCancel, onConfirm }) => {
  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>Confirm</Button>
        </>
      }
      isOpen={isOpen}
      title={warnings[currentWarning].title}
      onClose={onCancel}
    >
      <Text>{warnings[currentWarning].text}</Text>
    </Modal>
  );
};
