import { FC } from "react";

import { Text } from "theme-ui";

import * as analytics from "src/lib/analytics";
import { newIntercomMessage } from "src/lib/intercom";
import { Box } from "src/ui/box";
import { Link } from "src/ui/link";

type Props = {
  category: string;
};

export const RequestDestination: FC<Readonly<Props>> = ({ category }) => {
  return (
    <Box>
      <Text sx={{ fontSize: 1, color: "base.5", fontWeight: "semi", pt: 8 }}>
        Don’t see what you’re looking for? Let us know{" "}
        <Link
          onClick={() => {
            newIntercomMessage(
              `Hi, I would like to request a new destination. I want to sync data to ____ in order to help the _____ team accomplish _____.`,
            );
            analytics.track("Destination Catalog Suggest Destination Clicked", {
              current_category: category,
            });
          }}
        >
          here.
        </Link>
      </Text>
    </Box>
  );
};
