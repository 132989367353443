import { FC, ReactNode, useCallback } from "react";

import { Tooltip } from "@hightouchio/ui";
import { range } from "lodash";
import { ThemeUIStyleObject, Text, Grid } from "theme-ui";

import { Button } from "src/ui/button";

import { ChevronLeftIcon, ChevronRightIcon, ForwardIcon, RewindIcon } from "../icons";

export type PaginationProps = {
  setPage: (page: number) => void;
  page: number;

  text?: string;
  label?: string;
  count: number | undefined;
  rowsPerPage: number;

  sx?: ThemeUIStyleObject;
  disabledTooltip?: string;
  compact?: boolean;
  alwaysShow?: boolean;
};

export const Pagination: FC<Readonly<PaginationProps>> = ({
  setPage,
  disabledTooltip,
  page,
  compact = false,
  label = "rows",
  count,
  rowsPerPage,
  sx = {},
  alwaysShow = false,
  ...props
}) => {
  const text = props.text ?? `${count ?? 0} ${label}`;
  const pages = Math.ceil((count ?? 0) / rowsPerPage);

  if (pages <= 1 && !alwaysShow) {
    return null;
  }

  const onSetPage = (newPage: number) => {
    if (newPage > pages) {
      setPage(pages);
    } else if (newPage < 0) {
      setPage(0);
    } else {
      setPage(newPage);
    }
  };

  const next = useCallback(
    (n = 1) => {
      onSetPage(page + n);
    },
    [onSetPage, page],
  );

  const prev = useCallback(
    (n = 1) => {
      onSetPage(page - n);
    },
    [onSetPage, page],
  );

  let elements: ReactNode[] = [];

  if (pages <= 5) {
    elements = range(0, pages).map((index) => (
      <Page key={index} active={page === index} page={index} onClick={() => onSetPage(index)} />
    ));
  } else {
    if (page < 4) {
      elements = [
        range(0, 5).map((index) => <Page key={index} active={page === index} page={index} onClick={() => onSetPage(index)} />),
        <Ellipsis key="ellipsis-5" forward onClick={() => next(5)} />,
        <Page key={pages - 1} page={pages - 1} onClick={() => onSetPage(pages - 1)} />,
      ];
    } else if (page >= 4 && page < pages - 4) {
      elements = [
        <Page key={0} page={0} onClick={() => onSetPage(0)} />,
        <Ellipsis key="ellipsis-start" backward onClick={() => prev(5)} />,
        <Page key={page - 1} page={page - 1} onClick={() => onSetPage(page - 1)} />,
        <Page key={page} active page={page} onClick={() => onSetPage(page)} />,
        <Page key={page + 1} page={page + 1} onClick={() => onSetPage(page + 1)} />,
        <Ellipsis key="ellipsis-end" forward onClick={() => next(5)} />,
        <Page key={pages - 1} page={pages - 1} onClick={() => onSetPage(pages - 1)} />,
      ];
    } else {
      elements = [
        <Page key={0} page={0} onClick={() => onSetPage(0)} />,
        <Ellipsis key="ellipsis-1" backward onClick={() => prev(5)} />,
        ...range(pages - 5, pages).map((index) => (
          <Page key={index} active={page === index} page={index} onClick={() => onSetPage(index)} />
        )),
      ];
    }
  }

  return (
    <Grid
      gap={1}
      sx={{ gridAutoColumns: "max-content", gridAutoFlow: "column", alignSelf: "flex-end", mt: 4, alignItems: "center", ...sx }}
    >
      <Text sx={{ color: "base.4", mr: 2 }}>{text}</Text>
      <Button
        disabled={page === 0}
        size="small"
        sx={compact ? { minWidth: 0, px: 1, color: "base.5" } : undefined}
        variant="secondary"
        onClick={() => prev()}
      >
        {compact ? <ChevronLeftIcon size={18} /> : "Previous"}
      </Button>

      {elements}
      <Tooltip isDisabled={page === pages - 1 && !disabledTooltip} message={disabledTooltip || ""}>
        <Button
          disabled={page === pages - 1}
          size="small"
          sx={compact ? { minWidth: 0, px: 1, color: "base.5" } : undefined}
          variant="secondary"
          onClick={() => next()}
        >
          {compact ? <ChevronRightIcon size={18} /> : "Next"}
        </Button>
      </Tooltip>
    </Grid>
  );
};

const Page = ({ active = false, page, onClick }) => {
  return (
    <Button
      size="small"
      sx={{
        minWidth: "unset",
        bg: active ? "base.2" : undefined,
        pointerEvents: active ? "none" : undefined,
      }}
      variant="secondary"
      onClick={onClick}
    >
      {page + 1}
    </Button>
  );
};

const Ellipsis = ({ onClick, forward = false, backward = false }) => {
  return (
    <Button
      size="small"
      sx={{
        width: "36px",
        minWidth: "unset",
        svg: { display: "none" },
        ":hover": { span: { display: "none" }, svg: { display: "block" } },
      }}
      variant="secondary"
      onClick={onClick}
    >
      {backward && <RewindIcon size={14} />}
      <span>...</span>
      {forward && <ForwardIcon size={14} />}
    </Button>
  );
};
