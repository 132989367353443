import { FC } from "react";

import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Section } from "src/ui/section";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { MappingsField } from "../mappings-field";

export const validation = Yup.object().shape({
  automationId: Yup.string().required(),
  mappings: COMMON_SCHEMAS.mappings.required(),
});

export const QualtricsForm: FC = () => {
  const { config, setConfig, errors } = useDestinationForm();

  return (
    <>
      <Section>
        <Field
          description={
            "This is the import automation that Hightouch will send files to. \
            You can find it in the Qualtrics console. The Qualtrics automation \
            will asynchronously process the files on its own schedule, after \
            the Hightouch sync is complete."
          }
          error={errors?.automationId}
          label="What is file import automation ID?"
        >
          <Input
            placeholder="AU_ABCDE12345"
            value={config.automationId}
            onChange={(automationId) => {
              setConfig({ ...config, automationId });
            }}
          />
        </Field>
      </Section>

      <MappingsField />
    </>
  );
};

export default {
  form: QualtricsForm,
  validation,
};
