import { FC } from "react";

import { Text } from "theme-ui";

import {
  BrowserImage,
  BrowserImageHover,
  DbtImage,
  DbtImageHover,
  LookerImage,
  LookerImageHover,
  SQLImage,
  SQLImageHover,
  TableImage,
  TableImageHover,
} from "src/components/models";
import { ChevronDownIcon } from "src/ui/icons";
import { Menu } from "src/ui/menu";
import { QueryType } from "src/utils/models";
import { UseSourcesResult } from "src/utils/sources";

type Source = UseSourcesResult["data"][0];

type Props = {
  source: Source;
  type: QueryType;
  onChange: (type: QueryType) => void;
};

export const QUERY_TYPE_OPTIONS = {
  [QueryType.RawSql]: {
    label: "Query with SQL",
    description: "Filter, transform, and join your data using a SQL query.",
    image: {
      src: SQLImage,
      alt: "Empty SQL editor.",
    },
    imageHover: {
      src: SQLImageHover,
      alt: "SQL editor with example query 'select * from users;'.",
    },
  },
  [QueryType.DbtModel]: {
    label: "Import model from dbt",
    description: "Use a table or materialized view generated by dbt.",
    image: {
      src: DbtImage,
      alt: "Semi-opaque, open dropdown with three example dbt model names such as 'dbt.model.name.1'.",
    },
    imageHover: {
      src: DbtImageHover,
      alt: "Opaque, open dropdown with three example dbt model names such as 'dbt.model.name.1'.",
    },
  },
  [QueryType.LookerLook]: {
    label: "Import model from Looker",
    description: "Convert a Look into a SQL query that runs your data source.",
    image: {
      src: LookerImage,
      alt: "Semi-opaque open dropdown with three example Look names such as 'look.1'.",
    },
    imageHover: {
      src: LookerImageHover,
      alt: "Opaque open dropdown with three example Look names such as 'look.1'.",
    },
  },
  [QueryType.Table]: {
    label: "Select a table or view",
    description: "Read all rows from an existing table or view in your database.",
    image: {
      src: TableImage,
      alt: "Semi-opaque open dropdown with three example table names such as 'schema.table.name.1'.",
    },
    imageHover: {
      src: TableImageHover,
      alt: "Opaque open dropdown with three example table names such as 'schema.table.name.1'.",
    },
  },
  [QueryType.Custom]: {
    label: "Browse available data",
    description: "Select a file, spreadsheet, collection, or other dataset.",
    image: {
      src: BrowserImage,
      alt: "Two semi-opaque radio buttons 'Source 1' (selected) and 'Source 2', and a semi-opaque closed dropdown reading 'Select and object'.",
    },
    imageHover: {
      src: BrowserImageHover,
      alt: "Two opaque radio buttons 'Source 1' (selected) and 'Source 2', and an opaque closed dropdown reading 'Select and object'.",
    },
  },
};

export const QueryTypeMenu: FC<Readonly<Props>> = ({ source, type, onChange }) => {
  const options = source?.definition?.supportedQueries
    .filter((type) => {
      return QUERY_TYPE_OPTIONS[type];
    })
    .map((type) => {
      const option = QUERY_TYPE_OPTIONS[type];
      option.onClick = () => onChange(type as QueryType);
      return option;
    });

  return (
    <Menu options={options ?? []} sx={{ display: "flex", width: "min-content", height: "32px" }}>
      <Text sx={{ mr: 2, fontSize: 3, fontWeight: "semi", whiteSpace: "nowrap" }}>{QUERY_TYPE_OPTIONS[type]?.label}</Text>
      <ChevronDownIcon />
    </Menu>
  );
};
