import { CSSProperties, Fragment, useState, FC, useEffect } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { ButtonGroup, Button, IconButton, Paragraph, ConfirmationDialog, useToast, Switch } from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/browser";
import { isEqual } from "lodash";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Grid, Text, ThemeUIStyleObject } from "theme-ui";
import * as Yup from "yup";

import { DefaultPageContainerPadding } from "src/components/layout/page-container";
import { usePermission } from "src/contexts/permission-context";
import { Audience, VisualQueryFilter } from "src/types/visual";
import { Badge } from "src/ui/badge";
import { Box, Row } from "src/ui/box";
import { Field, FieldError } from "src/ui/field";
import { Input } from "src/ui/input";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select/select";

import { Column, SplitTestDefinition } from "../../../../backend/lib/query/visual/types";
import { Indices } from "../../../../design";
import { NAV_WIDTHS_PER_BREAKPOINT } from "../layout/nav";

type Props = {
  audience: Audience;
  data: VisualQueryFilter["splitTestDefinition"];
  onSave: (data: SplitTestDefinition | undefined) => Promise<void>;
};

const validationSchema = Yup.object().shape({
  groupColumnName: Yup.string().required("Column name required"),
  samplingType: Yup.string().required(),
  splits: Yup.array().of(
    Yup.object().shape({
      groupValue: Yup.string().required("Split group name required"),
      percentage: Yup.number()
        .typeError("Split percentage must be a number")
        .min(1, "Split percentages must be between 1 and 99")
        .max(99, "Split percentages must be between 1 and 99")
        .required("Split group percentage required"),
    }),
  ),
  stratificationVariables: Yup.array().of(
    Yup.mixed()
      .transform((variableObj) => (Object.keys(variableObj).length ? variableObj : undefined))
      .required("Stratification variable required"),
  ),
});

export const badgeStyles: CSSProperties = {
  position: "relative",
  right: -6,
};

export const tooltipStyles: ThemeUIStyleObject = {
  bg: "white",
  color: "secondary",
  overflow: "visible",
  boxShadow: "large",
};

export const caretStyles: ThemeUIStyleObject = {
  fontSize: 0,
  lineHeight: 2,
  px: 3,
  ":hover": {
    "::before": {
      content: "''",
      position: "absolute",
      top: "-14px",
      left: "16px",
      width: "10px",
      height: "10px",
      bg: "white",
      transform: "rotate(45deg)",
      zIndex: Indices.Modal,
    },
  },
};

function getTotalPercentage(splitPercentages: number[]): number {
  return splitPercentages.reduce((sum, percentage) => sum + percentage, 0);
}

const STICKY_FOOTER_HEIGHT = "96px";

/**
 * @deprecated LegacySplits
 *
 * Remove this component in favor of <Splits /> once all customers have been migrated to Splits V2
 * This component should only be used for customers that are still on Splits V1
 */
export const LegacySplits: FC<Readonly<Props>> = ({ audience, data: splitTestDefinition, onSave }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [splitsEnabled, setSplitsEnabled] = useState(Boolean(splitTestDefinition));

  const { toast } = useToast();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    reset,
    setValue,
    watch,
  } = useForm<SplitTestDefinition>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      groupColumnName: splitTestDefinition?.groupColumnName || "",
      samplingType: splitTestDefinition?.samplingType || "non-stratified",
      splits: splitTestDefinition?.splits || [
        { groupValue: "", percentage: 50 },
        { groupValue: "", percentage: 50 },
      ],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Circular type problem with Column[]
      stratificationVariables: splitTestDefinition?.stratificationVariables || [],
    },
  });

  const { fields: stratificationVariableFields, append: stratificationVariableAppend, remove: stratificationVariableRemove } =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - no circular types until react-hook-form v8
    useFieldArray({
      control,
      name: "stratificationVariables",
    });

  const {
    fields: splitGroups,
    append: splitGroupAppend,
    remove: splitGroupRemove,
  } = useFieldArray({
    control,
    name: "splits",
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - no circular types until react-hook-form v8
  const groupColumnName = watch("groupColumnName");
  const samplingType = watch("samplingType");
  const splits = watch("splits");
  const stratificationVariables = watch("stratificationVariables");

  const [cancelConfirmationVisible, setCancelConfirmationVisible] = useState(false);

  useEffect(() => {
    if (samplingType === "stratified") {
      // Scroll the page down to hint that new form fields are now displayed
      // Otherwise it's not obvious due to the sticky footer
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }
  }, [samplingType]);

  // TODO: This is a manual validation hack. Was unable to get consistent
  // error behavior while throwing this error with react-hook-form + yup validation.
  const totalPercentage = getTotalPercentage(splits?.map((split) => split?.percentage || 0) || []);
  const isValidCurrentPercentage = totalPercentage === 100;

  const newSplitTestDefinition = splitsEnabled
    ? {
        groupColumnName,
        samplingType,
        splits,
        stratificationVariables,
      }
    : undefined;

  const saveSplitsConfig = async (data: SplitTestDefinition | undefined) => {
    setSaveLoading(true);

    try {
      await onSave?.(data);
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "save-splits-config",
        title: "Couldn't save splits configuration",
        variant: "error",
      });
    }

    setSaveLoading(false);
  };

  const handleFormErrors = () => {
    toast({
      id: "save-splits-config",
      title: "Couldn't save splits configuration",
      variant: "error",
    });
  };

  const handleSave = async (formData: SplitTestDefinition) => {
    if (!isValidCurrentPercentage) {
      handleFormErrors();
    } else {
      saveSplitsConfig(splitsEnabled ? formData : undefined);
    }
  };

  const resetForm = () => {
    reset(audience?.visual_query_filter?.splitTestDefinition);
    setSplitsEnabled(Boolean(splitTestDefinition));
  };

  const hasDirtyFields = isEqual(audience?.visual_query_filter?.splitTestDefinition, newSplitTestDefinition);

  const showRemoveSplitButton = (splits || []).length > 2;

  const updatePermission = usePermission();

  return (
    <Row
      sx={{
        alignItems: "start",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
        mb: STICKY_FOOTER_HEIGHT, // prevent fixed footer from covering any content when scrolled to bottom
      }}
    >
      <Grid gap={6} sx={{ maxWidth: "800px" }}>
        <Box>
          <Row gap={4} sx={{ alignItems: "center" }}>
            <Text sx={{ fontSize: "16px", fontWeight: 500 }}>Enable splits</Text>
            <Switch isChecked={splitsEnabled} isDisabled={updatePermission.unauthorized} onChange={setSplitsEnabled} />
          </Row>
          <Text sx={{ mt: 2 }}>
            With splits, you may divide the audience into multiple defined groups based on specific percentages. These groups
            can be stratified based on multiple variables to ensure that certain subgroups are equally represented in the
            splits.
          </Text>
        </Box>
        {splitsEnabled && (
          <>
            <Section divider>
              <Field
                description="This column will be created to define which split group a particular row belongs to."
                error={errors.groupColumnName && errors.groupColumnName.message}
                label="Column name"
                labelSx={{ fontWeight: 500 }}
                size="large"
              >
                <Input
                  {...register("groupColumnName" as const)}
                  error={Boolean(errors.groupColumnName)}
                  placeholder="Enter a name for your group column (e.g. test_group)..."
                  sx={{ width: "360px" }}
                />
              </Field>
            </Section>
            <Section divider>
              <Field
                description="This defines how your groups should be split in terms of quantity and percentages. For accurate splits, Hightouch recommends a minimum audience size of 100 members."
                label="Split groups"
                labelSx={{ fontWeight: 500 }}
                size="large"
              >
                {Boolean(splits?.length) && (
                  <Grid
                    gap={2}
                    sx={{
                      mb: 4,
                      display: "grid",
                      gridTemplateColumns: `256px minmax(min-content, max-content) ${showRemoveSplitButton ? "20px" : ""}`,
                    }}
                  >
                    {["Groups", "Splits", showRemoveSplitButton && " "].filter(Boolean).map((columnName, index) => (
                      <Text
                        key={`${columnName}-${index}`}
                        sx={{
                          fontSize: "12px",
                          fontWeight: 600,
                          textTransform: "uppercase",
                        }}
                      >
                        {columnName}
                      </Text>
                    ))}

                    {splitGroups.map(({ id }, index) => (
                      <Fragment key={id}>
                        <Box>
                          <Input
                            {...register(`splits.${index}.groupValue` as const)}
                            error={Boolean(errors.splits?.[index]?.groupValue)}
                            placeholder="Enter a group value name..."
                            sx={{ height: "36px", maxWidth: "280px", mr: 2 }}
                          />
                          {errors.splits?.[index]?.groupValue && (
                            <FieldError error={errors.splits?.[index]?.groupValue?.message} />
                          )}
                        </Box>
                        <Box>
                          <Grid
                            gap="6px"
                            sx={{
                              border: "1px solid #E9ECF5",
                              boxSizing: "content-box",
                              gridTemplateColumns: "1fr 1fr",
                              width: "62px",
                            }}
                          >
                            <Input
                              {...register(`splits.${index}.percentage` as const, { valueAsNumber: true })}
                              error={Boolean(errors.splits?.[index]?.percentage)}
                              min="1"
                              sx={{ border: "none", height: "36px", maxWidth: "280px", pr: 0 }}
                              type="number"
                            />
                            <Text color="gray.500" sx={{ alignSelf: "center" }}>
                              %
                            </Text>
                          </Grid>
                          {errors.splits?.[index]?.percentage && (
                            <FieldError error={errors.splits?.[index]?.percentage?.message} />
                          )}
                        </Box>
                        {showRemoveSplitButton && (
                          <IconButton
                            aria-label="Remove split group"
                            icon={XMarkIcon}
                            onClick={() => splitGroupRemove(index)}
                          />
                        )}
                      </Fragment>
                    ))}
                    {isSubmitted && !isValidCurrentPercentage && <FieldError error="Percentages must add up to 100%" />}
                    <Box sx={{ gridColumnStart: 1 }}>
                      <Button variant="secondary" onClick={() => splitGroupAppend({ groupValue: "", percentage: 1 })}>
                        Add split group
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Field>
            </Section>
            <Section>
              <Row sx={{ alignItems: "center", mb: 4 }}>
                <Text sx={{ fontSize: "16px", fontWeight: 500 }}>Stratified Sampling (Advanced)</Text>
                <Box style={badgeStyles}>
                  <Badge
                    sx={caretStyles}
                    tooltip="This feature is currently in beta. Any feedback is greatly appreciated."
                    tooltipSx={tooltipStyles}
                    variant="blue"
                  >
                    Beta
                  </Badge>
                </Box>

                <Switch
                  isChecked={samplingType === "stratified"}
                  isDisabled={updatePermission.unauthorized}
                  ml={4}
                  onChange={(enabled) => {
                    setValue("stratificationVariables", []);

                    if (enabled) {
                      stratificationVariableAppend({} as Column);
                    }

                    setValue("samplingType", enabled ? "stratified" : "non-stratified");
                  }}
                />
              </Row>
              <Text>
                Stratified sampling allows users to stratify their groups such that each group will contain an equal allocation
                of values from specified stratification variables.{" "}
                <i>Please note that stratified sampling can only be used with one-time syncs.</i>
              </Text>
              {samplingType === "stratified" && (
                <Field
                  description="The below columns are used to stratify each group. For example, you may want to make sure that your groups are
                  stratified along the age factor."
                  label="Stratification variables"
                  labelSx={{ fontWeight: 500 }}
                  size="large"
                  sx={{ mt: 5 }}
                >
                  {Boolean(stratificationVariables.length) && (
                    <Grid gap={2} sx={{ mb: 4 }}>
                      {stratificationVariableFields.map(({ id }, index) => (
                        <Controller
                          key={id}
                          control={control}
                          name={`stratificationVariables.${index}`}
                          render={({ field }) => (
                            <>
                              <Row>
                                <Select
                                  isError={Boolean(errors.stratificationVariables?.[index])}
                                  options={audience?.parent?.filterable_audience_columns.map((filterableColumn) => ({
                                    label: filterableColumn.name,
                                    value: filterableColumn.column_reference,
                                  }))}
                                  placeholder="Select a column..."
                                  sx={{ maxWidth: "360px", mr: 2 }}
                                  value={field.value}
                                  onChange={(option) => field.onChange(option.value)}
                                />
                                {stratificationVariables.length > 1 && (
                                  <IconButton
                                    aria-label="Remove stratification variable"
                                    icon={XMarkIcon}
                                    onClick={() => stratificationVariableRemove(index)}
                                  />
                                )}
                              </Row>
                              {errors.stratificationVariables?.[index] && (
                                <FieldError error={errors.stratificationVariables?.[index]?.["message"]} />
                              )}
                            </>
                          )}
                        />
                      ))}
                    </Grid>
                  )}
                  <Button
                    variant="secondary"
                    onClick={() => {
                      stratificationVariableAppend({} as Column);
                    }}
                  >
                    Add variable
                  </Button>
                </Field>
              )}
            </Section>
          </>
        )}
      </Grid>
      <Row
        sx={{
          position: "fixed",
          left: NAV_WIDTHS_PER_BREAKPOINT, // put the fixed footer next to the global sidebar nav
          right: 0,
          bottom: 0,
          height: STICKY_FOOTER_HEIGHT,
          pr: DefaultPageContainerPadding.X,
          bg: "white",
          justifyContent: "right",
          borderTop: "2px solid",
          borderColor: "base.3",
        }}
      >
        <Row sx={{ justifyContent: "center", alignItems: "center" }}>
          <ButtonGroup>
            <Button isJustified isDisabled={hasDirtyFields} size="lg" onClick={() => setCancelConfirmationVisible(true)}>
              Cancel
            </Button>
            <Button
              isJustified
              isDisabled={hasDirtyFields}
              isLoading={saveLoading}
              size="lg"
              variant="primary"
              onClick={handleSubmit(handleSave, handleFormErrors)}
            >
              Save
            </Button>
          </ButtonGroup>
        </Row>
      </Row>

      <ConfirmationDialog
        confirmButtonText="Clear changes"
        isOpen={cancelConfirmationVisible}
        title="Are you sure?"
        variant="warning"
        onClose={() => setCancelConfirmationVisible(false)}
        onConfirm={() => {
          resetForm();
          setCancelConfirmationVisible(false);
        }}
      >
        <Paragraph>Your progress will be lost.</Paragraph>
      </ConfirmationDialog>
    </Row>
  );
};
