import { useState } from "react";

import { Grid, Flex } from "theme-ui";

import { Arrow } from "src/ui/arrow";
import { Button } from "src/ui/button";
import { XIcon } from "src/ui/icons";
import { Input } from "src/ui/input";

import { Permission } from "../permission";

export const KeyValueMapping = ({ mapping, setMapping }) => {
  const [newKey, setNewKey] = useState(null);

  return (
    <Grid gap={3}>
      {Object.keys(mapping || {})?.map((key, i) => (
        <Flex key={i} sx={{ alignItems: "center", flex: 1 }}>
          <Input disabled readOnly value={key} />
          <Arrow />
          <Input
            placeholder={`Value of ${key}`}
            value={mapping?.[key] || ""}
            onChange={(value) => {
              const newMapping = { ...mapping };
              newMapping[key] = value;
              setMapping(newMapping);
            }}
          />
          <Permission>
            <Button
              sx={{ ml: 4 }}
              variant="plain"
              onClick={() => {
                const newMapping = { ...mapping };
                delete newMapping[key];
                setMapping(newMapping);
              }}
            >
              <XIcon size={18} />
            </Button>
          </Permission>
        </Flex>
      ))}
      <Permission>
        <Flex sx={{ alignItems: "center" }}>
          <Input placeholder="Key" value={newKey || ""} onChange={setNewKey} />
          <Button
            disabled={!newKey || mapping?.[newKey]}
            label="Add key"
            size="small"
            sx={{ ml: 1 }}
            variant="secondary"
            onClick={() => {
              if (!newKey) {
                return;
              }

              const newMapping = { ...mapping };
              newMapping[newKey] = "";
              setMapping(newMapping);
              setNewKey(null);
            }}
          />
        </Flex>
      </Permission>
    </Grid>
  );
};
