import { useState, FC } from "react";

import { capitalize } from "lodash";
import { Text } from "theme-ui";

import { Button } from "src/ui/button";
import { Modal } from "src/ui/modal";

type Props = {
  variant?: "error" | "warning";
  error: string;
};

export const ErrorModal: FC<Readonly<Props>> = ({ error, variant = "error" }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Button variant={variant === "error" ? "redOutline" : "amberOutline"} onClick={() => setIsOpen(true)}>
        View {variant}
      </Button>
      <Modal
        footer={
          <Button variant="secondary" onClick={() => setIsOpen(false)}>
            Close
          </Button>
        }
        isOpen={isOpen}
        sx={{ minWidth: "300px" }}
        title={capitalize(variant)}
        onClose={() => setIsOpen(false)}
      >
        <Text sx={{ whiteSpace: "pre-line" }}>{error}</Text>
      </Modal>
    </>
  );
};
