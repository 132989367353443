import { useCallback, useState } from "react";

import { useDropzone } from "react-dropzone";
import { ThemeUIStyleObject, Text, Flex } from "theme-ui";

import { CheckIcon } from "src/ui/icons";

import { colors } from "../../../../design/colors";
import { Column } from "../box";
import { Field } from "../field";

interface Props {
  /** Description to show within uploader type  */
  descripion?: string;
  /**
   * List of accepted file types.
   * If `undefined`, will accept all file types
   */
  acceptedFileTypes?: string[];

  /**
   * Function to transform file bolb into value,
   * "base64" : Will transform your file into a base64 string
   * "JSONParse": Will parse your JSON file into an object
   *  Custom Function: Will give you the file to return any value format
   */
  transformation: "base64" | "JSONParse" | "string" | ((blob: File) => any);
  label?: string;
  value: string;
  onChange?: (value: any) => void;
  sx?: ThemeUIStyleObject;
}

export const FileUploader = ({ transformation, onChange, ...props }: Props) => {
  const [error, setError] = useState<string>("");
  const [uploadedFileName, setUploadedFileName] = useState<string | undefined>(undefined);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: useCallback(async (acceptedFiles) => {
      setError("");
      if (acceptedFiles.length === 0) {
        return setUploadedFileName(undefined);
      }

      setUploadedFileName(acceptedFiles[0].name);
      if (transformation === "JSONParse") {
        const fileContents = await acceptedFiles[0].text();
        try {
          if (typeof onChange === "function") {
            onChange(JSON.parse(fileContents));
          }
        } catch (err) {
          setError("Could not parse file.");
        }
      } else if (transformation === "base64") {
        if (typeof onChange === "function") {
          onChange(await fileToBase64(acceptedFiles[0]));
        }
      } else if (transformation === "string") {
        if (typeof onChange === "function") {
          const res = await fileToString(acceptedFiles[0]);
          onChange(res);
        }
      } else {
        const transformedValue = transformation(acceptedFiles[0]);

        if (typeof onChange === "function") {
          onChange(transformedValue);
        }
      }
    }, []),
    accept: Array.isArray(props.acceptedFileTypes) && props.acceptedFileTypes.length > 0 ? props.acceptedFileTypes : undefined,
  });

  return (
    <Field error={error} label={props.label ?? ""}>
      <Column
        sx={{
          alignItems: "center",
          textAlign: "center",
          padding: "20px",
          borderWidth: 1,
          borderRadius: 2,
          borderColor: uploadedFileName ? "green" : "base.3",
          borderStyle: "dashed",
          backgroundColor: uploadedFileName ? colors.blue[2] : "base.1",
          color: "base.5",
          outline: "none",
          transition: "border .24s ease-in-out",
          ...props.sx,
        }}
      >
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {!uploadedFileName ? (
            <>
              <p>Drag your file here, or click to select a file.</p>
              {Array.isArray(props.acceptedFileTypes) && props.acceptedFileTypes.length > 0 && (
                <p>Accepted file types: {props.acceptedFileTypes.join(", ")}</p>
              )}
            </>
          ) : (
            <Flex sx={{ alignItems: "center" }}>
              <Text sx={{ fontWeight: "bold" }}>Uploaded file: {uploadedFileName}</Text>
              <CheckIcon
                color="green"
                size={18}
                sx={{
                  transition: "all 0.15s",
                }}
              />
            </Flex>
          )}
        </div>
      </Column>
    </Field>
  );
};

const fileToBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString().split(",")[1] || "");
    reader.onerror = (error) => reject(error);
  });
};

const fileToString = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      resolve(reader.result?.toString() || "");
    };
    reader.onerror = (error) => reject(error);
  });
};
