import * as Yup from "yup";

// YUP SCHEMAS
const standardMappingSchema = Yup.object().shape({
  from: Yup.mixed().nullable().required(),
  to: Yup.string().nullable().required(),
  object: Yup.string().nullable().notRequired(),
  type: Yup.string().default("standard"),
  ignoreNull: Yup.boolean().notRequired(),
});

const referenceMappingSchema = Yup.object().shape({
  lookup: Yup.object().shape({
    from: Yup.mixed().nullable().required(),
    by: Yup.string().nullable().required(),
    object: Yup.string().nullable().required(),
    byType: Yup.string().nullable().notRequired(),
  }),
  to: Yup.string().nullable().required(),
  object: Yup.string().nullable().notRequired(),
  type: Yup.string().default("reference"),
});

const staticMappingSchema = Yup.object().shape({
  to: Yup.string().nullable().required(),
  value: Yup.mixed().nullable(), // This is not required so we can use `null`
  valueType: Yup.string().required(),
  type: Yup.string().default("static"),
});

const variableMappingSchema = Yup.object().shape({
  to: Yup.string().nullable().required(),
  variable: Yup.string().required(),
  type: Yup.string().default("variable"),
});

const freeformMappingSchema = Yup.object().shape({
  from: Yup.mixed().nullable().required(),
  to: Yup.string().nullable().required(),
});

const templateMappingSchema = Yup.object().shape({
  template: Yup.string().nullable().required(),
  to: Yup.string().nullable().required(),
  type: Yup.string().default("template"),
});

export const COMMON_SCHEMAS = {
  standardMapping: standardMappingSchema,
  referenceMapping: referenceMappingSchema,
  staticMapping: staticMappingSchema,
  variableMapping: variableMappingSchema,
  freeformMapping: freeformMappingSchema,
  templateMapping: templateMappingSchema,
  standardOrStaticMapping: Yup.lazy((val) =>
    val?.["type"] === "static" || val?.["value"] !== undefined
      ? staticMappingSchema
      : standardMappingSchema,
  ),
  mappings: Yup.array()
    .of(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Yup.lazy((val) => {
        const v = val as any;
        if (v?.type === "reference" || typeof v?.lookup === "object") {
          return referenceMappingSchema;
        } else if (v?.type === "static" || v?.value !== undefined) {
          return staticMappingSchema;
        } else if (v?.type === "variable" || typeof v?.variable === "string") {
          return variableMappingSchema;
        } else if (v?.type === "template" || typeof v?.template === "string") {
          return templateMappingSchema;
        } else {
          return standardMappingSchema;
        }
      }),
    )
    .notRequired()
    .default([]),
  externalIdMapping: freeformMappingSchema.required().default(undefined),

  columnOrConstant: Yup.lazy((val) =>
    typeof val !== "string"
      ? Yup.object()
          .shape({ from: Yup.mixed().nullable().required() })
          .required()
          .default(null)
          .nullable()
      : Yup.string().required(),
  ),

  optionalColumnOrConstant: Yup.lazy((val) =>
    typeof val !== "string"
      ? Yup.object().shape({ from: Yup.string().notRequired() }).notRequired()
      : Yup.string().notRequired(),
  ),

  column: Yup.object()
    .shape({ from: Yup.mixed().required() })
    .default(null)
    .nullable(),

  associationMappings: Yup.array().of(referenceMappingSchema),
};
