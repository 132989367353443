import { FC } from "react";

import { Box, Heading, HeadingProps } from "@hightouchio/ui";

export const BrandHeading: FC<Readonly<HeadingProps & { fontWeight?: "semibold" | "bold" }>> = ({
  children,
  fontWeight = "semibold",
  ...props
}) => {
  return (
    <Box sx={{ h2: { fontFamily: "'Sharp Sans Display No 1'", lineHeight: "1.2", fontWeight } }}>
      <Heading {...props}>{children}</Heading>
    </Box>
  );
};
