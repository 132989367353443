import { FC, ReactNode, useEffect, useState } from "react";

import { flattenDeep } from "lodash";
import { useFormContext, useWatch } from "react-hook-form";

import {
  BinaryBoolean,
  BinaryBooleanOperator,
  BooleanType,
  FormkitBoolean,
  FormkitModifier,
  isUnaryBoolean,
  ModifierType,
  UnaryBoolean,
  UnaryBooleanOperator,
} from "../../../../formkit";
import { getNestedKeys, processReferences } from "../formkit";
import { useFormkitContext } from "./formkit-context";

type Props = {
  children: ReactNode;
  type: ModifierType;
  condition: FormkitBoolean | undefined;
  node: FormkitModifier;
};

export const Modifier: FC<Readonly<Props>> = ({ children, node, condition, type }) => {
  const context = useFormkitContext();
  const { watch, setValue } = useFormContext();
  const [result, setResult] = useState<boolean>(false);

  const watcher = useWatch();

  const processCondition = () => {
    if (typeof condition === "boolean") {
      setResult(condition);
    } else if (condition?.type === BooleanType.Unary) {
      const cond = condition as UnaryBoolean;
      if (typeof cond.operand === "object") {
        const valueWithProcessedRefs = processReferences(cond.operand, context, watch);
        let booleanValue: boolean;
        // Sometimes processReferences() passes the original object back. Get a boolean value from it.
        if (isUnaryBoolean(valueWithProcessedRefs)) {
          booleanValue = Boolean(valueWithProcessedRefs.operand);
        } else {
          booleanValue = Boolean(valueWithProcessedRefs);
        }
        if (cond.operator === UnaryBooleanOperator.Not) {
          setResult(!booleanValue);
        } else {
          setResult(booleanValue);
        }
      } else {
        setResult(Boolean(condition));
      }
    } else if (condition?.type === BooleanType.Binary) {
      const cond = condition as BinaryBoolean;
      const leftValue = processReferences(cond.leftOperand, context, watch);
      const rightValue = processReferences(cond.rightOperand, context, watch);

      if (cond.operator === BinaryBooleanOperator.Equals) {
        setResult(leftValue === rightValue);
      } else if (cond.operator === BinaryBooleanOperator.NotEquals) {
        setResult(leftValue !== rightValue);
      } else if (cond.operator === BinaryBooleanOperator.And) {
        setResult(Boolean(leftValue && rightValue));
      } else if (cond.operator === BinaryBooleanOperator.Or) {
        setResult(Boolean(leftValue || rightValue));
      } else if (
        cond.operator === BinaryBooleanOperator.GreaterThan &&
        typeof leftValue === "number" &&
        typeof rightValue === "number"
      ) {
        setResult(leftValue > rightValue);
      } else if (
        cond.operator === BinaryBooleanOperator.GreaterThanOrEqual &&
        typeof leftValue === "number" &&
        typeof rightValue === "number"
      ) {
        setResult(leftValue >= rightValue);
      } else if (
        cond.operator === BinaryBooleanOperator.LessThan &&
        typeof leftValue === "number" &&
        typeof rightValue === "number"
      ) {
        setResult(leftValue < rightValue);
      } else if (
        cond.operator === BinaryBooleanOperator.LessThanOrEqual &&
        typeof leftValue === "number" &&
        typeof rightValue === "number"
      ) {
        setResult(leftValue <= rightValue);
      }
    }
  };

  useEffect(() => {
    if (condition) {
      processCondition();
    }
  }, [context, condition, watcher]);

  useEffect(() => {
    if (type === ModifierType.Reset) {
      const subscription = watch((_, { name }) => {
        if (name && node?.keys?.includes(name)) {
          const keys = flattenDeep<string>(getNestedKeys(node));
          keys.forEach((key) => setValue(key, undefined));
        }
      });
      return () => subscription.unsubscribe();
    }

    return undefined;
  }, [watch]);

  if (type === ModifierType.Show) {
    return result ? <>{children}</> : null;
  } else {
    return <>{children}</>;
  }
};
