import { FC } from "react";

import { Box } from "theme-ui";

type SubtitleProps = {
  text: string;
};

export const Subtitle: FC<SubtitleProps> = ({ text }) => {
  return (
    <Box
      sx={{
        fontWeight: 300,
        fontSize: "12px",
        mb: 2,
        textTransform: "uppercase",
      }}
    >
      {text}
    </Box>
  );
};

type TitleProps = {
  title: string;
};
export const Title: FC<TitleProps> = ({ title }) => {
  return (
    <Box
      sx={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "100%",
        pr: 2,
      }}
    >
      {title}
    </Box>
  );
};
