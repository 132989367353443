import { FC, ReactNode } from "react";

import { Column } from "@hightouchio/ui";

import { useHeaderHeight } from "src/contexts/header-height-context";

export const PageSidebar: FC<Readonly<{ children: ReactNode }>> = ({ children }) => {
  const { headerHeight } = useHeaderHeight();

  return (
    <Column
      bg="gray.100"
      borderRight="1px"
      borderRightColor="gray.300"
      flexShrink={0}
      gap={6}
      height={`calc(100vh - ${headerHeight}px)`}
      overflowY="auto"
      overscrollBehavior="none"
      position="sticky"
      py={6}
      top={`${headerHeight}px`}
      width="280px"
    >
      {children}
    </Column>
  );
};
