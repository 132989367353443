import { times } from "lodash";
import { Theme as ThemeUI } from "theme-ui";

import { colors, fonts, fontSizes, fontWeights, lineHeights, radii, forms, borders, shadows } from "../../../design";
import badges from "./badges";
import buttons from "./buttons";
import messages from "./messages";

export { ThemeProvider } from "./theme-provider";

const makeTheme = <T extends ThemeUI>(t: T) => {
  return {
    legacy: t,
  } as unknown as T;
};

const theme = makeTheme({
  breakpoints: ["800px", "1000px", "1200px", "1600px"],
  styles: {
    root: {
      fontFamily: "inter",
      fontWeight: "body",
      fontSize: "14px",
      color: colors.black,
      bg: "white",
      lineHeight: lineHeights.body,
    },
    body: {
      display: "flex",
    },
    hr: {
      borderColor: "base.4",
    },
    strong: {
      fontWeight: 500,
    },
    a: {
      color: "blue",
      textDecoration: "none",
      outline: "none",
    },
    h1: {
      color: "black",
      fontWeight: "bold",
      lineHeight: "heading",
      margin: 0,
      fontSize: 4,
    },
    h2: {
      variant: "styles.h1",
      fontSize: 3,
    },
    h3: {
      variant: "styles.h1",
      fontSize: 3,
    },
    h4: {
      variant: "styles.h1",
      fontSize: 2,
    },
  },
  colors,
  forms,
  space: times(100, (n) => n * 4),
  sizes: {
    large: "800px",
    med: "500px",
    small: "350px",
  },
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  radii,
  shadows,
  borders,
  text: {
    default: {
      display: "block",
    },
    subtle: {
      fontSize: 1,
      color: "dark.2",
    },
  },
  cards: {
    primary: {
      border: "small",
      bg: "white",
      overflowY: "hidden",
      overflowX: "auto",
      maxHeight: "100%",
      flexShrink: 0,
      borderRadius: 2,
      display: "flex",
      flexDirection: "column",
    },
    modal: {
      bg: "white",
      maxHeight: "100%",
      flexShrink: 0,
      minWidth: "200px",
      borderRadius: 1,
      border: "none",
      display: "flex",
      flexDirection: "column",
      boxShadow: "large",
    },
  },
  buttons,
  badges,
  messages,
});

export type Theme = typeof theme;

export default theme;
