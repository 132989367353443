import { FC, useState, useMemo } from "react";

import { Box, Heading, Text, Button, FormField, TextInput, ClipboardButton, Paragraph, Link } from "@hightouchio/ui";
import { sha256 } from "js-sha256";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { Settings } from "src/components/settings";
import { useApiKeysQuery, useCreateApiKeyMutation, useDeleteApiKeysMutation } from "src/graphql";
import { Modal } from "src/ui/modal";
import { Table } from "src/ui/table";
import { useRowSelect } from "src/ui/table/use-row-select";

export const APIKeys: FC = () => {
  const [key, setKey] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [createNew, setCreateNew] = useState(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);

  const { data } = useApiKeysQuery();
  const { isLoading: createLoading, mutateAsync: createApiKey } = useCreateApiKeyMutation();
  const { mutateAsync: bulkDelete } = useDeleteApiKeysMutation();

  const apiKeys = data?.api_keys;

  const columns = useMemo(
    () => [
      {
        name: "Name",
        cell: ({ name }) => <Text fontWeight="medium">{name}</Text>,
      },
      {
        name: "Last used",
        max: "240px",
        cell: ({ last_used }) => (last_used ? moment(last_used)?.calendar() : "Never"),
      },
      {
        name: "Created at",
        max: "240px",
        cell: ({ created_at }) => moment(created_at).calendar(),
      },
    ],
    [],
  );

  return (
    <>
      <Settings route="api-keys">
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Heading>API keys</Heading>
            <Paragraph mt={1}>
              Create API keys for programmatic access to your workspace via our{" "}
              <Link href="https://hightouch.com/docs/developer-tools/api-guide">API</Link> or{" "}
              <Link href="https://hightouch.com/docs/developer-tools/cli-guide">CLI</Link>.
            </Paragraph>
          </Box>

          <Box display="flex" gap={3}>
            {selectedRows.length > 0 && (
              <Button variant="warning" onClick={() => setConfirmingDelete(true)}>
                Delete selected
              </Button>
            )}

            <Button
              variant="primary"
              onClick={() => {
                const id = uuidv4();
                setKey(id);
                setName(null);
                setCreateNew(true);
              }}
            >
              Add API key
            </Button>
          </Box>
        </Box>

        <Box mt={6}>
          <Table
            columns={columns}
            data={apiKeys}
            placeholder={placeholder}
            selectedRows={selectedRows}
            sx={{ maxHeight: "600px" }}
            onSelect={onRowSelect}
          />
        </Box>
      </Settings>

      <Modal
        bodySx={{ bg: "white" }}
        footer={
          <>
            <Button onClick={() => setCreateNew(false)}>Cancel</Button>
            <Button
              isDisabled={!name}
              isLoading={createLoading}
              variant="primary"
              onClick={async () => {
                if (key) {
                  await createApiKey({
                    object: { name, hash: sha256.create().update(key).hex() },
                  });
                }

                setCreateNew(false);
              }}
            >
              Create API key
            </Button>
          </>
        }
        isOpen={createNew}
        title="Create API Key"
        onClose={() => {
          setCreateNew(false);
        }}
      >
        <Box display="flex" flexDirection="column" gap={6}>
          <FormField label="Name">
            <TextInput
              placeholder="Enter a name..."
              value={name ?? ""}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </FormField>

          <FormField
            description="This key will only be displayed once, please copy it into your secrets manager."
            label="API key"
          >
            <Box display="flex" gap={3}>
              <TextInput isReadOnly value={key ?? ""} />
              <ClipboardButton text={key ?? ""} />
            </Box>
          </FormField>
        </Box>
      </Modal>

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="API key"
        onClose={() => setConfirmingDelete(false)}
        onDelete={async () => {
          await bulkDelete({ ids: selectedRows.map(String) });
          onRowSelect([]);
        }}
      />
    </>
  );
};

const placeholder = {
  title: "No API keys",
  error: "API keys failed to load, please try again.",
};
