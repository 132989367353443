import { useEffect, useState } from "react";

import { Flex, Image, ThemeUIStyleObject, Text } from "theme-ui";

const Logo = ({
  name,
  logoUrl,
  size = "24px",
  background,
  zoom,
  sx = {},
}: {
  sx?: ThemeUIStyleObject;
  name: string;
  size?: string;
  logoUrl?: string;
  background?: string;
  zoom?: string;
}) => {
  const [logoExists, setLogoExists] = useState(true);

  useEffect(() => {
    setLogoExists(true);
  }, [logoUrl]);

  return logoUrl && logoExists ? (
    <Image
      src={logoUrl}
      sx={{ height: size, width: "auto", transform: `scale(${zoom || 1})`, ...sx }}
      onError={() => {
        if (logoUrl) {
          setLogoExists(false);
        }
      }}
    />
  ) : (
    <Flex
      sx={{
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        width: size,
        height: size,
        bg: background || "transparent",
        ...sx,
      }}
    >
      <Text sx={{ fontWeight: "bold", color: "white", fontSize: 1 }}>
        {name?.split(" ")?.[0]?.[0]}
        {name?.split(" ")?.[1]?.[0]}
      </Text>
    </Flex>
  );
};

export default Logo;
