import { usePagerDutyCredentialsQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { TextArea } from "src/ui/input";
import { Select } from "src/ui/select";

import { PagerdutyAlert, PagerdutyAlertConfig } from "./types";

const severityOptions = [
  { label: "Critical", value: "critical" },
  { label: "Error", value: "error" },
  { label: "Warning", value: "warning" },
  { label: "Info", value: "info" },
];

export const PagerdutyForm = ({
  alert,
  setAlert,
  config,
  setConfig,
}: {
  alert: PagerdutyAlert;
  setAlert: (alert: PagerdutyAlert) => void;
  config: PagerdutyAlertConfig;
  setConfig: (config: PagerdutyAlertConfig) => void;
}) => {
  const {
    data: pagerdutyCredentialsData,
    isLoading: pagerdutyCredentialsLoading,
    error: pagerdutyCredentialsError,
  } = usePagerDutyCredentialsQuery();

  return (
    <>
      <Field error={pagerdutyCredentialsError?.message} label="Credentials">
        <Select
          isLoading={pagerdutyCredentialsLoading}
          options={pagerdutyCredentialsData?.pagerduty_credentials?.map((credential) => ({
            label: `PagerDuty account (${credential.id})`,
            value: credential.id,
          }))}
          placeholder="Select a PagerDuty account..."
          value={alert?.pagerdutyCredentialId}
          width="360px"
          onChange={(selected) => {
            setAlert({ ...alert, pagerdutyCredentialId: selected?.value });
          }}
        />
      </Field>

      <Field label="Row error severity">
        <Select
          options={severityOptions}
          placeholder="Select severity..."
          value={config?.rowErrorSeverity || "warning"}
          width="360px"
          onChange={(selected) => {
            setAlert({ ...alert, rowErrorSeverity: selected?.value });
          }}
        />
      </Field>

      <Field label="Fatal error severity">
        <Select
          options={severityOptions}
          placeholder="Select severity..."
          value={config?.rowErrorSeverity || "error"}
          width="360px"
          onChange={(selected) => {
            setAlert({ ...alert, rowErrorSeverity: selected?.value });
          }}
        />
      </Field>

      <Field optional label="Custom fatal error message">
        <TextArea
          placeholder="Sync {{ id }} failed with error: {{ error }}"
          rows={3}
          value={config?.fatalErrorMessage || ""}
          onValue={(fatalErrorMessage) => setConfig({ ...config, fatalErrorMessage })}
        />
      </Field>

      <Field optional label="Custom row error message">
        <TextArea
          placeholder="Sync {{ id }} failed with error: {{ error }}"
          rows={3}
          value={config?.rowErrorMessage || ""}
          onValue={(rowErrorMessage) => setConfig({ ...config, rowErrorMessage })}
        />
      </Field>
    </>
  );
};
