import { ReactElement, FC, useEffect, useState } from "react";

import { Text, Image, ThemeUIStyleObject } from "theme-ui";

import { useSearchLookerLooksQuery, useDbtSyncModelsQuery } from "src/graphql";
import { Fade } from "src/ui/animations";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { ExternalLinkIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { PageSpinner } from "src/ui/loading";
import { Selectable } from "src/ui/selectable";
import { QueryType } from "src/utils/models";
import { UseSourcesResult } from "src/utils/sources";

import { QUERY_TYPE_OPTIONS } from "./query-type-menu";

type Source = UseSourcesResult["data"][0];

type Props = {
  source: Source;
  selected: QueryType | undefined;
  onChange: (value: QueryType | undefined) => void;
};

const helperContent = {
  dbt_model: {
    text: "To use this modeling method, you first need to connect Hightouch to the Git repo containing your dbt models.",
    docs: `${import.meta.env.VITE_DOCS_URL}/models/dbt-models`,
    link: `/extensions/dbt-models`,
    name: "dbt",
  },
  looker_look: {
    text: "To use this modeling method, you first need to connect Hightouch to your Looker instance.",
    docs: `${import.meta.env.VITE_DOCS_URL}/models/looker-models`,
    link: `/extensions/looker`,
    name: "Looker",
  },
};

export const QueryTypeSelect: FC<Readonly<Props>> = ({ source, selected, onChange }) => {
  const supportedQueries = source.definition?.supportedQueries.filter((type) => {
    // Demo sources don't allow use Table models.
    return QUERY_TYPE_OPTIONS[type];
  }) as QueryType[];

  const { data: searchQuery, isLoading: searchLoading } = useSearchLookerLooksQuery();
  const looks = searchQuery?.searchLookerLooks?.looks ?? [];
  const noLooks = !searchLoading && !looks?.length;
  const { data, isFetching: loading } = useDbtSyncModelsQuery(
    {
      sourceId: source?.id,
    },
    { enabled: Boolean(source?.id) },
  );
  const noDBTModels = !loading && !data?.dbt_sync_models?.length;

  useEffect(() => {
    if (!selected) {
      onChange(supportedQueries.length === 1 ? supportedQueries[0] : undefined);
    }
  }, [selected]);

  return loading || searchLoading ? (
    <PageSpinner />
  ) : (
    <>
      <Row sx={{ flexWrap: "wrap", gap: 6 }}>
        {supportedQueries.map((type: QueryType) => {
          const disabled = (type === "dbt_model" && noDBTModels) || (type === "looker_look" && noLooks);
          return <SelectQueryType key={type} disabled={disabled} type={type} onChange={onChange} />;
        })}
      </Row>
    </>
  );
};

function SelectQueryType({
  type,
  onChange,
  disabled,
}: {
  type: QueryType;
  onChange: (type: QueryType | undefined) => void;
  disabled: boolean;
}): ReactElement<any, any> {
  const { label, description, image, imageHover } = QUERY_TYPE_OPTIONS[type];
  const [showHelper, setShowHelper] = useState(false);
  return (
    <Row
      sx={{
        opacity: disabled && !showHelper ? "50%" : "100%",
      }}
      onMouseEnter={() => setShowHelper(true)}
      onMouseLeave={() => setShowHelper(false)}
    >
      <Selectable
        disabled={disabled}
        selected={false}
        sx={{
          ...selectStyles,
        }}
        onSelect={() => onChange(type)}
      >
        <Column>
          <Heading
            sx={{
              fontSize: 3,
              fontWeight: "bold",
              mb: 4,
            }}
            variant="h3"
          >
            {label}
          </Heading>
          {image && <Image alt={image.alt} src={image.src} />}
          {imageHover && <Image alt={imageHover.alt} src={imageHover.src} />}
          <Text
            sx={{
              my: 4,
              lineHeight: 1.75,
            }}
          >
            {description}
          </Text>
        </Column>
        {disabled && showHelper && (
          <Fade hidden={!showHelper}>
            <Row
              sx={{
                flexGrow: 1,
                bg: "white",
                position: "absolute",
                top: disabled && showHelper ? 0 : "270px",
                left: 0,
                minHeight: disabled && showHelper ? "270px" : "0",
                p: 6,
              }}
            >
              <Column sx={{ padding: 1, flexGrow: 1, fontSize: "14px", lineHeight: "20px" }}>
                <Text sx={{ mb: 4, fontWeight: 600, fontSize: "16px" }}>
                  {helperContent[type].name} extension not configured
                </Text>
                <Text>{helperContent[type].text}</Text>
                <Row sx={{ alignItems: "center", mt: 4 }}>
                  <Link to={helperContent[type].link}>
                    <Button size="small" variant="primary">
                      Configure {helperContent[type].name}
                    </Button>
                  </Link>
                  <Link newTab sx={{ color: "base.5", ml: 4 }} to={helperContent[type].docs}>
                    <Row sx={{ alignItems: "center" }}>
                      <Row mr={1}>Learn more</Row> <ExternalLinkIcon color="currentColor" size={18} />
                    </Row>
                  </Link>
                </Row>
              </Column>
            </Row>
          </Fade>
        )}
      </Selectable>
    </Row>
  );
}

const selectStyles: ThemeUIStyleObject = {
  width: "300px",
  height: "270px",
  p: 6,
  color: "base.6",
  bg: "white",
  border: "small",
  borderColor: "#D4D9DF",
  borderRadius: 2,
  img: {
    width: "100%",
    height: "auto",
    // hide hover img
    "&:last-of-type": {
      display: "none",
    },
  },
  ":hover:not(disabled)": {
    boxShadow: "none",
    color: "base.9",
    cursor: "pointer",
    img: {
      // hide default img
      display: "none",
      // show hover img
      "&:last-of-type": {
        display: "inline-block",
      },
    },
  },
};
