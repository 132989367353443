import { FC, ReactNode } from "react";

import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/solid";
import { Box, Column, Link, Text } from "@hightouchio/ui";

import grain from "src/assets/backgrounds/grain.png";
import { useUser } from "src/contexts/user-context";
import { Fade } from "src/ui/animations";
import { Logo } from "src/ui/brand";
import { BrandHeading } from "src/ui/brand/brand-heading";

interface Props {
  children: ReactNode;
  logo?: boolean;
  heading?: string;
  pt?: number;
}

export const AuthLayout: FC<Readonly<Props>> = ({ children, logo = true, heading, pt = 32 }) => {
  const { user, isCompanyRequired } = useUser();

  return (
    <Column
      align="center"
      background={`url(${grain}) repeat, linear-gradient(107.85deg, #002124 0%, #02373B 17.58%, #033E40 31.02%, #085954 64.76%)`}
      color="white"
      height="100vh"
      pt={pt}
      px={8}
      width="100vw"
    >
      {user && !isCompanyRequired && (
        <Box
          pos="absolute"
          right={4}
          sx={{
            opacity: 0.5,
            color: "white",
            a: { color: "inherit", display: "flex", alignItems: "center", gap: 2, ":hover": { color: "inherit" } },
            ":hover": { opacity: 1 },
          }}
          top={4}
        >
          <Link href={`${import.meta.env.VITE_API_BASE_URL}/auth/logout`}>
            <ArrowRightOnRectangleIcon fill="currentColor" width="20px" />
            <Text>Logout</Text>
          </Link>
        </Box>
      )}
      {logo && (
        <Box left={8} pos="absolute" top={8}>
          <Logo size="126px" variant="full" />
        </Box>
      )}
      <Fade sx={{ display: "flex", flexDirection: "column", flex: 1, alignItems: "center", width: "100%" }}>
        {heading && (
          <BrandHeading fontWeight="bold" mb={12} size="3xl">
            {heading}
          </BrandHeading>
        )}
        {children}
      </Fade>
    </Column>
  );
};
