import { FC, ReactNode, forwardRef } from "react";

import { Column, Row } from "src/ui/box";

import { Indices } from "../../../../../design";

interface HeaderProps {
  children: ReactNode;
}

export const Header = forwardRef<HTMLDivElement, HeaderProps>(({ children }, ref) => {
  return (
    <Column
      ref={ref}
      sx={{
        position: "sticky",
        width: "100%",
        top: 0,
        left: 0,
        height: "max-content",
        zIndex: Indices.Popup,
        bg: "white",
      }}
    >
      {children}
    </Column>
  );
});

Header.displayName = "Header";

interface TopbarProps {
  children: ReactNode;
}

export const Topbar: FC<TopbarProps> = ({ children }) => {
  return (
    <Row
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        height: "64px",
        px: 6,
        width: "100%",
        bg: "base.0",
        flexShrink: 0,
        borderBottom: "small",
      }}
    >
      {children}
    </Row>
  );
};
