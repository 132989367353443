import { FC, ReactNode } from "react";

import { Column, Heading, Box, Text } from "@hightouchio/ui";

interface Props {
  title: string;
  description: string;
  button: ReactNode;
}

export const PageAlert: FC<Readonly<Props>> = ({ title, description, button }) => {
  return (
    <Column align="flex-start" bg="avocado" gap={4} px={6} py={6}>
      <Heading>{title}</Heading>
      <Box maxW="64ch">
        <Text>{description}</Text>
      </Box>
      {button}
    </Column>
  );
};
