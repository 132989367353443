import { useCallback } from "react";

import { To, useNavigate as useNavigateRR, NavigateOptions as NavigateOptionsRR } from "react-router-dom";

import { useUser } from "src/contexts/user-context";

export type NavigateOptions = NavigateOptionsRR & { slug?: boolean };

export type NavigateFunction = (to: To | number, options?: NavigateOptions) => void;

export const useNavigate = (): NavigateFunction => {
  const { slug } = useUser();
  const navigate = useNavigateRR();

  const wrappedNavigate = useCallback(
    (to: To | number, options: NavigateOptions = {}) => {
      if (typeof to === "number") {
        navigate(to);
        return;
      }

      const path = typeof to === "string" ? to : to.pathname;
      const addSlug = options.slug === false ? false : path?.startsWith("/");
      const nextPath = addSlug ? { ...(typeof to === "object" ? to : {}), pathname: `/${slug}${path}` } : path;

      if (nextPath) {
        navigate(nextPath, options);
      }
    },
    [navigate, slug],
  );

  return wrappedNavigate;
};
