import { FC, useEffect, useState } from "react";

import { Box, Button, Heading, FormField, TextInput, Switch, useToast } from "@hightouchio/ui";

import { useCreateGitSyncConfigMutation, useGitSyncConfigQuery, useUpdateGitSyncConfigMutation } from "src/graphql";
import { Container } from "src/ui/box";
import { Section } from "src/ui/section";

export const DbtGitSync: FC = () => {
  const { data } = useGitSyncConfigQuery();
  const config = data?.git_sync_config?.[0];

  const [username, setUsername] = useState(config?.username || "");
  const [password, setPassword] = useState(config?.password || "");
  const [repository, setRepository] = useState(config?.repository || "");
  const [schema, setSchema] = useState<string | null>(config?.schema || "");
  const [bidi, setBidi] = useState(config?.bidi || false);
  const [advancedBitbucket, setAdvancedBitbucket] = useState(config?.bidi_metadata?.git_api_base_url?.length || false);
  const [bidiMetadata, setBidiMetadata] = useState(
    config?.bidi_metadata || { branch: "master", type: "commit", git_service: "github" },
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (config) {
      setUsername(config.username);
      setPassword(config.password);
      setRepository(config.repository);
      setSchema(config.schema);
      setBidi(config.bidi);
      setBidiMetadata(config.bidi_metadata || { branch: "master", type: "commit", git_service: "github" });
    }
  }, [config]);

  const { toast } = useToast();

  const { mutateAsync: createGitConfigSync } = useCreateGitSyncConfigMutation();
  const { mutateAsync: updateGitConfigSync } = useUpdateGitSyncConfigMutation();

  const save = async () => {
    try {
      setLoading(true);

      if (!config) {
        await createGitConfigSync({
          config: {
            username,
            password,
            repository,
            schema,
            bidi,
            bidi_metadata: bidiMetadata,
          },
        });
      } else {
        await updateGitConfigSync({
          id: config.id,
          config: {
            username,
            password,
            repository,
            schema,
            bidi,
            bidi_metadata: bidiMetadata,
          },
        });
      }

      toast({
        id: "git-sync-config",
        title: "Git sync confg saved",
        variant: "success",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Section
      footer={
        <Button isLoading={loading} variant="primary" onClick={save}>
          Save
        </Button>
      }
      header={<Heading>Git credentials</Heading>}
    >
      <Container center={false} size="small">
        <Box display="flex" flexDirection="column" gap={6}>
          <FormField label="Username">
            <TextInput value={username} onChange={(event) => setUsername(event.target.value)} />
          </FormField>

          <FormField label="Token">
            <TextInput type="password" value={password} onChange={(event) => setPassword(event.target.value)} />
          </FormField>

          <FormField label="Repository">
            <TextInput value={repository} onChange={(event) => setRepository(event.target.value)} />
          </FormField>

          <FormField label="Schema">
            <TextInput value={schema || ""} onChange={(event) => setSchema(event.target.value)} />
          </FormField>

          <FormField label="Bidirectional">
            <Switch isChecked={bidi} onChange={setBidi} />
          </FormField>

          {bidi && (
            <>
              <FormField label="Branch">
                <TextInput
                  value={bidiMetadata?.branch || ""}
                  onChange={(event) => setBidiMetadata({ ...bidiMetadata, branch: event.target.value })}
                />
              </FormField>

              {bidiMetadata.git_service === "bitbucket" && (
                <FormField label="Advanced Bitbucket settings">
                  <Switch isChecked={advancedBitbucket} onChange={setAdvancedBitbucket} />
                </FormField>
              )}

              {advancedBitbucket && (
                <FormField label="Bitbucket API endpoint">
                  <TextInput
                    value={bidiMetadata?.git_api_base_url || ""}
                    onChange={(event) =>
                      setBidiMetadata({
                        ...bidiMetadata,
                        git_api_base_url: event.target.value.length > 0 ? event.target.value : undefined,
                      })
                    }
                  />
                </FormField>
              )}
            </>
          )}
        </Box>
      </Container>
    </Section>
  );
};
