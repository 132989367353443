import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const EventIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M13.3051 5.88243V6.06547C12.8144 6.05584 12.3237 6.05584 11.8331 6.05584V5.89206C11.8331 5.22733 11.2737 4.68784 10.6064 4.68784H9.63482C8.52589 4.68784 7.62305 3.80152 7.62305 2.72254C7.62305 2.32755 7.95671 2 8.35906 2C8.77123 2 9.09508 2.32755 9.09508 2.72254C9.09508 3.01155 9.34042 3.24276 9.63482 3.24276H10.6064C12.0882 3.2524 13.2953 4.43736 13.3051 5.88243Z"
        opacity="0.4"
      />
      <path
        clipRule="evenodd"
        d="M15.164 6.08267C15.4791 6.08699 15.7949 6.09132 16.1119 6.09457C19.5172 6.09457 22 8.52229 22 11.8749V16.1812C22 19.5337 19.5172 21.9615 16.1119 21.9615C14.7478 21.9904 13.3837 22 12.0098 22C10.6359 22 9.25221 21.9904 7.88813 21.9615C4.48283 21.9615 2 19.5337 2 16.1812V11.8749C2 8.52229 4.48283 6.09457 7.89794 6.09457C9.18351 6.0753 10.4985 6.05603 11.8332 6.05603C12.3238 6.05603 12.8145 6.05603 13.3052 6.06566C13.9238 6.06566 14.5425 6.07415 15.164 6.08267ZM10.8518 14.7457H9.82139V15.7669C9.82139 16.1619 9.48773 16.4895 9.08538 16.4895C8.67321 16.4895 8.34936 16.1619 8.34936 15.7669V14.7457H7.30913C6.90677 14.7457 6.57311 14.4278 6.57311 14.0232C6.57311 13.6282 6.90677 13.3007 7.30913 13.3007H8.34936V12.2891C8.34936 11.8941 8.67321 11.5666 9.08538 11.5666C9.48773 11.5666 9.82139 11.8941 9.82139 12.2891V13.3007H10.8518C11.2542 13.3007 11.5878 13.6282 11.5878 14.0232C11.5878 14.4278 11.2542 14.7457 10.8518 14.7457ZM15.0226 13.1176H15.1207C15.5231 13.1176 15.8567 12.7997 15.8567 12.3951C15.8567 12.0001 15.5231 11.6725 15.1207 11.6725H15.0226C14.6104 11.6725 14.2866 12.0001 14.2866 12.3951C14.2866 12.7997 14.6104 13.1176 15.0226 13.1176ZM16.7007 16.4317H16.7988C17.2012 16.4317 17.5348 16.1137 17.5348 15.7091C17.5348 15.3141 17.2012 14.9866 16.7988 14.9866H16.7007C16.2885 14.9866 15.9647 15.3141 15.9647 15.7091C15.9647 16.1137 16.2885 16.4317 16.7007 16.4317Z"
        fillRule="evenodd"
      />
    </Icon>
  );
};
