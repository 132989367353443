import { useCallback, useState } from "react";

import {
  Box,
  FormField,
  TextInput,
  RadioGroup,
  Radio,
  Button,
  ClipboardButton,
  Text,
  Textarea,
  Paragraph,
} from "@hightouchio/ui";
import { useDropzone } from "react-dropzone";

import { useCreateGoogleServiceAccountMutation } from "../../graphql";
import { Permission } from "../permission";

export const gcpConfigValidator = (config): boolean => {
  return config.client_email && config.private_key;
};

/**
 * GCP Form
 * @param config
 * @param setConfig
 * @constructor
 */
export const GCPForm = ({ config, setConfig }) => {
  const [accessType, setAccessType] = useState<string>("managedServiceAccount");

  const getAccessTypeForm = () => {
    if (accessType === "managedServiceAccount") {
      return <ManagedServiceAccountSetup config={config} setConfig={setConfig} />;
    } else if (accessType === "customServiceAccount") {
      return <CustomServiceAccountSetup config={config} setConfig={setConfig} />;
    } else {
      return null;
    }
  };

  return (
    <>
      <FormField label="Access method" mt={6}>
        <RadioGroup
          orientation="vertical"
          value={accessType}
          onChange={(type) => {
            setAccessType(type as string);
            // Clear out the other config when we switch.
            setConfig({});
          }}
        >
          <Radio label="Service account managed by Hightouch (recommended)" value="managedServiceAccount" />
          <Radio label="Bring your own service account" value="customServiceAccount" />
        </RadioGroup>
      </FormField>

      {getAccessTypeForm()}
    </>
  );
};

const ManagedServiceAccountSetup = ({ config, setConfig }) => {
  const {
    isLoading: creatingServiceAccount,
    error: serviceAccountError,
    mutateAsync: createServiceAccount,
  } = useCreateGoogleServiceAccountMutation();
  const serviceAccountErrorMessage = parseError(serviceAccountError);

  return (
    <>
      {!config.client_email ? (
        <FormField
          description="Hightouch will provision a new GCP service account for you."
          error={serviceAccountErrorMessage}
          label="Service account details"
          mt={6}
        >
          <Permission>
            <Button
              isLoading={creatingServiceAccount}
              onClick={async () => {
                const { createGoogleServiceAccount } = await createServiceAccount({});
                const credentialsStr = createGoogleServiceAccount?.serviceAccount?.credentials;
                const credentials = JSON.parse(credentialsStr);
                setConfig(credentials);
              }}
            >
              Create a new service account
            </Button>
          </Permission>
        </FormField>
      ) : (
        <FormField
          description="Please copy the service account address below. You will need it later when granting Hightouch access to your data. (You will bind IAM policies to this service account.)"
          label="Service account details"
          mt={6}
        >
          <Box display="flex" gap={3}>
            <TextInput isReadOnly value={config.client_email} />
            <ClipboardButton text={config.client_email} />
          </Box>
        </FormField>
      )}
    </>
  );
};

const parseError = (graphqlError: any) => {
  if (graphqlError) {
    const index = graphqlError.message.indexOf("{");
    const json = JSON.parse(graphqlError.message.slice(index));
    const error = json?.errors?.[0]?.message;

    return error;
  }
};

// keys required in private key upload
const requiredKeys = ["client_email", "private_key", "token_uri"];

const CustomServiceAccountSetup = ({ config, setConfig }) => {
  const [error, setError] = useState<string>("");

  const parseCredentials = (credentials) => {
    const missingKeys: string[] = [];
    for (const key of requiredKeys) {
      if (!credentials[key]) {
        missingKeys.push(key);
      }
    }
    if (missingKeys.length > 0) {
      setError(`Missing keys from credential file: ${missingKeys.join(", ")}`);
      return;
    }
    setConfig(credentials);
  };

  // Create file upload drop zone.
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: useCallback(async (acceptedFiles) => {
      setError("");
      if (acceptedFiles.length === 0) {
        return;
      }

      const fileContents = await acceptedFiles[0].text();
      try {
        parseCredentials(JSON.parse(fileContents));
      } catch (err) {
        setError("Could not parse private key file.");
      }
    }, []),
    accept: ".json",
  });

  return (
    <>
      <FormField
        description={
          <>
            <Paragraph>
              This can be obtained in the Google Cloud web console by navigating to the <Text fontWeight="semibold">IAM</Text>{" "}
              page and clicking on <Text fontWeight="semibold">Service Accounts</Text> in the left sidebar. Then, find your
              service account in the list, go to its <Text fontWeight="semibold">Keys</Text> tab, and click{" "}
              <Text fontWeight="semibold">Add Key</Text>. Finally, click on <Text fontWeight="semibold">Create new key</Text>{" "}
              and choose <Text fontWeight="semibold">JSON</Text>.
            </Paragraph>
          </>
        }
        error={error}
        label="Upload JSON key file for service account"
        mt={6}
      >
        {config.client_id ? (
          <Box display="flex" flexDirection="column" gap={6}>
            <FormField label="Client ID">
              <TextInput isReadOnly value={config.client_id} />
            </FormField>

            <FormField label="Client email">
              <TextInput isReadOnly value={config.client_email} />
            </FormField>

            <FormField label="Private key">
              <Textarea isReadOnly value="<REDACTED>" />
            </FormField>
          </Box>
        ) : (
          <Box bg="gray.50" border="1px" borderColor="gray.400" borderRadius="md" borderStyle="dashed">
            <Box cursor="pointer" py={10} textAlign="center" {...getRootProps()}>
              <input {...getInputProps()} />
              <Paragraph>Drag your file here, or click to select a file.</Paragraph>
            </Box>
          </Box>
        )}
      </FormField>
    </>
  );
};
