import { FC } from "react";

import { useToken } from "@chakra-ui/system";

import { IconProps, Icon } from "./icon";

export const SourceIcon: FC<Readonly<IconProps>> = (props) => {
  const [color] = useToken("colors", [props.color ?? ""]);

  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M18.9715 7.64636V4.07067L18.382 3.78905C14.0205 1.70573 8.95094 1.70573 4.58945 3.78905L3.99988 4.07067V7.64636V17.3702V18.3734V19.6051L6.33657 20.3026C9.69607 21.3056 13.2753 21.3056 16.6348 20.3026L18.9715 19.6051V18.3734V17.3702V7.64636Z"
        fill="white"
        fillOpacity="0.3"
        fillRule="evenodd"
      />
      <path d="M6 7V7C9.49463 8.58847 13.5054 8.58847 17 7V7" stroke={color} strokeLinecap="round" strokeWidth="1.6" />
      <path
        d="M6 11.5V11.5C9.49463 13.0885 13.5054 13.0885 17 11.5V11.5"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.6"
      />
      <path d="M6 16V16C9.49463 17.5885 13.5054 17.5885 17 16V16" stroke={color} strokeLinecap="round" strokeWidth="1.6" />
    </Icon>
  );
};
