import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const SendMonoIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M23.2,0.9c-0.7-0.7-1.8-1-2.7-0.7L2,5.5C0.9,5.8,0.2,6.7,0,7.8c-0.1,1.1,0.3,2.1,1.3,2.7l7.5,4.6l4.5,7.5
	c0.5,0.8,1.4,1.3,2.3,1.3c0.1,0,0.2,0,0.4,0c1.1-0.1,2-0.9,2.3-1.9l5.5-18.3C24.2,2.7,23.9,1.6,23.2,0.9z M15.8,21.2l-4.1-6.8
	l5.9-5.9c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0l-5.9,5.9L2.8,8.2l18.5-5.3L15.8,21.2z"
      />
    </Icon>
  );
};
