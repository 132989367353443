import { FC } from "react";

import { Image, Box, Text } from "theme-ui";

import placeholderDestination from "src/components/permission/destination.svg";
import { DestinationDefinitionFragment as DestinationDefinition, SyncQuery } from "src/graphql";
import { Arrow } from "src/ui/arrow";
import { ObjectBadge } from "src/ui/badge";
import { Row } from "src/ui/box";
import { Link } from "src/ui/link";
import { Tooltip } from "src/ui/tooltip";
import { QueryType } from "src/utils/models";
import { UseSourceResult, SourceBadges, SourceIcon } from "src/utils/sources";
import { getObjectName } from "src/utils/syncs";

export type Source = NonNullable<UseSourceResult["data"]>;

type Props = {
  model: SyncQuery["syncs"][0]["segment"] | undefined;
  source: Source | null;
  destination: any;
  sync: any;
  destinationDefinition: DestinationDefinition | undefined | null;
};

export const SyncName: FC<Readonly<Props>> = ({ model, source, destination, destinationDefinition, sync }) => {
  return (
    <Row sx={{ alignItems: "center", flex: 1 }}>
      {model ? (
        <Link
          sx={{
            color: "unset",
            p: 3,
            border: "small",
            borderColor: "white",
            ":hover": {
              bg: "base.0",
              borderColor: "primaries.3",
              borderRadius: 1,
            },
          }}
          to={model?.query_type === QueryType.Visual ? `/audiences/${model?.id}` : `/models/${model?.id}`}
        >
          <Row sx={{ alignItems: "center" }}>
            <SourceIcon placeholder={true} source={source} sx={{ width: "32px" }} />
            <Box sx={{ ml: 4 }}>
              <Text sx={{ fontSize: 0, color: "base.4", fontWeight: "bold", textTransform: "uppercase" }}>
                {model?.query_type === QueryType.Visual ? "Audience" : "Model"}
              </Text>
              <IntegrationRow>
                <Text sx={{ fontWeight: "bold" }}>{model?.name || "Private model"}</Text>
                <SourceBadges source={source} sx={{ ml: 0 }} />
              </IntegrationRow>
            </Box>
          </Row>
        </Link>
      ) : (
        <Tooltip text="This source is only visible to some users">
          <Row sx={{ alignItems: "center", margin: 3 }}>
            <SourceIcon placeholder={true} source={source} sx={{ width: "32px" }} />
            <Box sx={{ ml: 4 }}>
              <Text sx={{ fontSize: 0, color: "base.4", fontWeight: "bold", textTransform: "uppercase" }}>Model</Text>
              <Text sx={{ fontWeight: "bold" }}>Private Model</Text>
              <SourceBadges source={source} />
            </Box>
          </Row>
        </Tooltip>
      )}

      <Arrow size={20} />

      <Row sx={{ alignItems: "center" }}>
        {destination ? (
          <Link
            sx={{
              color: "unset",
              p: 3,
              border: "small",
              borderColor: "white",
              ":hover": {
                bg: "base.0",
                borderColor: "primaries.3",
                borderRadius: 1,
              },
            }}
            to={`/destinations/${destination?.id}`}
          >
            <Row sx={{ alignItems: "center" }}>
              <Image alt={destinationDefinition?.name} src={destinationDefinition?.icon} width={32} />
              <Box sx={{ ml: 4 }}>
                <Text sx={{ fontSize: 0, color: "base.4", fontWeight: "bold", textTransform: "uppercase" }}>Destination</Text>
                <IntegrationRow>
                  <Text sx={{ fontWeight: "bold" }}>{destination?.name || destinationDefinition?.name}</Text>
                  {sync?.config?.object && <ObjectBadge>{getObjectName(sync.config.object)}</ObjectBadge>}
                </IntegrationRow>
              </Box>
            </Row>
          </Link>
        ) : (
          <Tooltip text="This destination is only visible to some users">
            <Row sx={{ alignItems: "center", margin: 3 }}>
              <Row sx={{ alignItems: "center" }}>
                <Image alt="Private destination" src={placeholderDestination} width={32} />
                <Box sx={{ ml: 4 }}>
                  <Text sx={{ fontSize: 0, color: "base.4", fontWeight: "bold", textTransform: "uppercase" }}>Destination</Text>
                  <Row sx={{ alignItems: "center" }}>
                    <Text sx={{ fontWeight: "bold" }}>Private Destination</Text>
                  </Row>
                </Box>
              </Row>
            </Row>
          </Tooltip>
        )}
      </Row>
    </Row>
  );
};

const IntegrationRow = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: ["column", "column", "column", "row", "row"],
      gap: 2,
      alignItems: ["flex-start", "flex-start", "flex-start", "center", "center"],
      flexWrap: "wrap",
    }}
  >
    {children}
  </Box>
);
