import { FC, useEffect } from "react";

import { useClearRefinements, useCurrentRefinements, useInstantSearch, useMenu } from "react-instantsearch-hooks-web";

import { ScrollContainer } from "src/components/scroll-container";
import { DestinationDefinitionFragment as DestinationDefinition } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Column, Row } from "src/ui/box";
import { PageSpinner } from "src/ui/loading";
import { Placeholder } from "src/ui/table/placeholder";

import { AlgoliaSearchInput } from "../../search/algolia-search-input";
import { CategoryMenu } from "./category-menu";
import { DestinationDetails } from "./destination-details";
import { DestinationHits } from "./destination-hits";

type DestinationsCatalogProps = {
  destinationDefinitions: DestinationDefinition[];
  error: Error | boolean | null;
  selection: DestinationDefinition | null;
  onSelect: (destination: DestinationDefinition | null, hit: any) => void;
};

export const DestinationsCatalog: FC<Readonly<DestinationsCatalogProps>> = ({
  destinationDefinitions,
  error,
  selection,
  onSelect,
}) => {
  const { results, indexUiState } = useInstantSearch();
  const { items: currentRefinements } = useCurrentRefinements();
  const menuApi = useMenu({
    attribute: "categories.name",
    limit: 30,
    sortBy: ["name:asc"],
  });
  const { refine: clearRefinements } = useClearRefinements();
  const currentCategory = currentRefinements?.[0]?.refinements?.[0]?.label ?? "All";

  useEffect(() => {
    analytics.track("Destination Catalog Page Viewed");
  }, []);

  useEffect(() => {
    if (results?.query.length === 1) {
      analytics.track("Destination Catalog Search Performed", {
        current_category: currentCategory,
      });

      // reset category to "all" when beginning a search
      clearRefinements();
    }
  }, [results?.query.length]);

  // scroll results to top when a category filter occurs
  useEffect(() => {
    if (selection) {
      onSelect(null, null);
    }
    if (results?.query.length < 2 && window.scrollY > 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [currentRefinements?.[0]?.refinements?.[0], results?.query]);

  if (error || destinationDefinitions?.length === 0) {
    return (
      <Placeholder
        content={{
          title: "No destinations",
          error: "Destinations failed to load, please try again.",
        }}
        error={Boolean(error)}
      />
    );
  }

  if (!indexUiState?.configure) {
    return <PageSpinner />;
  }

  return (
    <Row sx={{ position: "relative", width: "100%", justifyContent: "flex-start", flex: 1, gap: 8 }}>
      <ScrollContainer
        key={results?.queryID || "scroll"}
        sx={{
          height: "100%",
          position: "sticky",
          top: "180px",
          width: "220px",
        }}
      >
        <CategoryMenu
          clear={clearRefinements}
          currentCategory={currentCategory}
          items={menuApi.items}
          query={results?.query ?? ""}
          refine={menuApi.refine}
        />
      </ScrollContainer>

      <Column
        sx={{
          mt: -8,
          flex: 1,
          alignItems: "stretch",
          minWidth: selection ? "436px" : "inherit",
        }}
      >
        <Column
          sx={{ zIndex: 1, position: "sticky", top: "144px", bg: "white", mb: 4, height: "104px", justifyContent: "flex-end" }}
        >
          <AlgoliaSearchInput placeholder="Search destinations..." />
        </Column>

        <DestinationHits
          category={currentCategory}
          destinations={destinationDefinitions}
          selection={selection}
          onSelect={onSelect}
        />
      </Column>
      {selection && (
        <ScrollContainer
          key={selection.type}
          sx={{
            height: "100%",
            position: "sticky",
            top: "180px",
            width: "100%",
            maxWidth: "356px",
            "@media screen and (max-width: 1280px)": {
              display: "none",
            },
          }}
        >
          <DestinationDetails category={currentCategory} definition={selection} onSelect={onSelect} />
        </ScrollContainer>
      )}
    </Row>
  );
};
