import { useFlags } from "launchdarkly-react-client-sdk";

import {
  useBillingByDestinationQuery,
  useBillingOrganizationQuery,
  useOrganizationOverageDatesQuery,
  useWorkspaceOverageDatesQuery,
} from "src/graphql";

interface Entitlements {
  destinations: number;
  premiumDestinations: number;
  audiences: boolean;
  externalStorage: boolean;
}

export type BillingDestinationType = {
  count: number;
  isFreeDestination: boolean;
  isPremiumDestination: boolean;
  isInTrial: boolean;
};

export type UseEntitlementsResult = {
  isLoading: boolean;
  data: {
    organization: any;
    destinationBillingTypes: Record<string, BillingDestinationType>;
    overage: {
      destinationOverage: boolean;
      premiumOverage: boolean;
      overage: boolean;
      firstOverageDate: string | null;
      overageLockout: boolean;
      destinationOverageText: string;
    };
    entitlements: Entitlements;
    destinationsCounts: {
      billableDestinations: number;
      freeDestinations: number;
      premiumDestinations: number;
      totalDestinations: number;
    };
  };
};

export const useEntitlements = (enabled: boolean): UseEntitlementsResult => {
  const { appSsLockoutOverage } = useFlags();
  const { audiencesEnabled, externalStorageEnabled } = useFlags();

  const { data: orgData, isLoading: loadingOrg } = useBillingOrganizationQuery({}, { enabled });
  const organization = orgData?.getBillingOrganization;

  const { data: orgOverageData, isLoading: loadingOrgOverageInfo } = useOrganizationOverageDatesQuery(
    {},
    { enabled: enabled && Boolean(organization) },
  );
  const { data: workspaceOverageData, isLoading: loadingworkspaceOverageInfo } = useWorkspaceOverageDatesQuery(
    {},
    { enabled: enabled && !organization },
  );

  const overageInfo = organization
    ? orgOverageData?.organization_overage_dates[0]
    : workspaceOverageData?.workspace_overage_dates[0];
  const loadingOverageInfo = organization ? loadingOrgOverageInfo : loadingworkspaceOverageInfo;

  const { data: billingbyDestination, isLoading: loadingBillingByDestinationInfo } = useBillingByDestinationQuery(
    {},
    { enabled },
  );
  const billingByDestinationInfo = billingbyDestination?.billing_by_destination || [];

  const entitlements: Entitlements = {
    destinations: 0,
    premiumDestinations: 0,
    audiences: false,
    externalStorage: false,
    ...overageInfo?.["entitlements"],
  };
  entitlements.audiences = entitlements.audiences || audiencesEnabled;
  entitlements.externalStorage = entitlements.externalStorage || externalStorageEnabled;

  // Some destinations are grouped together for billing purposes: https://hightouch.com/docs/pricing/ss-pricing#grouped-destinations
  const destinationBillingTypes: Record<string, BillingDestinationType> = {};

  for (const destination of billingByDestinationInfo) {
    const { billing_type, is_free_destination, is_premium_destination, is_in_trial } = destination;

    // Some destinations in the production database have a deprecated billing_type of "callback".
    // TODO: Do this filtering in the view definition.
    if (billing_type === "callback") {
      continue;
    }

    // Typecheck complains that the properties might be null.
    const billingType = billing_type || "";
    const isFreeDestination = is_free_destination || false;
    const isPremiumDestination = is_premium_destination || false;
    const isInTrial = is_in_trial || false;

    const currentCount = destinationBillingTypes[billingType]?.count || 0;
    const count = currentCount + 1;

    destinationBillingTypes[billingType] = { count, isFreeDestination, isPremiumDestination, isInTrial };
  }

  const destinationOverage = Boolean(overageInfo?.["is_org_in_overage"] || overageInfo?.["is_workspace_in_overage"]);
  const premiumOverage = Number(overageInfo?.num_premium_destination_types) > entitlements?.premiumDestinations;
  const destinationOverageText = "This workspace has exceeded the number of destinations allowed on its current plan.";

  const freeDestinations = billingByDestinationInfo.filter((d) => d.is_free_destination).length;
  const premiumDestinations = overageInfo?.num_premium_destination_types;

  // Account for the one free destination if it is being used.
  const allPaidDestinationTypes = billingByDestinationInfo.filter(
    ({ is_in_trial, is_free_destination }) => !is_in_trial && !is_free_destination,
  );
  let billableDestinations = overageInfo?.num_paid_destination_types;
  let totalDestinations = billableDestinations + freeDestinations;
  if (allPaidDestinationTypes.length > 0) {
    billableDestinations += 1;
    totalDestinations += 1;
  }

  return {
    isLoading: loadingOrg || loadingOverageInfo || loadingBillingByDestinationInfo,
    data: {
      organization,
      destinationBillingTypes,
      overage: {
        destinationOverage,
        premiumOverage,
        overage: destinationOverage,
        firstOverageDate: overageInfo?.overage_date,
        overageLockout: Boolean(appSsLockoutOverage) && destinationOverage,
        destinationOverageText,
      },
      entitlements,
      destinationsCounts: {
        billableDestinations,
        freeDestinations,
        premiumDestinations,
        totalDestinations,
      },
    },
  };
};
