import { FC, useState } from "react";

import { FolderIcon } from "@heroicons/react/24/solid";
import { Box, BoxProps, ConfirmationDialog, Paragraph, Row, Text, useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import pluralize from "pluralize";

import {
  ResourcePermissionGrant,
  useDeleteFolderMutation,
  useUpdateFoldersMutation,
  useUpdateModelsWhereMutation,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import { DotsIcon } from "src/ui/icons";
import { Menu } from "src/ui/menu";

import { AddFolder } from "./add-folder";
import { EditFolder } from "./edit-folder";
import { Folder } from "./types";

interface FolderProps {
  isSelected: boolean;
  isOpen?: boolean;
  count: number | undefined;
  name: string;
  depth: number;
  onClick: () => void;
  icon?: JSX.Element;
  bg?: BoxProps["bg"];
  folder?: Folder;
  parentFolder?: Folder;
  setSelectedFolder: (folder: string | null) => void;
}

export const IndividualFolder: FC<FolderProps> = ({
  count,
  name,
  depth,
  icon,
  onClick,
  isSelected,
  bg,
  folder,
  parentFolder,
  setSelectedFolder,
  isOpen,
}) => {
  const iconNode = icon || <FolderIcon />;

  const [menuOpen, setMenuOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [updatingFolderOpen, setUpdatingFolderOpen] = useState(false);
  const [addingChild, setAddingChild] = useState(false);

  const { mutateAsync: updateSegment } = useUpdateModelsWhereMutation();
  const { mutateAsync: updateFolders } = useUpdateFoldersMutation();
  const { mutateAsync: deleteFolder } = useDeleteFolderMutation();
  const { toast } = useToast();

  const hasMenu = folder?.parentId !== undefined && !!folder;

  const parentFolderName = parentFolder?.name || (folder?.type === "audiences" ? "All audiences" : "All models");

  const getDeletionBody = () => {
    if (folder?.count) {
      return (
        <Text>
          Removing the folder {name} will cause{" "}
          <Text fontWeight="semibold">
            {count} {pluralize(folder.type, count)}
          </Text>{" "}
          to be moved to the parent folder <Text fontWeight="semibold">{parentFolderName}</Text>
        </Text>
      );
    }

    if (folder?.children.length) {
      return (
        <Text>
          Removing the folder {name} will cause{" "}
          <Text fontWeight="semibold">
            {folder.children.length} {pluralize("folder", folder.children.length)}
          </Text>{" "}
          to be moved to the parent folder <Text fontWeight="semibold">{parentFolderName}</Text>
        </Text>
      );
    }

    return "There are no resource or child folders in this folder.";
  };

  const onConfirmDelete = async () => {
    // typeguard.
    if (!folder?.id) {
      return;
    }

    try {
      // move all the segments to the parent folder.
      await updateSegment({
        where: {
          folder_id: { _eq: folder.id },
        },
        input: {
          folder_id: folder?.parentId,
        },
      });

      // move the child folders to the parent folder.
      await updateFolders({
        ids: folder?.children.map((child) => child.id) || [],
        object: {
          parent_id: folder?.parentId,
        },
      });

      // delete the folder.
      await deleteFolder({
        id: folder?.id,
      });

      toast({
        id: "delete-folder",
        title: "Folder was deleted",
        variant: "success",
      });
    } catch (err) {
      toast({
        id: "delete-folder",
        title: "There was an error deleting the folder",
        variant: "error",
      });

      Sentry.captureException(err);
    }

    setSelectedFolder(parentFolder?.id || null);
  };

  const { hasPermission: userCanUpdate } = useHasPermission([
    { resource: "workspace", grants: [ResourcePermissionGrant.Update] },
  ]);

  return (
    <Row
      _hover={{
        bg: isSelected ? undefined : "gray.200",
        cursor: "pointer",
        ".icon": {
          color: "forest",
        },
        color: "forest",
        ".action-button": {
          display: "block",
        },
        ".count": {
          display: hasMenu && userCanUpdate ? "none" : undefined,
        },
        svg: { fill: "forest" },
      }}
      align="center"
      bg={bg || isSelected ? "gray.300" : undefined}
      color={isSelected ? "gray.900" : "gray.600"}
      cursor="pointer"
      gap={2}
      justify="space-between"
      pl={`${20 + (depth + 1) * 24}px`}
      pr={5}
      py={1.5}
      transition="all 0.1s"
      width="100%"
      onClick={onClick}
    >
      <Row
        align="center"
        gap={2}
        sx={{ overflow: "hidden", span: { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" } }}
      >
        <Box className="icon" color={isSelected || isOpen ? "gray.900" : "gray.600"} flexShrink={0} width="16px">
          {iconNode}
        </Box>
        <Text fontWeight={folder ? "normal" : "medium"}>{name}</Text>
      </Row>
      {!menuOpen && (
        <Box className="count" flexShrink={0}>
          <Text size="sm">{count}</Text>
        </Box>
      )}
      {hasMenu && userCanUpdate && (
        <Box
          className="action-button"
          sx={{
            display: menuOpen ? "block" : "none",
            ":hover": {
              cursor: "pointer",
              color: "base.500",
            },
          }}
        >
          <Menu
            options={[
              {
                label: "Edit folder",
                onClick: () => setUpdatingFolderOpen(true),
              },
              {
                label: "Remove folder",
                onClick: () => {
                  setDeleteConfirmOpen(true);
                },
              },
              {
                label: "Add subfolder",
                onClick: () => setAddingChild(true),
              },
            ]}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setMenuOpen(true);
            }}
            onClose={() => setMenuOpen(false)}
          >
            <DotsIcon
              height={20}
              sx={{
                borderRadius: "50%",
                ":hover": {
                  bg: "gray.400",
                },
              }}
              width={20}
            />
          </Menu>
        </Box>
      )}
      <ConfirmationDialog
        confirmButtonText="Remove"
        isOpen={deleteConfirmOpen}
        title="Remove folder"
        variant="danger"
        onClose={() => setDeleteConfirmOpen(false)}
        onConfirm={onConfirmDelete}
      >
        <Paragraph>{getDeletionBody()}</Paragraph>
      </ConfirmationDialog>

      {folder?.type && updatingFolderOpen && <EditFolder folder={folder} onClose={() => setUpdatingFolderOpen(false)} />}

      {folder && addingChild && (
        <AddFolder
          toggleDisabled
          defaultParentFolder={folder.id}
          folderType={folder.type as "audiences" | "models"}
          viewType="models"
          onClose={(id) => {
            setAddingChild(false);
            id && setSelectedFolder(id);
          }}
        />
      )}
    </Row>
  );
};
