import { FC, useEffect, useState } from "react";

import { useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Text } from "theme-ui";

import { useObject } from "src/components/audiences/use-object";
import { ExploreWithSave } from "src/components/explore/explore-with-save";
import { Page } from "src/components/layout";
import { useObjectQuery } from "src/graphql";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { PageSpinner } from "src/ui/loading";
import { Modal } from "src/ui/modal";
import { DeleteColumnsError, QueryType, useModelRun, useQueryState, useUpdateQuery } from "src/utils/models";
import { useSource } from "src/utils/sources";

import { Heading } from "../../../ui/heading";
import { WarningIcon } from "../../../ui/icons";

export const AudienceObjectQuery: FC = () => {
  const { audience_id: id } = useParams<{ audience_id?: string }>();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { objectPath, objectNameCapitalized } = useObject();
  const { queryState, initQueryState, setSQL, setDBTModel, setTable, isQueryDefined } = useQueryState();

  const { data: objectData, isLoading: objectLoading } = useObjectQuery(
    {
      id: id ?? "",
    },
    { enabled: Boolean(id) },
  );

  const update = useUpdateQuery();

  const object = objectData?.segments_by_pk;
  const type = object?.query_type as QueryType;
  const { data: source, loading: sourceLoading } = useSource(object?.connection?.id);

  const {
    runQuery,
    getSchema,
    cancelQuery,
    resetRunState,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    rawColumns,
  } = useModelRun(type, object?.columns, {
    modelId: object?.id,
    variables: { sourceId: source?.id, ...queryState },
  });

  const [saveError, setSaveError] = useState<{ deletedColumns: string[] } | null>(null);

  const save = async (data) => {
    try {
      await update({ model: object, queryState, columns: data?.columns ?? rawColumns });

      toast({
        id: `save-${objectPath}`,
        title: `${objectNameCapitalized} was updated`,
        variant: "success",
      });
    } catch (error) {
      if (error instanceof DeleteColumnsError) {
        setSaveError({ deletedColumns: error.deletedColumns });
        return;
      }

      toast({
        id: `save-${objectPath}`,
        title: `${objectNameCapitalized} couldn't be updated`,
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    initQueryState(object);
  }, [object]);

  if (objectLoading || sourceLoading) {
    return <PageSpinner />;
  }

  return (
    <Page
      crumbs={[
        { label: "Audiences", link: "/audiences" },
        {
          label: objectNameCapitalized,
          link: `/audiences/setup/${objectPath}`,
        },
        {
          label: object?.name ?? "",
          link: `/audiences/setup/${objectPath}/${id}`,
        },
        {
          label: "Query",
        },
      ]}
      sx={{ height: "100vh", overflow: "hidden" }}
    >
      <ExploreWithSave
        cancelQuery={cancelQuery}
        columns={columns}
        error={queryError}
        errorAtLine={queryErrorAtLine}
        getSchema={getSchema}
        isQueryDefined={isQueryDefined}
        isResultTruncated={Boolean(isResultTruncated)}
        loading={queryLoading}
        numRowsWithoutLimit={numRowsWithoutLimit}
        reset={resetRunState}
        rows={rows}
        runQuery={runQuery}
        saveLabel="Save"
        source={source}
        type={type}
        onBack={() => navigate(`/audiences/setup/${objectPath}/${id}`)}
        onSave={save}
        {...queryState}
        rowsPerPage={15}
        onDBTModelChange={setDBTModel}
        onSQLChange={setSQL}
        onTableChange={setTable}
      />

      {saveError ? (
        <DeleteColumnModal
          columnNames={saveError.deletedColumns}
          modelName={object?.name ?? ""}
          onReset={() => setSaveError(null)}
        />
      ) : null}
    </Page>
  );
};

const DeleteColumnModal = ({
  modelName,
  columnNames,
  onReset,
}: {
  modelName: string;
  columnNames: string[];
  onReset: () => void;
}) => {
  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onReset}>
            OK
          </Button>
        </>
      }
      header={
        <Row sx={{ alignItems: "center" }}>
          <WarningIcon color="red" sx={{ mr: 4 }} />
          <Heading variant="h2">Error saving model</Heading>
        </Row>
      }
      sx={{ maxWidth: 1000 }}
      onClose={onReset}
    >
      <Text>
        Your change to the model "<strong>{modelName}</strong>" would remove the following columns:
      </Text>
      <Grid gap={1} sx={{ mt: 8 }}>
        {columnNames.map((name) => (
          <Text key={name} sx={{ fontWeight: "bold", ml: 4 }}>
            {name}
          </Text>
        ))}
      </Grid>
      <Text sx={{ mt: 8 }}>
        At least one of those columns is used as a key in a model relationship, and saving this change would break that
        relationship. Please remove those columns from your model relationships before changing the model.
      </Text>
    </Modal>
  );
};
