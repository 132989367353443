import { useState } from "react";

import { Box, Column } from "@hightouchio/ui";
import { Navigate } from "react-router-dom";

import { AuthLayout } from "src/components/auth/auth-layout";
import { GoogleButton, MicrosoftButton, EmailButton } from "src/components/auth/buttons";
import { CompanyForm } from "src/components/auth/company-form";
import { useAuthState } from "src/components/auth/use-auth-state";
import { useUser } from "src/contexts/user-context";
import { useSaveUserCompanyInfoMutation } from "src/graphql";
import { PartnerConnectHeader } from "src/pages/partner-connect";
import { Card } from "src/ui/card";
import { useNavigate } from "src/utils/navigate";

export const Restricted = () => {
  const navigate = useNavigate();
  const { user, isCompanyRequired, refetch } = useUser();
  const { returnTo, partnerInformation, googleAuthUrl, microsoftAuthUrl } = useAuthState();
  const [isCompanyForm, setIsCompanyForm] = useState(false);

  const { mutateAsync: saveUserCompanyInfo } = useSaveUserCompanyInfoMutation();

  if (!isCompanyRequired) {
    return <Navigate to="/workspaces" />;
  }

  if (isCompanyForm) {
    return (
      <AuthLayout heading="Tell us about your company">
        <Card>
          <CompanyForm
            onSubmit={async (data) => {
              await saveUserCompanyInfo({
                userId: String(user?.id),
                ...data,
              });
              await refetch();
              navigate("/hello", { slug: false });
            }}
          />
        </Card>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout heading="Please try again with a work email">
      <Card sx={{ h2: { color: "gray.900" }, maxWidth: "402px", width: "100%" }}>
        {partnerInformation && <PartnerConnectHeader partnerInformation={partnerInformation} />}

        <Column gap={3} mb={8} sx={{ button: { bg: "gray.100", ":hover": { bg: "gray.200" }, ":active": { bg: "gray.300" } } }}>
          <GoogleButton
            action="Sign up"
            onClick={() => {
              window.location.href = googleAuthUrl;
            }}
          />
          <MicrosoftButton
            action="Sign up"
            onClick={() => {
              window.location.href = microsoftAuthUrl;
            }}
          />
          <EmailButton
            action="Sign up"
            onClick={() => {
              navigate(returnTo ? `/restricted/email?returnTo=${returnTo}` : "/restricted/email", { slug: false });
            }}
          />
        </Column>

        <Box
          as="button"
          sx={{ bg: "none", color: "gray.500", ":hover": { color: "gray.700", bg: "none" }, width: "max-content", mx: "auto" }}
          onClick={() => setIsCompanyForm(true)}
        >
          I don't have a work email
        </Box>
      </Card>
    </AuthLayout>
  );
};
