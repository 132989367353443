import { FC } from "react";

import { Text, Grid } from "theme-ui";

import { DbtSyncModelQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Link } from "src/ui/link";
import { Strike } from "src/utils/strike";

type Props = {
  model: DbtSyncModelQuery["dbt_sync_models_by_pk"];
  oldModel?: DbtSyncModelQuery["dbt_sync_models_by_pk"];
};

export const DbtQuery: FC<Readonly<Props>> = ({ model, oldModel }) => {
  const getName = (model) => model?.data?.alias || model?.name;

  return (
    <Grid gap={3}>
      <Field inline label="Name">
        <Text>
          <Strike _new={getName(model)} old={getName(oldModel)} />
        </Text>
      </Field>

      <Field inline label="dbt Unique ID">
        <Text>
          <Strike _new={model?.dbt_unique_id} old={oldModel?.dbt_unique_id} />
        </Text>
      </Field>

      {model?.data?.description && (
        <Field inline label="Description">
          <Text>
            <Strike _new={model?.data?.description} old={oldModel?.data?.description} />
          </Text>
        </Field>
      )}

      {model?.dbt_sync_config?.repository && (
        <Field inline label="Repository">
          <Link newTab to={model?.dbt_sync_config?.repository}>
            {model?.dbt_sync_config?.repository}
          </Link>
        </Field>
      )}
    </Grid>
  );
};
