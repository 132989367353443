import { useRef, useState, useEffect, useLayoutEffect, FC } from "react";

import { autocompletion, completionKeymap, closeBrackets, closeBracketsKeymap } from "@codemirror/autocomplete";
import { defaultKeymap as originalDefaultKeymap, indentWithTab, history, historyKeymap } from "@codemirror/commands";
import { json } from "@codemirror/lang-json";
import { sql } from "@codemirror/lang-sql";
import {
  LanguageSupport,
  syntaxHighlighting,
  defaultHighlightStyle,
  indentOnInput,
  bracketMatching,
} from "@codemirror/language";
import { searchKeymap, highlightSelectionMatches } from "@codemirror/search";
import { EditorState, Compartment, Extension } from "@codemirror/state";
import { EditorView, ViewUpdate, keymap, lineNumbers, placeholder } from "@codemirror/view";
import { Box } from "theme-ui";

import css from "./editor.module.css";
import { highlightErroredLine } from "./highlight-errored-line";

const erroredLineCompartment = new Compartment();

// Disable ⌘ + Enter shortcut to insert a blank line, because it
// also triggers a preview in the SQL editor
const defaultKeymap = originalDefaultKeymap.filter((keyBinding) => {
  return keyBinding.key !== "Mod-Enter";
});

type Language = "sql" | "json" | LanguageSupport;

const initializeLanguage = (language: Language): LanguageSupport => {
  switch (language) {
    case "sql":
      return sql();
    case "json":
      return json();
    default:
      return language;
  }
};

// Compartments allow replacing specific extensions without affecting all the other ones
// See https://codemirror.net/6/docs/ref/#state.Compartment
const languageCompartment = new Compartment();
const placeholderCompartment = new Compartment();
const readOnlyCompartment = new Compartment();

export interface Props {
  /**
   * Line number to highlight with a red background, in case there's an error
   */
  highlightErroredLine?: number;

  /**
   * Determines if user can edit the code
   */
  readOnly?: boolean;

  /**
   * Code to show in the editor
   */
  value: string;

  /**
   * Language of the code
   */
  language: Language;

  /**
   * Text to show when there's no code
   */
  placeholder?: string;

  /**
   * CodeMirror extensions
   */
  extensions?: Extension[];

  /**
   * Callback for when editor is initialized
   */
  onInit?: (options: { view: EditorView }) => void;

  /**
   * Callback for when the code is changed
   */
  onChange?: ChangeCallback;
}

type ChangeCallback = (value: string) => void;

export const Editor: FC<Props> = ({
  highlightErroredLine: highlightErroredLineNumber,
  readOnly = false,
  value,
  language,
  placeholder: placeholderText,
  extensions,
  onInit,
  onChange,
}) => {
  const container = useRef<HTMLDivElement>(null);
  const [view, setView] = useState<EditorView | undefined>();

  // Store the reference to the latest `onChange` function, so that `updateListener`
  // always calls the latest `onChange` value without triggering `useEffect` update
  // and forcing CodeMirror to re-bind itself
  const lazyOnChange = useRef<ChangeCallback | undefined>(onChange);

  useLayoutEffect(() => {
    lazyOnChange.current = onChange;
  }, [onChange]);

  useEffect(() => {
    if (!container.current) {
      return undefined;
    }

    const updateListener = EditorView.updateListener.of((viewUpdate: ViewUpdate) => {
      if (viewUpdate.docChanged) {
        const value = viewUpdate.state.doc.toString();

        if (typeof lazyOnChange.current === "function") {
          lazyOnChange.current(value);
        }
      }
    });

    const state = EditorState.create({
      doc: value,
      extensions: [
        lineNumbers(),
        highlightSelectionMatches(),
        indentOnInput(),
        syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
        bracketMatching(),
        closeBrackets(),
        autocompletion(),
        history(),
        updateListener,
        keymap.of([
          ...closeBracketsKeymap,
          ...defaultKeymap,
          ...historyKeymap,
          ...searchKeymap,
          ...completionKeymap,
          indentWithTab,
        ]),
        languageCompartment.of(initializeLanguage(language)),
        placeholderCompartment.of(placeholder("")),
        readOnlyCompartment.of(EditorState.readOnly.of(readOnly)),
        erroredLineCompartment.of(highlightErroredLine(highlightErroredLineNumber)),
        ...(extensions ?? []),
      ],
    });

    const view = new EditorView({
      state,
      parent: container.current,
    });

    setView(view);

    return () => {
      view.destroy();
      setView(undefined);
    };
  }, []);

  useEffect(() => {
    if (!view) {
      return;
    }

    view.dispatch({
      effects: erroredLineCompartment.reconfigure(highlightErroredLine(highlightErroredLineNumber)),
    });
  }, [view, highlightErroredLineNumber]);

  useEffect(() => {
    if (view && typeof onInit === "function") {
      onInit({ view });
    }
  }, [view, onInit]);

  useEffect(() => {
    if (!view) {
      return;
    }

    view.dispatch({
      effects: languageCompartment.reconfigure(initializeLanguage(language)),
    });
  }, [view, language]);

  useEffect(() => {
    if (!view || !placeholderText) {
      return;
    }

    view.dispatch({
      effects: placeholderCompartment.reconfigure(placeholder(placeholderText)),
    });
  }, [view, placeholderText]);

  useEffect(() => {
    if (!view) {
      return;
    }

    view.dispatch({
      effects: readOnlyCompartment.reconfigure(EditorState.readOnly.of(readOnly)),
    });
  }, [view, readOnly]);

  useEffect(() => {
    if (!view) {
      return;
    }

    const currentValue = view.state.doc.toString();

    // Update editor content only when `value` is updated, except when it's
    // updated as a result of this component calling `onChange` as user is typing
    if (value !== currentValue) {
      view.dispatch({
        changes: {
          from: 0,
          to: currentValue.length,
          insert: value ?? "",
        },
      });
    }
  }, [view, value]);

  return <Box ref={container} className={css.editor} sx={{ width: "100%", height: "100%" }} />;
};
