import { useState } from "react";

import { Text, Flex, Grid, Close } from "theme-ui";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Arrow } from "src/ui/arrow";
import { Button } from "src/ui/button";
import { ChevronDownIcon, ChevronUpIcon } from "src/ui/icons";
import { Select } from "src/ui/select";

export const LookupMapper = ({ destName, destColumns, destColumnsLoading, destColumnsDisabled, errors = false }) => {
  const { hightouchColumns, config, setConfig } = useDestinationForm();

  const sfColOpts = destColumns?.map((sfc) => ({
    label: sfc,
    value: sfc,
  }));

  const lookupMappings = config?.externalIdMappings || (config?.externalIdMapping && [config?.externalIdMapping]) || [];

  const [newMapping, setNewMapping] = useState({ from: null, to: null });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  return (
    <Grid gap={4}>
      {lookupMappings?.length
        ? lookupMappings.map((mapping, idx) => {
            return (
              <Flex key={idx} sx={{ alignItems: "center" }}>
                <Text sx={{ flex: 1, p: 1, bg: "grays.2", borderRadius: 1 }}>{mapping?.from}</Text>
                <Arrow />
                <Text sx={{ flex: 1, p: 1, bg: "grays.2", borderRadius: 1 }}>{mapping?.to}</Text>
                <Flex sx={{ alignItems: "center" }}>
                  <Button
                    disabled={idx === 0}
                    sx={{ p: 0, mr: 1 }}
                    variant="secondary"
                    onClick={() => {
                      const newMappings = reorder(lookupMappings, idx, idx - 1);

                      setConfig({ ...config, externalIdMappings: newMappings });
                    }}
                  >
                    <ChevronUpIcon />
                  </Button>
                  <Button
                    disabled={idx >= lookupMappings?.length - 1}
                    sx={{ p: 0 }}
                    variant="secondary"
                    onClick={() => {
                      const newMappings = reorder(lookupMappings, idx, idx + 1);

                      setConfig({ ...config, externalIdMappings: newMappings });
                    }}
                  >
                    <ChevronDownIcon />
                  </Button>
                </Flex>

                <Close
                  onClick={(event) => {
                    event.stopPropagation();
                    const newMappings = lookupMappings.filter((c) => c.to !== mapping.to);

                    setConfig({ ...config, externalIdMappings: newMappings });
                  }}
                />
              </Flex>
            );
          })
        : null}
      <Flex sx={{ alignItems: "center" }}>
        <Select
          isError={errors}
          options={hightouchColumns}
          placeholder="Select a Hightouch column"
          value={newMapping?.from}
          onChange={(selected) => {
            setNewMapping({ ...newMapping, from: selected.value });
          }}
        />
        <Arrow />
        <Select
          disabled={destColumnsDisabled}
          isError={errors}
          isLoading={destColumnsLoading}
          options={sfColOpts}
          placeholder={`Select a ${destName} column`}
          value={newMapping?.to ? sfColOpts.find((c) => c.value === newMapping?.to) : null}
          onChange={(selected) => {
            setNewMapping({ ...newMapping, to: selected.value });
          }}
        />
      </Flex>

      <Button
        disabled={!(newMapping?.to && newMapping?.from) || lookupMappings?.some((c) => c?.to === newMapping?.to)}
        label="Add merge rule"
        size="small"
        variant="secondary"
        onClick={() => {
          const newMappings = [...lookupMappings, newMapping];
          const curMapping = config.mappings || [];
          const withoutExternalIdMapping = curMapping.filter((c) => {
            return c.to !== newMapping?.to;
          });
          setConfig({
            ...config,
            externalIdMappings: newMappings,
            mappings: withoutExternalIdMapping,
          });
          setNewMapping({ from: null, to: null });
        }}
      />
    </Grid>
  );
};
