import { ReactElement } from "react";

import * as Yup from "yup";

import { Field } from "src/ui/field";
import { Input } from "src/ui/input";

import { CustomQuery, CustomQueryFormProps, CustomQueryViewProps } from "./custom-query";

const PATH_LABEL_EDIT = "Relative path to S3 object inside bucket";
const PATH_LABEL_VIEW = "Object path";
const PATH_DESCRIPTION = "The object must be a CSV file (with header row) or a JSON file.";

function QueryForm({ query, onChange }: Readonly<CustomQueryFormProps>): ReactElement<any, any> {
  return (
    <Field description={PATH_DESCRIPTION} label={PATH_LABEL_EDIT} size="large">
      <Input
        placeholder="file.csv"
        value={query?.path}
        onChange={(path) =>
          onChange({
            path,
            type: "string",
          })
        }
      />
    </Field>
  );
}

function QueryView(props: Readonly<CustomQueryViewProps>): ReactElement<any, any> {
  return (
    <Field inline label={PATH_LABEL_VIEW}>
      {props.query.path}
    </Field>
  );
}

const querySchema = Yup.lazy<CustomQuery | undefined>((_) => {
  return Yup.object().shape({ type: Yup.string().required() });
});

export default { QueryForm, QueryView, querySchema };
