import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const ResizeContractIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M21.7099 2.28994C21.617 2.19621 21.5064 2.12182 21.3845 2.07105C21.2627 2.02028 21.132 1.99414 20.9999 1.99414C20.8679 1.99414 20.7372 2.02028 20.6154 2.07105C20.4935 2.12182 20.3829 2.19621 20.2899 2.28994L14.4999 8.08994V6.49994C14.4999 6.23472 14.3946 5.98037 14.207 5.79283C14.0195 5.6053 13.7652 5.49994 13.4999 5.49994C13.2347 5.49994 12.9804 5.6053 12.7928 5.79283C12.6053 5.98037 12.4999 6.23472 12.4999 6.49994V10.4999C12.5015 10.6306 12.5287 10.7597 12.5799 10.8799C12.6814 11.1243 12.8756 11.3185 13.1199 11.4199C13.2402 11.4712 13.3693 11.4984 13.4999 11.4999H17.4999C17.7652 11.4999 18.0195 11.3946 18.207 11.207C18.3946 11.0195 18.4999 10.7652 18.4999 10.4999C18.4999 10.2347 18.3946 9.98037 18.207 9.79283C18.0195 9.6053 17.7652 9.49994 17.4999 9.49994H15.9099L21.7099 3.70994C21.8037 3.61698 21.8781 3.50637 21.9288 3.38452C21.9796 3.26266 22.0057 3.13195 22.0057 2.99994C22.0057 2.86793 21.9796 2.73722 21.9288 2.61536C21.8781 2.4935 21.8037 2.3829 21.7099 2.28994ZM10.8799 12.5799C10.7597 12.5287 10.6306 12.5015 10.4999 12.4999H6.49994C6.23472 12.4999 5.98037 12.6053 5.79283 12.7928C5.6053 12.9804 5.49994 13.2347 5.49994 13.4999C5.49994 13.7652 5.6053 14.0195 5.79283 14.207C5.98037 14.3946 6.23472 14.4999 6.49994 14.4999H8.08994L2.28994 20.2899C2.19621 20.3829 2.12182 20.4935 2.07105 20.6154C2.02028 20.7372 1.99414 20.8679 1.99414 20.9999C1.99414 21.132 2.02028 21.2627 2.07105 21.3845C2.12182 21.5064 2.19621 21.617 2.28994 21.7099C2.3829 21.8037 2.4935 21.8781 2.61536 21.9288C2.73722 21.9796 2.86793 22.0057 2.99994 22.0057C3.13195 22.0057 3.26266 21.9796 3.38452 21.9288C3.50637 21.8781 3.61698 21.8037 3.70994 21.7099L9.49994 15.9099V17.4999C9.49994 17.7652 9.6053 18.0195 9.79283 18.207C9.98037 18.3946 10.2347 18.4999 10.4999 18.4999C10.7652 18.4999 11.0195 18.3946 11.207 18.207C11.3946 18.0195 11.4999 17.7652 11.4999 17.4999V13.4999C11.4984 13.3693 11.4712 13.2402 11.4199 13.1199C11.3185 12.8756 11.1243 12.6814 10.8799 12.5799Z" />
    </Icon>
  );
};
