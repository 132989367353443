import { Fragment, FC, ReactElement } from "react";

import { Link, Box, Row } from "@hightouchio/ui";

import { SIZES } from "src/ui/box/container";

export type Crumb = {
  label: string | ReactElement;
  link?: string;
};

type Props = {
  crumbs: Crumb[];
};

export const Breadcrumbs: FC<Readonly<Props>> = ({ crumbs }) => {
  return (
    <Row fontSize="lg" gap={2} maxWidth={SIZES.page} mx="auto" overflow="hidden" p={6} width="100%">
      {crumbs.map(({ label, link }, index) => (
        <Fragment key={index}>
          {link ? (
            <Box sx={{ a: { color: "gray.600", fontSize: "inherit" } }}>
              <Link href={link}>{label}</Link>
            </Box>
          ) : (
            <Box
              sx={{
                fontWeight: 500,
                color: index === crumbs.length - 1 ? "black" : "gray.600",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {label}
            </Box>
          )}
          {index < crumbs.length - 1 && (
            <Box sx={{ fontWeight: 600, color: "gray.400", flexShrink: 0, fontSize: "inherit" }}>/</Box>
          )}
        </Fragment>
      ))}
    </Row>
  );
};
