import { useEffect, FC } from "react";

import { Box } from "@hightouchio/ui";
import { Route, Routes, Navigate, useLocation, useParams } from "react-router-dom";

import { Layout } from "src/components/layout";
import { Warning } from "src/components/warning";
import { useUser } from "src/contexts/user-context";
import useHasPermission from "src/hooks/use-has-permission";
import * as analytics from "src/lib/analytics";

import { ErrorBoundary } from "../error-boundary";
import { config, RouteObject } from "./config";

const CustomRoute: FC<Readonly<RouteObject>> = ({
  analyticsData = { pageName: "" },
  component,
  unauthorizedRedirect,
  permissions,
  permissionPathParam,
}) => {
  const { user, isCompanyRequired } = useUser();
  const location = useLocation();
  const params = useParams();

  if (permissionPathParam) {
    permissions?.forEach((permission) => {
      permission.resource_id = params[permissionPathParam];
    });
  }
  const { hasPermission, isLoading } = useHasPermission(permissions);

  useEffect(() => {
    // send data to analytics when route renders
    if (analyticsData?.pageName && user) {
      const { pageName, ...data } = analyticsData;
      const { id, ...otherParams } = params;

      const dataToSend = {
        ...data,
        ...otherParams,
      };

      // 'id' is not an allowed key for analytics
      if (id) {
        dataToSend["resource_id"] = id;
      }

      analytics.track(`${pageName} Viewed`, dataToSend);
    }
  }, [analyticsData, location.pathname, params, user]);

  if (!user) {
    return <Navigate state={location?.state} to={`/login?returnTo=${encodeURI(location?.pathname)}`} />;
  }

  if (isCompanyRequired) {
    return <Navigate state={location?.state} to={`/restricted?returnTo=${encodeURI(location?.pathname)}`} />;
  }

  if (isLoading) {
    return null;
  }

  if (permissions && !hasPermission && unauthorizedRedirect) {
    let unauthorizedRedirectWithParams = unauthorizedRedirect;

    // If user enters unauthorized route:
    // bounce user back to page that makes the most sense.
    // Ex: syncs/123/debugger/604 -> syncs/123/debugger
    for (const [key, value] of Object.entries(params)) {
      unauthorizedRedirectWithParams = unauthorizedRedirectWithParams.replace(`:${key}`, value || "");
    }

    return <Navigate to={unauthorizedRedirectWithParams} />;
  }

  return <>{component}</>;
};

export const RootRedirect: FC = () => {
  const { onboarding } = useUser();

  return <Navigate to={onboarding ? "onboarding" : "syncs"} />;
};

const routes = config.map((props) => (
  <Route
    key={props.path}
    element={
      <ErrorBoundary>
        <CustomRoute {...props} />
      </ErrorBoundary>
    }
    path={props.path}
  />
));

export const WorkspaceRouter: FC = () => {
  const { slug } = useUser();

  if (!slug) {
    return null;
  }

  return (
    <Routes>
      <Route element={<Layout />} path={`/${encodeURIComponent(slug)}`}>
        {routes}
        <Route index element={<RootRedirect />} />
        <Route element={<NotFound />} path="*" />
      </Route>
    </Routes>
  );
};

const NotFound: FC = () => {
  return (
    <Box mt={24}>
      <Warning subtitle="Sorry, we could not find what you were looking for!" title="Not found" />
    </Box>
  );
};
