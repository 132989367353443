import { FC, useState, ReactNode } from "react";

import { Text, Divider, Grid } from "theme-ui";

import { Sidebar } from "src/components/page";
import { Box, Column } from "src/ui/box";
import { Link } from "src/ui/link";

import { InviteFormModal } from "../modals/invite-modal";

type Props = {
  buttons?: ReactNode;
  name: string;
  docsUrl: string;
  hideInviteTeammate?: boolean;
  hideSendMessage?: boolean;
  invite?: string;
  message?: string;
  top?: string;
};

export const SidebarForm: FC<Readonly<Props>> = ({
  buttons,
  name,
  docsUrl,
  hideSendMessage,
  hideInviteTeammate,
  invite,
  message,
  top,
}) => {
  const [openInvite, setOpenInvite] = useState(false);

  const docsLink = docsUrl.startsWith("http") ? docsUrl : `${import.meta.env.VITE_DOCS_URL}/${docsUrl}`;

  return (
    <Sidebar top={top}>
      {buttons && (
        <Grid gap={3} mb={10}>
          {buttons}
        </Grid>
      )}
      <Column sx={{ gap: 8 }}>
        <Block
          content={
            <>
              Not sure where to start? Check out the{" "}
              <Link newTab to={docsLink}>
                docs for {name}
              </Link>{" "}
              for step-by-step instructions.
            </>
          }
          title="Read our docs"
        />
        <Block
          content={
            <>
              {invite || "If you’re missing credentials or connection info"},{" "}
              <Link
                onClick={() => {
                  setOpenInvite(true);
                }}
              >
                invite a teammate
              </Link>{" "}
              to join you in this Hightouch workspace.
            </>
          }
          hide={hideInviteTeammate}
          title="Invite a teammate"
        />
        <Block
          content={
            <>
              We’re here to help!{" "}
              <Link onClick={() => window["Intercom"]?.("showNewMessage", message || "I could use some help")}>
                Chat with us
              </Link>{" "}
              if you feel stuck or have any questions.
            </>
          }
          hide={hideSendMessage}
          title="Contact support"
        />
      </Column>
      <InviteFormModal close={() => setOpenInvite(false)} name="Hightouch" open={openInvite} />
    </Sidebar>
  );
};

const Block: FC<Readonly<{ title: string; content: ReactNode; hide?: boolean }>> = ({ title, content, hide }) => {
  if (hide) return null;

  return (
    <Box sx={{ lineHeight: "20px" }}>
      <Text sx={{ color: "base.6" }}>{title}</Text>
      <Divider sx={{ borderColor: "base.2" }} />
      <Box sx={{ fontSize: "12px" }}>{content}</Box>
    </Box>
  );
};
