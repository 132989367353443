export type Operator = any;

export enum StringOperator {
  Equals = "=",
  DoesNotEqual = "<>",
  Contains = "contains",
  DoesNotContain = "notcontains",
  StartsWith = "startswith",
  DoesNotStartWith = "notstartswith",
  EndsWith = "endswith",
  DoesNotEndWith = "notendswith",
  Exists = "exists",
  DoesNotExist = "notexists",
}

export enum NumberOperator {
  Equals = "=",
  DoesNotEqual = "<>",
  GreaterThan = ">",
  LessThan = "<",
  GreaterThanOrEqualTo = ">=",
  LessThanOrEqualTo = "<=",
  Exists = "exists",
  DoesNotExist = "notexists",
}

export enum BooleanOperator {
  Equals = "=",
  DoesNotEqual = "<>",
  Exists = "exists",
  DoesNotExist = "notexists",
}

export enum TimestampOperator {
  Before = "before",
  After = "after",
  Within = "within",
  NotWithin = "notwithin",
  Exists = "exists",
  DoesNotExist = "notexists",
  Anniversary = "anniversary",
  Between = "between",
}

export enum JsonArrayOperator {
  Contains = "contains",
}
