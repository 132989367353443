import { FC } from "react";

import { Grid, Image, Paragraph } from "theme-ui";

import * as analytics from "src/lib/analytics";
import { newIntercomMessage } from "src/lib/intercom";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { DestinationIcon } from "src/ui/icons";
import { Selectable } from "src/ui/selectable";

type DestinationShadowProps = {
  category: string;
  selected: boolean;
  hit: any; // Algolia doesn't expose this type to us, so we are leaving it untyped.
  onSendEvent: (eventType: string, hit: any, eventName?: string) => void;
};

export const DestinationShadow: FC<Readonly<DestinationShadowProps>> = ({ category, selected, hit, onSendEvent }) => {
  if (!hit) {
    return null;
  }

  const messageIntercom = (hit) => {
    newIntercomMessage(
      `Hi, I would like to request a new destination. I want to sync data to ${hit.name} in order to help the _____ team accomplish _____. We use this specific functionality: _____.`,
    );
  };

  const selectShadow = (hit) => {
    messageIntercom(hit);
    analytics.track("Destination Catalog Destination Clicked", {
      destination_name: hit.name,
      destination_slug: hit.slug,
      destination_status: hit.status,
      current_category: category,
    });
    onSendEvent("click", hit, "Destination Catalog Destination Clicked");
  };

  return (
    <Selectable
      selected={selected}
      sx={{
        position: "relative",
        justifyContent: "start",
        px: 5,
        py: 3,
        border: "none",
        borderBottom: "small",
        borderRadius: 0,
        overflow: "hidden",
        ":hover": {
          bg: "white",
          boxShadow: "none",
          "& p": {
            color: "base.7",
          },
          "& button": {
            color: "primary",
          },
        },
      }}
      onSelect={() => selectShadow(hit)}
    >
      <Grid columns="repeat(7, 1fr)" gap={4}>
        <Row sx={{ alignItems: "center", gridColumn: "1/6" }}>
          {hit?.icon?.asset?.url && (
            <Image
              src={`${hit?.icon?.asset?.url}?h=40`}
              sx={{
                height: "20px",
                width: "20px",
                objectFit: "contain",
                flexShrink: 0,
                opacity: 0.25,
              }}
            />
          )}
          {!hit?.icon?.asset?.url && <DestinationIcon size={20} sx={{ opacity: 0.25 }} />}
          <Column sx={{ ml: 4 }}>
            <Paragraph
              sx={{
                fontSize: 1,
                fontWeight: "semi",
                wordBreak: "break-word",
                color: "base.5",
                transition: "all 100ms ease-in-out",
              }}
            >
              {hit.name}
            </Paragraph>
          </Column>
        </Row>
        <Button
          size={undefined}
          sx={{
            bg: "transparent",
            padding: "0 !important",
            color: "base.5",
            fontSize: 1,
            fontWeight: "unset",
            height: "auto !important",
            gridColumn: "6",
            "&:hover": {
              bg: "transparent !important",
              color: "base.5 !important",
            },
          }}
          variant={undefined}
          onClick={() => {
            messageIntercom(hit);
          }}
        >
          Request for development
        </Button>
      </Grid>
    </Selectable>
  );
};
