import { useEffect, useState } from "react";

import { isEmpty } from "lodash";

interface BaseQuery<DataType> {
  data: DataType | undefined;
  isLoading: boolean;
  error: Error | null;
  isRefetching: boolean;
  dataUpdatedAt: number;
  refetch: () => void;
}

interface IncrementalQuery<DataType> {
  data: DataType;
  fullQueryLoading: boolean;
  fullQueryRefetching: boolean;
  fullQueryError: Error | null;
  fullQueryRefetch: () => void;
  minimalQueryLoading: boolean;
  minimalQueryRefetching: boolean;
  minimalQueryError: Error | null;
}

export function useIncrementalQuery<MinimalQueryData, FullQueryData extends MinimalQueryData>(
  minimalQuery: BaseQuery<MinimalQueryData>,
  fullQuery: BaseQuery<FullQueryData>,
): IncrementalQuery<(Partial<FullQueryData> & MinimalQueryData) | undefined> {
  const [data, setData] = useState<Partial<FullQueryData> & MinimalQueryData>();

  // We merge and only keep the fields from the most recent query
  useEffect(() => {
    if ([minimalQuery.data, fullQuery.data].every((d) => d === null || d === undefined)) {
      setData(undefined);
      return;
    }

    if (fullQuery.dataUpdatedAt > minimalQuery.dataUpdatedAt) {
      setData(fullQuery.data || undefined);
      return;
    }

    setData(fullQuery.data || minimalQuery.data || undefined);
  }, [minimalQuery.dataUpdatedAt, fullQuery.dataUpdatedAt]);

  // merge() returns {} if both arguments are undefined
  if (isEmpty(data)) {
    return {
      data: undefined,
      fullQueryError: fullQuery.error,
      fullQueryLoading: fullQuery.isLoading,
      fullQueryRefetching: fullQuery.isRefetching,
      fullQueryRefetch: fullQuery.refetch,
      minimalQueryError: minimalQuery.error,
      minimalQueryLoading: minimalQuery.isLoading,
      minimalQueryRefetching: minimalQuery.isRefetching,
    };
  }

  return {
    data,
    fullQueryError: fullQuery.error,
    fullQueryLoading: fullQuery.isLoading,
    fullQueryRefetching: fullQuery.isRefetching,
    fullQueryRefetch: fullQuery.refetch,
    minimalQueryError: minimalQuery.error,
    minimalQueryLoading: minimalQuery.isLoading,
    minimalQueryRefetching: minimalQuery.isRefetching,
  };
}
