import { FC } from "react";

import { Text } from "theme-ui";

import { Column } from "src/ui/box";

export const VisualQuery: FC<{ model: any }> = ({ model }) => {
  return (
    <Column sx={{ p: 4 }}>
      <Text sx={{ fontWeight: "bold", mb: 2, color: "base.4" }}>Audience</Text>
      <Text sx={{ fontWeight: "semi", fontSize: 2 }}>{model.name}</Text>
    </Column>
  );
};
