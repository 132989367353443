import { FC, ReactElement, useEffect, useState } from "react";

import { Box, Paragraph, Text, UpsellBadge } from "@hightouchio/ui";
import CronParser, { CronExpression } from "cron-parser";
import CronGenerator from "cron-time-generator2";
import { format } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import moment from "moment";
import { Grid } from "theme-ui";

import { useUser } from "src/contexts/user-context";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { DateTimeSelect } from "src/ui/datetime-select";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { NewSelect } from "src/ui/new-select";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { isScheduleBusinessTier, validCronExpression } from "src/utils/schedule";

import { FeaturePreview } from "../feature-gates";
import { Permission } from "../permission";
import { ConfigureDbtCloudSchedule } from "./dbt-cloud-schedule";
import { ConfigureFivetranSchedule } from "./fivetran-schedule";
import { defaultTime, Schedule, ScheduleIntervalUnit, ScheduleType } from "./types";
import { VisualCronExpression } from "./visual-cron-expression";

interface ScheduleOption {
  label: string | ReactElement;
  value: ScheduleType;
  description: string;
}

const getScheduleOptions = (resource: "sync" | "sequence"): ScheduleOption[] => {
  const { fivetranExtension } = useFlags();
  const options: ScheduleOption[] = [
    {
      label: "Manual",
      value: ScheduleType.MANUAL,
      description: `Trigger your ${resource} manually in the Hightouch app or using our API`,
    },
    {
      label: "Interval",
      value: ScheduleType.INTERVAL,
      description: `Schedule your ${resource} to run on a set interval (e.g., once per hour)`,
    },
    {
      label: "Custom recurrence",
      value: ScheduleType.CUSTOM,
      description: `Schedule your ${resource} to run on specific days (e.g., Mondays at 9am)`,
    },
    {
      label: "Cron expression",
      value: ScheduleType.CRON,
      description: `Schedule your ${resource} using a cron expression`,
    },
    {
      label: "dbt Cloud",
      value: ScheduleType.DBT_CLOUD,
      description: `Automatically trigger your ${resource} upon completion of a dbt Cloud job`,
    },
  ];
  if (fivetranExtension) {
    options.push({
      label: "Fivetran",
      value: ScheduleType.FIVETRAN,
      description: `Automatically trigger your ${resource} upon completion of a Fivetran job`,
    });
  }
  return options;
};

interface IntervalUnitOption {
  label: string;
  value: ScheduleIntervalUnit;
}

const intervalUnitOptions: IntervalUnitOption[] = [
  { label: "Minute(s)", value: ScheduleIntervalUnit.MINUTE },
  { label: "Hour(s)", value: ScheduleIntervalUnit.HOUR },
  { label: "Day(s)", value: ScheduleIntervalUnit.DAY },
  { label: "Week(s)", value: ScheduleIntervalUnit.WEEK },
];

export interface ScheduleManagerProps {
  schedule: Schedule | null;
  setSchedule: (schedule: Schedule) => void;
  resource?: "sync" | "sequence";
}

/**
 * Return a Date for the next whole [unit], e.g. the next hour
 * @param unit
 */
function nextInterval(unit: "second" | "minute" | "hour" | "day" | "week"): Date {
  const date = moment().add(1, unit);

  if (unit === "week") {
    date.days(0).hours(0).minutes(0).seconds(0).milliseconds();
  } else if (unit === "day") {
    date.hours(0).minutes(0).seconds(0).milliseconds();
  } else if (unit === "hour") {
    date.minutes(0).seconds(0).milliseconds();
  } else if (unit === "minute") {
    date.seconds(0).milliseconds();
  } else if (unit === "second") {
    date.milliseconds(0);
  } else {
    throw new Error("Unexpected time unit");
  }

  return date.toDate();
}

const CronExpressionTable = ({ expression }: { expression: CronExpression }) => {
  // We copy the passed cron expression to avoid mutating it
  const cron = CronParser.parseExpression(expression.stringify(), { utc: true });

  const next5: Date[] = [];
  for (let i = 0; i < 5; i++) {
    next5.push(cron.next().toDate());
  }

  return (
    <Box pt={4}>
      <Paragraph>
        <Text fontWeight="medium">Syncs are scheduled in UTC. The next five sync runs will trigger at:</Text>
        <ul style={{ listStyleType: "none" }}>
          {next5.map((date) => (
            <li key={date.toString()} style={{ margin: 1 }}>
              <Text key={date.toString()} size="sm">
                {format(date, "PPpp")} UTC
              </Text>
            </li>
          ))}
        </ul>
      </Paragraph>
    </Box>
  );
};

export const ScheduleManager: FC<ScheduleManagerProps> = ({ schedule, setSchedule, resource = "sync" }) => {
  const { workspace } = useUser();
  const [cronExpressionErrorMessage, setCronExpressionErrorMessage] = useState<undefined | string>();
  const [cronExpression, setCronExpression] = useState<CronExpression>();
  const { appSubHourlySyncsEnabled } = useFlags();

  const formatFromColumnOption = (option) => {
    if (option?.value === "minute" && !appSubHourlySyncsEnabled && workspace?.organization?.plan?.sku !== "business_tier") {
      return (
        <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
          {option.label}
          <UpsellBadge ml={8}>Business tier</UpsellBadge>
        </Row>
      );
    }
    return option?.label;
  };

  // Resetting default states
  useEffect(() => {
    if (schedule?.type === ScheduleType.CRON) {
      if (!schedule?.schedule?.expression) {
        const now = new Date();

        const expression = CronGenerator.onSpecificDaysAt([now.getDay()], now.getHours());

        setSchedule({
          ...schedule,
          schedule: {
            expression,
          },
        });
        return;
      }
    }
    if (schedule?.type === ScheduleType.CUSTOM && !schedule?.schedule?.expressions) {
      setSchedule({
        ...schedule,
        schedule: {
          expressions: [
            {
              days: {},
              time: defaultTime,
            },
          ],
        },
      });
    }
  }, [schedule?.type]);

  // Check for cron expression validity
  useEffect(() => {
    if (schedule?.type === ScheduleType.CRON && schedule?.schedule?.expression) {
      const exp = validCronExpression(schedule?.schedule?.expression);
      if (exp) {
        setCronExpression(exp);
        setCronExpressionErrorMessage(undefined);
      } else {
        setCronExpression(undefined);
        setCronExpressionErrorMessage("Invalid cron expression.");
      }
    }
  }, [schedule?.type === ScheduleType.CRON && schedule?.schedule?.expression]);

  return (
    <Grid gap={12} sx={{ width: "100%", "& > div:not(:last-child)": { pb: 12, borderBottom: "small" } }}>
      <Section title="Schedule type">
        <RadioGroup
          options={getScheduleOptions(resource)}
          value={schedule?.type}
          onChange={(type) => (type === schedule?.type ? undefined : setSchedule({ schedule: undefined, type }))}
        />
      </Section>
      {schedule?.type && schedule?.type !== ScheduleType.MANUAL && (
        <Section title="Schedule configuration">
          <Grid gap={8}>
            {schedule?.type === ScheduleType.INTERVAL && (
              <>
                <Grid gap={2} sx={{ gridAutoFlow: "column", gridAutoColumns: "max-content", alignItems: "center" }}>
                  <Text fontWeight="semibold">Every</Text>
                  <Input
                    min="1"
                    sx={{ width: "240px" }}
                    type="number"
                    value={schedule?.schedule?.interval?.quantity ? String(schedule?.schedule?.interval?.quantity) : ""}
                    onChange={(value) =>
                      setSchedule({
                        ...schedule,
                        schedule: {
                          interval: {
                            ...schedule?.schedule?.interval,
                            quantity: value ? Number(value) : null,
                          },
                        },
                      })
                    }
                  />
                  <Select
                    formatOptionLabel={formatFromColumnOption}
                    options={intervalUnitOptions}
                    placeholder="Interval..."
                    value={
                      schedule?.schedule?.interval?.unit
                        ? intervalUnitOptions.find((s) => schedule?.schedule?.interval?.unit === s.value)
                        : null
                    }
                    onChange={(selected) =>
                      setSchedule({
                        ...schedule,
                        schedule: {
                          interval: {
                            ...schedule?.schedule?.interval,
                            unit: selected.value,
                          },
                        },
                      })
                    }
                  />
                </Grid>
                <FeaturePreview
                  enabled={Boolean(
                    workspace?.organization?.plan?.sku === "business_tier" ||
                      !schedule?.schedule?.interval?.unit ||
                      schedule?.schedule?.interval?.unit !== "minute" ||
                      appSubHourlySyncsEnabled,
                  )}
                  featureDetails={{
                    pitch: "Data syncing at sub-hourly frequencies",
                    description:
                      "Realtime syncing enables you to schedule syncs to be run at sub-hourly frequencies. This ensures your business systems are updated with the freshest data.",
                    bullets: [
                      "Schedule Hightouch syncs to run every few minutes",
                      "Great for operational use cases that require sub-hourly response times",
                      "Recommended when connecting Hightouch to a transactional source, like a database",
                    ],
                    image: {
                      src: "https://cdn.sanity.io/images/pwmfmi47/production/848482d36fb62b7df5542d8e8996031a77c2db9e-996x708.png",
                    },
                  }}
                  featureName="continuous syncing"
                  variant="hidden"
                />
              </>
            )}

            {schedule?.type === ScheduleType.DBT_CLOUD && (
              <ConfigureDbtCloudSchedule schedule={schedule} setSchedule={setSchedule} />
            )}

            {schedule?.type === ScheduleType.FIVETRAN && (
              <ConfigureFivetranSchedule schedule={schedule} setSchedule={setSchedule} />
            )}

            {schedule?.type === ScheduleType.CUSTOM && (
              <>
                <Grid gap={8}>
                  {schedule?.schedule?.expressions?.length &&
                    schedule.schedule.expressions.map((_expression, index) => (
                      <VisualCronExpression key={index} index={index} schedule={schedule} setSchedule={setSchedule} />
                    ))}
                </Grid>

                <Permission>
                  <Button
                    label="Add recurrence"
                    size="small"
                    variant="secondary"
                    onClick={() => {
                      setSchedule({
                        ...schedule,
                        schedule: {
                          ...schedule?.schedule,
                          expressions: [...(schedule?.schedule?.expressions ?? []), { days: {}, time: defaultTime }],
                        },
                      });
                    }}
                  />
                </Permission>
              </>
            )}

            {schedule?.type === ScheduleType.CRON && (
              <Field error={cronExpressionErrorMessage} label="Cron expression">
                <Input
                  sx={{ width: "300px" }}
                  value={schedule?.schedule?.expression}
                  onChange={(expression) => {
                    setSchedule({
                      ...schedule,
                      schedule: {
                        expression,
                      },
                    });
                  }}
                />
                {!cronExpressionErrorMessage && cronExpression && <CronExpressionTable expression={cronExpression} />}
              </Field>
            )}

            {(workspace?.organization?.plan?.sku === "business_tier" || !isScheduleBusinessTier(schedule)) && (
              <Grid
                columns={schedule?.startDate || schedule?.endDate ? "repeat(2, max-content)" : "repeat(4, max-content)"}
                gap={2}
                sx={{ gridAutoFlow: "row", alignItems: "center" }}
              >
                <Row sx={{ justifyContent: "end" }}>
                  <Text>This schedule will be applied effective</Text>
                </Row>
                <Row sx={{ flex: "0 1 0", justifyContent: "start" }}>
                  <NewSelect
                    options={[
                      { key: "immediate", value: "immediate", label: "immediately" },
                      { key: "deferred", value: "deferred", label: "from" },
                    ]}
                    value={schedule?.startDate ? "deferred" : "immediate"}
                    onChange={(value) =>
                      setSchedule({
                        ...schedule,
                        startDate: value === "immediate" ? null : schedule?.startDate ?? nextInterval("day").toISOString(),
                      })
                    }
                  />
                </Row>
                {schedule?.startDate && (
                  <DateTimeSelect
                    sx={{ gridColumn: 2, mb: 4 }}
                    value={schedule?.startDate ? new Date(schedule?.startDate) : nextInterval("day")}
                    onChange={(value) => {
                      setSchedule({
                        ...schedule,
                        startDate: value.toISOString(),
                      });
                    }}
                  />
                )}
                <Row sx={{ justifyContent: "end" }}>
                  <Text>and will remain effective</Text>
                </Row>
                <Row sx={{ width: "min-content" }}>
                  <NewSelect
                    options={[
                      { key: "indefinite", value: "indefinite", label: "indefinitely" },
                      { key: "finite", value: "finite", label: "until" },
                    ]}
                    value={schedule?.endDate ? "finite" : "indefinite"}
                    onChange={(value) =>
                      setSchedule({
                        ...schedule,
                        endDate: value === "indefinite" ? null : schedule?.endDate ?? nextInterval("week").toISOString(),
                      })
                    }
                  />
                </Row>
                {schedule?.endDate && (
                  <DateTimeSelect
                    sx={{ gridColumn: 2 }}
                    value={schedule?.endDate ? new Date(schedule?.endDate) : nextInterval("week")}
                    onChange={(value) => {
                      setSchedule({
                        ...schedule,
                        endDate: value.toISOString(),
                      });
                    }}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Section>
      )}
    </Grid>
  );
};
