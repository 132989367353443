import { FC, useState, useMemo, ReactNode } from "react";

import { Button, ButtonGroup, Heading, Row, useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import pluralize from "pluralize";
import { Text } from "theme-ui";

import searchPlaceholder from "src/assets/placeholders/search.svg";
import sourcePlaceholder from "src/assets/placeholders/source.svg";
import { Filters, getHasuaExpFromFilters, sourceFilterDefinitions } from "src/components/filter";
import { CreateViewModal } from "src/components/filter/create-view";
import { Views } from "src/components/filter/views";
import { EditLabels } from "src/components/labels/edit-labels";
import { LabelsCell } from "src/components/labels/labels-cell";
import { Page } from "src/components/layout";
import { BulkDeleteSourcesModal } from "src/components/modals/bulk-delete-sources-modal";
import { PermissionedLinkButton } from "src/components/permissioned-button";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  ConnectionsBoolExp,
  ConnectionsOrderBy,
  ResourcePermissionGrant,
  useAddLabelsToSourcesMutation,
  useDeleteSourcesMutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { DropdownButton } from "src/ui/button";
import { SearchInput } from "src/ui/input";
import { PageSpinner } from "src/ui/loading";
import { Menu, MenuOption } from "src/ui/menu";
import { Modal } from "src/ui/modal";
import { Table, TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { Placeholder } from "src/ui/table/placeholder";
import { useFiltering } from "src/ui/table/use-filtering";
import { useRowSelect } from "src/ui/table/use-row-select";
import { TextWithTooltip } from "src/ui/text";
import { useNavigate } from "src/utils/navigate";
import { SourceBadges, SourceIcon, useSources } from "src/utils/sources";
import { openUrl } from "src/utils/urls";

import { useLabels } from "../../components/labels/use-labels";
import { useUser } from "../../contexts/user-context";

enum SortKeys {
  Name = "name",
  Type = "type",
  UpdatedAt = "updated_at",
}

export const Sources: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");
  const [isConfirmingDeletion, setConfirmingDeletion] = useState(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [warningOpen, setWarningOpen] = useState(false);
  const [createViewModalOpen, setCreateViewModalOpen] = useState(false);
  const [addingLabels, setAddingLabels] = useState(false);

  const { workspace } = useUser();

  const { labels } = useLabels();

  const { hasPermission: hasDeletePerm } = useHasPermission([{ resource: "source", grants: [ResourcePermissionGrant.Delete] }]);

  const { mutateAsync: bulkDelete, isLoading: isBulkDeleting } = useDeleteSourcesMutation();
  const { mutateAsync: addLabels, isLoading: loadingAddLabels } = useAddLabelsToSourcesMutation();

  const { onSort, orderBy } = useTableConfig<ConnectionsOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SortKeys),
  });

  const {
    state: { creatingView, filters, selectedView, viewNotSaved, views, updatingView },
    actions: { createView, deleteView, resetViewFilters, selectView, updateCurrentView, updateFilters },
  } = useFiltering({ viewKey: "sources" });

  const hasuraFilters = useMemo(() => {
    const hasuraFilters: ConnectionsBoolExp = {
      _and: [getHasuaExpFromFilters(sourceFilterDefinitions, filters)],
    };

    if (search && Array.isArray(hasuraFilters._and)) {
      hasuraFilters._and.push({
        name: {
          _ilike: `%${search}%`,
        },
      });
    }

    return hasuraFilters;
  }, [filters, search]);

  const { data: allSourcesForFilters } = useSources({ limit: 1000 });

  const { data: sources, error, loading, isRefetching } = useSources({ filters: hasuraFilters, orderBy });

  const actions: MenuOption[] = [{ label: "Add labels", onClick: () => setAddingLabels(true) }];

  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText = destinationOverageText + " To create a source, upgrade your plan.";

  if (hasDeletePerm) {
    actions.push({
      label: "Delete",
      onClick: () => setConfirmingDeletion(true),
      sx: { color: "red", ":hover::not(:disabled)": { backgroundColor: "reds.0" } },
    });
  }

  const deleteSources = async () => {
    if (hasDeletePerm) {
      try {
        await bulkDelete({ ids: selectedRows.map(String) });

        toast({
          id: "delete-sources",
          title: "Selected sources were deleted",
          variant: "success",
        });

        onRowSelect([]);
      } catch (error) {
        // XXX: This uses the old deletion modal, that doesn't automatically toast on error
        toast({
          id: "delete-sources",
          title: "Couldn't delete selected sources",
          variant: "error",
        });

        Sentry.captureException(error);
      }
    }
  };

  const bulkAddLabels = async (labels: Record<string, string>) => {
    const labelCount = Object.keys(labels).length;

    try {
      await Promise.all(
        selectedRows.map((r) => {
          return addLabels({ id: r.toString(), labels });
        }),
      );
      setAddingLabels(false);

      toast({
        id: "bulk-add-source-labels",
        title: `Added ${labelCount} ${pluralize("label", labelCount)} to ${selectedRows.length} ${pluralize(
          "source",
          selectedRows.length,
        )}`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      toast({
        id: "bulk-add-source-labels",
        title: "Couldn't update labels",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const placeholder = useMemo(
    () => ({
      image: searchPlaceholder,
      title: search ? "No sources match your search" : filters?.length ? "No sources match your filters" : "",
      error: "Sources failed to load, please try again.",
    }),
    [filters, search],
  );

  const columns: TableColumn[] = [
    {
      name: "Name",
      sortDirection: orderBy?.name,
      onClick: () => onSort(SortKeys.Name),
      cell: (source) => (
        <Row sx={{ alignItems: "center" }}>
          <TextWithTooltip
            sx={{
              fontWeight: "semi",
              maxWidth: "350px",
            }}
            text={source.name}
          >
            {source.name}
          </TextWithTooltip>
          <LabelsCell labels={source.tags} />
          <SourceBadges source={source} />
        </Row>
      ),
    },
    {
      name: "Type",
      sortDirection: orderBy?.type,
      onClick: () => onSort(SortKeys.Type),
      cell: (source) => (
        <Row sx={{ alignItems: "center" }}>
          <SourceIcon source={source} sx={{ width: "18px", mr: 2, flexShrink: 0 }} />
          <TextWithTooltip sx={{ fontWeight: "semi", maxWidth: "350px" }} text={source.definition?.name ?? "-"}>
            {source.definition?.name ?? "-"}
          </TextWithTooltip>
        </Row>
      ),
    },
    {
      ...LastUpdatedColumn,
      sortDirection: orderBy?.updated_at,
      onClick: () => onSort(SortKeys.UpdatedAt),
    },
  ];

  if (loading && !isRefetching) {
    return <PageSpinner />;
  }

  return (
    <>
      <Row align="center" justify="space-between" mb={8} width="100%">
        <Row align="center" gap={2}>
          <Heading size="xl">Sources</Heading>
          <Views
            deletePermissionResource="source"
            value={selectedView}
            views={views}
            onChange={selectView}
            onDelete={deleteView}
          />
          {viewNotSaved &&
            (selectedView === "Default view" ? (
              <Button
                onClick={() => {
                  setCreateViewModalOpen(true);
                }}
              >
                Save as
              </Button>
            ) : (
              <DropdownButton
                loading={updatingView}
                options={[
                  {
                    label: "Save as",
                    onClick: () => {
                      setCreateViewModalOpen(true);
                    },
                  },
                  {
                    label: "Reset changes",
                    onClick: () => {
                      resetViewFilters();
                    },
                  },
                ]}
                onClick={updateCurrentView}
              >
                Save
              </DropdownButton>
            ))}
        </Row>
        <ButtonGroup>
          {selectedRows.length > 0 && (
            <Row align="center" gap={2}>
              <Text as="span" sx={{ color: "base.5" }}>{`${pluralize("source", selectedRows.length, true)} selected`}</Text>
              <Menu options={actions}>
                <Button isLoading={isBulkDeleting} variant="secondary">
                  Select action
                </Button>
              </Menu>
            </Row>
          )}
          <PermissionedLinkButton
            href="/sources/new"
            isDisabled={overageLockout}
            permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Create] }]}
            tooltip={overageLockout && overageText}
            variant="primary"
            onClick={() => {
              analytics.track("Add Source Clicked");
            }}
          >
            Add source
          </PermissionedLinkButton>
        </ButtonGroup>
      </Row>
      <Row align="center" justify="space-between" mb={3} width="100%">
        <Row flexWrap="nowrap" gap={2}>
          <SearchInput placeholder="Search sources by name..." value={search ?? ""} onChange={setSearch} />
          <Filters
            data={allSourcesForFilters}
            filterDefinitions={sourceFilterDefinitions}
            filters={filters}
            resourceType="source"
            onChange={updateFilters}
          />
        </Row>
      </Row>

      <Table
        columns={columns}
        data={sources}
        error={Boolean(error)}
        loading={isRefetching}
        placeholder={placeholder}
        selectedRows={selectedRows}
        onRowClick={({ id }, event) => openUrl(`/sources/${id}`, navigate, event)}
        onSelect={onRowSelect}
      />

      <BulkDeleteSourcesModal
        isOpen={isConfirmingDeletion}
        loading={isBulkDeleting}
        sources={selectedRows as string[]}
        workspaceName={workspace?.name ?? ""}
        onCancel={() => {
          setConfirmingDeletion(false);
        }}
        onDelete={deleteSources}
      />
      <Modal
        footer={
          <>
            <Button
              variant="secondary"
              onClick={() => {
                setWarningOpen(false);
              }}
            >
              Close
            </Button>
            <Button
              onClick={() => {
                navigate("/sources/new");
              }}
            >
              Add source
            </Button>
          </>
        }
        isOpen={warningOpen}
        title="Demo source"
        onClose={() => {
          setWarningOpen(false);
        }}
      >
        <Text>This is Hightouch's demo source. Create your own source in less than 5 minutes!</Text>
      </Modal>

      <CreateViewModal
        isOpen={createViewModalOpen}
        loading={creatingView}
        onClose={() => setCreateViewModalOpen(false)}
        onSave={createView}
      />

      <EditLabels
        description="You can label source that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={addingLabels}
        loading={loadingAddLabels}
        saveLabel={`Apply to ${selectedRows.length} ${pluralize("source", selectedRows.length)}`}
        title="Add labels"
        onClose={() => setAddingLabels(false)}
        onSave={bulkAddLabels}
      />
    </>
  );
};

const SourcesPage: FC<Readonly<{ children: ReactNode; pageAlert?: ReactNode }>> = ({ children, pageAlert }) => (
  <PermissionProvider permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Update] }]}>
    <Page fullWidth outsideTopbar={pageAlert}>
      {children}
    </Page>
  </PermissionProvider>
);

const Loader = () => {
  const { resources } = useUser();

  if (resources?.source) {
    return (
      <SourcesPage>
        <Sources />
      </SourcesPage>
    );
  }

  return (
    <SourcesPage>
      <Heading mb={8} size="xl">
        Sources
      </Heading>
      <Placeholder
        content={{
          image: sourcePlaceholder,
          title: "No sources in this workspace",
          body: "A source is where your data is stored and managed. Hightouch supports 20+ popular sources, including data warehouses like Snowflake and BigQuery, spreadsheets like Airtable and Google Sheets, and other data systems like SFTP, S3, and Mixpanel.",
          button: (
            <PermissionedLinkButton
              href="/sources/new"
              permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Create] }]}
              variant="primary"
            >
              Add source
            </PermissionedLinkButton>
          ),
        }}
        error={false}
      />
    </SourcesPage>
  );
};

export default Loader;
