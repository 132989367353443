import { FC } from "react";

import { default as LoadingSkeleton } from "react-loading-skeleton";
import { Spinner as ThemedSpinner, Flex, ThemeUIStyleObject, ThemeUICSSObject } from "theme-ui";
import "react-loading-skeleton/dist/skeleton.css";

export const Skeleton = LoadingSkeleton;

export interface SpinnerProps {
  size?: number;
  color?: string;
  sx?: ThemeUIStyleObject;
  center?: boolean;
}

export const Spinner: FC<Readonly<SpinnerProps>> = ({ size = 24, color = "forest", sx = {}, center = false }) => (
  <ThemedSpinner size={size} sx={{ color, m: center ? "auto" : 0, ...sx }} />
);

export const PageSpinner = () => (
  <Flex sx={{ width: "100%", height: "100vh", justifyContent: "center", alignItems: "center" }}>
    <Spinner size={64} />
  </Flex>
);

export const OverlaySpinner = ({ sx = {} }: { sx?: ThemeUICSSObject }) => (
  <Flex
    sx={{
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
      minHeight: 0,
      height: "100%",
      width: "100%",
      bg: "rgba(250, 251, 252, .75)",
      ...sx,
    }}
  >
    <Spinner size={64} />
  </Flex>
);
