import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const ChatMonoIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M16.2,14.2c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7h0c0.9,0,1.7,0.8,1.7,1.7S17.2,14.2,16.2,14.2z" />

      <path d="M11.9,14.2c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7h0c0.9,0,1.7,0.8,1.7,1.7S12.9,14.2,11.9,14.2z" />

      <path d="M7.6,14.2c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7h0c0.9,0,1.7,0.8,1.7,1.7S8.6,14.2,7.6,14.2z" />

      <path
        d="M12,24c-1.6,0-3.3-0.3-4.8-1c-0.2,0-0.7,0.1-1.1,0.1c-2.2,0.3-3.7,0.4-4.6-0.5c-0.9-0.9-0.8-2.5-0.5-4.6
			C1,17.6,1,17.1,1,16.9C-1,12.4,0,7,3.5,3.5C5.8,1.2,8.8,0,12,0c3.2,0,6.2,1.2,8.5,3.5c4.7,4.7,4.7,12.3,0,17
			C18.2,22.8,15.1,24,12,24z M7.2,20.4c0.3,0,0.5,0,0.8,0.1c3.6,1.7,7.9,0.9,10.7-1.9l0,0c3.7-3.7,3.7-9.7,0-13.3
			c-1.8-1.8-4.1-2.8-6.7-2.8c-2.5,0-4.9,1-6.7,2.8C2.5,8.1,1.8,12.4,3.4,16c0.2,0.6,0.2,1.1,0,2.3c-0.1,0.6-0.2,1.8-0.2,2.4
			c0.6,0.1,1.8-0.1,2.4-0.2C6.4,20.5,6.8,20.4,7.2,20.4z M3.3,21L3.3,21L3.3,21z"
      />
    </Icon>
  );
};
