import { FC, ReactNode } from "react";

import { Heading, Column, Box, Paragraph } from "@hightouchio/ui";

import PlaceholderSrc from "src/assets/placeholders/generic.svg";

import ErrorSrc from "./error.svg";

export type PlaceholderContent = {
  image?: string;
  title?: string;
  body?: string | ReactNode;
  button?: ReactNode;
  error?: string;
  custom?: ReactNode;
  customError?: ReactNode;
};

type PlaceholderProps = {
  error?: boolean;
  content: PlaceholderContent | undefined;
};

export const Placeholder: FC<Readonly<PlaceholderProps>> = ({ error, content }) => {
  return (
    <Column align="center" overflow="hidden" p={12} width="100%">
      {error ? (
        <>
          <Box as="img" mb={6} src={ErrorSrc} />
          {content?.customError || (
            <Box
              sx={{
                p: {
                  color: "red",
                  ":first-letter": {
                    textTransform: "capitalize",
                  },
                },
              }}
            >
              <Paragraph>{content?.error || defaultError}</Paragraph>
            </Box>
          )}
        </>
      ) : content?.title ? (
        <>
          <Box as="img" maxW="180px" mb={6} src={content?.image ?? PlaceholderSrc} />

          <Heading mb={2}>{content.title}</Heading>
          {content?.body && <Paragraph textAlign="center">{content.body}</Paragraph>}
          {content?.button && <Box mt={6}>{content.button}</Box>}
        </>
      ) : (
        content?.custom
      )}
    </Column>
  );
};

const defaultError = "Results were unable to load, please try again.";
