import { FC, useEffect } from "react";

import * as Yup from "yup";

import { useAirtableListBasesQuery, useAirtableListTablesQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Spinner } from "src/ui/loading";
import { NewSelect } from "src/ui/new-select";

import { CustomQueryViewProps, CustomQueryFormProps } from "../custom-query";

export const QueryForm: FC<Readonly<CustomQueryFormProps>> = ({ source, query, onChange, setError }) => {
  const {
    data: bases,
    isLoading: basesLoading,
    error: basesError,
    refetch: listBases,
  } = useAirtableListBasesQuery({ connectionId: String(source.id) });

  const {
    data: tables,
    isLoading: tablesLoading,
    error: tablesError,
    refetch: listTables,
  } = useAirtableListTablesQuery(
    { connectionId: String(source.id), baseId: query?.baseId ?? "" },
    { enabled: Boolean(query?.baseId) },
  );

  useEffect(() => {
    if (basesError || tablesError) {
      setError(basesError || tablesError);
    }
  }, [basesError, tablesError]);

  return (
    <>
      <Field label="Base" size="large">
        <NewSelect
          error={Boolean(basesError)}
          loading={basesLoading}
          options={bases?.airtableListBases?.map((base) => {
            return { label: base.name, value: base.id };
          })}
          placeholder="Select a base..."
          reload={listBases}
          value={query?.baseId}
          onChange={(baseId) => onChange({ ...query, baseId })}
        />
      </Field>
      <Field label="Table" size="large">
        <NewSelect
          disabled={!query?.baseId}
          error={Boolean(tablesError)}
          loading={tablesLoading}
          options={tables?.airtableListTables?.map((table) => {
            return { label: table.name, value: table.id };
          })}
          placeholder="Select a table..."
          reload={listTables}
          value={query?.tableId}
          onChange={(tableId) => onChange({ ...query, tableId, viewId: undefined })}
        />
      </Field>
      {query?.tableId && (
        <Field optional label="View" size="large">
          <NewSelect
            error={Boolean(tablesError)}
            loading={tablesLoading}
            options={tables?.airtableListTables
              ?.find((table) => table.id === query?.tableId)
              ?.views?.map((view) => {
                return { label: view.name, value: view.id };
              })}
            placeholder="Select a view..."
            value={
              tables?.airtableListTables
                ?.find((table) => table.id === query?.tableId)
                ?.views?.find((view) => view.id === query?.viewId)?.id || null
            }
            onChange={(viewId) => onChange({ ...query, viewId })}
          />
        </Field>
      )}
    </>
  );
};

export const QueryView: FC<Readonly<CustomQueryViewProps>> = ({ source, query, setError }) => {
  const {
    data: bases,
    isLoading: basesLoading,
    error: basesError,
  } = useAirtableListBasesQuery({ connectionId: String(source.id) });

  const {
    data: tables,
    isLoading: tablesLoading,
    error: tablesError,
  } = useAirtableListTablesQuery(
    { connectionId: String(source.id), baseId: String(query.baseId) },
    { enabled: Boolean(query?.baseId) },
  );

  useEffect(() => {
    if (basesError || tablesError) {
      setError(basesError || tablesError);
    }
  }, [basesError, tablesError]);

  if (basesLoading || tablesLoading) {
    return <Spinner />;
  }

  const base = bases?.airtableListBases?.find((base) => base.id === query.baseId);
  const baseName = base ? base.name : `Unknown base with id ${query.baseId}`;
  const table = tables?.airtableListTables?.find((table) => table.id === query.tableId);
  const tableName = table ? table.name : `Unknown table with id ${query.tableId}`;
  let view: Record<string, unknown> | null | undefined = null;
  let viewName: string | null = null;
  if (query.viewId) {
    view = table?.views?.find((view) => view.id === query.viewId);
    viewName = view ? String(view.name) : `Unknown view with id ${query.viewId}`;
  }

  return (
    <>
      <Field inline label="Base">
        {baseName}
      </Field>
      <Field inline label="Table">
        {tableName}
      </Field>
      {viewName && (
        <Field inline label="View">
          {viewName}
        </Field>
      )}
    </>
  );
};

export const querySchema = Yup.object().shape({
  type: Yup.string().required().equals(["table"]),
  baseId: Yup.string().required(),
  tableId: Yup.string().required(),
  viewId: Yup.string().notRequired(),
});
