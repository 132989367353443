import { FC, useState, useEffect } from "react";

import { useToast } from "@hightouchio/ui";
import { useNavigate, useParams } from "react-router-dom";
import { Text } from "theme-ui";

import { AudienceExplore } from "src/components/audiences/audience-explore";
import { Destinations } from "src/components/clone/destinations";
import { FormErrorProvider } from "src/contexts/form-error-context";
import { useUser } from "src/contexts/user-context";
import { useAudienceQuery, useCreateAudienceMutation } from "src/graphql";
import { Column } from "src/ui/box";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { Input } from "src/ui/input";
import { PageSpinner } from "src/ui/loading";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { useDestinations } from "src/utils/destinations";
import { QueryType, useModelRun, useQueryState } from "src/utils/models";
import { useSource } from "src/utils/sources";

export const CloneAudience: FC = () => {
  const { audience_id: id } = useParams<{ audience_id: string }>();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { user } = useUser();
  const [step, setStep] = useState<number>(0);
  const [name, setName] = useState<string>("");
  const [selectedSyncs, setSelectedSyncs] = useState<Set<string>>(new Set());

  const { queryState, initQueryState, setVisualQueryFilter } = useQueryState();

  const { data: audienceData, isLoading: modelLoading } = useAudienceQuery(
    {
      id: id ?? "",
    },
    { enabled: Boolean(id) },
  );

  const { isLoading: creating, mutateAsync: createAudience } = useCreateAudienceMutation();

  const audience = audienceData?.segments_by_pk;
  const parentModel = audience?.parent;
  const syncs = audience?.syncs;

  const {
    data: { definitions },
    loading: destinationsLoading,
  } = useDestinations();

  const { data: source, loading: sourceLoading, error: sourceError } = useSource(audience?.connection?.id);

  const create = async () => {
    await createAudience({
      input: {
        query_type: QueryType.Visual,
        visual_query_parent_id: parentModel?.id,
        visual_query_filter: queryState?.visualQueryFilter,
        name,
        primary_key: parentModel?.primary_key,
        connection_id: source?.id,
        created_by: user?.id != null ? String(user?.id) : undefined,
        destination_instances: {
          data: selectedSyncs.size
            ? syncs
                ?.filter(({ id }) => selectedSyncs.has(String(id)))
                ?.map(({ destination, config, schedule, sync_alerts, row_threshold_attempted, row_threshold_total }) => ({
                  destination_id: destination!.id,
                  config,
                  schedule,
                  schedule_paused: true,
                  created_by: user?.id != null ? String(user?.id) : undefined,
                  row_threshold_attempted,
                  row_threshold_total,
                  alert_instances: {
                    data: sync_alerts.map((alert) => ({
                      alert_id: alert.id,
                      fatal_error: alert.fatal_error,
                      row_error: alert.row_error,
                    })),
                  },
                })) ?? []
            : [],
        },
      },
    });

    toast({
      id: "clone-audience",
      title: `Cloned ${audience?.name} and ${selectedSyncs?.size} syncs`,
      variant: "success",
    });

    navigate("/audiences");
  };

  const {
    runQuery,
    getSchema,
    cancelQuery,
    resetRunState,
    loading: queryLoading,
    error: queryError,
    rows,
    numRowsWithoutLimit,
    columns,
    transformedSql,
  } = useModelRun(QueryType.Visual, audience?.parent?.columns, {
    modelId: audience?.id,
    variables: { sourceId: source?.id, parentModelId: parentModel?.id, ...queryState },
  });

  useEffect(() => {
    initQueryState(audience);
  }, [audience]);

  useEffect(() => {
    if (syncs) {
      setSelectedSyncs(new Set(syncs.map((sync) => String(sync.id))));
    }
  }, [syncs]);

  if (modelLoading || destinationsLoading || sourceLoading) {
    return <PageSpinner />;
  }

  const steps: Step[] = [
    {
      title: "Change audience",
      render: () => (
        <FormErrorProvider>
          <AudienceExplore
            audience={audience}
            cancelQuery={cancelQuery}
            columns={columns}
            error={queryError || sourceError?.message}
            getSchema={getSchema}
            loading={queryLoading || sourceLoading}
            numRowsWithoutLimit={numRowsWithoutLimit}
            parentModel={parentModel}
            reset={resetRunState}
            rows={rows}
            runQuery={runQuery}
            source={source}
            transformedSql={transformedSql}
            onVisualQueryFilterChange={setVisualQueryFilter}
            {...queryState}
            rowsPerPage={15}
          />
        </FormErrorProvider>
      ),
    },
  ];

  if (syncs?.length) {
    steps.push({
      title: "Select syncs",
      header: (
        <Column sx={{ gap: 2 }}>
          <Heading>Connect destinations</Heading>
          <Text sx={{ fontWeight: "semi", color: "base.5" }}>
            Select all the destinations you'd like to sync to. Cloned syncs are disabled by default, review the cloned
            configuration before enabling.
          </Text>
        </Column>
      ),
      render: () => (
        <Destinations definitions={definitions} selected={selectedSyncs} setSelected={setSelectedSyncs} syncs={syncs} />
      ),
    });
  }

  steps.push({
    title: "Finalize",
    disabled: !name,
    submitting: creating,
    header: <Heading>Finalize your settings</Heading>,
    render: () => (
      <Column sx={{ gap: 8, maxWidth: "600px" }}>
        <Field label="Audience name">
          <Input placeholder={audience?.name} value={name} onChange={setName} />
        </Field>
      </Column>
    ),
  });

  return (
    <Wizard
      setStep={setStep}
      step={step}
      steps={steps}
      title="Clone audience"
      onCancel={() => {
        navigate(`/audiences/${id}`);
      }}
      onSubmit={create}
    />
  );
};
