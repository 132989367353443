import { FC } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { Row } from "src/ui/box";

import { Label } from "./label";

export const labelsContainerMaxWidth = 280;

type Props = {
  labels: Record<string, string>;
  sx?: ThemeUIStyleObject;
};

export const Labels: FC<Readonly<Props>> = ({ labels = {}, sx = {} }) => {
  const entries = Object.entries(labels).sort(([a], [b]) => a.localeCompare(b));

  return (
    <Row
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        m: -1,
        maxWidth: `${labelsContainerMaxWidth}px`,
        ...sx,
      }}
    >
      {entries.map(([key, value]) => (
        <Label key={key} label={{ key, value }} sx={{ m: 1 }} />
      ))}
    </Row>
  );
};
