import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const SegmentIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M10.153 5.556c.05.103.084.214.1.328l.278 4.14.138 2.081c.002.214.035.427.1.631.167.397.568.649 1.005.631l6.657-.435c.289-.005.567.103.774.3.173.163.284.378.32.608l.011.14c-.275 3.815-3.077 6.997-6.884 7.818-3.806.821-7.71-.914-9.591-4.263a8.215 8.215 0 01-.997-3.146 6.018 6.018 0 01-.063-.986c-.006-4.09 2.906-7.626 6.983-8.478.491-.077.972.183 1.169.63z" />
      <path
        d="M12.87 2c4.56.117 8.392 3.396 9.13 7.812l-.007.033-.02.047.003.13a.8.8 0 01-.192.473.827.827 0 01-.462.27l-.11.016-7.68.498a.917.917 0 01-.7-.227.884.884 0 01-.289-.537l-.515-7.67a.123.123 0 010-.08.785.785 0 01.258-.555.815.815 0 01.584-.21z"
        opacity={0.4}
      />
    </Icon>
  );
};
