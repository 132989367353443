import { FC, useState, useCallback, useMemo, ReactNode } from "react";

import { Row, Column, Button, Heading, useToast, ButtonGroup } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import pluralize from "pluralize";
import { Text, Image } from "theme-ui";
import { isPresent } from "ts-extras";

import destinationPlaceholder from "src/assets/placeholders/destination.svg";
import searchPlaceholder from "src/assets/placeholders/search.svg";
import { destinationFilterDefinitions, Filters, getHasuaExpFromFilters } from "src/components/filter";
import { CreateViewModal } from "src/components/filter/create-view";
import { Views } from "src/components/filter/views";
import { EditLabels } from "src/components/labels/edit-labels";
import { LabelsCell } from "src/components/labels/labels-cell";
import { Page } from "src/components/layout";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { PermissionedLinkButton } from "src/components/permissioned-button";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  DestinationsBoolExp,
  DestinationsOrderBy,
  ResourcePermissionGrant,
  useDeleteDestinationsMutation,
  useUpdateDestinationV2Mutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { DropdownButton } from "src/ui/button";
import { SearchInput } from "src/ui/input";
import { PageSpinner } from "src/ui/loading";
import { Menu } from "src/ui/menu";
import { Table, TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { Placeholder } from "src/ui/table/placeholder";
import { useFiltering } from "src/ui/table/use-filtering";
import { useRowSelect } from "src/ui/table/use-row-select";
import { TextWithTooltip } from "src/ui/text";
import { useDestinations } from "src/utils/destinations";
import { useNavigate } from "src/utils/navigate";
import { openUrl } from "src/utils/urls";

import { useLabels } from "../../components/labels/use-labels";

enum SortKeys {
  Name = "name",
  Type = "type",
  UpdatedAt = "updated_at",
}

export const Destinations: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [createViewModalOpen, setCreateViewModalOpen] = useState(false);
  const [addingLabels, setAddingLabels] = useState(false);

  const { hasPermission: userCanUpdate } = useHasPermission([
    { resource: "destination", grants: [ResourcePermissionGrant.Update] },
  ]);
  const { hasPermission: userCanDelete } = useHasPermission([
    { resource: "destination", grants: [ResourcePermissionGrant.Delete] },
  ]);

  const { labels } = useLabels();

  const { mutateAsync: updateDestination, isLoading: updatingDestination } = useUpdateDestinationV2Mutation();
  const { mutateAsync: bulkDelete, isLoading: loadingBulkDelete } = useDeleteDestinationsMutation();

  const { onSort, orderBy } = useTableConfig<DestinationsOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SortKeys),
  });

  const {
    state: { creatingView, filters, selectedView, viewNotSaved, views, updatingView },
    actions: { createView, deleteView, resetViewFilters, selectView, updateCurrentView, updateFilters },
  } = useFiltering({ viewKey: "destination" });

  const hasuraFilters: DestinationsBoolExp = useMemo(() => {
    const initial: DestinationsBoolExp = {
      _and: [getHasuaExpFromFilters(destinationFilterDefinitions, filters)],
    };
    if (search) {
      initial._and!.push({ name: { _ilike: `%${search}%` } });
    }
    return initial;
  }, [filters, search]);

  const {
    data: { destinations: allDestinations, definitions: allDefinitions },
  } = useDestinations({ limit: 100 });

  // used for filtering
  const allDestinationsWithDefinitionData = useMemo(() => {
    return (allDestinations ?? []).map((destination) => {
      const definition = (allDefinitions ?? []).find(({ type }) => type === destination.type);

      return { ...destination, definition };
    });
  }, [allDestinations, allDefinitions]);

  const {
    data: { destinations, definitions },
    loading: destinationsLoading,
    error: destinationsError,
    isRefetching,
  } = useDestinations({
    filters: hasuraFilters,
    destinationsOrderBy: orderBy,
  });

  const bulkAddLabels = async (labels: Record<string, string>) => {
    try {
      const promises = selectedRows.map((id) => updateDestination({ id: String(id), append: { tags: labels } }));
      await Promise.all(promises);

      setAddingLabels(false);
      onRowSelect([]);

      const labelCount = Object.keys(labels).length;

      toast({
        id: "bulk-add-destination-labels",
        title: `Added ${labelCount} ${pluralize("label", labelCount)} to ${selectedRows.length} ${pluralize(
          "destination",
          selectedRows.length,
        )}`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "bulk-add-destination-labels",
        title: "Couldn't update labels for selected destinations",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const bulkDeleteDestinations = async () => {
    if (userCanDelete) {
      await bulkDelete({ ids: selectedRows.map(String) });
      onRowSelect([]);
    }
  };

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        sortDirection: orderBy?.name,
        onClick: () => onSort(SortKeys.Name),
        cell: ({ name, tags, type }) => {
          const definition = definitions?.find((definition) => definition.type === type);

          return (
            <Row align="center">
              <TextWithTooltip sx={{ fontWeight: "semi", maxWidth: "350px" }} text={name || definition?.name}>
                {name || definition?.name}
              </TextWithTooltip>
              <LabelsCell labels={tags} />
            </Row>
          );
        },
      },
      {
        name: "Type",
        sortDirection: orderBy?.type,
        onClick: () => onSort(SortKeys.Type),
        cell: ({ type }) => {
          const definition = definitions?.find((definition) => definition.type === type);
          return (
            <Row align="center">
              <Image
                alt={definition?.name}
                src={definition?.icon}
                sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", mr: 2, flexShrink: 0 }}
              />
              <TextWithTooltip disabled={!definition?.name} sx={{ fontWeight: "semi" }} text={definition?.name ?? ""}>
                {definition?.name}
              </TextWithTooltip>
            </Row>
          );
        },
      },
      {
        ...LastUpdatedColumn,
        sortDirection: orderBy?.updated_at,
        onClick: () => onSort(SortKeys.UpdatedAt),
      },
    ],
    [definitions, orderBy],
  );

  const actions = [
    userCanUpdate ? { label: "Add labels", onClick: () => setAddingLabels(true) } : null,
    userCanDelete
      ? {
          label: "Delete",
          onClick: () => setConfirmingDelete(true),
          sx: { color: "red", ":hover::not(:disabled)": { backgroundColor: "reds.0" } },
        }
      : null,
  ].filter(isPresent);

  const placeholder = useMemo(
    () => ({
      image: searchPlaceholder,
      title: "No destinations found",
      error: "Destinations failed to load, please try again.",
    }),
    [],
  );

  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText = destinationOverageText + " To create a destination, upgrade your plan.";

  const onRowClick = useCallback(({ id }, event) => openUrl(`/destinations/${id}`, navigate, event), [navigate]);

  if (destinationsLoading) {
    return <PageSpinner />;
  }

  return (
    <>
      <Column mb={3} width="100%">
        <Row align="center" justify="space-between" mb={8}>
          <Row align="center" gap={2}>
            <Heading size="xl">Destinations</Heading>
            <Views
              deletePermissionResource="destination"
              value={selectedView}
              views={views}
              onChange={selectView}
              onDelete={deleteView}
            />
            {viewNotSaved &&
              (selectedView === "Default view" ? (
                <Button
                  onClick={() => {
                    setCreateViewModalOpen(true);
                  }}
                >
                  Save as
                </Button>
              ) : (
                <DropdownButton
                  loading={updatingView}
                  options={[
                    {
                      label: "Save as",
                      onClick: () => {
                        setCreateViewModalOpen(true);
                      },
                    },
                    {
                      label: "Reset changes",
                      onClick: () => {
                        resetViewFilters();
                      },
                    },
                  ]}
                  onClick={updateCurrentView}
                >
                  Save
                </DropdownButton>
              ))}
          </Row>
          <ButtonGroup>
            {actions.length > 0 && selectedRows.length > 0 && (
              <Row align="center" gap={2}>
                <Text as="span" sx={{ color: "base.5" }}>{`${pluralize(
                  "destination",
                  selectedRows.length,
                  true,
                )} selected`}</Text>
                <Menu options={actions}>
                  <Button isLoading={loadingBulkDelete}>Select action</Button>
                </Menu>
              </Row>
            )}
            <PermissionedLinkButton
              href="/destinations/new"
              isDisabled={overageLockout}
              permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Create] }]}
              tooltip={overageLockout && overageText}
              variant="primary"
              onClick={() => {
                analytics.track("Add Destination Clicked");
              }}
            >
              Add destination
            </PermissionedLinkButton>
          </ButtonGroup>
        </Row>
        <Row flexWrap="nowrap" gap={2}>
          <SearchInput placeholder="Search destinations by name..." value={search ?? ""} onChange={setSearch} />
          <Filters
            data={allDestinationsWithDefinitionData}
            filterDefinitions={destinationFilterDefinitions}
            filters={filters}
            resourceType="destination"
            onChange={updateFilters}
          />
        </Row>
      </Column>

      <Table
        columns={columns}
        data={destinations}
        error={Boolean(destinationsError)}
        loading={isRefetching}
        placeholder={placeholder}
        selectedRows={selectedRows}
        onRowClick={onRowClick}
        onSelect={userCanDelete ? onRowSelect : undefined}
      />

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="destination"
        onClose={() => setConfirmingDelete(false)}
        onDelete={bulkDeleteDestinations}
      />

      <CreateViewModal
        isOpen={createViewModalOpen}
        loading={creatingView}
        onClose={() => setCreateViewModalOpen(false)}
        onSave={createView}
      />

      <EditLabels
        description="You can label destinations that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={addingLabels}
        loading={updatingDestination}
        saveLabel={`Apply to ${selectedRows.length} ${pluralize("destination", selectedRows.length)}`}
        title="Add labels"
        onClose={() => setAddingLabels(false)}
        onSave={bulkAddLabels}
      />
    </>
  );
};

const DestinationsPage: FC<Readonly<{ children: ReactNode }>> = ({ children }) => (
  <PermissionProvider permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Create] }]}>
    <Page fullWidth>{children}</Page>
  </PermissionProvider>
);

const Loader = () => {
  const { resources } = useUser();

  if (resources?.destination) {
    return (
      <DestinationsPage>
        <Destinations />
      </DestinationsPage>
    );
  }

  return (
    <DestinationsPage>
      <Heading mb={8} size="xl">
        Destinations
      </Heading>
      <Placeholder
        content={{
          image: destinationPlaceholder,
          title: "No destinations in this workspace",
          body: "A destination is where your data will be sent. Hightouch supports 100+ popular destinations, including CRMs like Salesforce, marketing automation tools like HubSpot, and ad platforms like Google. You can also build your own destination.",
          button: (
            <PermissionedLinkButton
              href="/destinations/new"
              permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Create] }]}
              variant="primary"
            >
              Add destination
            </PermissionedLinkButton>
          ),
        }}
      />
    </DestinationsPage>
  );
};

export default Loader;
