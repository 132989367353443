import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const TableIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
        opacity="0.4"
      />
      <path d="M6.909 9.65941V9.64941H10.3923C10.8946 9.64941 11.3025 9.99941 11.3025 10.4284C11.3025 10.8694 10.8946 11.2194 10.3923 11.2194H6.909C6.40672 11.2194 6 10.8694 6 10.4394C6 10.0094 6.40672 9.65941 6.909 9.65941Z" />
      <path d="M6.909 12.6594V12.6494H10.3923C10.8946 12.6494 11.3025 12.9994 11.3025 13.4284C11.3025 13.8694 10.8946 14.2194 10.3923 14.2194H6.909C6.40672 14.2194 6 13.8694 6 13.4394C6 13.0094 6.40672 12.6594 6.909 12.6594Z" />
      <path d="M13.659 9.65941V9.64941H17.1423C17.6446 9.64941 18.0525 9.99941 18.0525 10.4284C18.0525 10.8694 17.6446 11.2194 17.1423 11.2194H13.659C13.1567 11.2194 12.75 10.8694 12.75 10.4394C12.75 10.0094 13.1567 9.65941 13.659 9.65941Z" />
      <path d="M13.659 12.6594V12.6494H17.1423C17.6446 12.6494 18.0525 12.9994 18.0525 13.4284C18.0525 13.8694 17.6446 14.2194 17.1423 14.2194H13.659C13.1567 14.2194 12.75 13.8694 12.75 13.4394C12.75 13.0094 13.1567 12.6594 13.659 12.6594Z" />
      <path d="M6.909 15.6594V15.6494H10.3923C10.8946 15.6494 11.3025 15.9994 11.3025 16.4284C11.3025 16.8694 10.8946 17.2194 10.3923 17.2194H6.909C6.40672 17.2194 6 16.8694 6 16.4394C6 16.0094 6.40672 15.6594 6.909 15.6594Z" />
      <path d="M13.659 15.6594V15.6494H17.1423C17.6446 15.6494 18.0525 15.9994 18.0525 16.4284C18.0525 16.8694 17.6446 17.2194 17.1423 17.2194H13.659C13.1567 17.2194 12.75 16.8694 12.75 16.4394C12.75 16.0094 13.1567 15.6594 13.659 15.6594Z" />
      <rect height="1.5" rx="0.75" width="12" x="6" y="6" />
    </Icon>
  );
};
