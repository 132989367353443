import { keyframes } from "@emotion/react";

import { colors } from "../../../../design/colors";

export function pulsatingBorder(opts: { variant: "primary" | "secondary" }): string {
  const pulseAnimation = keyframes({
    from: { boxShadow: `0 0 0 0px ${opts.variant === "primary" ? colors.grass : colors.base[3]}` },
    to: { boxShadow: "0 0 0 20px rgba(0, 0, 0, 0)" },
  });

  return `${pulseAnimation} 2s infinite`;
}
