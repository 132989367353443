export enum FilterType {
  Status = "status",
  //Size = "size",
  CreatedBy = "created",
  Destination = "destination",
  Label = "label",
  Type = "type",
}

export enum FilterOperator {
  Contains = "_contains",
  Equals = "_eq",
  GreaterThan = "_gt",
  GreaterThanOrEqual = "_gte",
  LessThan = "_lt",
  LessThanOrEqual = "_lte",
  NotEquals = "_ne",
  Includes = "_in",
  Excludes = "_nin",
  IncludesKeys = "_has_keys_all",
  ExcludesKeys = "_not_has_keys_all",
}

export type FilterValue = any;

export type FilterDefinition<ResourceBoolExp> = {
  default?: boolean;
  property: string;
  value?: string;
  operatorOptions?: any;
  valueOptions?: (data: any) => any;
  props?: any;
  query: ({ value, operator }: Omit<Filter, "type">) => ResourceBoolExp | undefined;
};

export type Filter = {
  type: FilterType;
  operator: FilterOperator;
  value: FilterValue;
};

export type FilterDefinitions<ResourceBoolExp> = Partial<Record<FilterType, FilterDefinition<ResourceBoolExp>>>;
