import { FC } from "react";

import pluralize from "pluralize";

import { CreatableSelect, CreatableSelectProps } from "./creatable-select";
import { Option } from "./select";

export type Props = CreatableSelectProps & {
  onChange: (options: Option[]) => void;
};

export const CreatableMultiSelect: FC<Props> = ({ options = [], value, width, onChange, onCreateOption, placeholder }) => {
  return (
    <CreatableSelect
      formatCreateLabel={(label) => `Add ${label}`}
      isClearable={false}
      isMulti={true}
      noOptionsMessage={() => "Type to create a new value..."}
      options={options}
      placeholder={placeholder || "Add multiple values..."}
      tip={`${
        options.length ? `${options.length} selectable ${pluralize("value", options.length > 1)}. ` : ""
      }Type to create a new value.`}
      value={value}
      width={width}
      onChange={onChange}
      onCreateOption={onCreateOption}
    />
  );
};
