import { FC, useMemo } from "react";

import { LinkButton, SearchInput } from "@hightouchio/ui";
import { Image, Text } from "theme-ui";

import { Permission } from "src/components/permission";
import { PermissionProvider } from "src/contexts/permission-context";
import { ResourcePermissionGrant, SyncTemplatesBoolExp, useSyncTemplatesQuery } from "src/graphql";
import useQueryState from "src/hooks/use-query-state";
import { Row } from "src/ui/box";
import { PageSpinner } from "src/ui/loading";
import { Table } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useDestinations } from "src/utils/destinations";
import { useNavigate } from "src/utils/navigate";
import { SourceBadges, SourceIcon, useSources } from "src/utils/sources";
import { openUrl } from "src/utils/urls";

export const SyncTemplates: FC = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useQueryState("search");

  const hasuraFilters = useMemo(() => {
    const hasuraFilters: SyncTemplatesBoolExp = {};

    if (search) {
      hasuraFilters.name = { _ilike: `%${search}%` };
    }
    return hasuraFilters;
  }, [search]);

  const { data: syncTemplatesData, isLoading: syncTemplatesLoading } = useSyncTemplatesQuery(
    { filters: hasuraFilters },
    { keepPreviousData: true },
  );

  const syncTemplates = syncTemplatesData?.sync_templates;

  const {
    data: { definitions: destinationDefinitions },
    loading: destinationsLoading,
  } = useDestinations();

  const { data: sources, loading: sourcesLoading } = useSources();

  if (destinationsLoading || !destinationDefinitions || sourcesLoading || syncTemplatesLoading) {
    return <PageSpinner />;
  }

  const columns = [
    {
      name: "Name",
      key: "name",
      cell: (name) => <Text sx={{ fontWeight: "semi" }}>{name}</Text>,
    },
    {
      name: "Parent model",
      cell: ({ segment: { name, connection } }) => {
        const source = sources.find((source) => source.id === connection.id);

        return (
          <Row sx={{ alignItems: "center" }}>
            <SourceIcon source={source} sx={{ width: "18px", mr: 2, flexShrink: 0 }} />
            <Text sx={{ fontWeight: "semi" }}>{name}</Text>
            <SourceBadges source={source} />
          </Row>
        );
      },
    },
    {
      name: "Destination",
      cell: ({ destination: { name, type } }) => {
        const definition = destinationDefinitions.find((def) => def.type === type);

        return (
          <Row sx={{ alignItems: "center" }}>
            <Image alt={definition?.name} src={definition?.icon} sx={{ width: "18px", mr: 2, flexShrink: 0 }} />
            <Text sx={{ fontWeight: "semi" }}>{name}</Text>
          </Row>
        );
      },
    },
    LastUpdatedColumn,
  ];

  return (
    <PermissionProvider permissions={[{ resource: "sync_template", grants: [ResourcePermissionGrant.Create] }]}>
      <Row
        sx={{
          justifyContent: "space-between",
          mt: 2,
          mb: 3,
        }}
      >
        <SearchInput placeholder="Search by name..." value={search ?? ""} onChange={(event) => setSearch(event.target.value)} />
        <Permission>
          <LinkButton href="/audiences/setup/sync-templates/new" variant="primary">
            Add sync template
          </LinkButton>
        </Permission>
      </Row>
      <Table
        columns={columns}
        data={syncTemplates}
        error={false}
        placeholder={{
          title: "No sync templates",
          error: "Sync templates failed to load, please try again.",
        }}
        onRowClick={({ id }, event) => openUrl(`/audiences/setup/sync-templates/${id}`, navigate, event)}
      />
    </PermissionProvider>
  );
};
