import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const ChevronLeftIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M15.41 16.59L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.59Z" />
    </Icon>
  );
};
