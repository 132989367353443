import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const ResizeExpandIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M9.79 12.79L4 18.59V17C4 16.7348 3.89464 16.4804 3.70711 16.2929C3.51957 16.1054 3.26522 16 3 16C2.73478 16 2.48043 16.1054 2.29289 16.2929C2.10536 16.4804 2 16.7348 2 17V21C2.00158 21.1307 2.02876 21.2598 2.08 21.38C2.18147 21.6243 2.37565 21.8185 2.62 21.92C2.74022 21.9712 2.86932 21.9984 3 22H7C7.26522 22 7.51957 21.8946 7.70711 21.7071C7.89464 21.5196 8 21.2652 8 21C8 20.7348 7.89464 20.4804 7.70711 20.2929C7.51957 20.1054 7.26522 20 7 20H5.41L11.21 14.21C11.3983 14.0217 11.5041 13.7663 11.5041 13.5C11.5041 13.2337 11.3983 12.9783 11.21 12.79C11.0217 12.6017 10.7663 12.4959 10.5 12.4959C10.2337 12.4959 9.9783 12.6017 9.79 12.79V12.79ZM21.92 2.62C21.8185 2.37565 21.6243 2.18147 21.38 2.08C21.2598 2.02876 21.1307 2.00158 21 2H17C16.7348 2 16.4804 2.10536 16.2929 2.29289C16.1054 2.48043 16 2.73478 16 3C16 3.26522 16.1054 3.51957 16.2929 3.70711C16.4804 3.89464 16.7348 4 17 4H18.59L12.79 9.79C12.6963 9.88296 12.6219 9.99356 12.5711 10.1154C12.5203 10.2373 12.4942 10.368 12.4942 10.5C12.4942 10.632 12.5203 10.7627 12.5711 10.8846C12.6219 11.0064 12.6963 11.117 12.79 11.21C12.883 11.3037 12.9936 11.3781 13.1154 11.4289C13.2373 11.4797 13.368 11.5058 13.5 11.5058C13.632 11.5058 13.7627 11.4797 13.8846 11.4289C14.0064 11.3781 14.117 11.3037 14.21 11.21L20 5.41V7C20 7.26522 20.1054 7.51957 20.2929 7.70711C20.4804 7.89464 20.7348 8 21 8C21.2652 8 21.5196 7.89464 21.7071 7.70711C21.8946 7.51957 22 7.26522 22 7V3C21.9984 2.86932 21.9712 2.74022 21.92 2.62Z" />
    </Icon>
  );
};
