import CronParser, { CronExpression } from "cron-parser";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Schedule, ScheduleType } from "src/components/schedule/types";
import { useUser } from "src/contexts/user-context";

export const validCronExpression = (exp: string | undefined): CronExpression | false => {
  if (!exp) {
    return false;
  }

  try {
    return CronParser.parseExpression(exp, { utc: true });
  } catch (error) {
    return false;
  }
};

export const isScheduleBusinessTier = (schedule: Schedule | null): boolean => {
  const { appSubHourlySyncsEnabled } = useFlags();
  return (
    schedule?.type === ScheduleType.INTERVAL && schedule?.schedule?.interval?.unit === "minute" && !appSubHourlySyncsEnabled
  );
};

export const isScheduleComplete = (schedule: any): boolean => {
  const { workspace } = useUser();

  if (
    isScheduleBusinessTier(schedule) &&
    (!workspace?.organization || workspace?.organization?.plan?.sku !== "business_tier")
  ) {
    return false;
  }

  switch (schedule?.type) {
    case ScheduleType.MANUAL:
      return true;
    case ScheduleType.INTERVAL:
      return (
        schedule?.schedule?.interval?.quantity >= 1 &&
        Number.isInteger(schedule?.schedule?.interval?.quantity) &&
        schedule?.schedule?.interval?.unit
      );
    case ScheduleType.CRON:
      return Boolean(validCronExpression(schedule?.schedule?.expression));
    case ScheduleType.CUSTOM:
      return Array.isArray(schedule?.schedule?.expressions);
    case ScheduleType.DBT_CLOUD:
      return schedule?.schedule?.account?.id && schedule?.schedule?.job?.id;
    case ScheduleType.FIVETRAN:
      return schedule?.schedule?.triggers?.length > 0 && schedule?.schedule?.groupId;
    default:
      return false;
  }
};

export const getScheduleLabel = (type) => {
  switch (type) {
    case ScheduleType.INTERVAL:
      return "Interval";
    case ScheduleType.CUSTOM:
      return "Custom";
    case ScheduleType.CRON:
      return "Cron";
    case ScheduleType.DBT_CLOUD:
      return "dbt Cloud";
    default:
      return "Manual";
  }
};
