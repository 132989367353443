import { useEffect, useMemo, FC } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { Text } from "theme-ui";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  ReferencePropertyDefaultOperators,
  FilterableColumn,
  isColumnReference,
  ReferencedPropertyCondition,
  ReferencePropertyOperatorOptions,
} from "src/types/visual";
import { NewSelect } from "src/ui/new-select";

import { AttributeSelect } from "./attribute-select";
import { FilterProps, HStack, OperatorLabel, RemoveButton } from "./condition";
import { validateReferencePropertyFilter } from "./condition-validation";
import { Filter } from "./filter";
import { getColumnOptions } from "./property-filter";

export type ReferencePropertyFilterProps = FilterProps<ReferencedPropertyCondition> & {
  referenceColumns?: FilterableColumn[];
};

export const ReferencePropertyFilter: FC<Readonly<ReferencePropertyFilterProps>> = (props) => {
  const { appAudienceQueryBuilderValidation } = useFlags();
  const { columns, referenceColumns, condition, onChange, onRemove } = props;
  const filterId = useMemo<string>(uuidv4, []);

  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();

  const filterErrors = getErrors(filterId);
  const propertyError = filterErrors?.property;
  const valueFromColumnError = filterErrors?.valueFromColumn;

  useEffect(() => {
    if (appAudienceQueryBuilderValidation) {
      setFieldError(filterId, validateReferencePropertyFilter(condition));
    } else {
      removeErrors([filterId]);
    }

    return () => {
      removeErrors([filterId]);
    };
  }, [appAudienceQueryBuilderValidation, condition.property, condition.valueFromColumn, filterId]);

  const propertyOptions = getColumnOptions(columns ?? [], !isColumnReference(condition.property));
  // Use string for valueFromColumn instead of column reference
  const referenceOptions = getColumnOptions(referenceColumns ?? [], true);
  const operatorOptions = condition.propertyType ? ReferencePropertyOperatorOptions[condition.propertyType] : undefined;
  const operatorLabel = operatorOptions?.find((option) => option.value === condition.operator)?.label;

  return (
    <HStack gap={2} sx={{ alignItems: "flex-start" }}>
      <AttributeSelect
        error={propertyError}
        options={propertyOptions}
        placeholder="Select a property"
        value={condition.property}
        onChange={(value, { type }) => {
          onChange({
            propertyType: type,
            property: value,
            operator: ReferencePropertyDefaultOperators[type],
            valueFromColumn: null,
          });
        }}
      />
      {condition.property && (
        <Filter
          content={
            <HStack gap={2} sx={{ alignItems: "flex-start" }}>
              <NewSelect
                options={operatorOptions}
                placeholder="Filter on"
                sx={{ flex: "0 0 auto" }}
                value={condition.operator}
                width={200}
                onChange={(operator) => {
                  onChange({ operator });
                }}
              />
              <AttributeSelect
                error={valueFromColumnError}
                options={referenceOptions}
                placeholder="Select a parent property"
                value={condition.valueFromColumn}
                width={180}
                onChange={(valueFromColumn) => onChange({ valueFromColumn })}
              />
            </HStack>
          }
          error={valueFromColumnError}
          isPlaceholder={condition.valueFromColumn === null}
        >
          <OperatorLabel>{operatorLabel}</OperatorLabel>
          <Text
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              color: condition.valueFromColumn === null ? "base.5" : "",
            }}
          >
            {condition.valueFromColumn || "(Select a parent property)"}
          </Text>
        </Filter>
      )}
      <RemoveButton onRemove={onRemove} />
    </HStack>
  );
};
