import { isPresent } from "ts-extras";

import {
  EventCondition,
  FunnelCondition,
  NumberOfCondition,
  PropertyCondition,
  ReferencedPropertyCondition,
  SegmentSetCondition,
  TimestampOperator,
} from "src/types/visual";

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};
type ValidationResult<TCondition> = PartialRecord<keyof TCondition, string | null>;
type Validator<TCondition> = (condition: TCondition) => ValidationResult<TCondition>;

export const requiredErrorMessage = "This field is required";
export const numberErrorMessage = "This field must contain a number";

export const validatePropertyCondition: Validator<PropertyCondition> = ({
  property,
  propertyType,
  timeType,
  operator,
  value,
}) => {
  if (!property) {
    return {
      property: !property ? requiredErrorMessage : null,
    };
  }

  if (operator === "exists" || operator === "notexists") {
    return {};
  }

  if (propertyType === "timestamp") {
    // 'between' works differently than the rest of the value types
    switch (operator) {
      case TimestampOperator.Between:
        if (timeType === "absolute") {
          return {
            value:
              value === null || typeof value.after !== "string" || typeof value.before !== "string"
                ? "Please enter a valid date range"
                : null,
          };
        } else if (timeType === "relative") {
          return {
            value:
              isNaN(value?.after?.quantity) ||
              !isPresent(value?.after?.quantity) ||
              isNaN(value?.before?.quantity) ||
              !isPresent(value?.before?.quantity)
                ? "Please enter a valid date range"
                : null,
          };
        }
        break;
      case TimestampOperator.Before:
      case TimestampOperator.After:
      case TimestampOperator.Within:
      case TimestampOperator.NotWithin:
        if (timeType === "absolute") {
          return {
            value: typeof value !== "string" ? numberErrorMessage : null,
          };
        } else if (timeType === "relative") {
          return {
            value: value === null || isNaN(value.quantity) || value.quantity === null ? numberErrorMessage : null,
          };
        }
        break;
      case TimestampOperator.Exists:
      case TimestampOperator.DoesNotExist:
      case TimestampOperator.Anniversary:
      default:
        break;
    }

    // TODO: validate absolute dates
  }

  if (operator == null || value == null) {
    return {
      property: null,
      operator: operator == null ? requiredErrorMessage : null,
      value: null,
      // TODO: One day - should we disallow a `null` value (i.e. any value)?
      // value: value == null ? requiredErrorMessage : null,
    };
  }

  return {};
};

export const validateReferencePropertyFilter: Validator<ReferencedPropertyCondition> = ({ property, valueFromColumn }) => {
  if (!property) {
    return {
      property: property == null ? requiredErrorMessage : null,
    };
  }

  if (!valueFromColumn) {
    return {
      property: null,
      valueFromColumn: valueFromColumn == null ? requiredErrorMessage : null,
    };
  }

  return {};
};

export const validateEventCondition: Validator<EventCondition> = ({ eventModelId, value }) => ({
  eventModelId: eventModelId == null ? requiredErrorMessage : null,
  value: value == null ? requiredErrorMessage : null,
});

export const validateNumberOfCondition: Validator<NumberOfCondition> = ({ relationshipId, value }) => ({
  relationshipId: relationshipId == null ? requiredErrorMessage : null,
  value: value == null ? requiredErrorMessage : null,
});

export const validateFunnelCondition: Validator<FunnelCondition> = ({ eventModelId }) => {
  return { eventModelId: eventModelId != null ? null : requiredErrorMessage };
};

export const validateSegmentSetCondition: Validator<SegmentSetCondition> = ({ modelId, includes }) => ({
  modelId: modelId == null ? requiredErrorMessage : null,
  includes: typeof includes !== "boolean" ? requiredErrorMessage : null,
});
