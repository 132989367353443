import { FC } from "react";

import { Text } from "theme-ui";

import * as analytics from "src/lib/analytics";
import { Column } from "src/ui/box";
import { Selectable } from "src/ui/selectable";

type Props = {
  currentCategory: string;
  query: string;
  refine: (value: string) => void;
  clear: () => void;
  items: any;
};

export const CategoryMenu: FC<Props> = ({ currentCategory, query, clear, refine, items }) => {
  return (
    <Column>
      <Selectable
        selected={currentCategory === "All"}
        sx={{
          px: 2,
          py: 1,
          borderRadius: 1,
          fontSize: 1,
          fontWeight: "bold",
          cursor: "pointer",
          color: currentCategory === "All" ? "forest" : "base.5",
          bg: currentCategory === "All" ? "avocado" : undefined,
          border: "none",
          ":hover": {
            bg: currentCategory === "All" ? undefined : "gray.100",
          },
        }}
        onSelect={() => {
          clear();
          analytics.track("Destination Catalog Category Clicked", { current_category: "All" });
        }}
      >
        All
      </Selectable>
      {items?.map((filter) => {
        const selected = currentCategory === filter.label;
        return (
          <Selectable
            key={filter.label}
            selected={selected}
            sx={{
              px: 2,
              py: 1,
              borderRadius: 1,
              fontSize: 1,
              fontWeight: "bold",
              cursor: "pointer",
              color: selected ? "forest" : "base.5",
              bg: selected ? "avocado" : undefined,
              border: "none",
              flexDirection: "row",
              gap: 1,
              ":hover": {
                bg: selected ? undefined : "gray.100",
              },
            }}
            onSelect={() => {
              refine(filter.value);
              analytics.track("Destination Catalog Category Clicked", { current_category: filter.label });
            }}
          >
            {filter.label}
            {query !== "" && <Text sx={{ fontWeight: "semi" }}>{`(${filter.count})`}</Text>}
          </Selectable>
        );
      })}
    </Column>
  );
};
