import { Condition, ConditionType, AndCondition, OrCondition } from "src/types/visual";

export function toOrConditions(conditions: Condition[] | undefined): OrCondition[] | undefined {
  if (!conditions) {
    return undefined;
  }

  return conditions.map((condition) => {
    if (condition.type !== ConditionType.Or) {
      return {
        type: ConditionType.Or,
        conditions: [condition],
      };
    }
    return condition;
  });
}

export function toSingleCondition(
  conditions: AndCondition[] | OrCondition[] | Condition[] | undefined,
): AndCondition<AndCondition | OrCondition>[] | OrCondition<AndCondition | OrCondition>[] | undefined {
  if (!conditions) {
    return undefined;
  }

  // Tech debt: conditions may only have one condition at the top level.
  // See https://carryinternal.slack.com/archives/C024T56QQLW/p1669910261515869
  if (Array.isArray(conditions)) {
    if (conditions.length === 0) {
      return [];
    }

    if (conditions.length > 1) {
      return [{ type: ConditionType.And, conditions: toOrConditions(conditions) ?? [] }];
    }

    /* Start: new format. One condition at the top level */

    const condition = conditions?.[0];
    const hasTopLevelAndOrCondition = condition?.type === ConditionType.And || condition?.type === ConditionType.Or;
    const allImmediateChildrenAreOrConditions =
      hasTopLevelAndOrCondition && condition.conditions.every(({ type }) => type === ConditionType.Or);

    // Verify top level condition is an and/or
    // If not, wrap it with `AND` condition
    if (
      // Has not been formatted yet
      !hasTopLevelAndOrCondition ||
      // top level condition is And/Or but its immediate children are not OR conditions
      // (base assumption in query builder is that top level conditions were ORed together)
      (hasTopLevelAndOrCondition && !allImmediateChildrenAreOrConditions)
    ) {
      return [{ type: ConditionType.And, conditions: toOrConditions(conditions) ?? [] }];
    }

    // There is only one AND or OR condition that has been formatted correctly.
    // Its immediate children (if there are any) are OR conditions
    return conditions as AndCondition<AndCondition>[];

    /* End: new format */
  }

  return conditions;
}
