import type { FormkitBoolean } from "./booleans";
import type { BaseFormkitNode, FormkitNode, NodeType } from "./nodes";

export type FormkitModifier = BaseFormkitNode & {
  type: NodeType.Modifier;
  modifier: ModifierType;
  condition?: FormkitBoolean;
  keys?: string[];
  children: FormkitNode[];
};

export enum ModifierType {
  Show = "show",
  Reset = "reset",
}
