import { FC } from "react";

import { Outlet } from "react-router-dom";

import { Nav } from "src/components/layout/nav";
import { useUser } from "src/contexts/user-context";
import { Column } from "src/ui/box";

export const Layout: FC = () => {
  const { user } = useUser();

  return (
    <>
      {user && <Nav />}

      <Column
        sx={{
          flex: 1,
        }}
      >
        <Outlet />
      </Column>
    </>
  );
};
