import { FC, useEffect, useMemo, useState, MouseEventHandler, MouseEvent as ReactMouseEvent } from "react";

import { Box as HightouchUiBox, Tooltip as HightouchUiTooltip, Switch } from "@hightouchio/ui";
import { capitalize } from "lodash";
import { Portal } from "react-portal";
import { Bar, BarChart, Cell, LabelList, Pie, PieChart, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { Box, Text } from "theme-ui";

import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { XIcon } from "src/ui/icons";
import { Table, TableColumn } from "src/ui/table";
import { TextWithTooltip } from "src/ui/text";
import { abbreviateNumber } from "src/utils/numbers";

const axisWidth = 120;
const horizontalPadding = 4;

const purpleColor = "#E7ECFE";
const greenColor = "#E1F9FE";
const salmonColor = "#FFE8E3";
const fuschiaColor = "#F6E3FF";
const blueColor = "#E3FAFF";
const yellowColor = "#FEFCE7";
const redColor = "#FEE7E7";
const orangeColor = "#fef3e7";
const tealColor = "#e7fef7";
const grayColor = "#F2F2F2";
const greenerColor = "#CCFFCC";

export const colors = [
  purpleColor,
  greenColor,
  salmonColor,
  fuschiaColor,
  blueColor,
  yellowColor,
  redColor,
  orangeColor,
  tealColor,
  grayColor,
  greenerColor,
];
export const hoverColors = [
  "#728ff9",
  "#6de1fa",
  "#ff856a",
  "#cf6aff",
  "#6ae4ff",
  "#f9ed72",
  "#f97272",
  "#f9b872",
  "#72f9d0",
  "#b5b5b5",
  "#00e500",
];

const getVisibleValue = (value: unknown) => {
  if (value === null) {
    return "null";
  }

  if (value === "") {
    return "<empty string>";
  }

  return value;
};

const valueFormatter = (value: number | string, formatKey: "percentage" | "count"): string => {
  if (formatKey === "percentage" && typeof value === "number") {
    return `${(value * 100).toFixed(1)}%`;
  } else if (typeof value === "number") {
    return abbreviateNumber(value);
  }

  if (value.length > 11) {
    return value.slice(0, 11) + "...";
  }

  return value;
};

type Props = {
  chartType: "bar" | "pie" | "table";
  color?: string;
  data: { value: string; count: number; percentage?: number }[];
  graphName: string;
  graphWidth: number;
  hoverColor?: string;
  isActive?: boolean;
  isAnimationActive?: boolean;
  secondaryAxisLabel?: string;
  onFilterChange?: () => void;
  onMouseEnterCell?: MouseEventHandler<HTMLDivElement>;
  onMouseLeaveCell?: MouseEventHandler<HTMLDivElement>;
  onRemoveGraph: (graphName: string) => void;
};

export const BreakdownGraph: FC<Props> = ({
  chartType,
  color = colors[0],
  data,
  graphName,
  graphWidth: _graphWidth,
  hoverColor = hoverColors[0],
  isActive,
  isAnimationActive,
  secondaryAxisLabel,
  onMouseEnterCell,
  onMouseLeaveCell,
  onRemoveGraph,
}) => {
  const [animate, setAnimate] = useState(true);
  const [activeCell, setActiveCell] = useState<number | null>(null);
  const [showAllData, setShowAllData] = useState(false);
  const [dataFormat, setDataFormat] = useState<"count" | "percentage">("count");
  const [layout, setLayout] = useState<"vertical" | "horizontal">("vertical");

  const formattedData = useMemo(() => data.map(({ value, ...rest }) => ({ ...rest, value: getVisibleValue(value) })), [data]);

  const filteredData = formattedData.filter(({ value }) => showAllData || value !== "Other");
  const hasOtherColumn = useMemo(() => formattedData.some(({ value }) => value === "Other"), [data]);

  const graphWidth = _graphWidth - horizontalPadding * 4 * 2; // Remove horizontal padding of <li> elements

  const enterCell = (event: ReactMouseEvent<HTMLDivElement>, cellIndex: number) => {
    setAnimate(false);
    setActiveCell(cellIndex);
    onMouseEnterCell?.(event);
  };

  const leaveCell = (event: ReactMouseEvent<HTMLDivElement>) => {
    setActiveCell(null);
    onMouseEnterCell?.(event);
  };

  const valueAxisName = (
    <>
      {capitalize(graphName)}
      {!showAllData && chartType !== "table" && hasOtherColumn && (
        <Text sx={{ display: "inline-block", ml: 1, color: "base.5" }}>(top 10)</Text>
      )}
    </>
  );

  const secondaryAxisName = (
    <>
      {capitalize(secondaryAxisLabel)}
      <Text sx={{ display: "inline-block", ml: 1, color: "base.5" }}>
        {dataFormat === "percentage" ? " (percentage of total)" : ""}
      </Text>
    </>
  );

  const columns: TableColumn[] = [
    {
      name: capitalize(graphName),
      cell: ({ value }) => value,
    },
    {
      name: "Count",
      cell: ({ count }) => count,
    },
  ];

  const hasFilters = hasOtherColumn || chartType === "bar";
  const showSecondaryAxisName = chartType !== "bar" || layout === "vertical";

  return (
    <Column
      as="li"
      sx={{
        mx: horizontalPadding,
        width: chartType === "table" ? "100%" : graphWidth,
        ":not(:last-child)": { mb: 8 },
      }}
      onMouseLeave={onMouseLeaveCell}
    >
      <Column>
        {chartType !== "bar" && (
          <Row sx={{ alignItems: "center", justifyContent: "space-between", width: "100%", mb: 2 }}>
            {chartType === "pie" && (
              <TextWithTooltip
                content={<Text sx={{ whiteSpace: "nowrap" }}>{showSecondaryAxisName ? valueAxisName : secondaryAxisName}</Text>}
                sx={{
                  pr: 2,
                  fontWeight: 500,
                }}
              >
                {showSecondaryAxisName ? valueAxisName : secondaryAxisName}
              </TextWithTooltip>
            )}
            <Button aria-label="Remove overlap" sx={{ ml: "auto" }} variant="plain" onClick={() => onRemoveGraph(graphName)}>
              <XIcon color="base.4" size={16} />
            </Button>
          </Row>
        )}
        {chartType === "pie" && (
          <PieChart key={`graphName-${showAllData.toString()}-pie`} height={230} width={graphWidth}>
            <Pie
              animationBegin={0}
              animationDuration={600}
              data={filteredData}
              dataKey="count"
              fill={color}
              isAnimationActive={isAnimationActive || animate}
              nameKey="value"
              onMouseEnter={enterCell}
              onMouseLeave={leaveCell}
            >
              {filteredData.map((_, cellIndex) => (
                <Cell
                  key={`cell-${cellIndex}`}
                  fill={
                    isActive && activeCell === cellIndex
                      ? hoverColors[cellIndex % hoverColors.length]
                      : colors[cellIndex % colors.length]
                  }
                />
              ))}
              <LabelList
                dataKey="value"
                fill="#6d6d6d"
                formatter={(value) => valueFormatter(value, "count")}
                position="outside"
                stroke="transparent"
              />
            </Pie>
            <Tooltip content={<TooltipContent />} isAnimationActive={false} />
          </PieChart>
        )}
        {chartType === "bar" && (
          <>
            <Row sx={{ alignItems: "center", justifyContent: "space-between", width: "100%", mb: 2 }}>
              <TextWithTooltip
                content={<Text sx={{ whiteSpace: "nowrap" }}>{showSecondaryAxisName ? valueAxisName : secondaryAxisName}</Text>}
                sx={{
                  pr: 2,
                  fontWeight: 500,
                  textAlign: "right",
                  width: `${axisWidth}px`,
                }}
              >
                {showSecondaryAxisName ? valueAxisName : secondaryAxisName}
              </TextWithTooltip>
              <Button aria-label="Remove overlap" variant="plain" onClick={() => onRemoveGraph(graphName)}>
                <XIcon color="base.4" size={16} />
              </Button>
            </Row>

            <BarChart
              key={`graphName-${showAllData.toString()}-bar`}
              data={filteredData}
              height={230}
              layout={layout}
              margin={{ right: 50 }}
              width={graphWidth}
            >
              {layout === "vertical" && (
                <>
                  <XAxis
                    allowDecimals={dataFormat === "percentage"}
                    dataKey={dataFormat}
                    tickFormatter={(value) => valueFormatter(value, dataFormat)}
                    type="number"
                  />
                  <YAxis
                    dataKey="value"
                    interval={0}
                    label={{
                      textOverflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    offset={100}
                    tickFormatter={(value) => valueFormatter(value, "count")}
                    type="category"
                    width={axisWidth}
                  />
                </>
              )}
              {layout === "horizontal" && (
                <>
                  <XAxis
                    angle={-40}
                    dataKey="value"
                    height={70}
                    interval={0}
                    textAnchor="end"
                    tickFormatter={(value) => valueFormatter(value, "count")}
                  />
                  <YAxis
                    allowDecimals={dataFormat === "percentage"}
                    dataKey={dataFormat}
                    tickFormatter={(value) => valueFormatter(value, dataFormat)}
                    width={axisWidth}
                  />
                </>
              )}
              <Tooltip content={<TooltipContent />} cursor={{ fill: "transparent" }} isAnimationActive={false} />
              <Bar
                dataKey={dataFormat}
                fill={color}
                isAnimationActive={isAnimationActive || animate}
                onMouseEnter={enterCell}
                onMouseLeave={leaveCell}
              >
                {filteredData.map((_, cellIndex) => (
                  <Cell key={`cell-${cellIndex}`} fill={isActive && activeCell === cellIndex ? hoverColor : color} />
                ))}
              </Bar>
            </BarChart>
            <Row sx={{ ml: `${axisWidth}px`, justifyContent: "center" }}>
              <Text
                sx={{
                  mb: 2,
                  fontWeight: 500,
                  textAlign: "center",
                  overflowWrap: "break-word",
                }}
              >
                {layout === "vertical" ? secondaryAxisName : valueAxisName}
              </Text>
            </Row>
          </>
        )}
        {chartType === "table" && (
          <Row sx={{ mb: 8 }}>
            <Table columns={columns} data={data} />
          </Row>
        )}
      </Column>
      {chartType !== "table" && hasFilters && (
        <Column sx={{ bg: "white" }}>
          <Row
            sx={{
              justifyContent: "space-between",
              pt: 4,
              mt: -2,
              ...(graphWidth < 450
                ? {
                    flexDirection: "column",
                  }
                : {}),
            }}
          >
            {hasOtherColumn && (
              <HightouchUiBox alignItems="center" display="flex" justifyContent="space-between">
                <HightouchUiTooltip message="Display the number of values that aren't in the top 10">
                  <Text
                    sx={{
                      textTransform: "uppercase",
                      fontSize: "10px",
                      color: "base.4",
                      fontWeight: "bold",
                    }}
                  >
                    Show all data
                  </Text>
                </HightouchUiTooltip>

                <Switch
                  isChecked={showAllData}
                  onChange={(value) => {
                    setAnimate(true);
                    setShowAllData(value);
                  }}
                />
              </HightouchUiBox>
            )}
            {chartType === "bar" && (
              <HightouchUiBox alignItems="center" display="flex" justifyContent="space-between" mt={2}>
                <Text
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "10px",
                    color: "base.4",
                    fontWeight: "bold",
                  }}
                >
                  Switch axes
                </Text>

                <Switch
                  isChecked={layout === "horizontal"}
                  onChange={() => {
                    setAnimate(true);
                    setLayout((prevValue) => (prevValue === "horizontal" ? "vertical" : "horizontal"));
                  }}
                />
              </HightouchUiBox>
            )}
            {chartType === "bar" && (
              <HightouchUiBox alignItems="center" display="flex" justifyContent="space-between" mt={2}>
                <Text
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "10px",
                    color: "base.4",
                    fontWeight: "bold",
                  }}
                >
                  Percentage
                </Text>

                <Switch
                  isChecked={dataFormat === "percentage"}
                  onChange={() => {
                    setAnimate(true);
                    setDataFormat((prevValue) => (prevValue === "count" ? "percentage" : "count"));
                  }}
                />
              </HightouchUiBox>
            )}
          </Row>
        </Column>
      )}
    </Column>
  );
};

const TooltipContent = ({ label, payload }: TooltipProps<number, string>) => {
  const [position, setPosition] = useState<{ x: number | null; y: number | null }>({ x: null, y: null });

  useEffect(() => {
    const handleWindowMouseMove = (event: MouseEvent) => {
      if (!payload?.[0]) {
        return null;
      }

      return setPosition({
        x: event.pageX,
        y: event.pageY,
      });
    };
    window.addEventListener("mousemove", handleWindowMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleWindowMouseMove);
    };
  }, [payload?.length]);

  if (!payload?.[0]) {
    return null;
  }

  const {
    payload: { count, percentage, value },
  } = payload[0];

  return (
    <Portal>
      <Box
        sx={{
          position: "absolute",
          top: `${position.y === null ? 0 : position.y + 20}px`,
          left: `${position.x === null ? 0 : position.x + 20}px`,
          mr: 3,
          display: position.x === null ? "none" : "visible",
          zIndex: 2000,
          bg: "white",
          p: 4,
          border: "small",
          borderRadius: 2,
        }}
      >
        <Text sx={{ fontWeight: 500, mb: 1 }}>{label ?? value}</Text>
        <Text>
          Count: {count} or {(percentage * 100).toFixed(2)}%
        </Text>
      </Box>
    </Portal>
  );
};
