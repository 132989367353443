export const INTEGRATION_FIELDS = `
name
slug
status
shortPitch
longPitch
features
releasedAt
updatedAt
categories {
  name
  type
  shortDescription
}
icon {
  asset {
    url
  }
}
`;

export const PARTNER_FIELDS = `
name
slug
icon {
  asset {
    url
  }
}
`;

export const SAMPLE_DATA_SOURCE_FIELDS = `
name
slug
description
shortDescription
releasedAt
updatedAt
sampleModels {
  name
  sql
}
categories {
  name
  type
  shortDescription
}
icon {
  asset {
    url
  }
}
`;

export const SOURCE_TYPE_TO_CMS_SLUG = {
  googlesheets: "google-sheets-source",
  bigquery: "google-bigquery",
  redshift: "amazon-redshift",
  athena: "amazon-athena",
  mssql: "sqlserver",
  postgres: "postgresql",
  airtable: "airtable-source",
  mixpanel: "mixpanel-source",
  sftp: "sftp-source",
  s3: "s3-source",
  mongodb: "mongodb-source",
};

export const DESTINATION_TYPE_TO_CMS_SLUG = {
  webhookV2: "webhook-v2",
  activeCampaign: "active-campaign",
  clientSuccess: "client-success",
  facebookOffline: "facebook-offline",
  facebookConversions: "facebook-conversions",
  googleAnalytics: "google-analytics",
  googlesheets: "google-sheets",
  googleCampaignManager: "google-campaign-manager",
  facebookAdCatalog: "facebook-catalog",
  googleSheetsSA: "google-sheets-sa",
  salesforceSandbox: "salesforce-sandbox",
  brazeCohorts: "braze-cohorts",
  pardotSandbox: "pardot-sandbox",
  sfmcFileDrop: "sfmc-file-drop",
  microsoftTeams: "microsoft-teams",
  amazonAds: "amazon-ads",
  awsLambda: "aws-lambda",
  googlePubSub: "google-pubsub",
  googleDrive: "google-drive",
  googleCloudFunctions: "google-cloud-functions",
  campaignMonitor: "campaign-monitor",
  adobeTarget: "adobe-target",
  apacheKafka: "apache-kafka",
  azureFunctions: "azure-functions",
  redditAds: "reddit-ads",
};

export const DESTINATION_TYPE_TO_DOCS_SLUG = {
  webhookV2: "webhook-v2",
  activeCampaign: "active-campaign",
  clientSuccess: "client-success",
  facebookOffline: "facebook-offline",
  facebookConversions: "facebook-conversions",
  googleAnalytics: "google-analytics",
  googlesheets: "google-sheets",
  googleCampaignManager: "google-campaign-manager",
  facebookAdCatalog: "facebook-catalog",
  googleSheetsSA: "google-sheets",
  salesforceSandbox: "salesforce",
  brazeCohorts: "braze",
  pardotSandbox: "pardot",
  sfmcFileDrop: "sfmc-file-drop",
  microsoftTeams: "microsoft-teams",
  amazonAds: "amazon-ads",
  awsLambda: "aws-lambda",
  googlePubSub: "google-pubsub",
  googleDrive: "google-drive",
  googleCloudFunctions: "google-cloud-functions",
  campaignMonitor: "campaign-monitor",
  adobeTarget: "adobe-target",
  apacheKafka: "apache-kafka",
  azureFunctions: "azure-functions",
  redditAds: "reddit-ads",
};
