import { FC, ReactNode, useRef } from "react";

import { Label as ThemedLabel, Text, Box, Flex, ThemeUIStyleObject } from "theme-ui";

import { ReloadButton } from "../button";
import { Tooltip } from "../tooltip";

export type Props = {
  children: ReactNode;
  size?: "small" | "large";
  description?: string | ReactNode;
  htmlFor?: string;
  required?: boolean;
  optional?: boolean;
  help?: string | ReactNode;
  reload?: () => void;
  loading?: boolean;
  sx?: ThemeUIStyleObject;
  inline?: boolean;
  labelComponent?: (children: ReactNode) => ReactNode;
};

export type LabelProps = Props;

export const Label: FC<Readonly<LabelProps>> = ({
  size,
  children,
  description,
  htmlFor,
  reload,
  loading = false,
  optional = false,
  help,
  required = false,
  sx,
  inline = false,
  labelComponent,
}) => {
  const container = useRef<HTMLDivElement>(null);

  return (
    <Box ref={container} sx={{ mb: size === "large" ? 6 : inline ? 0 : 2 }}>
      <Flex sx={{ alignItems: "center" }}>
        {labelComponent ? (
          labelComponent(children)
        ) : (
          <ThemedLabel htmlFor={htmlFor} sx={sx} variant={inline ? "inlineLabel" : size === "large" ? "labelLarge" : "label"}>
            {children}
          </ThemedLabel>
        )}
        {required && <Text sx={{ color: "red", ml: 2 }}>*</Text>}
        {optional && <Text sx={{ color: "base.4", fontSize: 1, ml: 2 }}>(optional)</Text>}
        {help && <Tooltip sx={{ ml: 2 }} text={help} />}
        {reload && <ReloadButton loading={loading} sx={{ ml: 2 }} onClick={reload} />}
      </Flex>
      {description && (
        <Text sx={{ fontSize: size === "large" ? 1 : 0, color: "base.5", mt: size === "large" ? 2 : 1, pb: 2 }}>
          {description}
        </Text>
      )}
    </Box>
  );
};
