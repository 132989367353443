import { useEffect, useMemo, useState } from "react";

import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { Box, Button, ButtonGroup, Link, Paragraph, Row, Text, SearchInput } from "@hightouchio/ui";
import pluralize from "pluralize";
import { useParams } from "react-router-dom";

import { SyncsCell } from "src/components/syncs/syncs-cell";
import {
  AudiencesForPriorityListsQuery,
  SegmentsBoolExp,
  SegmentsOrderBy,
  useAudiencesForPriorityListsQuery,
} from "src/graphql";
import { Modal } from "src/ui/modal";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useRowSelect } from "src/ui/table/use-row-select";
import { TextWithTooltip } from "src/ui/text";
import { useDestinations } from "src/utils/destinations";
import { abbreviateNumber } from "src/utils/numbers";

enum SortKeys {
  Name = "name",
  NumSyncs = "syncs_aggregate.count",
  UpdatedAt = "updated_at",
}

export const AudienceSelector = ({ parentModelId, isOpen, onClose, selectedData, onSubmit }) => {
  const { priority_list_id } = useParams();
  const [search, setSearch] = useState("");
  const { selectedRows, onRowSelect } = useRowSelect();
  const [selectedAudiences, setSelectedAudiences] = useState<AudiencesForPriorityListsQuery["segments"][0][]>([]);
  const { limit, offset, orderBy, page, setPage, onSort } = useTableConfig<SegmentsOrderBy>({
    defaultSortKey: "updated_at",
    limit: 10,
    sortOptions: Object.values(SortKeys),
  });

  const filters: SegmentsBoolExp = useMemo(() => {
    const hasuraFilters: SegmentsBoolExp = {
      query_type: { _eq: "visual" },
      visual_query_parent_id: { _eq: parentModelId },
    };

    if (search) {
      hasuraFilters.name = { _ilike: `%${search}%` };
    }

    return hasuraFilters;
  }, [priority_list_id, parentModelId, search]);

  const destinationsQueries = useDestinations();

  const audiencesForPriorityListsQuery = useAudiencesForPriorityListsQuery(
    {
      filters,
      limit,
      offset,
      orderBy,
    },
    {
      enabled: Boolean(parentModelId),
    },
  );

  const audiences = audiencesForPriorityListsQuery.data?.segments ?? [];
  const audiencesCount = audiencesForPriorityListsQuery.data?.segments_aggregate?.aggregate?.count ?? 0;

  const isRowDisabled = (data: AudiencesForPriorityListsQuery["segments"][0]) => {
    const membership = data.priority_list_memberships?.[0];

    return membership ? membership.priority_list.id != priority_list_id : false;
  };

  const submit = () => {
    onSubmit(selectedAudiences);
  };

  useEffect(() => {
    onRowSelect(selectedData.map(({ id }) => id));
  }, [selectedData]);

  useEffect(() => {
    // save all audience data (including audiences not on current page)
    if (audiences.length > 0) {
      setSelectedAudiences((previousAudiences) => {
        // remove audiences that are not selected
        const newAudiences = previousAudiences.filter(({ id }) => selectedRows.includes(id));

        // add in new audiences that were selected
        selectedRows.forEach((id) => {
          if (!newAudiences.find((audience) => audience?.id === id)) {
            const audience = audiences.find((audience) => audience.id === id);

            if (audience) {
              newAudiences.push(audience);
            }
          }
        });

        return newAudiences;
      });
    }
  }, [audiences, selectedRows]);

  const columns: TableColumn[] = useMemo(
    () => [
      {
        name: "Name",
        sortDirection: orderBy?.name,
        onClick: () => onSort(SortKeys.Name),
        cell: ({ name }) => (
          <Row align="center">
            <TextWithTooltip sx={{ maxWidth: "350px" }} text={name}>
              {name}
            </TextWithTooltip>
          </Row>
        ),
      },
      {
        name: "Priority list",
        cell: ({ priority_list_memberships }: AudiencesForPriorityListsQuery["segments"][0]) => {
          const membership = priority_list_memberships?.[0];
          const showExternalLink = membership && membership?.priority_list.id != priority_list_id;

          return (
            <Row align="center" onClick={(event) => event.stopPropagation()}>
              {showExternalLink ? (
                <>
                  <Box mr={1}>
                    <Text>#{membership.rank + 1} in</Text>
                  </Box>
                  <Link href={`/audiences/priority-lists/${membership.priority_list.id}`}>
                    {membership.priority_list.name}
                    <Box display="inline-block" height="16px" ml={1} width="16px">
                      <ArrowTopRightOnSquareIcon />
                    </Box>
                  </Link>
                </>
              ) : (
                <Text>-</Text>
              )}
            </Row>
          );
        },
      },
      {
        name: "Size",
        key: "query_runs.[0].size",
        max: "max-content",
        cell: (size) =>
          size ? (
            <Text>{abbreviateNumber(size)}</Text>
          ) : (
            <Box color="gray.00">
              <Text>-</Text>
            </Box>
          ),
      },
      {
        name: "Syncs",
        sortDirection: orderBy?.syncs_aggregate?.count,
        onClick: () => onSort(SortKeys.NumSyncs),
        max: "max-content",
        disabled: ({ syncs }) => Boolean(syncs?.length),
        cell: ({ syncs }) => {
          return <SyncsCell definitions={destinationsQueries.data.definitions ?? []} syncs={syncs} />;
        },
      },
      {
        ...LastUpdatedColumn,
        sortDirection: orderBy?.updated_at,
        onClick: () => onSort(SortKeys.UpdatedAt),
      },
    ],
    [destinationsQueries.data.definitions, orderBy, onSort],
  );

  return (
    <Modal
      bodySx={{ bg: "white" }}
      footer={
        <ButtonGroup>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isDisabled={audiencesCount === 0}
            variant="primary"
            onClick={() => {
              submit();
              onClose();
            }}
          >
            Add audiences
          </Button>
        </ButtonGroup>
      }
      isOpen={isOpen}
      sx={{ width: "100%" }}
      title="Add audiences to priority list"
      onClose={onClose}
    >
      <Row mb={4}>
        <Paragraph>
          Select audiences for this priority list. Audiences that are used in other priority lists may not be used.
        </Paragraph>
      </Row>
      <Row align="center" justify="space-between" mb={4}>
        <SearchInput placeholder="Search audiences..." value={search} onChange={(event) => setSearch(event.target.value)} />
        {selectedRows?.length > 0 && (
          <Row color="gray.700">
            <Text>
              {selectedRows.length} {pluralize("audience", selectedRows.length)} selected
            </Text>
          </Row>
        )}
      </Row>
      <Table
        columns={columns}
        data={audiences}
        disabled={isRowDisabled}
        error={Boolean(audiencesForPriorityListsQuery.error)}
        loading={audiencesForPriorityListsQuery.isLoading}
        placeholder={{
          title: "No audiences",
          body: "This parent model may not have any audiences, or all audiences are being used in other priority lists.",
          error: "Priority lists failed to load, please try again.",
        }}
        selectedRows={selectedRows}
        onSelect={onRowSelect}
      />
      <Pagination count={audiencesCount} label="audiences" page={page} rowsPerPage={limit} setPage={setPage} />
    </Modal>
  );
};
