import React, { FC, ReactNode, useMemo, useState } from "react";

import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { Box, Button, Checkbox, Column, Row, Text, SearchInput } from "@hightouchio/ui";
import { orderBy, uniqBy } from "lodash";

import { ModelFiltersQuery } from "src/graphql";
import useQueryState from "src/hooks/use-query-state";
import { QueryTypeDictionary } from "src/utils/models";
import { SyncStatusBadge, SyncStatusToText } from "src/utils/syncs";

interface FilterProps {
  filters: CollapsableFilterProps[];
}

export const Filters: FC<FilterProps> = ({ filters }) => {
  const onClear = () => {
    filters.forEach((filter) => {
      filter.setSelectedOptions(filter.options);
    });
  };

  const canClear = filters.some((filter) => filter.selectedOptions.length !== filter.options.length);

  return (
    <Column>
      <Row align="center" height={9} justify="space-between" px={5}>
        <Text fontWeight="semibold" size="sm" textTransform="uppercase">
          Filters
        </Text>
        {canClear && (
          <Button size="sm" onClick={onClear}>
            Reset
          </Button>
        )}
      </Row>

      {filters.map((filter) => (
        <CollapsableFilter key={filter.title} {...filter} />
      ))}
    </Column>
  );
};

interface FilterOption {
  id: string;
  label?: string;
  renderOption?: () => ReactNode;
}

interface CollapsableFilterProps {
  title: string;
  options: FilterOption[];
  selectedOptions: FilterOption[];
  setSelectedOptions: (option: FilterOption[]) => void;
}

const CollapsableFilter: FC<CollapsableFilterProps> = ({ title, options, setSelectedOptions, selectedOptions }) => {
  const [isOpen, setIsOpen] = useState(options.length !== selectedOptions.length);
  const [search, setSearch] = useState<string>("");

  const Icon = isOpen ? ChevronDownIcon : ChevronRightIcon;

  const filteredOptions = orderBy(
    options?.filter((o) => {
      if (o.label && String(o.label).toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      if (o.id && String(o.id).toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      return false;
    }) || [],
    ["label"],
    ["asc"],
  );

  return (
    <Column>
      <Row
        _active={{ outline: "none" }}
        _focus={{ outline: "none" }}
        _hover={{
          ".icon": {
            color: "gray.900",
          },
          cursor: "pointer",
          color: "forest",
        }}
        align="center"
        as="button"
        color={isOpen ? "gray.900" : "gray.600"}
        gap={2}
        px={5}
        py={1.5}
        transition="all 0.1s"
        width="100%"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Box className="icon" color={isOpen ? "gray.900" : "gray.600"}>
          <Icon strokeWidth={4} width={16} />
        </Box>
        <Text fontWeight="medium">{title}</Text>
      </Row>

      {isOpen && (
        <Column>
          <Column mx={5} pt={1}>
            <SearchInput
              placeholder="Filter values..."
              value={search ?? ""}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </Column>
          {filteredOptions.length === 0 ? (
            <Row ml={5} my={2} sx={{ span: { color: "gray.600" } }}>
              <Text>No options</Text>
            </Row>
          ) : (
            <Column
              borderBottom="solid 1px"
              borderColor="gray.400"
              maxHeight="200px"
              overflowX="hidden"
              overflowY="auto"
              py={2}
            >
              {filteredOptions.map((option) => {
                const isChecked = Boolean(selectedOptions.find((o) => o.id === option.id));
                return (
                  <FilterRow
                    key={option.id}
                    isAll={selectedOptions.length === 1 && selectedOptions[0]?.id === option.id}
                    isChecked={isChecked}
                    option={option}
                    onCheckboxClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (isChecked) {
                        setSelectedOptions(selectedOptions.filter((o) => o.id !== option.id));
                      } else {
                        setSelectedOptions([...selectedOptions, option]);
                      }
                    }}
                    onRowClick={() => {
                      if (selectedOptions.length === 1 && selectedOptions[0]?.id === option.id) {
                        setSelectedOptions(options);
                      } else {
                        setSelectedOptions([option]);
                      }
                    }}
                  />
                );
              })}
            </Column>
          )}
        </Column>
      )}
    </Column>
  );
};

type FilterRowProps = {
  onCheckboxClick: (e: React.MouseEvent<HTMLElement>) => void;
  onRowClick: () => void;
  isChecked: boolean;
  option: FilterOption;
  isAll: boolean;
};

const FilterRow: FC<FilterRowProps> = ({ onCheckboxClick, onRowClick, option, isChecked, isAll }) => {
  const [isCheckboxHovered, setIsCheckboxHovered] = useState(false);
  return (
    <Row
      key={option.id}
      _hover={{
        bg: "gray.200",
        ".action-button": {
          display: "block",
        },
        cursor: "pointer",
      }}
      justifyContent="space-between"
      pos="relative"
      px={5}
      py={1}
      transition="all 0.1s"
      width="100%"
      onClick={onRowClick}
    >
      <Row
        alignItems="center"
        flex={1}
        gap={2}
        overflow="hidden"
        sx={{
          "label > span": { width: "16px", height: "16px", svg: { strokeWidth: "1.5px !important" } },
          "& > div": { display: "flex" },
        }}
      >
        <Checkbox
          isChecked={isChecked}
          onChange={() => {}}
          onClick={onCheckboxClick}
          onMouseEnter={() => {
            setIsCheckboxHovered(true);
          }}
          onMouseLeave={() => {
            setIsCheckboxHovered(false);
          }}
        />
        <Row
          gap={2}
          overflow="hidden"
          sx={{ span: { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" } }}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {option.renderOption ? option.renderOption() : option.label}
        </Row>
      </Row>
      <Row className="action-button" color="gray.600" display="none" pl={2}>
        <Text size="sm">{isCheckboxHovered ? "Toggle" : isAll ? "All" : "Only"}</Text>
      </Row>
    </Row>
  );
};

interface FilterHookProps {
  configResult: FilterOption[];
  queryParamName: string;
}

export function useFilter({ configResult, queryParamName }: FilterHookProps) {
  const [selectedOptions, setSelectedOptions] = useQueryState(queryParamName);

  const getSelectedOptions = () => {
    if (!selectedOptions || selectedOptions.length === 0) {
      return configResult;
    }

    return selectedOptions
      .split(",")
      .map((id) => {
        const option = configResult.find((o) => o?.id?.toString() === id?.toString());
        if (!option) {
          return null;
        }
        return option;
      })
      .filter((o) => !!o) as FilterOption[];
  };

  const result = useMemo(() => {
    return {
      selectedOptions: getSelectedOptions(),
      setSelectedOptions: (options: FilterOption[]) => {
        if (options.length === configResult.length) {
          setSelectedOptions("");
          return;
        }
        setSelectedOptions(options.map((o) => o.id).join(","));
      },
      options: configResult,
    };
  }, [configResult, queryParamName, selectedOptions, setSelectedOptions]);

  return result;
}

export const modelQueryTypeFilterConfig = (models: ModelFiltersQuery["segments"]): FilterOption[] => {
  const items = models
    .filter((s) => !!s.query_type)
    .map(({ query_type }) => ({
      id: query_type!,
      label: QueryTypeDictionary[query_type!],
    }));

  return uniqBy(items, "id");
};

export const syncStatusFilterConfig = (syncs: { status: string | null }[]): FilterOption[] => {
  const items = syncs
    ?.filter(({ status }) => !!status)
    ?.map(({ status }) => {
      const mappedStatus = SyncStatusToText[status!];
      return {
        id: status!,
        label: mappedStatus, // label is only used for search
        renderOption: () => {
          return (
            <Row align="center">
              <SyncStatusBadge status={status} tooltip={false} />
            </Row>
          );
        },
      };
    });

  return uniqBy(items, "id");
};

export const createdByFilterConfig = (
  items: { created_by?: string; created_by_user: { id: string; name: string } | null }[],
): FilterOption[] => {
  const res = items
    .filter((item) => !!(item.created_by || item.created_by_user))
    .map((item) => {
      return {
        id: item.created_by_user?.id || item.created_by || "",
        label: item?.created_by_user?.name || "Unknown",
      };
    });

  return uniqBy(res, "id");
};

export const labelFilterConfig = (items: { tags: Record<string, string> }[]): FilterOption[] => {
  const allLabels: Record<string, string>[] = [];
  items.forEach(({ tags }) => {
    Object.entries(tags).forEach(([key, value]) => {
      const entry = {};
      entry[key] = value;
      allLabels.push(entry);
    });
  });
  const res = allLabels.map((entry) => {
    const obj = Object.entries(entry)[0]!;
    return { label: `${obj[0]}: ${obj[1]}`, id: [obj[0], obj[1]].join(":") };
  });
  return uniqBy(res, "id");
};

export const sourceFilterConfig = (
  syncs: {
    segment: {
      connection: {
        id?: string;
        name?: string;
        definition?: {
          name?: string;
          icon?: string;
        };
      };
    };
  }[],
): FilterOption[] => {
  const items = syncs
    ?.filter(({ segment }) => !!segment?.connection?.id)
    .map(({ segment }) => ({
      id: segment?.connection?.id || "",
      label: `${segment?.connection?.name} ${segment?.connection?.definition?.name}`, // label is only used for search
      renderOption: () => {
        return (
          <>
            {segment?.connection?.definition?.name && (
              <Box
                alt={segment?.connection?.definition?.name}
                as="img"
                flexShrink={0}
                maxHeight="100%"
                objectFit="contain"
                src={segment?.connection?.definition?.icon}
                width="18px"
              />
            )}
            <Text>{segment?.connection?.name}</Text>
          </>
        );
      },
    }));

  return uniqBy(items, "id");
};

export const destinationFilterConfig = (
  syncs: {
    destination: {
      id?: string | null;
      name?: string | null;
      definition?: {
        icon?: string;
        name?: string;
      };
    } | null;
  }[],
): FilterOption[] => {
  const items = syncs.map(({ destination }) => {
    const { id, name, definition } = destination!;

    return {
      id: id || "",
      label: `${name} ${definition?.name}`, // label is only used for search
      renderOption: () => {
        return (
          <>
            <Box
              alt={definition?.name}
              as="img"
              flexShrink={0}
              maxHeight="100%"
              objectFit="contain"
              src={definition?.icon}
              width="18px"
            />
            <Text>{name || definition?.name}</Text>
          </>
        );
      },
    };
  });

  return uniqBy(items, "id");
};
