import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const DoubleArrowLeftIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M 23.460938 22.660156 C 22.738281 23.382812 21.570312 23.382812 20.847656 22.660156 L 11.617188 13.429688 C 10.898438 12.710938 10.898438 11.542969 11.617188 10.820312 L 20.847656 1.589844 C 21.570312 0.867188 22.738281 0.867188 23.460938 1.589844 C 24.179688 2.308594 24.179688 3.480469 23.460938 4.199219 L 15.535156 12.125 L 23.460938 20.050781 C 24.179688 20.773438 24.179688 21.941406 23.460938 22.660156 Z M 12.382812 22.660156 C 11.660156 23.382812 10.492188 23.382812 9.773438 22.660156 L 0.539062 13.429688 C -0.179688 12.710938 -0.179688 11.542969 0.539062 10.820312 L 9.773438 1.589844 C 10.492188 0.867188 11.660156 0.867188 12.382812 1.589844 C 13.101562 2.308594 13.101562 3.480469 12.382812 4.199219 L 4.457031 12.125 L 12.382812 20.050781 C 13.101562 20.773438 13.101562 21.941406 12.382812 22.660156 Z M 12.382812 22.660156" />
    </Icon>
  );
};
