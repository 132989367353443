import { FC } from "react";

import { Box, Button as ThemedButton } from "theme-ui";

import { colors } from "../../../../design/colors";
import { ChangeButton, Popover, Subtitle, Title } from "./index";
import { popoverContent } from "./popover";

type TileProps = {
  popoverContent?: keyof typeof popoverContent;
  popoverDisabled?: boolean;
  popoverIsOpen?: boolean;
  popoverAsTooltip?: boolean;
  active: boolean;
  icon: JSX.Element;
  badge?: JSX.Element;
  title: string;
  subtitle: string | undefined;
  showChange: boolean;
  onChangeClick?: () => void;
  onClick?: () => void;
};

export const Tile: FC<TileProps> = ({
  popoverContent,
  popoverDisabled,
  popoverIsOpen,
  popoverAsTooltip,
  active,
  icon,
  badge,
  title,
  subtitle,
  showChange,
  onClick,
  onChangeClick,
}) => {
  const getTile = () => {
    return (
      <ThemedButton
        role="button"
        sx={{
          alignItems: "center",
          textAlign: "left",
          width: "100%",
          flex: "1",
          color: "base.7",
          px: 4,
          py: 4,
          height: "80px",
          fontSize: 16,
          fontWeight: 500,
          borderRadius: "inherit",
          svg: {
            bg: active ? colors.base[1] : colors.base[1],
            fill: active ? colors.base[4] : colors.base[4],
            borderRadius: "50%",
          },
          ".changeButton": {
            display: "none",
          },
          ":hover": {
            svg: {
              bg: active ? colors.avocado : colors.base[1],
              fill: active ? colors.forest : colors.base[4],
            },
            ".changeButton": {
              display: showChange ? "inline" : "none",
            },
            bg: active ? colors.gray["100"] : "",
          },
          cursor: active ? "pointer" : "auto",
          position: "relative",
        }}
        variant="plain"
        onClick={active && onClick ? onClick : undefined}
      >
        <Box
          sx={{
            color: active ? colors.forest : !showChange ? colors.base[3] : "inherit",
            flexGrow: 1,
            alignItems: "center",
            display: "flex",
          }}
        >
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            {icon}
            <Box sx={{ ml: 4 }}>
              {subtitle && <Subtitle text={subtitle} />}
              <Title title={title} />
            </Box>
          </Box>
          {showChange && typeof onChangeClick === "function" && <ChangeButton onClick={onChangeClick} />}
        </Box>
        {badge && <Box sx={{ position: "absolute", top: "-5px", right: "-5px;" }}>{badge}</Box>}
      </ThemedButton>
    );
  };

  return popoverContent ? (
    <Popover asTooltip={popoverAsTooltip} content={popoverContent} disabled={Boolean(popoverDisabled)} isOpen={popoverIsOpen}>
      {getTile()}
    </Popover>
  ) : (
    getTile()
  );
};
