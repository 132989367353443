import { useEffect, useMemo, FC } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { Text } from "theme-ui";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  NumberOfCondition,
  NumberOperator,
  numberOfOperatorOptions,
  initialPropertyCondition,
  AudienceParent,
} from "src/types/visual";
import { Row } from "src/ui/box";
import { NewSelect } from "src/ui/new-select";

import { AmountInput } from "./amount-input";
import { AttributeSelect } from "./attribute-select";
import {
  ConditionField,
  FilterProps,
  HStack,
  OperatorLabel,
  PlusButton,
  RemoveButton,
  removeSubcondition,
  updateSubconditions,
} from "./condition";
import { validateNumberOfCondition } from "./condition-validation";
import { Filter } from "./filter";

const RelatedTo = {
  operator: NumberOperator.GreaterThan,
  value: 1,
};

const NotRelatedTo = {
  operator: NumberOperator.Equals,
  value: 0,
};

const relatedOptions = [
  { label: "have", value: "related" },
  { label: "does not have", value: "not related" },
];

export const NumberOfFilter: FC<Readonly<FilterProps<NumberOfCondition>>> = (props) => {
  const { appAudienceQueryBuilderValidation } = useFlags();
  const { relationships, condition, onChange, onRemove } = props;

  const filterId = useMemo<string>(uuidv4, []);

  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();

  const filterErrors = getErrors(filterId);
  const modelError = filterErrors?.relationshipId;
  const valueError = filterErrors?.value;

  useEffect(() => {
    if (appAudienceQueryBuilderValidation) {
      setFieldError(filterId, validateNumberOfCondition(condition));
    } else {
      removeErrors([filterId]);
    }

    return () => {
      removeErrors([filterId]);
    };
  }, [appAudienceQueryBuilderValidation, condition.relationshipId, condition.value, filterId]);

  // Don't show related events under the options. Users should use the
  // EventConditionFilter for creating queries based on events.
  const relationshipOptions = relationships
    ?.filter(({ to_model: { event }, merge_columns }) => !event && !merge_columns)
    ?.map(({ id, to_model: { name } }) => ({ value: id, label: name }));

  const relatedModel = relationships?.find(({ id }) => id === condition.relationshipId)?.to_model;

  const isNotRelated = condition.value === 0 && condition.operator === NumberOperator.Equals;
  const relatedValue = isNotRelated ? "not related" : "related";
  const relatedLabel = relatedOptions?.find(({ value }) => relatedValue === value)?.label;
  const operatorLabel = numberOfOperatorOptions.find(({ value }) => value === condition.operator)?.label;

  return (
    <>
      <HStack gap={2} sx={{ alignItems: "flex-start" }}>
        <Filter
          content={
            <>
              <NewSelect
                error={Boolean(valueError)}
                options={relatedOptions}
                placeholder="Select relation"
                sx={{ flex: "0 0 auto" }}
                value={relatedValue}
                width={140}
                onChange={(value) => {
                  if (value === "related") {
                    onChange(RelatedTo);
                  } else {
                    onChange(NotRelatedTo);
                  }
                }}
              />
              {isNotRelated ? null : (
                <>
                  <NewSelect
                    options={numberOfOperatorOptions}
                    placeholder="Filter on"
                    sx={{ flex: "0 0 auto" }}
                    value={condition.operator}
                    width={200}
                    onChange={(operator) => onChange({ operator })}
                  />
                  <AmountInput
                    sx={{ width: "100px" }}
                    type="number"
                    value={condition.value}
                    onChange={(value) => {
                      onChange({ value });
                    }}
                  />
                </>
              )}
            </>
          }
          error={valueError}
        >
          <OperatorLabel>{`${relatedLabel}${isNotRelated ? "" : ` ${operatorLabel} ${condition.value}`}`}</OperatorLabel>
        </Filter>
        <AttributeSelect
          error={modelError}
          options={relationshipOptions}
          placeholder="Select a model"
          value={condition.relationshipId}
          onChange={(relationshipId) => {
            onChange({
              relationshipId,
            });
          }}
        />
        <RemoveButton onRemove={onRemove} />
      </HStack>
      {condition.subconditions?.map((subcondition, index) => (
        <Row key={index} sx={{ alignItems: "flex-start", pl: 12, width: "100%" }}>
          <Text sx={{ flexShrink: 0, my: 2, color: "base.4", width: "3.5rem" }}>{index === 0 ? "WHERE" : "AND"}</Text>
          <ConditionField
            {...props}
            columns={relatedModel?.filterable_audience_columns}
            condition={subcondition}
            // this is okay since we only use property filters
            parent={relatedModel as unknown as AudienceParent}
            traits={undefined}
            onChange={updateSubconditions(onChange, condition.subconditions, index)}
            onRemove={removeSubcondition(onChange, condition.subconditions, index)}
          />
        </Row>
      ))}
      <Row sx={{ pl: 12 }}>
        <PlusButton
          disabled={!condition.relationshipId}
          onClick={() => {
            onChange({ subconditions: [...(condition.subconditions ?? []), initialPropertyCondition] });
          }}
        >
          property
        </PlusButton>
      </Row>
    </>
  );
};
