import { FC, useState, useEffect } from "react";

import { Text } from "theme-ui";

import { Wrap, Column, Row } from "src/ui/box";
import { ReloadButton } from "src/ui/button";
import { TableIcon } from "src/ui/icons";
import { SearchInput } from "src/ui/input";
import { Spinner } from "src/ui/loading";
import { UseSourcesResult, SourceTile } from "src/utils/sources";

import { SelectorRow } from "./selector-row";

type Source = UseSourcesResult["data"][0];

type Props = {
  objectDefinitions: any;
  reload: () => void;
  onChange: (table: string) => void;
  table: string | null | undefined;
  source: Source;
  loading: boolean;
};

export const TableSelector: FC<Readonly<Props>> = ({ source, loading, table, onChange, objectDefinitions, reload }) => {
  const [search, setSearch] = useState<string>("");
  const [initialReload, setInitialReload] = useState(false);

  const customerObjectDefinitions = objectDefinitions?.filter(
    ({ object_definition_group }) => object_definition_group?.name?.toLowerCase() !== "hightouch_planner",
  );

  const tables = search
    ? customerObjectDefinitions?.filter(({ name, object_definition_group }) => {
        const lowercaseName = name?.toLowerCase();
        const displayName = object_definition_group?.name
          ? `${object_definition_group?.name?.toLowerCase()}.${lowercaseName}`
          : lowercaseName;

        return displayName.includes(search.toLowerCase());
      })
    : customerObjectDefinitions;

  useEffect(() => {
    if (!loading && !objectDefinitions?.length && !initialReload) {
      reload();
      setInitialReload(true);
    }
  }, [loading, objectDefinitions, initialReload]);

  return (
    <Column sx={{ width: "100%", flex: 1, overflow: "hidden", bg: "white", pt: 4, border: "small" }}>
      <Row sx={{ width: "100%", alignItems: "center", justifyContent: "space-between", px: 2, pb: 2, borderBottom: "small" }}>
        <SearchInput placeholder="Search tables..." value={search} onChange={setSearch} />
        <Wrap spacing={2} sx={{ alignItems: "center", flexShrink: 0 }}>
          <SourceTile source={source} />
          {table && <Text sx={{ color: "base.6" }}>({table})</Text>}
          <ReloadButton loading={loading} onClick={() => reload()} />
        </Wrap>
      </Row>
      {loading ? (
        <Column sx={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
          <Spinner size={64} />
        </Column>
      ) : !tables?.length ? (
        <Column sx={{ flex: 1, alignItems: "center", mt: 4, p: 4 }}>
          <Text sx={{ fontSize: 3, fontWeight: "semi" }}>{search ? "No tables match your search" : "No tables found"}</Text>
          <Text sx={{ color: "base.5", mt: 2 }}>Try refreshing to populate the tables</Text>
        </Column>
      ) : (
        <Column sx={{ overflow: "auto", flex: 1 }}>
          {tables.map(({ name, object_definition_group }) => {
            const key = `${object_definition_group ? `${object_definition_group.name}.` : ""}${name}`;
            const active = key === table;

            return (
              <SelectorRow key={key} icon={<TableIcon />} selected={active} onClick={() => onChange(key)}>
                {key}
              </SelectorRow>
            );
          })}
        </Column>
      )}
    </Column>
  );
};
