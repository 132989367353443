import { FC, useEffect, ReactNode, useState } from "react";

import parseDecimalNumber from "parse-decimal-number";
import { ThemeUIStyleObject } from "theme-ui";

import { Column } from "src/ui/box";
import { FieldError } from "src/ui/field";
import { Input } from "src/ui/input";

type Props = {
  error?: ReactNode;
  value: any;
  onChange: (value: any) => void;
  type?: "string" | "number";
  disabled?: boolean;
  placeholder?: string;
  sx?: ThemeUIStyleObject;
};

export const AmountInput: FC<Readonly<Props>> = ({
  error,
  value,
  onChange,
  type = "string",
  placeholder = "amount...",
  disabled = false,
  sx = {},
}) => {
  let initialDisplayVal = value;
  if (type === "number" && typeof value === "number") {
    initialDisplayVal = value.toLocaleString();
  }

  const [displayVal, setDisplayVal] = useState(initialDisplayVal);
  useEffect(() => {
    setDisplayVal(initialDisplayVal);
  }, [initialDisplayVal]);

  const isInvalid = type === "number" && displayVal && typeof value !== "number";

  return (
    <Column>
      <Input
        disabled={disabled}
        error={isInvalid || Boolean(error)}
        placeholder={placeholder}
        size="small"
        sx={{ maxWidth: "200px", ...sx }}
        value={displayVal}
        onChange={(val) => {
          // TODO: come back to update this. Make it work for all countries and stuff
          if (type === "number") {
            if (!val || !/^[0-9.,]+$/.test(val) || val.endsWith(".") || val.endsWith(",")) {
              setDisplayVal(val);
              onChange(null);
            } else {
              const withoutCommas = val.replace(/,/g, "");
              const parsedNumber = parseDecimalNumber(withoutCommas);
              if (isNaN(parsedNumber)) {
                setDisplayVal(val);
                onChange(null);
              } else {
                setDisplayVal(parsedNumber.toLocaleString());
                onChange(parsedNumber);
              }
            }
          } else if (type === "string") {
            setDisplayVal(val);
            onChange(val);
          }
        }}
      />
      {error && <FieldError error={error} sx={{ fontWeight: "semi", fontSize: 0 }} />}
    </Column>
  );
};
