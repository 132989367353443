import { ReactElement, useEffect, useState } from "react";

import { useToast } from "@hightouchio/ui";
import { Box } from "theme-ui";
import * as Yup from "yup";

import { useMongodbSourceListCollectionsQuery, useMongodbSourceListDatabasesQuery } from "src/graphql";

import { Button } from "../../../../ui/button";
import { Field } from "../../../../ui/field";
import { Select } from "../../../../ui/select";
import { getlineNumberofChar } from "../../../../utils/json";
import { Editor } from "../../../editor";
import { CustomQuery, CustomQueryFormProps, CustomQueryViewProps } from "../custom-query";

function QueryForm({ source, query, onChange, setError }: Readonly<CustomQueryFormProps>): ReactElement<any, any> {
  const [filterErrorLine, setFilterErrorLine] = useState<number>();
  const {
    data: databases,
    isLoading: databaseLoading,
    error: databaseError,
    refetch: listDatabases,
  } = useMongodbSourceListDatabasesQuery({ connectionId: String(source.id) });

  const {
    data: collections,
    isLoading: collectionLoading,
    error: collectionError,
    refetch: listCollections,
  } = useMongodbSourceListCollectionsQuery({ connectionId: String(source.id), database: String(query?.database) });

  const { toast } = useToast();

  useEffect(() => {
    if (!query?.type) {
      onChange({ type: "mongodb" });
    }
  }, [query]);

  useEffect(() => {
    setError(databaseError);
  }, [databaseError]);

  useEffect(() => {
    setError(collectionError);
  }, [collectionError]);

  const checkValidJson = (filter: string) => {
    try {
      const parsed = JSON.parse(filter);
      const formattedFilter = JSON.stringify(parsed, null, 2);
      onChange({ ...query, filter: formattedFilter });
      setFilterErrorLine(0);

      toast({
        id: "check-json",
        title: "JSON filter is valid",
        variant: "success",
      });
    } catch (e) {
      setFilterErrorLine(getlineNumberofChar(filter, parseInt(e.message.split("at position ")[1])));

      toast({
        id: "check-json",
        title: "JSON filter is invalid",
        variant: "error",
      });
    }
  };

  return (
    <>
      <Field label="Which database do you want to query?" size="large">
        <Select
          isError={Boolean(databaseError)}
          isLoading={databaseLoading}
          options={databases?.mongodbSourceListDatabases?.map((d) => {
            return {
              label: d.name,
              value: d.name,
            };
          })}
          placeholder="Select a database"
          reload={listDatabases}
          sx={{ maxWidth: "280px" }}
          value={query?.database || null}
          width="280px"
          onChange={(selected) => {
            onChange({ ...query, database: selected?.value });
          }}
        />
      </Field>
      <Field label="Which collection/view do you want to query?" size="large">
        <Select
          isError={Boolean(collectionError)}
          isLoading={collectionLoading}
          options={collections?.mongodbSourceListCollections?.map((d) => {
            return {
              label: d.name + `${d.isView ? " (View)" : ""}`,
              value: d.name,
            };
          })}
          placeholder="Select a collection/view"
          reload={listCollections}
          sx={{ maxWidth: "280px" }}
          value={query?.collection || ""}
          width="280px"
          onChange={(selected) => {
            onChange({ ...query, collection: selected.value });
          }}
        />
      </Field>
      <Field label="What filter do you want to apply to your query?" optional={true} size="large">
        <Box
          sx={{ width: "100%", border: "small", borderBottomLeftRadius: 1, borderBottomRightRadius: 1, height: "200px", mb: 4 }}
        >
          <Editor
            highlightErroredLine={filterErrorLine}
            language="json"
            placeholder={`{ "account_id": "20" }`}
            value={String(query?.filter ?? "")}
            onChange={(value) => {
              onChange({ ...query, filter: value ?? "" });
            }}
          />
        </Box>

        <Button
          label="Validate"
          variant="secondary"
          onClick={() => {
            checkValidJson(String(query?.filter));
          }}
        />
      </Field>
    </>
  );
}

function QueryView(props: Readonly<CustomQueryViewProps>): ReactElement<any, any> | null {
  const configuration = props.source.config;
  return (
    <>
      <Field inline label="Cluster URL">
        {String(configuration["host"])}
      </Field>
      <Field inline label="Database">
        {String(props.query["database"])}
      </Field>
      <Field inline label="Collection">
        {String(props.query["collection"])}
      </Field>
      {props.query["filter"] && (
        <Field inline label="Filter">
          <Editor language="json" readOnly={true} value={String(props.query["filter"])} />
        </Field>
      )}
    </>
  );
}

export default {
  QueryForm,
  QueryView,
  querySchema: Yup.lazy<CustomQuery | undefined>(() => {
    return Yup.object().shape({
      type: Yup.string().required().equals(["mongodb"]),
      database: Yup.string().required(),
      collection: Yup.string().required(),
    });
  }),
};
