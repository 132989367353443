import { FC, useEffect } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { ReactQueryDevtools } from "react-query/devtools";

import { CommandBar } from "src/components/commandbar";
import { Maintenance } from "src/components/maintenance";
import { Mobile } from "src/components/mobile";
import { Router, ForceReload } from "src/components/router";
import { HeaderHeightProvider } from "src/contexts/header-height-context";
import { useUser } from "src/contexts/user-context";
import * as analytics from "src/lib/analytics";
import { Intercom } from "src/lib/intercom";
import { Logrocket } from "src/lib/logrocket";

import { useWorkspaceActions } from "./commands";
import { LockOutModal } from "./lock-out-modal";

export const App: FC = () => {
  const { user } = useUser();
  const { appMaintenance } = useFlags();

  useEffect(() => {
    if (user && !user.not_member_of_current_workspace) {
      analytics.identify(String(user.id), {
        email: user.email,
        name: user.name,
      });

      if (user.current_workspace_id) {
        analytics.group(user.current_workspace_id);
      }
    }
  }, [user]);

  if (appMaintenance) {
    return <Maintenance />;
  }

  return (
    <HeaderHeightProvider>
      <Router>
        <ForceReload />
        <CommandBar>
          <Commands />
          <Intercom />
          <Logrocket />

          <div id="portalAnchor"></div>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          <Mobile />
          <LockOutModal />
        </CommandBar>
      </Router>
    </HeaderHeightProvider>
  );
};

const Commands: FC = () => {
  useWorkspaceActions();

  return null;
};
