import { Box, Heading } from "@hightouchio/ui";

import { GitActivity } from "src/components/git/git-activity";
import { Settings, DbtGitSync } from "src/components/settings";
import { Section } from "src/ui/section";

export const VersionControl = () => {
  return (
    <Settings route="version-control">
      <Box display="flex" flexDirection="column" gap={20}>
        <DbtGitSync />

        <Section header={<Heading>Git activity</Heading>}>
          <GitActivity />
        </Section>
      </Box>
    </Settings>
  );
};
