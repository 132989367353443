import { AudienceSplit } from "@hightouch/lib/query/visual/types";

import { AudienceSplitsInsertInput } from "src/graphql";

export const constructUpdateSplitsMutationPayload = ({
  audienceId,
  addSplits = [],
  removeSplits = [],
}: {
  audienceId: string | number;
  addSplits: AudienceSplit[];
  removeSplits: AudienceSplit[];
}) => {
  const addPayload: AudienceSplitsInsertInput[] = addSplits.map((split: AudienceSplit) => ({
    segment_id: String(audienceId),
    percentage: String(split.percentage), // Hasura is expecting `Numeric` type which is a string
    friendly_name: split.friendly_name,
    column_value: split.friendly_name,
    destination_instance_splits: {
      data: (split.destination_instance_ids ?? []).map((syncId) => ({
        destination_instance_id: String(syncId),
      })),
    },
  }));

  const removePayload: string[] = removeSplits.map((split) => String(split.id));

  return { addPayload, removePayload };
};
