import { FC } from "react";

import { HighlightStyle, syntaxHighlighting } from "@codemirror/language";
import { Compartment } from "@codemirror/state";
import { tags } from "@lezer/highlight";
import * as diff from "diff";

import { Editor } from "src/components/editor";
import { Row } from "src/ui/box";

import { highlightChanges } from "./editor/highlight-changes";

interface Props {
  diffs: diff.Change[];
}

const highlightChangesCompartment = new Compartment();

const theme = syntaxHighlighting(
  HighlightStyle.define([
    {
      tag: tags.string,
      color: "purple",
    },
    {
      tag: tags.bracket,
      color: "gray",
    },
    { tag: tags.number, color: "gray" },
  ]),
);

export const Diff: FC<Readonly<Props>> = ({ diffs }) => {
  const output = diffs.map((d) => d.value).join("");

  return (
    <Row sx={{ border: "small" }}>
      <Editor
        readOnly
        extensions={[theme, highlightChangesCompartment.of(highlightChanges(diffs))]}
        language="json"
        value={output}
      />
    </Row>
  );
};
