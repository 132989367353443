import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const CalendarMonoIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M12,16.3L12,16.3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h0c0.7,0,1.3-0.6,1.3-1.3S12.7,16.3,12,16.3z" />
      <path d="M7.3,16.3L7.3,16.3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h0c0.7,0,1.3-0.6,1.3-1.3S8,16.3,7.3,16.3z" />
      <path d="M7.3,12.1L7.3,12.1c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h0c0.7,0,1.3-0.6,1.3-1.3S8,12.1,7.3,12.1z" />
      <path d="M12,12.1L12,12.1c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h0c0.7,0,1.3-0.6,1.3-1.3S12.7,12.1,12,12.1z" />
      <path d="M16.8,12.1L16.8,12.1c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h0c0.7,0,1.3-0.6,1.3-1.3S17.5,12.1,16.8,12.1z" />
      <path d="M16.8,16.3L16.8,16.3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h0c0.7,0,1.3-0.6,1.3-1.3S17.5,16.3,16.8,16.3z" />
      <path
        d="M21.3,3.4c-0.9-0.9-2.2-1.5-3.6-1.6V1.3C17.6,0.6,17,0,16.3,0C15.6,0,15,0.6,15,1.3v0.4H9V1.3
		C9,0.6,8.4,0,7.7,0S6.4,0.6,6.4,1.3v0.5C3.1,2.2,1.1,4.5,1.1,8v9.7c0,3.9,2.5,6.4,6.4,6.4h9.1c3.9,0,6.4-2.4,6.4-6.3V8
		C22.9,6.1,22.4,4.5,21.3,3.4z M6.4,4.4v0.4c0,0.7,0.6,1.3,1.3,1.3S9,5.5,9,4.8V4.3H15v0.5c0,0.7,0.6,1.3,1.3,1.3
		c0.7,0,1.3-0.6,1.3-1.3V4.4c0.6,0.1,1.3,0.4,1.8,0.9c0.6,0.6,0.9,1.5,0.9,2.7H3.6C3.7,5.9,4.6,4.7,6.4,4.4z M16.5,21.4H7.5
		c-1.7,0-3.8-0.7-3.8-3.8v-7.1h16.7v7.2C20.4,20.2,19.1,21.4,16.5,21.4z"
      />
    </Icon>
  );
};
